import axios from "../config/axios";

const liveChat = {
  userChatList: (formData) => axios.post("/livechat/LiveChat/getChatList", formData), 
  joinIntoTheConversation: (formData) => axios.post("/livechat/LiveChat/joinIntoTheConversation", formData),      
  quickTextList: (formData) => axios.post("/livechat/LiveChat/quickTextList", formData),      
  addQuickText: (formData) => axios.post("/livechat/LiveChat/addQuickText", formData),      
  updateQuickText: (formData) => axios.post("/livechat/LiveChat/updateQuickText", formData),
  deleteQuickText: (formData) => axios.post("/livechat/LiveChat/deleteQuickText", formData),
  getQuickListForLiveChat: (formData) => axios.post("/livechat/LiveChat/getQuickListForLiveChat", formData),
  NotificationMemberList: (formData) => axios.post("/livechat/LiveChat/NotificationMemberList", formData),
  sendDataToWebhookLiveChat: (formData) => axios.post("/livechat/LiveChat/sendDataToWebhookLiveChat", formData),
  transferTheChat: (formData) => axios.post("/livechat/LiveChat/transferTheChat", formData),
  soundNotificationSettings: (formData) => axios.post("/livechat/LiveChat/soundNotificationSettings", formData),
  sendChatTranscript: (formData) => axios.post("/livechat/LiveChat/sendChatTranscript", formData),
  updateRead: (formData) => axios.post("/livechat/LiveChat/updateRead", formData),

  
  
        
};



export default liveChat;
