import axios from "../config/axios";

const marketing = {

  savebucket: (params) =>
    axios
      .post(`/marketing/contacts/savebucket`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getbucket: (params) =>
    axios
      .get(`/marketing/contacts/getbucket?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addfield: (params) =>
    axios
      .post(`/marketing/contacts/addfield`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  savefinal: (params) =>
    axios
      .post(`/marketing/contacts/savefinal`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateBucketName: (params) =>
    axios
      .post(`/marketing/contacts/updateBucketName`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteBucketName: (params) =>
    axios
      .post(`/marketing/contacts/deleteBucketName`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getBucketData: (params) =>
    axios
      .post(`/marketing/contacts/getBucketData`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* start pramanshu */
  addContactList: (params) =>
    axios
      .post(`/marketing/contacts/addContactList`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkEmail: (params) =>
    axios
      .post(`/marketing/contacts/checkEmail`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkPhone: (params) =>
    axios
      .post(`/marketing/contacts/checkPhone`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkPhoneSms: (params) =>
    axios
      .post(`/marketing/contacts/checkPhoneSms`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getContactLists: (params) =>
    axios
      .get(`/marketing/contacts/getContactLists?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  editContact: (params) =>
    axios
      .post(`/marketing/contacts/editContact`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteContact: (params) =>
    axios
      .post(`/marketing/contacts/deleteContact`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  exportContactList: (params) =>
    axios
      .post(`/marketing/contacts/exportContactList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getContactDetails: (params) =>
    axios
      .post(`/marketing/contacts/getContactDetails`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getBucketDetail: (params) =>
    axios
      .post(`/marketing/contacts/getBucketDetail`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

    getVariable: (params) =>
    axios
      .post(`/marketing/contacts/getVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveVariable: (params) =>
    axios
      .post(`/marketing/contacts/saveVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addVariable: (params) =>
    axios
      .post(`/marketing/contacts/addVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  

  savecontactZapier: (params) =>
    axios
      .post(`/marketing/contacts/savecontactZapier`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getwebhookZapier: (params) =>
    axios
      .post(`/marketing/contacts/getwebhookZapier`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getContactLabel: (params) =>
    axios
      .post(`/marketing/contacts/getContactLabel`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  addContactListByCSV: (formData) => axios.post("/marketing/contacts/addContactListByCSV", formData),

  getContactLabel: (params) =>
    axios
      .post(`/marketing/contacts/getContactLabel`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getContactLabelAndDataOnEdit: (formData) =>
    axios.post("/marketing/contacts/getContactLabelAndDataOnEdit", formData),

  listPlayBook: (params) =>
    axios
      .get(`/marketing/playbook/listPlayBook?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  playbookinfo: (params) =>
    axios
      .get(`/marketing/playbook/playbookinfo`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addplaybook: (params) =>
    axios
      .post(`/marketing/playbook/addplaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  editplaybook: (params) =>
    axios
      .post(`/marketing/playbook/editplaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteplaybook: (params) =>
    axios
      .post(`/marketing/playbook/deleteplaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),



  uploadPluginAssets: (params) =>
    axios
      .post(`/marketing/playbook/uploadPluginAssets`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getUploadedPluginAssets: (params) =>
    axios
      .post(`/marketing/playbook/getUploadedPluginAssets`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteUploadPluginAsset: (params) =>
    axios
      .post(`/marketing/playbook/deleteUploadPluginAsset`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateActionPlaybook: (params) =>
    axios
      .post(`/marketing/playbook/updateActionPlaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* End of code */

  yourCallList: (params) =>
    axios
      .get(`/marketing/Calllist/yourCallList?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  exportCallList: (params) =>
    axios
      .post(`/marketing/Calllist/exportCallList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  getyourNotes: (params) =>
    axios
      .post(`/marketing/Calllist/getyourNotes`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  addYourNotes: (params) =>
    axios
      .post(`/marketing/Calllist/addYourNotes`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  getTeammembar: (params) =>
    axios
      .post(`/marketing/Calllist/getTeammembar`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit 
  updateMass: (params) =>
    axios
      .post(`/marketing/Calllist/updateMass`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  //Rohit
  getCalllistteam: (params) =>
    axios
      .post(`/marketing/Calllist/getCalllistteam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  addteam: (params) =>
    axios
      .post(`/marketing/Calllist/addteam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  deleteYourNotes: (params) =>
    axios
      .post(`/marketing/Calllist/deleteYourNotes`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  getNotesemailcontent: (params) =>
    axios
      .post(`/marketing/Calllist/getNotesemailcontent`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  assignTeammembar: (params) =>
    axios
      .post(`/marketing/Calllist/assignTeammembar`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  updateCalllistteam: (params) =>
    axios
      .post(`/marketing/Calllist/updateCalllistteam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */
  //Rohit
  deleteTeam: (params) =>
    axios
      .post(`/marketing/Calllist/deleteTeam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */

  //Rohit
  getAllteammembar: (params) =>
    axios
      .post(`/marketing/Calllist/getAllteammembar`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  playBookBranchWiseStats: (params) =>
    axios
      .get(`/marketing/Analiytics/playBookBranchWiseStats/?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  exportplayBookBranchWiseStats: (params) =>
    axios
      .post(`/marketing/Analiytics/exportplayBookBranchWiseStats`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  //Rohit
  playBookBranchWiseStatsDetail: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/getPlaybookAnalyticsDetails`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getEmailStatus: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/getEmailAnalyticsDetails`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),    
  //Rohit
  exportplayBookBranchWiseStatsDetail: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/exportplayBookBranchWiseStatsDetail`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */

  getassociateCampaigns: (params) =>
    axios
      .post(`/marketing/contacts/getassociateCampaigns`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  subscribeUnSubscribe: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/subscribeUnSubscribe`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getDomainList: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/getDomainList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deletecolumn: (params) =>
    axios
      .post(`/marketing/contacts/deletecolumn`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  getTimezoneData: (params) =>
    axios
      .post(`/marketing/playbook/getTimezoneData`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getCurrentTime: (params) =>
    axios
      .post(`/marketing/playbook/currentTime`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkwebhooksUrl: (params) =>
    axios
      .post(`/marketing/contacts/checkwebhooksUrl`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getWebhooks: (params) =>
      axios
        .post(`/marketing/Segment/getWebhooks`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

getSegment: (params) =>
  axios
    .post(`/marketing/Segment/getSegment`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

  updateWebhooks: (params) =>
        axios
          .post(`/marketing/Segment/updateWebhooks`, params)
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),

  getAddsegment: (params) =>
          axios
            .post(`/marketing/Segment/getAddsegment`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
  
editSegment: (params) =>
      axios
        .post(`/marketing/Segment/editSegment`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

        

deleteData: (params) =>
  axios
    .post(`/marketing/Segment/deleteData`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

  addSegmentData: (params) =>
      axios
        .post(`/marketing/Segment/addSegmentData`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

updateSegmentData: (params) =>
  axios
    .post(`/marketing/Segment/updateSegmentData`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

  changeOnOff: (params) =>
    axios
      .post(`/marketing/Segment/changeOnOff`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getSegmentRule: (params) =>
    axios
      .post(`/marketing/Segment/getSegmentRule`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  

    
};

export default marketing;
