import React from 'react'
import DatatableList from './SendingQueueTable/DatatableList'
export default class SendingQueue extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount =()=>{
        
    }

    render(){
        return(
            <DatatableList playbook_id={this.props.playbook_id} branch_id={this.props.branch_id}>

            </DatatableList>
        )
    }
}