import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link, Redirect } from "react-router-dom";
let follow;
let needtocall;
let archive;
let teamSelected;
export const DatatableRow = props => (
   //@Rohit
  <Table.Row>
    <Table.Cell>{props.list.title}</Table.Cell>
    {/* <Table.Cell>{props.list.email_subject}</Table.Cell> */}
    <Table.Cell ><div contentEditable='true' dangerouslySetInnerHTML={{ __html: props.list.email_subject }}></div></Table.Cell>
    <Table.Cell>{props.list.total_send_email}</Table.Cell>
    <Table.Cell>{props.list.total_open_email}</Table.Cell>
    <Table.Cell>{props.list.total_send_clicks}</Table.Cell>
    <Table.Cell>
        <Link to={`../PlayBookAnalytics/${props.list.b_id}/${props.pid}`}>Analytics Detail</Link>
     </Table.Cell>
  </Table.Row>
);
DatatableRow.propTypes = {
  list: PropTypes.object.isRequired, 
  onSubmitFilter: PropTypes.func.isRequired,  
  
};


