import axios from "../../config/axios";
const reviews = {
  textTempl: (params) =>
    axios
      .get(`/reviewmanagement/reviews/textTempl?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  emailTempl: (params) =>
    axios
      .get(`/reviewmanagement/reviews/emailTempl?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  addTemplates: (params) =>
    axios
      .post(`/reviewmanagement/reviews/addTemplates`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addReviews: (params) =>
    axios
      .post(`/reviewmanagement/reviews/addReviews`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addReviewsNew: (params) =>
    axios
      .post(`/reviewmanagement/ReviewsNew/addReviews`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteTeam: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/delete`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  ResendDeviceKey: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/ResendDeviceKey`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateFbPageInfo: (params) =>
    axios
      .post("/reviewmanagement/reviews/updateFbPageInfo", params)
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => console.log(err)),

  getSubscribedPages: (params) =>
    axios
      .post("/reviewmanagement/reviews/getSubscribedPages", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getSubscribedGooglePages: (params) =>
    axios
      .post("/reviewmanagement/reviews/getSubscribedGooglePages", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteFbPage: (params) =>
    axios
      .post("/reviewmanagement/reviews/deleteFbPageInfo", params)
      .then((resp) => (resp ? resp.data : null)),

  addFbLink: (params) =>
    axios
      .post("/reviewmanagement/reviews/addFbLink", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteGPage: (params) =>
    axios
      .post("/reviewmanagement/reviews/deleteGPageInfo", params)
      .then((resp) => (resp ? resp.data : null)),

  getFbPages: (params) =>
    axios
      .get("/reviewmanagement/socialLogin/socialpagefb", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  custReviewsdataFromBackend: (params) =>
    axios
      .post("/reviewmanagement/reviews/custReviewsdataFromBackend", {
        ...params,
      })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  exportYourreviewCSVdata: (params) =>
    axios
      .post("/reviewmanagement/reviews/exportYourreviewCSVdata", {
        ...params,
      })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  SinglReview: (params) =>
    axios
      .post("/reviewmanagement/reviews/SinglReview", { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  EditReview: (params) =>
    axios
      .post("/reviewmanagement/reviews/EditReview", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  DeleteReview: (params) =>
    axios
      .post("/reviewmanagement/reviews/DeleteReview", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  ReplyReview: (params) =>
    axios
      .post("/reviewmanagement/reviews/ReplyReview", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addCustomerReview: (params) =>
    axios
      .post("/reviewmanagement/reviews/addCustomerReview", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  avgReview: (params) =>
    axios
      .post("/reviewmanagement/reviews/avgReview", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  avgReviewAll: (params) =>
    axios
      .post("/reviewmanagement/reviews/avgReviewAll", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  ReviewAllCount: (params) =>
    axios
      .post("/reviewmanagement/reviews/ReviewAllCount", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  teamActivityList: (params) =>
    axios
      .post("/reviewmanagement/reviews/load_team_activity_list", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  teamActivityListAuto: (params) =>
    axios
      .post("/reviewmanagement/reviews/load_team_activity_list_auto", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  downloadTeamActivity: (params) =>
    axios
      .post("/reviewmanagement/reviews/download_team_activity", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  downloadTeamActivityAuto: (params) =>
    axios
      .post("/reviewmanagement/reviews/download_team_activity_auto", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getTeamInfo: (params) =>
    axios
      .post("/reviewmanagement/reviews/get_team_info", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getSchedule: (params) =>
    axios
      .post("/reviewmanagement/reviews/get_schedule", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  socialLink: (params) =>
    axios
      .post("/reviewmanagement/reviews/socialLink", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  AssignReviews: (params) =>
    axios
      .post("/reviewmanagement/reviews/AssignReviews", params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getFacebookList: (params) =>
    axios
      .post(`/reviewmanagement/reviews/get_facebook_page_list`, params)
      .then((resp) => (resp ? resp.data : null)),

  addLinks: (params) =>
    axios
      .post(`/reviewmanagement/reviews/addLinks`, params)
      .then((resp) => (resp ? resp.data : null)),

  addReviewsLinkFacebook: (params) =>
    axios
      .post(`/reviewmanagement/reviews/addReviewsLinkFacebook`, params)
      .then((resp) => (resp ? resp.data : null)),

  LinkListFacebook: (params) =>
    axios
      .post(`/reviewmanagement/reviews/LinkListFacebook`, params)
      .then((resp) => (resp ? resp.data : null)),

  deleteReviewsLinkFacebook: (params) =>
    axios
      .post(`/reviewmanagement/reviews/deleteReviewsLinkFacebook`, params)
      .then((resp) => (resp ? resp.data : null)),

  addReviewsLinkGoogle: (params) =>
    axios
      .post(`/reviewmanagement/reviews/addReviewsLinkGoogle`, params)
      .then((resp) => (resp ? resp.data : null)),

  LinkListGoogle: (params) =>
    axios
      .post(`/reviewmanagement/reviews/LinkListGoogle`, params)
      .then((resp) => (resp ? resp.data : null)),

  deleteSchedule: (params) =>
    axios
      .post(`/reviewmanagement/reviews/deleteSchedule`, params)
      .then((resp) => (resp ? resp.data : null)),

  updateStartStop: (params) =>
    axios
      .post(`/reviewmanagement/reviews/updateStartStop`, params)
      .then((resp) => (resp ? resp.data : null)),

  updateScheduleDate: (params) =>
    axios
      .post(`/reviewmanagement/reviews/updateScheduleDate`, params)
      .then((resp) => (resp ? resp.data : null)),

  deleteReviewsLinkGoogle: (params) =>
    axios
      .post(`/reviewmanagement/reviews/deleteReviewsLinkGoogle`, params)
      .then((resp) => (resp ? resp.data : null)),

  checkGooglelink: (params) =>
    axios
      .post(`/reviewmanagement/reviews/checkGooglelink`, params)
      .then((resp) => (resp ? resp.data : null)),

  checkFBlink: (params) =>
    axios
      .post(`/reviewmanagement/reviews/checkFBlink`, params)
      .then((resp) => (resp ? resp.data : null)),

  addManuallyreview: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/addManuallyreview`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getManuallyreview: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/getManuallyreview`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getAutomatedreview: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/getAutomatedreview`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteManuallyreview: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/deleteManuallyreview`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getManuallyreviewbranch: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/getManuallyreviewbranch`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addNewBranch: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/addNewBranch`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  setDefaultTemplate: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/setDefaultTemplate`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  setDefaultTemplateAuto: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/setDefaultTemplateAuto`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  addNewButton: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/addNewButton`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addReviewLink: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/addReviewLink`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getBranchdetails: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/getBranchdetails`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addCopybranch: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/addCopybranch`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteBranchbyid: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/deleteBranchbyid`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getTeam: (params) =>
    axios
      .get(`/reviewmanagement/manuallyreviews/getTeam`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  aadTeammember: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/aadTeammember`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateBranch: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/updateBranch`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteButton: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/deleteButton`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteReviewbyid: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/deleteReviewbyid`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getEditreviewlink: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/getEditreviewlink`, {
        ...params,
      })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  settingsUpdate: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/settingsUpdate`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getWorkflow: (params) =>
    axios
      .get(`/reviewmanagement/ReviewsNew/getWorkflow?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  setCheckedOnOFF: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/setCheckedOnOFF`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getCheckedOnOFF: (params) =>
    axios
      .post(`/reviewmanagement/manuallyreviews/getCheckedOnOFF`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


      updateposition: (params) =>
      axios
          .post(`/reviewmanagement/manuallyreviews/updateposition`, params)
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),







};

export default reviews;
