import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import GridList from "../../../Grid";
import { Message, Segment } from 'semantic-ui-react'
import api from "../../../../api";
import DeliveryRates from './DeliveryRates';
import ContactEngagement from "./ContactEngagement";
const AnalyticDetails = ({ branch_id_pwsid, pid }) => {

    const [loading, setloading] = useState(false);
    const [responsemsg, setresponsemsg] = useState(null);
    const [resData, setresData] = useState(null);
    const eventSecond = ['clicked', 'complained', 'opened', 'unsubscribed'];
    const [refreshGrid, setrefreshGrid] = useState(false)
    const [tableTrue, setTableTrue] = useState(false);
    const [paramsdata, setparam] = useState(null);
    const [dataFalse, setdataFalse] = useState(false);

    useEffect(() => {

        getAnalyticData();

        if (paramsdata) {
            console.log(paramsdata);
        }

    }, [paramsdata]);

    const typeList = {
        'Delivered': 'Delivered',
        'Hard-bounced': 'PermanentFailure',
        'Marked as spam': 'SpamComplaint',
        'Soft-bounced': 'TemporaryFailure',
        'Unsubscribed': 'Unsubscribes',

    };
    const getAnalyticData = async () => {
        setloading(true);
        api.marketing.playBookBranchWiseStatsDetail({
            branch_id: branch_id_pwsid,
            pid: pid
        }).then((resp) => {
            console.log(resp);
            setloading(false);
            console.log('resp', resp);
            if (resp.status === true) {
                setresData(resp);
            } else {
                setdataFalse(true);
            }
        }).catch((err) => {
            setloading(false);
            console.log(err);
        });
    }



    const onBoxClick = (type, data, columnValue = '') => {
        setTableTrue(false);
        const checkData = data == '' ? 0 : +data;
        if (checkData === 0) {
            return false;
        }
        setparam(prevValue => {
            return {
                branch_id: branch_id_pwsid,
                type: typeList.hasOwnProperty(type) ? typeList[type] : columnValue
            };
        });
        // setparam(param);
        setTableTrue(true);
        window.scrollTo(0, document.body.scrollHeight);
    }

    const getDataSource = (params) => {
        setrefreshGrid(true);
        params.branch_id = paramsdata.branch_id;
        params.type = paramsdata.type
        console.log(params);
        return api.marketing.getEmailStatus(params)
    };

    const columnLists = [
        { text: "S.No", key: "id", sort: false },
        { text: "Email", key: "email", sort: false },
        { text: "Date", key: "date", sort: false },

    ];

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    return (
        <Segment
            style={{ width: "100%", paddingRight: "20px", paddingLeft: "20px" }}
        >
            {loading && <div className="ui loading form"></div>}
            {responsemsg &&
                <Message
                    color="green"

                    style={{
                        // display: this.state.responsemsg ? "block" : "none",
                        marginTop: 30,
                    }}
                >
                    {responsemsg}
                </Message>
            }
            <div className="col-md-12">
                <div className="">
                    <div className="kt-container" style={{ backgroundColor: "#fff" }}>
                        <div className="yr-pagetitle d-flex justify-content-between">
                            {resData &&
                                <div className="left yr-titleSelect kt-margin-t-10 kt-margin-b-10 flex-wrap justify-content-center">

                                    <Link to={'../../playBookBranchWiseStats/' + resData.playbookId} className="btn linear-gradient yr-add-new float-right flex-m-n mb-3">
                                        <i className="fas fa-chevron-left" style={{ fontSize: "12px" }}></i>Back to my email detail stats</Link>
                                    <div className="yr-allselect">
                                        <span>Detailed stats: {resData.playbookName}: {resData.branchName} </span>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
                    <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-details-analy">
                                {dataFalse &&
                                    <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className='col-md-4' style={{ fontSize: 16 }}>
                                            <Message
                                                color="red"

                                                style={{
                                                    // display: this.state.responsemsg ? "block" : "none",
                                                    marginTop: 30,
                                                }}
                                            >
                                                Analytic details of this branch not available.
                                            </Message>
                                        </div>
                                    </div>
                                }
                                {resData &&
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="yr-deliverrates">
                                                <h3>Delivery rates</h3>
                                                <DeliveryRates
                                                    analytics={resData.analyticDetails}
                                                    onBoxClick={onBoxClick}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="yr-contactengagement">
                                                <h3>Contact engagement</h3>
                                                <ul>
                                                    {Object.entries(resData.analyticDetails).map(([anaKey, anaValue], i) => (
                                                        eventSecond.includes(anaKey) && <ContactEngagement
                                                            analyticsKey={anaKey}
                                                            analyticsValue={anaValue ? anaValue : 0} totalEmail={resData.analyticDetails.totalEmail}
                                                            totalDelivered={resData.analyticDetails.delivered}
                                                            onBoxClick={onBoxClick}
                                                            key={i.toString()}
                                                        />
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="kt-content kt-grid__item kt-grid__item--fluid yr-details-analy">
                                <div className='row'>
                                    <div className='col-md-6 offset-md-4'>
                                        {tableTrue &&
                                            <>
                                                <Segment>
                                                    <h3>{getKeyByValue(typeList, paramsdata.type) ?? paramsdata.type}</h3>
                                                    <div className="email-data-status">
                                                        <GridList
                                                            dataSource={(params) => getDataSource(params)}
                                                            dataColumns={columnLists}
                                                            refreshGrid={refreshGrid}
                                                        >
                                                        </GridList>
                                                    </div>
                                                </Segment>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Segment>
    )
}

export default AnalyticDetails