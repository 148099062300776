import React, { useState } from "react";
import "../../../../../Modal/dialog.css";

const DialogBoxContactVia = ({ obj, modalCloseContactViaBox, openContactAddEdit, addContactZapier }) => {
	const [option, setOption] = useState("static")
	const setOptionValue = e => {
		setOption(e)
	}
	const openContactAddEditHere = e => {

		//alert(option);
		if (option == 'Zapier') {
			addContactZapier();
			setOption("static");
		} else {
			modalCloseContactViaBox(false, '')
			openContactAddEdit(option)
			setOption("static")
		}
	}
	const modalCloseContactViaBoxHere = () => {
		modalCloseContactViaBox(false, '')
		setOption("static")

	}
	return (
		<React.Fragment>
			<div
				className={obj.modalContactViaObject.show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: obj.modalContactViaObject.show ? "block" : "none" }}
				id="modalContactViaID"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					{obj.modalContactViaObject.loading && <div className="ui loading form"></div>}
					<div className="modal-content yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseContactViaBoxHere()}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{obj.modalContactViaObject.title}</h5>
						</div>
						<div className="modal-body">
							<form className="kt-form">
								<div className="kt-portlet__body">
									<div className="kt-section kt-section--first">
										<div className="kt-section__body">
											<div className="form-group row">
												<label className="col-lg-12 col-form-label">Please select one of the below option</label>
												<div className="col-lg-12">
													<div className="kt-radio-inline">
														<div className="mb-1">
															<label className="kt-radio inalign pl-0">
																<input type="radio" onClick={() => setOptionValue("static")} name="opt" checked={(option == "static") ? "checked" : ""} value="left" />
																<span></span>
															</label><span className="align-label ml-3">Static field </span>
														</div>
														<div className="mb-1">
															<label className="kt-radio inalign pl-0">
																<input type="radio" name="opt" onClick={() => setOptionValue("csv")} checked={(option == "csv") ? "checked" : ""} value="right" />
																<span></span>
															</label><span className="align-label ml-3">Import according to the fields being used</span>
														</div>
														<div className="mb-1">
															<label className="kt-radio inalign pl-0">
																<input type="radio" name="opt" onClick={() => setOptionValue("Zapier")} checked={(option == "Zapier") ? "checked" : ""} value="left" />
																<span></span>
															</label><span className="align-label ml-3">Add Contacts Via Zapier</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="kt-portlet__foot">
									<div className="kt-form__actions">
										<div className="row">
											<div className="col-lg-12 d-flex justify-content-end">
												<button type="reset" onClick={() => modalCloseContactViaBoxHere()} className="btn linear-gradient-cancel yr-cancelbtn mg-r" style={{ color: 'black' }}><span> Cancel </span></button>
												<button type="button" onClick={() => openContactAddEditHere()} className="btn linear-gradient yr-submitbtn" data-toggle="modal" data-target="#kt_modal_8" data-dismiss="modal" aria-label="Close">Continue</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxContactVia;