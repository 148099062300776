import React from "react";
import "../../../../../Modal/dialog.css";
import { Table } from 'semantic-ui-react';


const DialogBoxAssocCompaigns = ({ show_detail, title, message, modalType, modalClose, buttonmsg, modalbuttofun, loading,campaign_data }) => {
   
	return (
		<React.Fragment>
			<div
				className={show_detail ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show_detail ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
				   {loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model" style={{paddingBottom: 20}}>
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title pl-0">{title}</h5>
						</div>
						<div className="modal-body px-4">
						<div>
						{ campaign_data.length > 0 ? (
							<div>
								<table id="additional_captured" style={{marginTop:"20px"}} style={{border:'none',marginTop:"20px"}}>
									<tbody>
										{typeof campaign_data!='undefined' && campaign_data.length>0 && campaign_data.map((obj,index)=>{
										return (
												<tr>
														<td colspan="2" className=' add-gradient mb-3 activebuttonlink linear-gradient' style={{textTransform:"capitalize"}}>{obj.title}</td>
									
												</tr>
												)
											}) 
										}
									</tbody>
								</table>
							</div>
						 	):(<div>No campaigns data available</div>)
						}

							</div></div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxAssocCompaigns;