import React from "react";
import { Label, Message } from "semantic-ui-react";
// import "./profile.css";
import {
  Form,
  Input,
  TextArea,
  Checkbox,
  Radio,
  RadioGroup,
  Dropdown,
  Select,
} from "formsy-semantic-ui-react";
import { Button } from "semantic-ui-react";
import validationRule from "../../../validation";
import utilFunc from "../../../util_funs";

import axios from "../../../config/axios";
import api from "../../../api";
import { addValidationRule } from "formsy-react";

validationRule.isRequired();
validationRule.isRequiredOldPass();
validationRule.isRequiredNewPass();
validationRule.isRequiredNewConPass();
validationRule.newPasswordAndConfirmPasswordEqulaity();
validationRule.usPhoneLength();
validationRule.minCustomLength(6);




class UserProfile extends React.Component {
  

  state = {};
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      company: "",
      email: "",
      phone: "",
      website: "",
      oldpassword: "",
      newpassword: "",
      cnpassword: "",
      loading: false,
      formSuccess: false,
      formError: false,
      validationMessage: "",
      userToaster: false,
      userToasterColor: "",
      renderOption1:[],
      renderOption2:[],
      renderOptionSelectedVal:"",
      isd_code:"",
      address:""
    };
  }

  phoneFormat = (e) => {
    let phone = e.target.value;
    let p = phone;
    var obj = this;

    p = p.replace(/[^\d]*/gi, "");

    if (p.length < 3) {
      setTimeout(function () {
        obj.setState({ phone: p });
      }, 100);
    } else if (p.length == 3) {
      let pp = p;
      let d4 = p.indexOf("(");
      let d5 = p.indexOf(")");
      if (d4 == -1) {
        pp = "(" + pp;
      }
      if (d5 == -1) {
        pp = pp + ")";
      }
      obj.setState({ phone: pp });
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      setTimeout(function () {
        obj.setState({ phone: pp });
      }, 100);
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      setTimeout(function () {
        obj.setState({ phone: finalPhone });
      }, 100);
    }
  };

  renderDropDownDefault = (defaultCountryCodeArr,countryId = null)=>{
      return defaultCountryCodeArr.map((option, index) => {
        return (
          <option key={index} value={option.country_code} selected={countryId == option.country_code ? 'selected':''} >
            {option.country_name}
          </option>
        );
      });
  }

  renderDropDownRemaining = (remailCountryCodeArr,countryId = null)=>{
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option key={index} value={option.country_code} selected={countryId == option.country_code ? 'selected':''} >
          {option.country_name}
        </option>
      );
    });
}
  componentDidMount() {
    api.user
      .getProfile()
      .then((resp) => {
        if (resp.data && resp.data.data.profileDetails) {
          const profileUser = resp.data.data.profileDetails[0];
          const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
          const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
          this.setState({
            fname: profileUser.name,
            phone: profileUser.phone,
            company: profileUser.company,
            email: profileUser.email,
            website: profileUser.website,
            user_id: profileUser.user_id,
            renderOptionSelectedVal:profileUser.country_code,
            isd_code:profileUser.country_code,
            address:profileUser.address
          });
         this.setState({renderOption1:defaultCountryCodeArr})
         this.setState({renderOption2:remailCountryCodeArr})
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "phone") this.phoneFormat(e);
  };
  onValidSubmit = (e) => {
    //e.preventDefault();
    this.setState({ loading: true });
    let forms = {
      fname: this.state.fname,
      company: this.state.company,
      email: this.state.email,
      phone: this.state.phone,
      website: this.state.website,
      oldpassword: this.state.oldpassword,
      newpassword: this.state.newpassword,
      user_id: this.state.user_id,
      isd_code: this.state.isd_code,
      address: this.state.address,
    };
    api.user
      .saveProile(forms)
      .then((res) => {
        if (res.data.status == false) {
          if (res.data.message_flag == "password_not_found") {
            this.setState({
              validationMessage: "Current password is incorrect.",
            });
          }
          if (res.data.message_flag == "email_already_exist") {
            this.setState({
              validationMessage:
                "The email you changed is already associted with other account.",
            });
          }
          // this.setState({ formError: true })
          this.setState({ userToasterColor: "red" });
        } else {
          this.setState({
            validationMessage: "Profile updated successfully.",
          });
          // this.setState({ formSuccess: true })
          this.setState({ userToasterColor: "green" });
          this.setState({ oldpassword: "", newpassword: "", cnpassword: "" });
        }
        this.setState({ userToaster: true });
        this.setState({ loading: false });
        utilFunc.scrollTop(800);
        let instance = this;
        setTimeout(function () {
          instance.setState({ userToaster: false });
          // instance.setState({ formSuccess: false })
          // instance.setState({ formError: false })
        }, 10000);
      })
      .catch((err) => console.log(err));
    //ajax code
  };
  render() {
    const errorLabel = <div className="red" pointing />;
    return (
      <div
        className="tab-pane active"
        id="kt_portlet_base_demo_1_1_tab_content"
        role="tabpanel"
        >
        <div className="">
          <div className="tab-content">
            <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster ? "block" : "none",
                marginTop: 30,
              }}
            >
              {this.state.validationMessage}
            </Message>
            <div className="mh-profilePage">
                {this.state.loading && <div class="ui loading form"></div>}
                <Form
                noValidate
                autoComplete="off"
                onValidSubmit={this.onValidSubmit}
                className="kt-form kt-form--label-right"
                >
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Form.Group widths="equal">
                      <Form.Input
                        autoComplete="off"
                        className="col-lg-12 col-md-12 col-sm-12 form-group"
                        name="fname"
                        onChange={this.handleChange}
                        value={this.state.fname}
                        placeholder="Full Name"
                        validations="isRequired"
                        validationErrors={{
                          isRequired: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        autoComplete="off"
                        value={this.state.company}
                        name="company"
                        onChange={this.handleChange}
                        placeholder="Company Name"
                        validations="isRequired"
                        validationErrors={{
                          isRequired: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        autoComplete="off"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Primary Email Address"
                        validations="isEmail,isRequired"
                        validationErrors={{
                          isEmail: "Please enter a valid email address.",
                          isRequired: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <select class="form-control mh-selectOPt" name="isd_code" onChange={this.handleChange} id="isd_code_id" >                                                            
                        {  this.renderDropDownDefault(this.state.renderOption1,this.state.renderOptionSelectedVal) }
                        <optgroup label="Other countries">
                        { this.renderDropDownRemaining(this.state.renderOption2,this.state.renderOptionSelectedVal) } 
                        </optgroup>
                      </select>
                      <Form.Input
                        fluid
                        autoComplete="off"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder="Phone Number"
                        validations="isRequired,usPhoneLength"
                        validationErrors={{
                          isRequired: "This field is required.",
                          usPhoneLength:"Phone number must be at least 10 numbers."
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        autoComplete="off"
                        name="website"
                        value={this.state.website}
                        onChange={this.handleChange}
                        placeholder="Website"
                        validations="isRequired"
                        validationErrors={{
                          isRequired: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        autoComplete="off"
                        type="password"
                        onChange={this.handleChange}
                        value={this.state.oldpassword}
                        name="oldpassword"
                        placeholder="Current Password"
                        validations="isRequiredOldPass"
                        validationErrors={{
                          isRequiredOldPass: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        autoComplete="off"
                        type="password"
                        onChange={this.handleChange}
                        value={this.state.newpassword}
                        name="newpassword"
                        placeholder="New Password"
                        validations="isRequiredNewPass,minCustomLength"
                        validationErrors={{
                          isRequiredNewPass: "This field is required.",
                          minCustomLength: "Please enter at least 6 characters.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        autoComplete="off"
                        type="password"
                        onChange={this.handleChange}
                        value={this.state.cnpassword}
                        name="cnpassword"
                        placeholder="Confirm New Password"
                        validations="isRequiredNewConPass,newPasswordAndConfirmPasswordEqulaity,minCustomLength"
                        validationErrors={{
                          isRequiredNewConPass: "This field is required.",
                          minCustomLength: "Please enter at least 6 characters.",
                          newPasswordAndConfirmPasswordEqulaity:
                            "New password and confirm password should be same.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </Form.Group>
                   
                    <Form.Group widths="equal">
                      <Form.TextArea
                        fluid
                        autoComplete="off"
                        
                        onChange={this.handleChange}
                        value={this.state.address}
                        name="address"
                        placeholder="Address"
                        
                      />
                    </Form.Group>




                  </div>
                </div>

                <Form.Input
                  fluid
                  autoComplete="off"
                  type="hidden"
                  name="user_id"
                  value={this.state.user_id}
                />
                <div className="row">
                  <div className="col-md-12 text-right">
                    <button
                      name="submit"
                      type="submit"
                      className="btn linear-gradient yr-submitbtn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
