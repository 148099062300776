import React from "react";
import "./LiveChat.css";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import LeftSideAccordion from "./components/LeftSideAccordion";
import {
  toggleNavigation,
  setPageTitle,
  setMessage,
  toggleBreadcrum,
} from "../../../store/Page";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ScrollToBottom from "react-scroll-to-bottom";
import axios from "../../../config/axios";
import api from "../../../api";
import DialogBoxHipaaPin from "../DialogBoxHipaaPin";
import LiveChatBoxFooter from "./components/LiveChatBoxFooter";
import DialogBox from "./Modal/DialogBox";
import OpenQuickText from "./components/OpenQuickText";
import QuickSelect from "./components/QuickSelect";
import NotificationMemberList from "./components/NotificationMemberList";
import DialogBoxConfirmation from "./components/DialogBoxConfirmation";
import DialogBoxSoundNotificationSetting from "./components/DialogBoxSoundNotificationSetting";

class LiveChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      chat: [],
      chatLeftList: [],
      chatRepondedTimeWise: [],
      chatRemain: [],
      assetPath: props.user.assetPath,
      name: [],
      waittime: [],
      showLeftBarArr: [],
      hippaOn: false,
      modalstatusAddEdit: false,
      modalstatusHIPAA: false,
      modaltitle: "",
      hipaaValidateObject: {},
      user: props.user,
      blankBoxStyle: "block",
      automatedChatWaitTimeBar: "none",
      displayEmogies: "none",
      textAreaVal: [],
      show: false,
      addQuickText: false,
      quickTextOption: [],
      NotificationMemberListOption: [],
      quickSelectVal: "",
      NotificationMemberSelectVal: "",
      join_into_conversation_fromID: "",
      join_into_conversation_assignedID: "",
      join_into_conversation: "",
      anonymous_user_id: "",
      displayTextArea: "none",
      tmpVarVal: "",
      LeftSideAccordionWaitActive: false,
      LeftSideAccordionCurrentlyChattingActive: false,
      DialogBoxConfirmation: {},
      showSoundNotiDialog: false,
      modalstatusNotiAandMisc: false,
      soundNotiAndSettingmodalData: {
        sound_notification: props.user.sound_notification,
        sound_notificationChecked:
          props.user.sound_notification == 1 ? true : false,
        first_time_sound_notification: props.user.first_sound_notification,
        first_time_sound_notificationChecked:
          props.user.first_sound_notification == 1 ? true : false,
      },
      loading: false
    };

    this.myRef = React.createRef();
    var stateObjRef = this;
    setInterval(function () {
      if (stateObjRef.state.waittime.length > 0) {
        for (var i in stateObjRef.state.waittime) {
          var actualDateAndTime = stateObjRef.state.waittime[i].actualDateAndTime;
          var currentTime = Math.floor(Date.now() / 1000);
          var diff = currentTime - actualDateAndTime;
          if (diff > 3600) {
            try {
              stateObjRef.state.chat = stateObjRef.state.chat.filter(function (obj) {
                return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
              });
              stateObjRef.state.chatLeftList = stateObjRef.state.chatLeftList.filter(function (obj) {
                return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
              });


            } catch (e) {
              stateObjRef.setState({
                chat: stateObjRef.state.chat,
                chatLeftList: stateObjRef.state.chatLeftList,
              })
            }
            try {
              stateObjRef.state.chatRepondedTimeWise = stateObjRef.state.chatRepondedTimeWise.filter(function (obj) {
                return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
              });
              stateObjRef.setState({
                chatRepondedTimeWise: stateObjRef.state.chatRepondedTimeWise,
              })
            } catch (e) {

            }

            try {
              stateObjRef.state.waitTime = stateObjRef.state.waitTime.filter(function (obj) {
                return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
              });
              stateObjRef.setState({
                waitTime: stateObjRef.state.waitTime,
              })
            } catch (e) {

            }

            if (i == (stateObjRef.state.waittime.length - 1)) {
              var auid = "";
              try {
                var auid = stateObjRef.state.anonymous_user_id;
                var l = stateObjRef.state.chat.find(v => v.anonymous_user_id == auid).length;
                if (l > 0) {
                  auid = auid;
                }
              } catch (e) {
                auid = "";
              }
              if (auid == "") {
                if (stateObjRef.state.chat.length != 0) {
                  auid = stateObjRef.state.chat[0].anonymous_user_id;
                } else {
                  auid = ""
                }
              }
              if (auid == "") {
                stateObjRef.setState({
                  blankBoxStyle: "block",
                  displayTextArea: "none"

                })

              } else {
                setTimeout(function () {
                  try { stateObjRef.state.chat.find(v => v.anonymous_user_id == auid).displayChatContainer = 'block'; } catch (e) { console.log(e) }
                  try { stateObjRef.state.chat.find(v => v.anonymous_user_id == auid).displayWholeChatContainer = ''; } catch (e) { }
                  try { stateObjRef.state.chatLeftList.find(v => v.anonymous_user_id == auid).leftBarClass = 'kt-notification-v2__item mh-chatLeftBarActive'; } catch (e) { }
                  try { stateObjRef.state.chatRepondedTimeWise.find(v => v.anonymous_user_id == auid).leftBarClass = 'kt-notification-v2__item mh-chatLeftBarActive'; } catch (e) { }
                  stateObjRef.setState({
                    chat: stateObjRef.state.chat,
                    chatLeftList: stateObjRef.state.chatLeftList,
                    chatRepondedTimeWise: stateObjRef.state.chatRepondedTimeWise,
                  })
                }, 2000)
              }
            }

          }

          // stateObjRef.state.waittime[i].time = stateObjRef.state.waittime[i].time + 10;
          stateObjRef.state.waittime[i].displayTime = stateObjRef.secondsToHms(diff);
        }
        stateObjRef.setState({
          waittime: stateObjRef.state.waittime
        });
      }

    }, 10000)
  }

  secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h : "0";
    var mDisplay = m > 0 ? m : "0";
    var sDisplay = s > 0 ? s : "0";
    var unit = "";
    if (hDisplay != 0) unit = "Hr";
    else if (mDisplay != 0) unit = "Min";
    else unit = "Sec";
    return (
      ("0" + hDisplay).slice(-2) +
      " : " +
      ("0" + mDisplay).slice(-2) +
      " : " +
      ("0" + sDisplay).slice(-2) +
      " " +
      unit
    );
  };

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);
  tConvert = (time) => {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time.splice(-1, 1);
      time[4] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  dateTime = (dt) => {
    var date = new Date(dt * 1000).toString();
    var time = date.split(" ")[4];
    //alert(dt)
    time = this.tConvert(time);
    var newDate =
      date.split(" ")[1] +
      " " +
      date.split(" ")[2] +
      " " +
      date.split(" ")[3] +
      " " +
      time;
    return newDate;
  };
  // shouldComponentUpdate(nextProps, nextState) {
  //   return false;
  // }
  componentDidMount = () => {
    const stateObjRef = this;
    this.props.socket.on("refresh chatdata_agent_firsttime", function (msg) {
      console.log(msg)
      // chat arraysdas
      msg["detectedSourceLanguage"] = "en";
      let dataMsg = JSON.parse(msg.message);
      msg["message"] = dataMsg[0];
      let msgLength = dataMsg.length;
      if (stateObjRef.state.chat.length == 0) {
        stateObjRef.setState({
          displayTextArea: "",
          anonymous_user_id: msg.anonymous_user_id,
        });
        msg["displayChatContainer"] = "block";
        msg["displayWholeChatContainer"] = "";
      } else {
        msg["displayChatContainer"] = "none";
        msg["displayWholeChatContainer"] = "none";
      }
      if (stateObjRef.state.hippaOn == true) msg["hippaOn"] = "block";
      else msg["hippaOn"] = "none";

      msg["join_into_conversation"] = "0";
      msg["join_into_conversation_fromID"] = "";
      msg["join_into_conversation_assignedID"] = "";
      msg["leftBarClass"] = "kt-notification-v2__item";
      msg["closeConversation"] = "n";
      msg["read"] = "mh-Unread";


      stateObjRef.state.chatLeftList.unshift(msg);
      stateObjRef.state.chat.push(msg);

      // for name Arr
      const object = {
        anonymous_user_id: msg.anonymous_user_id,
        name: msg.name,
        hippaOn: msg.hippaOn,
        welcomeMsg: msg.welcomeMsg,
      };
      stateObjRef.state.name.push(object);

      // for waittime arr
      const wtObject = {
        anonymous_user_id: msg.anonymous_user_id,
        time: 0,
        displayTime: "00 : 00 :00 Sec",
        actualDateAndTime: msg.message.time,
      };
      stateObjRef.state.waittime.push(wtObject);

      // for remain chat
      stateObjRef.state.chatRemain[msg.anonymous_user_id] = [];
      if (msgLength > 1) {
        for (let i = 1; i < msgLength; i++) {
          var obj = {
            anonymous_user_id: msg.anonymous_user_id,
            button_color: msg.button_color,
            cahtversion: msg.cahtversion,
            chatBot_id: msg.chatBot_id,
            chat_logo: msg.chat_logo,
            company: msg.company,
            detectedSourceLanguage: msg.detectedSourceLanguage,
            from: "",
            hippaOn: msg.hippaOn,
            liveusercreatedon: "",
            message: dataMsg[i],
            name: msg.name,
            username: msg.username,
            welcomeMsg: msg.welcomeMsg,
          };
          stateObjRef.state.chatRemain[msg.anonymous_user_id].push(obj);
        }
      }

      //for Misc
      stateObjRef.state.textAreaVal[msg.anonymous_user_id] = "a";

      stateObjRef.setState({
        chat: stateObjRef.state.chat,
        chatLeftList: stateObjRef.state.chatLeftList,
        name: stateObjRef.state.name,
        waittime: stateObjRef.state.waittime,
        blankBoxStyle: "none",
        automatedChatWaitTimeBar: "block",
        chatRemain: stateObjRef.state.chatRemain,
        displaEmogies: stateObjRef.state.displaEmogies,
        textAreaVal: stateObjRef.state.textAreaVal,
      });
    });

    this.props.socket.on("refresh chatdata_agent", function (msg) {
      console.log("message", msg)
      stateObjRef.state.chatRemain[msg.anonymous_user_id].push(msg);
      stateObjRef.setState({
        chatRemain: stateObjRef.state.chatRemain,
      });
    });

    this.props.socket.on("refresh chat_user_name", function (msg) {
      stateObjRef.state.name = stateObjRef.state.name.filter(function (obj) {
        return obj.anonymous_user_id !== msg.anonymous_user_id;
      });

      var hippaOn = stateObjRef.state.chat.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).hippaOn;

      // if (stateObjRef.state.hippaOn == true) 
      // msg["hippaOn"] = "block";
      // else 
      // msg["hippaOn"] = "none";
      msg["hipaaOn"] = hippaOn;
      const object = {
        anonymous_user_id: msg.anonymous_user_id,
        name: msg.name,
        hippaOn: hippaOn,
        welcomeMsg: msg.welcomeMsg,
      };
      console.log(object)
      stateObjRef.state.name.push(object);
      stateObjRef.setState({
        name: stateObjRef.state.name,
      });
    });

    this.props.socket.on("refresh sibling_chat", function (msg) {
      if (stateObjRef.state.tmpVarVal == msg.tmpVarVal) {
      } else {

        if (typeof msg.jumpIn !== undefined && msg.jumpIn == true) {
          stateObjRef.siblingJumpin(msg)
        }

        stateObjRef.state.chatRemain[msg.anonymous_user_id].push(msg);
        stateObjRef.setState({
          chatRemain: stateObjRef.state.chatRemain,
        });
      }
    });

    this.props.socket.on("user_typing refresh", function (msg) {
      try {
        document.getElementById(
          "loader-" + msg.anonymous_user_id
        ).style.display = "block";
      } catch (e) { }
    });

    this.props.socket.on("user_typing_stop refresh", function (msg) {
      try {
        document.getElementById(
          "loader-" + msg.anonymous_user_id
        ).style.display = "none";
      } catch (e) { }
    });

    // set page title configuration
    this.props.setPageTitle({
      title: "Live Chat",
      subHeaderTitle: "Conversations",
      navigationName: "liveChat",
    });

    this.props.toggleNavigation({ toggle: true });
    this.props.toggleBreadcrum({ toggle: false });

    // get hipaa info
    api.user
      .getHippaConfBots({ chatBot_id: this.props.user.chatBot_id })
      .then((resp) => {
        if (resp.data && resp.data.data.hippaConfBot) {
          var hippaBots = resp.data.data.hippaConfBot;
          if (hippaBots.includes(this.props.user.chatVersion)) {
            this.setState({ hippaOn: true });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });

    //get live chat list on refresh the page
    api.liveChat
      .userChatList({ chatBot_id: this.props.user.chatBot_id })
      .then((resp) => {
        if (resp.data && resp.data.status) {
          if (resp.data.data.chat.length > 0) {
            this.setState({ loading: true })
            var anonymous_user_id = "";
            var LeftSideAccordionWaitActive = false;
            var LeftSideAccordionCurrentlyChattingActive = false;
            if (resp.data.data.AutomatedChatFirstAnoId != "0") {
              anonymous_user_id = resp.data.data.AutomatedChatFirstAnoId;
              LeftSideAccordionWaitActive = true;
              LeftSideAccordionCurrentlyChattingActive = false;
              try {
                resp.data.data.chatLeftList.find(
                  (v) => v.anonymous_user_id == anonymous_user_id
                ).leftBarClass =
                  "kt-notification-v2__item mh-chatLeftBarActive";
              } catch (e) { }
            } else if (resp.data.data.currentlyChattingFirstAnoId != "0") {
              anonymous_user_id = resp.data.data.currentlyChattingFirstAnoId;
              LeftSideAccordionWaitActive = false;
              LeftSideAccordionCurrentlyChattingActive = true;
              try {
                resp.data.data.chatRepondedTimeWise.find(
                  (v) => v.anonymous_user_id == anonymous_user_id
                ).leftBarClass =
                  "kt-notification-v2__item mh-chatLeftBarActive";
              } catch (e) { }
            }
            try {
              resp.data.data.chat.find(
                (v) => v.anonymous_user_id == anonymous_user_id
              ).displayChatContainer = "block";
            } catch (e) {
              console.log(e);
            }
            try {
              resp.data.data.chat.find(
                (v) => v.anonymous_user_id == anonymous_user_id
              ).displayWholeChatContainer = "";
            } catch (e) { }
            var join_into_conversation = resp.data.data.chat.find(
              (v) => v.anonymous_user_id == anonymous_user_id
            ).join_into_conversation;
            var join_into_conversation_fromID = resp.data.data.chat.find(
              (v) => v.anonymous_user_id == anonymous_user_id
            ).join_into_conversation_fromID;
            var join_into_conversation_assignedID = resp.data.data.chat.find(
              (v) => v.anonymous_user_id == anonymous_user_id
            ).join_into_conversation_assignedID;

            var closeConversation = resp.data.data.chat.find(
              (v) => v.anonymous_user_id == anonymous_user_id
            ).closeConversation;

            stateObjRef.setState(
              {
                chat: resp.data.data.chat,
                chatLeftList: resp.data.data.chatLeftList,
                name: resp.data.data.name,
                chatRemain: resp.data.data.chatRemain,
                blankBoxStyle: "none",
                automatedChatWaitTimeBar: "block",
                textAreaVal: resp.data.data.textAreaVal,
                waittime: resp.data.data.waitTime,
                chatRepondedTimeWise: resp.data.data.chatRepondedTimeWise,
                join_into_conversation_fromID: join_into_conversation_fromID,
                join_into_conversation_assignedID: join_into_conversation_assignedID,
                join_into_conversation: join_into_conversation,
                closeConversation: closeConversation,
                anonymous_user_id: anonymous_user_id,
                displayTextArea: "",
                LeftSideAccordionWaitActive: LeftSideAccordionWaitActive,
                LeftSideAccordionCurrentlyChattingActive: LeftSideAccordionCurrentlyChattingActive,
              },
              this.completeSetState
            );
          } else {
            stateObjRef.setState({
              LeftSideAccordionWaitActive: true,
              LeftSideAccordionCurrentlyChattingActive: false,
            });
          }
        } else {
        }
      })
      .catch((e) => {
        console.log(e);
      });

    this.setQuickTextForLiveChat();
    this.NotificationMemberList();
  };

  siblingJumpin = (msg) => {
    var d = new Date();
    var dateUTC = d.toUTCString();
    var dateUTCUnixTimeStamp = new Date(dateUTC).getTime() / 1000;
    var hippaOn = "none";
    if (this.state.hippaOn == true) {
      hippaOn = "block";
    }
    var leftBarClass = "kt-notification-v2__item";
    var join_into_conversation = "1";
    var findAnoId = 0;
    try {
      var findAnoIdOnj = this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      );
      Object.size = function (obj) {
        var size = 0,
          key;
        for (key in obj) {
          if (obj.hasOwnProperty(key)) size++;
        }
        return size;
      };

      findAnoId = Object.size(findAnoIdOnj);
    } catch (e) { }

    const object = {
      anonymous_user_id: msg.anonymous_user_id,
      hippaOn: hippaOn,
      leftBarClass: leftBarClass,
      join_into_conversation: join_into_conversation,
      lastRespondedAt: "" + dateUTCUnixTimeStamp + "",
    };
    if (findAnoId == 0) {
      this.state.chatRepondedTimeWise.push(object);
    } else {
      try {
        this.state.chatRepondedTimeWise.find(
          (v) => v.anonymous_user_id == msg.anonymous_user_id
        ).lastRespondedAt = "" + dateUTCUnixTimeStamp + "";
      } catch (e) { }
    }
    this.state.waittime.find(
      (v) => v.anonymous_user_id == msg.anonymous_user_id
    ).time = "0";
    this.state.waittime.find(
      (v) => v.anonymous_user_id == msg.anonymous_user_id
    ).displayTime = "00 : 00 : 00 Sec";
    this.state.waittime.find(
      (v) => v.anonymous_user_id == msg.anonymous_user_id
    ).actualDateAndTime = dateUTCUnixTimeStamp;
    this.state.chatRepondedTimeWise.sort((a, b) =>
      Number(a.lastRespondedAt) < Number(b.lastRespondedAt) ? 1 : -1
    );

    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).join_into_conversation_fromID = '0';
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).join_into_conversation_assignedID = '0'
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).join_into_conversation = 1;
    } catch (e) { }
    try {
      this.state.chatLeftList.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).join_into_conversation = 1;
    } catch (e) { }

    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }

    try {
      this.state.chatLeftList.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }


    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == msg.anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }


    this.setState({
      chatRepondedTimeWise: this.state.chatRepondedTimeWise,
      waittime: this.state.waittime,
      join_into_conversation_fromID: '0',
      join_into_conversation_assignedID: '0',
      join_into_conversation: 1,
      chat: this.state.chat,
      chatLeftList: this.state.chatLeftList
    });
  }

  completeSetState = (e) => {
    var thisV = this;
    setTimeout(function () {
      thisV.setState({ loading: false })
    }, 100);
  };
  renderName(anonymousUserId, namePre) {
    const { name } = this.state;
    return name.map(({ anonymous_user_id, name }, idx) =>
      anonymousUserId == anonymous_user_id ? (
        <span className="kt-widget3__username">{name}</span>
      ) : (
        ""
      )
    );
  }

  openLiveChatBoxOnClickLeftRow = (anonymous_user_id, hippaOn) => {
    if (hippaOn == "block") {
      this.openHipaaBox(anonymous_user_id);
    } else {
      try {
        this.state.chat.find(
          (v) => v.displayChatContainer == "block"
        ).displayChatContainer = "none";
      } catch (e) { }
      try {
        this.state.chat.find(
          (v) => v.displayWholeChatContainer == ""
        ).displayWholeChatContainer = "none";
      } catch (e) { }
      try {
        this.state.chatLeftList.find(
          (v) =>
            v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
        ).leftBarClass = "kt-notification-v2__item";
      } catch (e) { }
      try {
        this.state.chatRepondedTimeWise.find(
          (v) =>
            v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
        ).leftBarClass = "kt-notification-v2__item";
      } catch (e) { }

      try {
        this.state.chat.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).displayChatContainer = "block";
      } catch (e) { }
      try {
        this.state.chat.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).displayWholeChatContainer = "";
      } catch (e) { }
      try {
        this.state.name.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).hippaOn = "none";
      } catch (e) { }
      try {
        this.state.chatLeftList.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
      } catch (e) { }
      try {
        this.state.chatRepondedTimeWise.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
      } catch (e) { }

      this.setState({
        chat: this.state.chat,
        chatLeftList: this.state.chatLeftList,
        name: this.state.name,
        join_into_conversation_fromID: this.state.chat.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).join_into_conversation_fromID,
        join_into_conversation_assignedID: this.state.chat.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).join_into_conversation_assignedID,
        join_into_conversation: this.state.chat.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).join_into_conversation,
        anonymous_user_id: anonymous_user_id,
        closeConversation: this.state.chat.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).closeConversation,

      });
      document.getElementById(this.state.anonymous_user_id).value = "";

      //for read
      this.readChatFromLeft(anonymous_user_id)
    }
  };

  readChatFromLeft = (anonymous_user_id) => {
    var found = this.state.chat.find((v) => v.anonymous_user_id == anonymous_user_id).read
    if (found != "") {
      api.liveChat
        .updateRead({
          anonymous_user_id: anonymous_user_id,

        })
        .then((resp) => { });
    }

    this.state.chat.find((v) => v.anonymous_user_id == anonymous_user_id).read = "";
    this.state.chatLeftList.find((v) => v.anonymous_user_id == anonymous_user_id).read = "";

    this.setState({
      chat: this.state.chat,
      chatLeftList: this.state.chatLeftList,
    })
  }

  onValidHipaaSubmit = (anonymous_user_id) => {
    try {
      this.state.chat.find(
        (v) => v.displayChatContainer == "block"
      ).displayChatContainer = "none";
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.displayWholeChatContainer == ""
      ).displayWholeChatContainer = "none";
    } catch (e) { }
    // try {this.state.chat.find(v => v.hippaOn == "none").hippaOn = 'block';} catch (e) {}
    // try {this.state.chatRepondedTimeWise.find(v => v.hippaOn == "none").hippaOn = 'block';} catch (e) {}
    try {
      this.state.chatLeftList.find(
        (v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
      ).leftBarClass = "kt-notification-v2__item";
    } catch (e) { }
    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
      ).leftBarClass = "kt-notification-v2__item";
    } catch (e) { }

    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).displayWholeChatContainer = "";
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).displayChatContainer = "block";
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";
      this.state.chatLeftList.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";

    } catch (e) { }
    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }
    try {
      this.state.name.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }
    try {
      this.state.chatLeftList.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
    } catch (e) { }
    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
    } catch (e) { }

    this.setState({
      chat: this.state.chat,
      chatLeftList: this.state.chatLeftList,
      modalstatusHIPAA: false,
      name: this.state.name,
    });
    document.getElementById(this.state.anonymous_user_id).value = "";
    this.readChatFromLeft(anonymous_user_id)

  };
  modalClose = () => {
    this.setState({
      modalstatusHIPAA: false,
    });
  };
  openHipaaBox = (anonymous_user_id, callback) => {

    // this.setState({ loading: true })
    api.manageUser.checkHipaaAccount({ user_id: this.props.user.user_id, email: this.props.user.email }).then((data) => {
      try {
        if (data.data.data.subaccount_id == "found") {
          var type = "validate";
        } else {
          var type = "register";
        }
        this.setState({
          join_into_conversation_fromID: this.state.chat.find(
            (v) => v.anonymous_user_id == anonymous_user_id
          ).join_into_conversation_fromID,
          join_into_conversation_assignedID: this.state.chat.find(
            (v) => v.anonymous_user_id == anonymous_user_id
          ).join_into_conversation_assignedID,
          join_into_conversation: this.state.chat.find(
            (v) => v.anonymous_user_id == anonymous_user_id
          ).join_into_conversation,
          anonymous_user_id: anonymous_user_id,
          closeConversation: this.state.chat.find(
            (v) => v.anonymous_user_id == anonymous_user_id
          ).closeConversation,
          modalstatusHIPAA: true,
          modaltitle:
            "To view sensitive chat information, please enter your authorize email and PIN",
          hipaaValidateObject: {
            userId: this.props.user.user_id,
            userType: "User",
            via: "user",
            auto_id: anonymous_user_id,
            pin_email: this.props.user.email,
            refId: anonymous_user_id,
            chat_section: "livechat",
            type: type,
            loading: false

          },
        });
      } catch (err) { }
    });



  };
  openHipaaAfterRegister = auto_id => {
    this.setState({
      loading: false,
      modalstatusHIPAA: true,
      modaltitle:
        "To view sensitive chat information, please enter your authorize email and PIN",
      hipaaValidateObject: {
        userId: this.props.user.user_id,
        userType: "User",
        via: "user",
        auto_id: auto_id,
        pin_email: this.props.user.email,
        refId: auto_id,
        chat_section: "chatlist",
        type: "validate"

      },
    });
  }
  toggleEmogiesBar = (display, anonymous_user_id) => {
    if (display == "none") display = "block";
    else display = "none";
    this.state.displayEmogies = display;
    this.setState({
      displayEmogies: this.state.displayEmogies,
    });
  };

  addChatData = (anonymous_user_id, liveChatObject) => {
    liveChatObject.cahtversion = this.state.chat.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).cahtversion;
    liveChatObject.detectedSourceLanguage = this.state.chat.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).detectedSourceLanguage;
    liveChatObject.from = "";
    liveChatObject.hippaOn = this.state.chat.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).hippaOn;
    liveChatObject.username = this.state.chat.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).username;
    liveChatObject.welcomeMsg = this.state.chat.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).welcomeMsg;
    this.state.chatRemain[anonymous_user_id].push(liveChatObject);
    this.state.chat.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).join_into_conversation = 1;
    this.setState({
      chatRemain: this.state.chatRemain,
      chat: this.state.chat,
      quickSelectVal: "",
    });

    try {
      document.querySelector(".css-1qyrjaz").click();
    } catch (e) { }
  };

  sendJumpingMessage = (
    company,
    chatBot_id,
    chat_logo,
    primary_color,
    anonymous_user_id,
    commenttext,
    sendTextInProcess,
    socket
  ) => {
    if (sendTextInProcess == "Y") {
      return false;
    }
    if (commenttext == null) {
      var comment_text = "";
    } else {
      var comment_text = commenttext;
    }
    comment_text = comment_text.replace(/\\&#39;/g, "'");
    // comment_text =  comment_text.replace("\&#39;","'");
    // comment_text =  comment_text.replace("\'","'");
    // comment_text =  comment_text.replace("\'","'");
    // comment_text =  comment_text.replace("\'","'");
    // comment_text =  comment_text.replace("\'","'");
    // comment_text =  comment_text.replace("\'","'");
    // comment_text =  comment_text.replace("\'","'");
    // comment_text =  comment_text.replace("\'","'");
    var comment_text = comment_text.replace("&#39;", "'");

    var liveChatObject = {};
    liveChatObject.anonymous_user_id = anonymous_user_id;
    liveChatObject.name = this.state.name.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).name;
    liveChatObject.chatBot_id = chatBot_id;
    var d = new Date();
    var dateUTC = d.toUTCString();
    var dateUTCUnixTimeStamp = new Date(dateUTC).getTime() / 1000;
    var liveChatMessage = {
      From: "a",
      msgVal: comment_text,
      time: dateUTCUnixTimeStamp,
    };
    liveChatObject.message = liveChatMessage;
    liveChatObject.button_color = primary_color;
    liveChatObject.chat_logo = chat_logo;
    liveChatObject.company = company;
    liveChatObject.liveusercreatedon = dateUTCUnixTimeStamp;
    liveChatObject.jumpIn = true;
    if(comment_text.trim().length>0){
      socket.emit("live_chat_from_agent added", liveChatObject);
    }
    var tmpVarVal =
      Math.floor(Math.random() * 100000000 + 1) +
      "_" +
      Math.round(new Date().getTime() / 1000);

    liveChatObject.tmpVarVal = tmpVarVal;
    socket.emit("live_chat_from_agent_again added", liveChatObject);
    this.addChatData(anonymous_user_id, liveChatObject);
    var hippaOn = "none";
    if (this.state.hippaOn == true) {
      hippaOn = "block";
    }
    var leftBarClass = "kt-notification-v2__item";
    var join_into_conversation = "1";
    var findAnoId = 0;
    try {
      var findAnoIdOnj = this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      );
      Object.size = function (obj) {
        var size = 0,
          key;
        for (key in obj) {
          if (obj.hasOwnProperty(key)) size++;
        }
        return size;
      };

      findAnoId = Object.size(findAnoIdOnj);
    } catch (e) { }

    const object = {
      anonymous_user_id: anonymous_user_id,
      hippaOn: hippaOn,
      leftBarClass: leftBarClass,
      join_into_conversation: join_into_conversation,
      lastRespondedAt: "" + dateUTCUnixTimeStamp + "",
    };
    if (findAnoId == 0) {
      this.state.chatRepondedTimeWise.push(object);
    } else {
      try {
        this.state.chatRepondedTimeWise.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).lastRespondedAt = "" + dateUTCUnixTimeStamp + "";
      } catch (e) { }
    }
    this.state.waittime.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).time = "0";
    this.state.waittime.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).displayTime = "00 : 00 : 00 Sec";
    this.state.waittime.find(
      (v) => v.anonymous_user_id == anonymous_user_id
    ).actualDateAndTime = dateUTCUnixTimeStamp;
    this.state.chatRepondedTimeWise.sort((a, b) =>
      Number(a.lastRespondedAt) < Number(b.lastRespondedAt) ? 1 : -1
    );

    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).join_into_conversation_fromID = this.props.user.user_id;
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).join_into_conversation_assignedID = this.props.user.user_id;
    } catch (e) { }
    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).join_into_conversation = 1;
    } catch (e) { }
    try {
      this.state.chatLeftList.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).join_into_conversation = 1;
    } catch (e) { }


    try {
      this.state.chat.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }
    try {
      this.state.chatLeftList.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }

    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).hippaOn = "none";
    } catch (e) { }
    try {
      this.state.chatLeftList.find(
        (v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
      ).leftBarClass = "kt-notification-v2__item";
    } catch (e) { }
    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
      ).leftBarClass = "kt-notification-v2__item";
    } catch (e) { }
    try {
      this.state.chatRepondedTimeWise.find(
        (v) => v.anonymous_user_id == anonymous_user_id
      ).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
    } catch (e) { }

    this.setState({
      chatRepondedTimeWise: this.state.chatRepondedTimeWise,
      waittime: this.state.waittime,
      join_into_conversation_fromID: this.props.user.user_id,
      join_into_conversation_assignedID: this.props.user.user_id,
      join_into_conversation: 1,
      chat: this.state.chat,
      chatLeftList: this.state.chatLeftList,
      tmpVarVal: tmpVarVal,
      LeftSideAccordionWaitActive: false,
      LeftSideAccordionCurrentlyChattingActive: true,
      closeConversation: "n"
    });

    // WEB HOOK
    this.sendLiveChatDataForWebhook(
      anonymous_user_id,
      chatBot_id,
      comment_text
    );
    // WEB HOOK
  };

  isUrl = (s) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  };

  urlify = (text) => {
    var urlRegex = /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/g;
    return text.replace(urlRegex, function (url) {
      if (this.isUrl(url)) {
        url1 = url;
      } else {
        var url1 = "http://" + url;
      }
      return "<a href='" + url1 + "' target='_blank'>" + url + "</a>";
    });
    return text;
  };

  sendLiveChatDataForWebhook(anonymous_user_id, chatBot_id, text) {
    api.liveChat
      .sendDataToWebhookLiveChat({
        isLiveChat: "ON",
        anonymous_user_id: anonymous_user_id,
        chatBot_id: chatBot_id,
        welcome_msg: "",
        msg_from: "AGENT",
        origin_from: "WEB",
        msg: text,
      })
      .then((resp) => { });
  }

  sendAgent = (anonymous_user_id, comment_text) => {
    var company = this.props.user.company;
    var chatBot_id = this.props.user.chatBot_id;
    var chat_logo = this.props.user.chat_logo;
    var primary_color = this.props.user.primary_color;
    comment_text = this.urlify(comment_text);
    comment_text = comment_text.trim();

    
      comment_text = comment_text.replace(/(?:\r\n|\r|\n)/g, "<br>");
      if(comment_text.length>0){
        var liveChatObject = {};
        liveChatObject.anonymous_user_id = anonymous_user_id;
        liveChatObject.name = this.state.name.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).name;
        liveChatObject.chatBot_id = chatBot_id;
        var d = new Date();
        var dateUTC = d.toUTCString();
        var dateUTCUnixTimeStamp = new Date(dateUTC).getTime() / 1000;
        var liveChatMessage = {
          From: "a",
          msgVal: comment_text,
          time: dateUTCUnixTimeStamp,
        };
        liveChatObject.message = liveChatMessage;
        liveChatObject.button_color = primary_color;
        liveChatObject.chat_logo = chat_logo;
        liveChatObject.company = company;
        liveChatObject.liveusercreatedon = dateUTCUnixTimeStamp;
        if (comment_text.length > 0) {
        this.props.socket.emit("live_chat_from_agent added", liveChatObject);
        }
        var tmpVarVal =
          Math.floor(Math.random() * 100000000 + 1) +
          "_" +
          Math.round(new Date().getTime() / 1000);
        liveChatObject.tmpVarVal = tmpVarVal;
        this.props.socket.emit(
          "live_chat_from_agent_again added",
          liveChatObject
        );
        this.addChatData(anonymous_user_id, liveChatObject);
        this.state.waittime.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).time = "0";
        this.state.waittime.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).displayTime = "00 : 00 : 00 Sec";
        this.state.waittime.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).actualDateAndTime = "" + dateUTCUnixTimeStamp + "";
        this.state.chatRepondedTimeWise.find(
          (v) => v.anonymous_user_id == anonymous_user_id
        ).lastRespondedAt = "" + dateUTCUnixTimeStamp + "";
        this.state.chatRepondedTimeWise.sort((a, b) =>
          Number(a.lastRespondedAt) < Number(b.lastRespondedAt) ? 1 : -1
        );
        this.setState({
          waittime: this.state.waittime,
          chatRepondedTimeWise: this.state.chatRepondedTimeWise,
          tmpVarVal: tmpVarVal,
        });

      //  // WEB HOOK
      this.sendLiveChatDataForWebhook(
        anonymous_user_id,
        chatBot_id,
        comment_text
      );
      //  // WEB HOOK END

      // User Chat Log
      this.props.socket.emit("live_chat_log");
    } else {
    }
  };

  showModel = (e) => {
    this.setState({
      show: true,
    });
  };

  addQuickText = (e) => {
    this.child.child.addQuickText();
  };

  deleteAllQuickText = (e) => {
    this.child.child.deleteAllQuickText();
  };

  toggleModal = (e) => {
    this.child.hideToaster();
    this.setState({
      show: false,
    });
  };

  setQuickTextForLiveChat = () => {
    api.liveChat
      .getQuickListForLiveChat({})
      .then((resp) => {
        if (resp.data && resp.data.data.list) {
          var obj = { key: "", value: "addNew", text: this.setAddOption() };
          resp.data.data.list.unshift(obj);
          this.setState({
            quickTextOption: resp.data.data.list,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  NotificationMemberList = () => {
    api.liveChat
      .NotificationMemberList({})
      .then((resp) => {
        if (resp.data && resp.data.data.subAccountlist) {
          var list = resp.data.data.subAccountlist;
          this.setState({
            NotificationMemberListOption: list,
            NotificationMemberSelectVal: list[0].value,
          });
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  setAddOption = (e) => {
    return <div onClick={() => this.openAddOption()}>Click to Add Option </div>;
  };

  openAddOption = (e) => {
    this.showModel();
  };

  QuickSelectChange = (value, anonymousUserId) => {
    if (value == "addNew") {
    } else {
      this.setState({
        quickSelectVal: value,
      });
      document.getElementById(anonymousUserId).value = value;
      document.getElementById(anonymousUserId).focus();
    }
  };

  displayTypingLoader = (anonymousUserId) => {
    return anonymousUserId == this.state;
  };

  quickTextAddEditDelete = (e) => {
    this.setQuickTextForLiveChat();
  };

  NotificationMemberChange = (e) => {
    var DialogBoxConfirmation = {
      show: true,
      modaltitle: "Confirmation!",
      message:
        "Are you sure you want to transfer the chat to the <span class='mh-text-bold'>" +
        e.split("@@_@@")[3] +
        "</span>? You can still do the chat untill <span class='mh-text-bold'>" +
        e.split("@@_@@")[3] +
        "</span> jumps in to the conversation.",
      value: e,
    };
    this.setState({
      DialogBoxConfirmation: DialogBoxConfirmation,
    });
  };

  onDialogBoxConfirmationTransfer = (value) => {
    var DialogBoxConfirmation = {
      show: false,
      modaltitle: "!",
      message: "",
      value: "",
    };
    this.setState({
      DialogBoxConfirmation: DialogBoxConfirmation,
    });

    api.liveChat
      .transferTheChat({
        value: value,
        chatKey: this.props.user.chatBot_id,
        anonymous_user_id: this.state.anonymous_user_id,
      })
      .then((resp) => { })
      .catch((e) => {
        console.log(e);
      });
  };

  onDialogBoxConfirmationMarkAsClosed = (value) => {
    var DialogBoxConfirmation = {
      show: false,
      modaltitle: "!",
      message: "",
      value: "",
    };
    this.setState({
      DialogBoxConfirmation: DialogBoxConfirmation,
      loading: true
    });


    api.liveChat
      .sendChatTranscript({
        chatBot_id: this.props.user.chatBot_id,
        anonymous_user_id: this.state.anonymous_user_id,
      })
      .then((resp) => {
        this.state.chat.find(
          (v) => v.anonymous_user_id == this.state.anonymous_user_id
        ).closeConversation = 'y';

        var DialogBoxConfirmation = {
          show: true,
          modaltitle: "Alert!",
          yesOn: "none",
          message:
            "Conversation has been closed now.",
          value: ""
        };
        this.setState({
          chat: this.state.chat,
          DialogBoxConfirmation: DialogBoxConfirmation,
          loading: false,
          closeConversation: "y"

        });

        var liveChatObject = {};
        liveChatObject.anonymous_user_id = this.state.anonymous_user_id;
        this.props.socket.emit("live_chat_conversation closed", liveChatObject);


      })
      .catch((e) => {
        console.log(e);
      });
  };

  DialogBoxConfirmationClose = (e) => {
    var DialogBoxConfirmation = {
      show: false,
      modaltitle: "!",
      message: "",
    };
    this.setState({
      DialogBoxConfirmation: DialogBoxConfirmation,
    });
  };

  displayMarkAsClosed = (join_into_conversation, join_into_conversation_fromID, closeConversation, anonymous_user_id) => {
    return (
      (join_into_conversation == 1 && (join_into_conversation_fromID == this.props.user.user_id) && closeConversation == "n") ?
        <button
          to="#"
          onClick={() => this.markAsClosed(anonymous_user_id)}
          className="btn linear-gradient btn-sm mark-as-closed"
        >
          Mark as closed
        </button> : ""
    )
  }

  markAsClosed = (anonymous_user_id) => {
    var DialogBoxConfirmation = {
      show: true,
      modaltitle: "Confirm your action",
      yesOn: "block",
      message:
        "Are you sure you want to close this conversation?",
      value: ""
    };
    this.setState({
      DialogBoxConfirmation: DialogBoxConfirmation,
    });
  }

  renderWholeChatContainer() {
    const { chat } = this.state;
    return chat.map(
      (
        {
          anonymous_user_id,
          button_color,
          chatBot_id,
          chat_logo,
          cahtversion,
          company,
          from,
          liveusercreatedon,
          message,
          name,
          username,
          welcomeMsg,
          displayChatContainer,
          displayWholeChatContainer,
          join_into_conversation,
          join_into_conversation_fromID,
          hippaOn,
          closeConversation
        },
        idx
      ) => (
        <div
          className="kt-portlet"
          style={{ boxShadow: "none", display: displayWholeChatContainer, marginBottom: 0 }}
        >
          <div className="kt-portlet__head chat-header">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                <small>{(closeConversation == "y") ? "CLOSED" : (join_into_conversation == 1) ? "IN PROGRESS" : "WAITING"}</small>
              </h3>
            </div>
            <div className="kt-portlet__head-label">
              <button onClick={() => this.showModel()}
                class="btn btn-sm mark-as-closed">Quick response</button>

            </div>
            <div className="kt-portlet__head-label">
              <button onClick={() =>
                this.openSoundNotificationSetting()
              } class="btn btn-sm mark-as-closed">Sound notification settings</button>

            </div>



            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-actions">
                <button className="btn  btn-sm assignedto">
                  <NotificationMemberList
                    state={this.state}
                    user_id={this.props.user.user_id}
                    NotificationMemberChange={this.NotificationMemberChange}
                    anonymous_user_id={anonymous_user_id}
                  />
                </button>
                {this.displayMarkAsClosed(join_into_conversation, join_into_conversation_fromID, closeConversation, anonymous_user_id)}
              </div>
            </div>
          </div>
          <div id="chatBody" style={{ minHeight: 382 }}>
            <div style={{ display: displayChatContainer }}>
              {this.renderChat(anonymous_user_id)}
            </div>
          </div>
          <div className="yr-right-footer">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6" style={{ height: 29 }}>
                <img
                  id={"loader-" + anonymous_user_id}
                  style={{ display: "none" }}
                  className="mh-TypingLoaderImg"
                  src={this.state.assetPath + "/layouts/layout2/img/loader.gif"}
                />
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-6"
                style={{ textAlign: "right" }}
              >
                <QuickSelect
                  state={this.state}
                  user_id={this.props.user.user_id}
                  QuickSelectChange={this.QuickSelectChange}
                  anonymous_user_id={anonymous_user_id}
                />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  logoUrl(logoUrl) {
    return this.props.user.logoUrl != "" ? (
      <img src={this.props.user.logoUrl} />
    ) : (
      ""
    );
  }
  chatLogo(chat_logo, button_color) {
    return chat_logo != "" ? (
      <div className="kt-widget3__user-img chat-img">
        <img
          className="kt-widget3__img mh-companyChatLogo"
          src={this.state.assetPath + "/images/chatlogo/" + chat_logo}
          alt=""
        />
      </div>
    ) : (
      <div
        className="kt-widget3__user-img chat-img"
        style={{ backgroundColor: button_color }}
      >
        <img
          className="kt-widget3__img mh-defaultChatLogo"
          src={this.state.assetPath + "/layouts/layout2/img/zyra-tp-logo.png"}
          alt=""
        />
      </div>
    );
  }
  renderChat(anonymousUserId) {
    const { chat } = this.state;
    return chat.map(
      (
        {
          anonymous_user_id,
          button_color,
          chatBot_id,
          chat_logo,
          cahtversion,
          company,
          from,
          liveusercreatedon,
          message,
          name,
          username,
          welcomeMsg,
          displayChatContainer,
          hippaOn,
        },
        idx
      ) =>
        anonymous_user_id == anonymousUserId ? (
          hippaOn == "block" ? (
            <div className="hippaBox" style={{ display: hippaOn }}>
              <div className="yr-openMsg sensitive-chat-info-box">
                <div className="yr-welcome-box">
                  <span>
                    <div className="yr-awatarImg">
                      {this.logoUrl(this.props.user.logoUrl)}
                    </div>
                    <p style={{ fontWeight: 600, fontSize: 15 }}>
                      Please{" "}
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.openHipaaBox(anonymous_user_id)}
                      >
                        Click here
                      </a>{" "}
                      to see the sensitive chat information
                    </p>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: displayChatContainer }}>
              <ScrollToBottom
                checkInterval={10}
                followButtonClassName={anonymous_user_id}
                scrollViewClassName="kt-portlet__body"
                className={"kt-portlet__body mh-oneChatContainer " + anonymous_user_id}
                id="mh-oneChatContainer"
                ref={this.myRef}
              >
                <div className="kt-widget3">
                  <div className="kt-widget3__item left-item">
                    <div className="kt-widget3__header">
                      {this.chatLogo(chat_logo, button_color)}
                      <div className="kt-widget3__info">
                        <span className="kt-widget3__username">{company}</span>
                        <span className="kt-widget3__time ml-2">
                          {this.dateTime(message.time)}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget3__body highlited-body">
                      <p className="kt-widget3__text">
                        {ReactHtmlParser(welcomeMsg.replace(/%25/g, "%"))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="kt-widget3">
                  <div className="kt-widget3__item right-item">
                    <div className="kt-widget3__header">
                      <div className="kt-widget3__user-img chat-img">
                        <img
                          className="kt-widget3__img"
                          style={{ height: "42px" }}
                          src={
                            this.state.assetPath +
                            "/images/chatlogo/visitor-logo.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="kt-widget3__info">
                        <span className="kt-widget3__username">
                          {this.renderName(anonymous_user_id, name)}
                        </span>
                        <span className="kt-widget3__time ml-2">
                          {this.dateTime(message.time)}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget3__body">
                      <p className="kt-widget3__text">{message.msgVal}</p>
                    </div>
                  </div>
                </div>
                {this.renderRemainChat(anonymous_user_id)}
              </ScrollToBottom>
            </div>
          )
        ) : (
          ""
        )
    );
  }

  renderRemainChat(anonymousUserId) {
    const { chatRemain } = this.state;
    var anonymous_user_id,
      button_color,
      chatBot_id,
      chat_logo,
      cahtversion,
      company,
      from,
      liveusercreatedon,
      message,
      name,
      username,
      welcomeMsg = "";
    return chatRemain[anonymousUserId] === undefined
      ? ""
      : chatRemain[anonymousUserId].map(
        (
          {
            anonymous_user_id,
            button_color,
            chatBot_id,
            chat_logo,
            cahtversion,
            company,
            from,
            liveusercreatedon,
            message,
            name,
            username,
            welcomeMsg,
          },
          idx
        ) =>
          message.From == "a" ? ( message.msgVal !== '' &&
            <div className="kt-widget3">
              <div className="kt-widget3__item left-item">
                <div className="kt-widget3__header">
                  {this.chatLogo(chat_logo, button_color)}
                  <div className="kt-widget3__info">
                    <span className="kt-widget3__username">{company}</span>
                    <span className="kt-widget3__time ml-2">
                      {this.dateTime(message.time)}
                    </span>
                  </div>
                </div>
                <div className="kt-widget3__body highlited-body">
                  <p className="kt-widget3__text">
                    {ReactHtmlParser(message.msgVal)}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ('disconnected' in message) ?
              <div className={"kt-widget3 mh-connDisconnLeaveMsg mh-connDisconnLeaveMsg-type-" + message.disconnected}>
                <span class={"mh-connDisconnLeaveMsg-span mh-connDisconnLeaveMsg-span-type-" + message.disconnected}>
                  {this.dateTime(message.time) + " - " + message.msgVal}
                </span>
              </div> :
              <div className="kt-widget3">
                <div className="kt-widget3__item right-item">
                  <div className="kt-widget3__header">
                    <div className="kt-widget3__user-img chat-img">
                      <img
                        className="kt-widget3__img"
                        style={{ height: "42px" }}
                        src={
                          this.state.assetPath +
                          "/images/chatlogo/visitor-logo.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="kt-widget3__info">
                      <span className="kt-widget3__username">
                        {this.renderName(anonymous_user_id, name)}
                      </span>
                      <span className="kt-widget3__time ml-2">
                        {this.dateTime(message.time)}
                      </span>
                    </div>
                  </div>
                  <div className="kt-widget3__body">
                    <p className="kt-widget3__text">{message.msgVal}</p>
                  </div>
                </div>
              </div>

          )
      );
  }

  openLeftAccordion = (e) => {
    if (e == "w") {
      var w = true;
      if (this.state.LeftSideAccordionWaitActive == true) w = false;

      this.setState({
        LeftSideAccordionWaitActive: w,
        LeftSideAccordionCurrentlyChattingActive: false,
      });
    } else {
      var cc = true;
      if (this.state.LeftSideAccordionCurrentlyChattingActive == true)
        cc = false;
      this.setState({
        LeftSideAccordionWaitActive: false,
        LeftSideAccordionCurrentlyChattingActive: cc,
      });
    }
  };

  onValidSubmitSoundNotification = (which, value) => {
    this.state.soundNotiAndSettingmodalData[which] = value;
    if (which == "sound_notification") {
      if (value == "1")
        this.state.soundNotiAndSettingmodalData[
          "sound_notificationChecked"
        ] = true;
      else
        this.state.soundNotiAndSettingmodalData[
          "sound_notificationChecked"
        ] = false;
    } else {
      if (value == "1")
        this.state.soundNotiAndSettingmodalData[
          "first_time_sound_notificationChecked"
        ] = true;
      else
        this.state.soundNotiAndSettingmodalData[
          "first_time_sound_notificationChecked"
        ] = false;
    }

    this.setState({
      soundNotiAndSettingmodalData: this.state.soundNotiAndSettingmodalData,
    });

    console.log(this.state);
  };

  openSoundNotificationSetting = () => {
    this.setState({
      showSoundNotiDialog: true,
      modalstatusNotiAandMisc: true,
    });
  };

  soundSettingModalClose = (e) => {
    this.setState({
      showSoundNotiDialog: false,
      modalstatusNotiAandMisc: false,
    });
  };
  // alert(which)
  // alert(value)

  render() {
    return (
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="liveChatPage"
      >
        {this.state.loading && <div class="ui loading form"></div>}
        <div className="kt-content kt-grid__item kt-grid__item--fluid mh-custom-marg0">
          <div className="yr-body-p mh-custom-pad0">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet mh-custom-marg0">
                  <div className="kt-portlet__body py-0 mh-custom-pad0">
                    <div className="row">
                      <div className="col-lg-3 livechat-left yr-pd0-mob yr-pd0-lr">
                        <LeftSideAccordion
                          props={this.props}
                          state={this.state}
                          openLiveChatBoxOnClickLeftRow={
                            this.openLiveChatBoxOnClickLeftRow
                          }
                          openLeftAccordion={this.openLeftAccordion}
                        />
                      </div>
                      <div className="col-lg-9 right-side-chat yr-pd0-mob yr-pd0-lr">
                        <div
                          style={{ display: this.state.blankBoxStyle }}
                          className="mh-blankBoxOnLiveChat"
                        >
                          <div className="yr-right-chatbox yr-rightbar-chatBox hideshowChat mh-noChatBar">
                            <div className="yr-welcome-box">
                              <span>
                                <h1>Welcome {this.props.user.name}! </h1>

                                <p style={{ fontWeight: 600 }}>
                                  There are currenlty no chat. Feel free to edit
                                  you{" "}
                                  <a onClick={() => this.showModel()} href="#">
                                    quick response
                                  </a>{" "}
                                  or edit your{" "}
                                  <a
                                    onClick={() =>
                                      this.openSoundNotificationSetting()
                                    }
                                    href="#"
                                  >
                                    sound notification settings
                                  </a>
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.renderWholeChatContainer()}
                        <LiveChatBoxFooter
                          socket={this.props.socket}
                          sendJumpingMessage={this.sendJumpingMessage}
                          sessionUser={this.props.user}
                          state={this.state}
                          closeConversation={this.state.closeConversation}
                          toggleEmogiesBar={this.toggleEmogiesBar}
                          sendAgent={this.sendAgent}
                        />
                        <DialogBox
                          addQuickText={this.addQuickText}
                          deleteAllQuickText={this.deleteAllQuickText}
                          show={this.state.show}
                          toggleModal={this.toggleModal}
                          title="Quick Text"
                          width="700px"
                          height="320px"
                          modalBodyId={"quickText"}
                        //message={this.state.message}
                        >
                          <OpenQuickText
                            parentState={this.state}
                            ref={(child) => {
                              this.child = child;
                            }}
                            addQuickText={this.state.addQuickText}
                            sessionUser={this.props.user}
                            quickTextAddEditDelete={this.quickTextAddEditDelete}
                          />
                        </DialogBox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogBoxHipaaPin
          email={this.props.user.email}
          obj={this.state}
          modalClose={this.modalClose}
          onValidHipaaSubmit={this.onValidHipaaSubmit}
          openHipaaAfterRegister={this.openHipaaAfterRegister}
        />
        <DialogBoxConfirmation
          obj={this.state}
          DialogBoxConfirmationClose={this.DialogBoxConfirmationClose}
          DialogBoxConfirmation={this.state.DialogBoxConfirmation}
          onDialogBoxConfirmationTransfer={this.onDialogBoxConfirmationTransfer}
          onDialogBoxConfirmationMarkAsClosed={this.onDialogBoxConfirmationMarkAsClosed}
          width="400px"
          height="200px"
        />
        <DialogBoxSoundNotificationSetting
          obj={this.state}
          SoundNonificationSetting={this.SoundNonificationSetting}
          onValidSubmitSoundNotification={this.onValidSubmitSoundNotification}
          sessionUser={this.props.sessionUser}
          showSoundNotiDialog={this.state.showSoundNotiDialog}
          soundSettingModalClose={this.soundSettingModalClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company ? state.user.company : null,
  };
};
export default connect(mapStateToProps, {
  toggleNavigation,
  setPageTitle,
  setMessage,
  toggleBreadcrum,

})(LiveChat);
// export default LiveChat;
