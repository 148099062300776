import React from "react";
import "../../../../Modal/dialog.css";
class Modal extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div
                    className={this.props.show ? "modal completedchats fade show" : "modal completedchats fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.show ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 526 }}>
                        {this.props.loading && <div className="ui loading form"></div>}
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.props.onclose()}
                            >
                                X
						</button>
                            <div className="modal-header ">
                                <h5 className="modal-title">{this.props.title}</h5>
                                {this.props.modaltranscript && 
                                 this.props.modaltranscript
                                }
                            </div>
                            <div className="modal-body"><div className="px-4"> {this.props.body}</div>
                               
                            </div>
                            {this.props.footer}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default Modal;
