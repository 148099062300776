import React from "react";
import CustomErrorField from "../../../../Form/CustomErrorField";
import api from "../../../../../api";
import Modal from "../../../ChatSetup/components/Modal";
import MapContainer from "./MapContainer";
class ZipCodeRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      questions: [""],
      errors: [],
      edit_id: "",
      zipcoderules: [],
      modalstatus: false,
      modaltitle: '',
      modalbody: '',
      deletemodalid: '',
      noservice_branch_id: '',
      loading: true,
      receive_chat_notification:''

    };
  }

  componentDidMount() {
    this.onLoad();
  }

  componentDidUpdate(prevProps) {
    if (this.props.indextime != prevProps.indextime) {

      this.onLoad();
    }
  }

  onLoad = () => {
    api.botbuilder.getZipCodeRules({ branch_id: this.props.branch_id }).then((data) => {
      try {
        // let branch_detail = this.state.branchDetails;
        // branch_detail.ai_list = data.list;
        // this.setState({ branchDetails: branch_detail });
        var noservice_branch_id = data.data.zipcoderules[0].noservice_branch_id
        if (this.props.zipcodenewbranch_id != '') {
          noservice_branch_id = this.props.zipcodenewbranch_id
        }
        this.setState({ zipcoderules: data.data.zipcoderules, noservice_branch_id: noservice_branch_id, loading: false,receive_chat_notification:data.data.zipcoderules.length>0 ? data.data.zipcoderules[0].receive_chat_notification:'0' });
        // callback("success");
      } catch (err) {
        console.log(err);
      }
    });
  };

  changeZipcode = (data, index, type) => {
    let zipcode_rule = this.state.zipcoderules

    if (type == 'zipcode') {

      let regexp = /^[0-9\b]+$/

      if (data.target.value === '' || regexp.test(data.target.value)) {
        zipcode_rule[index].zipcode = data.target.value
      }
    }
    if (type == 'willing_travel') {
      //alert(data.target.value)
      zipcode_rule[index].willing_travel = data.target.value
    }

    if (type == 'noservice_branch_id') {
      this.setState({ noservice_branch_id: data.target.value })
    } else {
      this.setState({
        zipcoderules: zipcode_rule,

      })
    }


  }


  addLocation = () => {
    let text = { 'id': '', 'user_id': '', 'zipcode': '', 'willing_travel': '', 'branch_id': '', 'noservice_branch_id': '' }
    let push_data = this.state.zipcoderules;
    push_data.push(text);
    this.setState({
      zipcoderules: push_data
    })
  }


  deleteLocations = () => {
    // deleteLocations
    this.setState({
      deletemodalid: '',
      modalstatus: true,
      modaltitle: "Confirm your Action",
      modalbody: "Are you sure you want to delete ?",
    })
  }
  modalClose = () => {
    this.setState({
      modalstatus: false,
      modaltitle: "",
      modalbody: "",
      modalindex: "",
    });
  };

  modalFooter = () => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDeleteZipcode(this.state.deletemodalid)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
  }

  confirmDeleteZipcode = () => {
    let rules = this.state.zipcoderules
    let activeindex = rules.findIndex((list) => list == rules);

    rules.splice(activeindex, 1);
    this.setState({
      zipcoderules: rules
    }, () => {
      this.modalClose();
    })
  }

  saveZipCodeRules = () => {
    let errors = [];
    new Promise(resolve => {
      let zipcoderules = this.state.zipcoderules
      zipcoderules.forEach((element, index) => {

        if (element.zipcode == '') {

          if (typeof errors[index] != 'undefined') {
            errors[index].zipcode = 'This field is required';
          } else {
            let err = { zipcode: 'This field is required' };
            errors[index] = err;
          }


        }

        let recordlist = zipcoderules.filter(
          (list, typeindex) => {

            if (list.zipcode == element.zipcode && index != typeindex) {

              return true
            } else {
              return false;
            }
          }
        );

        if (recordlist != '' && element.zipcode != '') {
          // alert(recordlist);
          if (typeof errors[index] != 'undefined') {
            errors[index].zipcode = 'This zip code already exist';
          } else {
            let err = { zipcode: 'This zip code already exist' };
            errors[index] = err;
          }

        }




      });

      if (this.state.noservice_branch_id == '' || this.state.noservice_branch_id == 0) {

        let tindex = this.state.zipcoderules.length
        if (typeof errors[tindex] != 'undefined') {
          errors[tindex].noservice_branch_id = 'This field is required';
        } else {
          let err = { noservice_branch_id: 'This field is required' };
          errors[tindex] = err;
        }
      }

      resolve(errors);


    }).then((errors) => {

      if (errors.length == 0) {
        this.setState({ loading: true })


        api.botbuilder.saveZipCodeRules({ branch_id: this.props.branch_id, zipcoderules: this.state.zipcoderules, noservice_branch_id: this.state.noservice_branch_id, workspace_id: this.props.workspace_id,receive_chat_notification:this.state.receive_chat_notification }).then((data) => {
          try {
            if (data.status == true) {
              this.setState({ loading: false }, () => {
                this.props.popupClose()
                this.props.updateDeleteZipRule(true)
              });
            } else {
              this.setState({ loading: false }, () => {
                var rules_error = this.state.zipcoderules;
                data.data.map((obj, index) => {
                  let activeindex = rules_error.findIndex((list) => list.zipcode == obj);

                  if (typeof errors[activeindex] != 'undefined') {
                    errors[activeindex].zipcode = 'Sorry! we are not available at this location';
                  } else {
                    let err = { zipcode: 'Sorry! we are not available at this location' };
                    errors[activeindex] = err;
                  }



                })


                this.setState({ errors: errors }, () => {

                  let scrolltop =
                    document.querySelector(".errosbuilderclass").offsetTop -
                    document.body.scrollTop + 100;
                  window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
                })




              });

            }
            // callback("success");
          } catch (err) {
            console.log(err);
          }
        });


        // this.props.saveAIRules(this.state.aiRules, this.state.copy_rules_branch_id, (data_parm) => {


        //     this.setState({

        //         loading: false
        //     });
        //     this.props.popupClose()
        //     let scrolltop=document.querySelector("#airules").offsetTop - document.body.scrollTop;
        //     window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
        // });
      } else {
        console.log(errors)
        this.setState({ errors: errors }, () => {

          let scrolltop =
            document.querySelector(".errosbuilderclass").offsetTop -
            document.body.scrollTop + 100;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        })
      }
      //console.log(errors)
    })
  }

  receiveNotification=(e)=>{
  
    
    let val = e.target.checked ? "1" : "0";
    this.setState({
      receive_chat_notification: val,

    })
    
   
  }

  render() {
    const { errors, edit_id } = this.state;
    return (
      <div>
        <div className="yr-chat-round-box yr-closebtn">
          <button
            type="button"
            class="close linear-gradient"
            onClick={() => this.props.popupClose()}
          >
            &#10006;
          </button>
          <div className="box-title">Add zip code rules</div>

          {this.state.loading && <div className="ui loading form"></div>}

          {this.state.zipcoderules.length > 0 &&
            this.state.zipcoderules.map((obj, index) => {
              return (
                <div class="row" un_key={index}>
                  {index > 0 &&
                    <div className="col-md-12">
                      <button type="button" style={{ float: "right" }} title="Delete" className="btn btn-icon btn-sm cusrsorpointer" onClick={() => this.deleteLocations(index)}><i className="la la-trash red" /></button>
                    </div>
                  }
                  <label className="col-md-4 pd10botbuilder">
                    Zip Code
                  </label>
                  <div className={
                    errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('zipcode')
                      ? "col-md-8 errosbuilderclass"
                      : "col-md-8"
                  }>
                    <div className="form-group" style={{ marginBottom: 10 }}>
                      <input type="text" className={
                        errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('zipcode')
                          ? "form-control is-invalid"
                          : "form-control"
                      } value={obj.zipcode} onChange={(e) => this.changeZipcode(e, index, 'zipcode')}></input>
                      {errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('zipcode') && (
                        <CustomErrorField
                          message={errors[index].zipcode}
                          className="error invalid-feedback"
                        />
                      )}
                    </div>
                  </div>


                  <div className="col-md-4 pd10botbuilder">
                    Send Lead Within
                  </div>
                  <div className="col-md-8">
                    <div className="form-group" style={{ marginBottom: 10 }}>
                      <select className="form-control" value={obj.willing_travel} onChange={(e) => this.changeZipcode(e, index, 'willing_travel')}>
                        <option value="">Select</option>
                        {[5, 10, 15, 20, 25, 30, 35, 40].map((x, y) => {
                          return (
                            <option value={x}>{x} Miles</option>
                          )
                        })}

                      </select>
                      {/* {errors.length > 0 &&
                    typeof errors[index] != "undefined" && (
                    <CustomErrorField
                        message={errors[index]}
                        className="error invalid-feedback"
                    />
                    )} */}
                    </div>
                  </div>

                </div>




              );
            })}
          {this.state.edit_id == "" && (
            <a
              className="add-gradient form-group cusrsorpointer add-link"
              onClick={() => this.addLocation()}
            >
              +Click to add locations
            </a>
          )}



          <div className="form-group row my-4" className={
            errors.length > 0 && typeof errors[this.state.zipcoderules.length] != 'undefined' && errors[this.state.zipcoderules.length].hasOwnProperty('noservice_branch_id')
              ? "form-group row my-4 errosbuilderclass"
              : "form-group row my-4"
          }>
            <label className="col-md-12 col-form-label">If the zip code is outside of our service area. Go to Branch</label>
            <div className="col-md-12">
              <select className={
                errors.length > 0 && typeof errors[this.state.zipcoderules.length] != 'undefined' && errors[this.state.zipcoderules.length].hasOwnProperty('noservice_branch_id')
                  ? "form-control is-invalid"
                  : "form-control"
              } onChange={(e) => this.changeZipcode(e, '', 'noservice_branch_id')} value={this.state.noservice_branch_id}>
                <option value="">Select Branch</option>
                {this.props.branchList && this.props.branchList.map((value, index) => {
                  return (
                    <option key={index} value={value.branch_id}>{value.branch_name}</option>
                  )
                })}
              </select>
              {errors.length > 0 && typeof errors[this.state.zipcoderules.length] != 'undefined' && errors[this.state.zipcoderules.length].hasOwnProperty('noservice_branch_id') && (
                <CustomErrorField
                  message={errors[this.state.zipcoderules.length].noservice_branch_id}
                  className="error invalid-feedback"
                />
              )}
            </div>
            <div className="col-md-12">
              <button className="btn add-link" onClick={() => this.props.createNewBranch('zipcoderules')}>+Create New Branch</button>
            </div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <p>Receive notification leads from no servive area</p>
            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check" style={{textAlign:"left"}}>
              <label>
                <input type="checkbox" name="chatPopup"  onChange={(e)=>this.receiveNotification(e)} checked={(this.state.receive_chat_notification=='1') ? 'checked' : ''}/>  
                <span></span>
              </label>
            </span>       
          </div>
          <div className="form-group row my-4">
            <label className="col-md-12 col-form-label">Your service area</label>
            <div className="col-md-12" style={{ MaxWidth: "300px", height: "400px" }}>
              {this.state.loading == false &&
                <MapContainer mapdata={this.state.zipcoderules} />
              }
            </div>
          </div>




          {/* <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Close </span></button>
                        <button type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
                    </div>
                </div>             */}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button
              type="reset"
              class="btn yr-cancelbtn linear-gradient-cancel mg-r"
              onClick={() => this.props.popupClose()}
            >
              <span> Close </span>
            </button>
            <button
              type="submit"
              className="btn linear-gradient yr-submitbtn"
              onClick={() => this.saveZipCodeRules()}
            >
              Save
            </button>
          </div>
        </div>

        <Modal
          show={this.state.modalstatus}
          footer={this.modalFooter(this.state.modalindex)}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
        />


      </div>
    );
  }
}

export default ZipCodeRules;
