import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "./analytics.css";
import "../../../Modal/dialog.css";
import "../../../Modal/DialogBox.js";

import DatatableList from "./dataTables/DatatableList";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import AnalyticDetails from "./AnalyticDetails";

class PlayBookAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      yourCallList: [],
      tab: "tab1",
      bid: "",
      subscriber: 0,
      email: "",
      contacts: 0,
    };
  }

  componentDidMount() {
    console.log('asdf', this.props);
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Analiytics",
      navigationName: "marketing",
      subHeaderTitle: "Re-Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
  }



  render() {
    const { errors, email, email_subject, action, added_date } = this.state;
    console.log('*************');
    console.log(this.props.match.params);
    console.log('*************');
    return (
      <div className="col-md-12">
        {/* { <DatatableList activetab={this.state.activetab} branch_id_pwsid= { this.state.branch_id_pwsid} indextime={new Date()} />} */}

        {this.props.match.params.id &&
          <AnalyticDetails
            branch_id_pwsid={this.props.match.params.id}
            pid={this.props.match.params.pid}
            indextime={new Date()}

          />
        }

      </div>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(PlayBookAnalytics);
