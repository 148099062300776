import axios from "../config/axios";

const playbookdetail = {

    getPlaybookDetails: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/emailTemplateList`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    savePlaybookTemplate: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/savePlaybookTemplate`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    deletePlaybookTemplate: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/deletePlaybookTemplate`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    copyPlaybookTemplate: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/copyPlaybookTemplate`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    sendPreviewTest: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/sendPreviewTest`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    uploademailtemp: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/uploademailtemp`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),



    saveeditor: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/saveeditor`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateeditor: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/updateeditor`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    openEditor: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/openEditor`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    openRules: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/openRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    saveRules: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/saveRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveSeasonalRules: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/saveSeasonalRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateSendEmail: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/updateSendEmail`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateNeweditor: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/updateNeweditor`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveNeweditor: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/saveNeweditor`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    copyPlaybookNewTemplate: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/copyPlaybookNewTemplate`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    sentEmailtemplate: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/sentEmailtemplate`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    loadDirectEmailRules: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/loadDirectEmailRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    openTemplateFooter: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/openTemplateFooter`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateContentNewEditorFooter: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/updateContentNewEditorFooter`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    getPostCardContent: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/getPostCardContent`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getAltValue: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/getAltValue`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveAltValue: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/saveAltValue`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateposition: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/updateposition`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    getSendingQueuelist: (params) =>
        axios
            .get(`/marketing/PlaybookDetail/getSendingQueuelist?` + params, {})
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    confirmDeleteEntry: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/confirmDeleteEntry`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getTempplateButton: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/getTempplateButton`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    resetRules: (params) =>
        axios
            .post(`/marketing/PlaybookDetail/removeRule`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

};

export default playbookdetail;
