import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import "./contact.css";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import DatatableList from "./dataTables/DatatableList";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      bucketList: [],
      tab: "tab1",
      bid: "",
      subscriber: 0,
      bucket_name: "",
      contacts: 0,
    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Re-Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });

    this.setState({ bid: this.props.match.params.id }, function () {
      this.loadData();
    });
  }
  loadData = () => {
    api.marketing
      .getBucketDetail({ bid: this.props.match.params.id })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          this.setState({
            bucket_name: data.data.title,
            subscriber: data.data.subscriber,
            contacts: data.data.contacts,
          });
        } catch (err) {
          console.log(err);
        }
      });

    this.setState({
      loading: false,
    });
  };

  render() {
    const { errors, bucket_name, subscriber, contacts } = this.state;

    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div class="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact2 yr-manageUser-table">
            {this.state.loading && <div className="ui loading form"></div>}
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="yr-mt-10">
                  <div class="bck-btn kt-font-bolder kt-margin-b-20">
                    <Link to={"/marketing/contacts"} id="backtocontats">
                      <i
                        aria-hidden="true"
                        class="angle caret icon fas fa-caret-left"
                      ></i>
                      Back to all buckets
                    </Link>
                  </div>
                  <h2>{bucket_name}</h2>
                  <p class="kt-font-bold">
                    Your audience has <span class="blue">{contacts}</span>{" "}
                    contacts. <span class="blue">{subscriber}</span> of these
                    are subscribers.
                  </p>
                  <div class="clearfix"></div>
                </div>
                <div class="kt-portlet__body kt-margin-t-30">
                  <div class="tab-content yr-manageContacts-2">
                    <DatatableList
                      ref={(child) => {
                        this.child = child;
                      }}
                      loadData={this.loadData}
                      bid={this.props.match.params.id}
                      showUserToaster={this.showToaster}
                      userdata={this.props.user}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(ContactList);
