import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import api from "../../../../api";
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import DatatableList from "./Bucketlist/DatatableList";
import "../../Marketing/yr-style.css";
//import "./callList.css";
import './contactMultipleDropdown.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
//const options = [];
class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      bucketList: [],
      tab: "",
      title: "",
      uploadCSV: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      heading: [],
      rows: [],
      pval: {},
      checkedProperty: [],
      dialogTitle: "",
      dialogMessage: "",
      dialogLoading: false,
      dialogShow: false,
      newField: "",
      checkTerm: [],
      deleteID: '',
      modalstatus_Variable: '',
      disableClassName: "mh-ena kt-form__control yr-arrow",
      variabledata: [],
      bucketMsg: '',
      bucket_msg_err: '',
      vmsg: '',
      vmsg_err: '',
      vari: '',
      modalstatus_Add_Variable:false,
      emailErrors:[],
      isEmailError:false,

    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Re-Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.key != prevProps.location.key) {
      this.loadData();
    }
  }

  loadData = () => {
    api.marketing.getBucketData({}).then((data) => {
      try {
        if (data.status) {
          let tabVal = "";
          if (data.data.length == 0) tabVal = "tab1";

          this.setState({
            loading: false,
            bucketList: data.data,
            tab: tabVal,
          });
        } else {
          this.showToaster("red", data.data);
          utilFunc.scrollTop(800);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };


  onClickNext = (tab) => {
    if (tab == "tab3") {
      var obj = this;
      if( obj.state.title != '' ){
      this.validateForm(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ loading: true });
          var myform = document.getElementById("uploadContacts");
          var formData = new FormData(myform);
          const tokenInfo = util.getAuthTokens();
          var xhr = new XMLHttpRequest();
          var login_via=util.getCookie("loginVia");
            formData.append("loginvia",login_via);
          xhr.open(
            "POST",
            config.apiBaseURL + "/marketing/contacts/uploadcsv",
            true
          );
          xhr.setRequestHeader(
            config.access_token_name,
            tokenInfo.access_token
          );
          xhr.setRequestHeader(
            config.refresh_token_name,
            tokenInfo.refresh_token
          );
          xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
              const resp = JSON.parse(xhr.response);
              if (resp.status) {
                
                //obj.showToaster('green', 'Data saved successfully.');
                //utilFunc.scrollTop(800);
                let emerr=(resp.errors.length>0) ? true:false;
                console.log("error count",resp.errors.length);
                obj.setState({
                  loading: false,
                  title: "",
                  uploadCSV: "",
                  tab: tab,
                  heading: resp.data.head,
                  rows: resp.data.row,
                  isEmailError:emerr,
                  emailErrors:resp.errors
                });
              } else {
                obj.setState({
                  loading: false,
                });
                obj.showToaster("red", resp.data);
                utilFunc.scrollTop(800);
              }
            }
          };
          xhr.send(formData);
        } else {
          let scrolltop =
            document.querySelector(".focusError").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      });
      }else{
         // Added by rohit mmm
         const errors = {};
         this.setState({
           bucket_msg_err:'This field is required.',
          });
          errors["title"] = "This filed is required.";
          //alert(this.state.bucket_msg_err);
          setTimeout(
           function () {
             this.setState({ bucket_msg_err: false, errors: false});
           }.bind(this),
           5000
         );


      }
    } else if (tab == "tab4") {
      new Promise((resolve) => {
        let t = 0;
        document
          .querySelectorAll(".yr-title-select")
          .forEach(function (userItem1) {
            userItem1.style.border = "none";
            if (t == document.querySelectorAll(".yr-title-select").length - 1)
              resolve(true);

            t++;
          });
      }).then((status) => {
        var obj = this;
        this.validateForm3(this.state, function (status) {
          if (status) {
            obj.setState({ loading: true });
            api.marketing.savebucket(obj.state).then((data) => {
              obj.setState({ loading: false });
              try {
                if (data.status) {
                  obj.setState({
                    tab: tab,
                  });
                } else {
                  obj.showToaster("red", data.data);
                  utilFunc.scrollTop(800);
                }
              } catch (err) {
                console.log(err);
              }
            });
          } else {
            document
              .querySelectorAll(".yr-cat-columnBox")
              .forEach(function (userItem) {
                let Fname = userItem.getAttribute("name");
                if (
                  document.getElementsByName("property[" + Fname + "]")[0]
                    .value == ""
                ) {
                  userItem.querySelector(".yr-title-select").style.border =
                    "1px solid red";
                }
              });
          }
        });
      });
    } else if (tab == "tab5") {
      this.setState({ loading: true });
      api.marketing.savefinal(this.state).then((data) => {
        this.setState({ loading: false });
        try {
          if (data.status) {
            let bList = this.state.bucketList;
            bList.push(data.data);
            this.setState({
              tab: "",
              bucketList: bList,
              checkedProperty: [],
              checkTerm: [],
              pval: {},
            });
          } else {
            this.showToaster("red", data.data);
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.setState({
        tab: tab,
      });
    }
  };

  validateForm3 = (data, callback) => {
    if (this.state.checkedProperty.length != this.state.rows.length - 1) {
      this.showToaster("red", "Please categorize each columns.");
      utilFunc.scrollTop(800);
      callback(false);
    } else {
      callback(true);
    }
    // const errors = {};
    // if (data.title=='') {
    //     errors['title'] = "This filed is required.";
    // }
    // callback(errors);
    //return errors;
  };

  validateForm = (data, callback) => {
    const errors = {};
    if (data.title == "") {
      errors["title"] = "This filed is required.";
    }
    if (data.uploadCSV == "") {
      errors["uploadCSV"] = "This filed is required.";
    }
    callback(errors);
    //return errors;
  };

  handleCSV = (event) => {
    this.setState({ loading: true });
    var myform = document.getElementById("uploadContacts");
    var formData = new FormData(myform);
    const tokenInfo = util.getAuthTokens();
    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      config.apiBaseURL + "/marketing/contacts/chkvalidation",
      true
    );
    xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
    xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        this.setState({ loading: false });
        try {
          const resp = JSON.parse(xhr.response);
          if (resp.status) {
            let errList = this.state.errors;
            delete errList["uploadCSV"];
            this.setState({
              errors: errList,
              uploadCSV: formData.get("uploadCSV").name,
            });
          } else {
            let errList = this.state.errors;
            errList["uploadCSV"] = resp.data;
            this.setState({ errors: errList, uploadCSV: "" });
          }
        } catch (err) {
          console.log(err);
          let errList = this.state.errors;
          errList["uploadCSV"] = xhr.response;
          this.setState({ errors: errList, uploadCSV: "" });
        }
      }
    };
    xhr.send(formData);
  };

  onchange = (e) => {
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This filed is required.";
      this.setState({ errors: errList });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  oncheckTerm = (e) => {
    console.log(e.target);
    let chk = this.state.checkTerm;
    if (e.target.checked) {
      chk.push(e.target.value);
      this.setState({
        checkTerm: chk,
      });
    } else {
      let ckey = chk.indexOf(e.target.value);
      if (ckey > -1) chk.splice(ckey, 1);
      this.setState({
        checkTerm: chk,
      });
    }
    console.log(this.state.checkTerm);
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      5000
    );
  };

  renderHeading = () => {
    let hlist = this.state.heading;
    if (typeof hlist != "undefined") {
      return this.state.heading.map((data, key) => {
        let keyData = this.state.checkedProperty.indexOf(data.hid);
        let timedate = Date.now();
        return (
          <option
            key={data.hid + timedate}
            value={data.hid}
            disabled={key == 0 || keyData > -1 ? true : false}
          >
            {data.title}
          </option>
        );
      });
    }
  };

  onchangeHeading = (e, data) => {
    let pvalArr = this.state.pval;
    let checkedArr = this.state.checkedProperty;
    //checkedArr.push(e.target.value);
    //if(e.target.value==''){
    let index = checkedArr.indexOf(pvalArr[data]);
    if (index > -1) {
      checkedArr.splice(index, 1);
    }
    //checkedArr.push(e.target.value);
    //}else{
    if (e.target.value != "") checkedArr.push(e.target.value);
    //}
    pvalArr[data] = e.target.value;
    this.setState({
      pval: pvalArr,
      checkedProperty: checkedArr,
    });
  };

  addColumn = (action, data) => {
    if (action == "Add") {
      this.setState({
        dialogTitle: "Add",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,
        newField: "",
        errors: {},
        deleteID: '',
      });
    }
    if (action == "Close") {
      this.setState({
        dialogTitle: "",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: false,
        newField: "",
        errors: {},
        deleteID: '',
      });
    }
    if (action == "Close1") {
      this.setState({
        isEmailError:false
      });
    }
    if (action == "Save") {
      var obj = this;
      this.validateForm2(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ dialogLoading: true });
          api.marketing
            .addfield({ bid: obj.state.rows[0].bid, title: obj.state.newField })
            .then((data) => {
              obj.setState({ dialogLoading: false });
              try {
                if (data.status) {
                  let btList = obj.state.heading;
                  btList.push(data.data);
                  obj.setState({
                    heading: btList,
                    dialogTitle: "",
                    dialogMessage: "",
                    dialogShow: false,
                    newField: "",
                  });
                } else {
                  let errList = obj.state.errors;
                  errList["newField"] = data.data;
                  obj.setState({ errors: errList });
                }
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
    }

    if (action == "Delete") {
      this.setState({ dialogLoading: true });
      api.marketing.deletecolumn(this.state.rows[this.state.deleteID]).then((data) => {
        try {
          if (data.status) {
            let preArr = this.state.rows;
            preArr.splice(this.state.deleteID, 1);
            this.setState({
              dialogTitle: "",
              dialogMessage: "",
              dialogLoading: false,
              dialogShow: false,
              newField: "",
              errors: {},
              deleteID: '',
              rows: preArr,
            });
          } else {
            this.setState({
              dialogTitle: "",
              dialogMessage: "",
              dialogLoading: false,
              dialogShow: false,
              newField: "",
              errors: {},
              deleteID: '',
            });
            this.showToaster("red", "Something wents wrong.");
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }

  };

  validateForm2 = (data, callback) => {
    const errors = {};
    if (data.newField.trim() == "") {
      errors["newField"] = "This filed is required.";
      callback(errors);
    }
    this.state.heading.map((x, y) => {
      if (this.state.heading.length > 1) {
        if (
          x.title.trim().toLowerCase() == data.newField.trim().toLowerCase()
        ) {
          errors["newField"] = "Duplicate field.";
        }
      }
      if (this.state.heading.length == y + 1) callback(errors);
    });
    //callback(errors);
    //return errors;
  };

  createBucket = () => {
    this.setState({
      tab: "tab2",
    });
  };

  deleteColumn = (key) => {
    this.setState({
      dialogTitle: "Delete",
      dialogMessage: "Are you sure you want to delete it?",
      dialogLoading: false,
      dialogShow: true,
      newField: "",
      errors: {},
      deleteID: key,
    });
  }

 getAllVariable = () => {

  api.marketing.getVariable({}).then((data) => {
    try {
      this.setState({
        modalstatus_Variable: true,
        variable: (data.data.listvariable == 0) ? null : data.data.listvariable,
        //variable2: (data.data.listvariable == 0) ? null : data.data.listvariable,
        loading: false,
        // disableClassName:(this.options.length==0)?"kt-form__control yr-arrow mh-dis":"mh-ena kt-form__control yr-arrow"

      });

    } catch (err) {
      console.log(err);
    }
  });

 }

  Next = () => {
    
  if( this.state.title != '' ){
        //Rohit ABC
        //loading: true,
        this.setState({
          loading: true,
          variable:[],
          variableValue:[],
          //variable.filter(o => o.value !== "*");
        });
        api.marketing.getVariable({}).then((data) => {
          try {

            console.log( data.data.listvariable );
            this.setState({
              modalstatus_Variable: true,
              variable: (data.data.listvariable == 0) ? null : data.data.listvariable,
              variable2: (data.data.listvariable == 0) ? null : data.data.listvariable,
              loading: false,
              // disableClassName:(this.options.length==0)?"kt-form__control yr-arrow mh-dis":"mh-ena kt-form__control yr-arrow"

            });

            //add here
                  //"marketing/contacts/"
                  // document.querySelector('#teamMemberCallList .css-1qprcsu-option:first-child').onclick = function(e){
                  //   var flag = document.querySelector('#teamMemberCallList .css-1qprcsu-option:first-child input').checked;
                  //   console.log(flag)
                  //   alert(flag)
                  // }


          } catch (err) {
            console.log(err);
          }
        });
      }else{
        const errors = {};
        this.setState({
          bucket_msg_err:'This field is required.',
         });
         errors["title"] = "This filed is required.";
         //alert(this.state.bucket_msg_err);
         setTimeout(
          function () {
            this.setState({ bucket_msg_err: false, errors: false});
          }.bind(this),
          5000
        );
      }

  }

  modalClose_Variable = () => {
    this.setState({
      modalstatus_Variable: false,

    });
  };

  save_var = () =>{

    //alert('hi');
    document.getElementById('save_var').click();
  }
//Email Id is invalid: sjacob.v#@gmail.com (Row: 2)
  displayEmailErrors=()=>{
    if(this.state.emailErrors.length>0){
      return(<div className="list">{this.state.emailErrors.map((item)=>{
        return(<div class="form-group" style={{paddingLeft: '10px'}}><p style={{marginTop: '10px'}}>Email Id is invalid: <b style={{ color: "#de0a0a" }}>{item.email}</b> (Row: <b style={{ color: "#de0a0a" }}>{item.row})</b></p></div>);
      })}</div>);
    }
  } 
  save = () => {

    //added by rohit 
    //alert('hi');
    this.setState({
      loading: true
    });
    var bucket_name = this.state.title;
    var variableValue = this.state.variable2;
   //alert(bucket_name);
    api.marketing.saveVariable({ bucket_name: bucket_name, variableValue: variableValue }).then((data) => {
      try {
        this.setState({
          modalstatus_Variable: false,
          bucketMsg: data.msg,
          loading: false,
          tab: "",
          bucketList:  data.data,
          checkedProperty: [],
          checkTerm: [],
          pval: {},
          title:'', 
          variableValue:[]
        });
      setTimeout(
          function () {
            this.setState({ bucketMsg: false, variableValue:false});
          
            //this.loadData();
          }.bind(this),
          100
        );
       
  } catch (err) {
        console.log(err);
      }
    });
  };

  onClose = ()=>{
    //alert("hi");
  }

VariableChange = (value, event) => {
    
  var valv = event.option.value; 
  //alert( valv );
  if( valv != '*' ){
        this.setState({ variable2: value })
      }
  };

  onchangev = (e) => {
    //alert(e.target.value);
    this.setState({ vari: e.target.value });
  }


  saveV = () => {
    //added by rohit
    //var vari = this.state.vari;
    var vari = this.state.vari.trim();
    if( vari != '' ){
        //alert(bucket_name);
        this.setState({
          loading: true
        });
        api.marketing.addVariable({ vari: vari }).then((data) => {
          try {
            this.getAllVariable();
            this.setState({
              loading: false,
              vmsg: data.vmsg,
              vmsg_err: data.vmsg_err,
            });
            setTimeout(
              function () {
                this.setState({ vmsg: false, vmsg_err: false });
               }.bind(this),
              5000
            );
           
            let varlist2 = this.state.variable2;
            //let varlist = this.state.variable;
            var addnew =  { label: vari, value: vari }
            //console.log( addnew );
            varlist2.push(addnew);
            console.log( varlist2 );
            this.setState({ variable2: varlist2 })
            

            if( data.vmsg_1 == 0 ){
              this.modalClose_AddVariable();
            }
            
          } catch (err) {
            console.log(err);
          }
        });
    }else{

        this.setState({
          loading: false,
          vmsg: '',
          vmsg_err: 'This filed is required.',
        });
        setTimeout(
          function () {
            this.setState({ vmsg: false, vmsg_err: false });
          }.bind(this),
          5000
        );
    }
  };

  viewVariable = () => {
   //alert('hi');
    this.setState({
      //loading: true,
      modalstatus_Add_Variable: true,

    });

  }

 modalClose_AddVariable = () => {
    this.setState({
      modalstatus_Add_Variable: false,

    });
  };

  modalStyle = () => {
    document.getElementById('backModal').style.zIndex = 0;
  }

  render() {
    const { errors } = this.state;

    return (
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver completedchatmain"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact">
            {this.state.loading && <div className="ui loading form"></div>}
            <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster ? "block" : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
            {this.state.bucketMsg != '' ? (
              <div class="col-lg-12 ui green message del-msg-padding">{this.state.bucketMsg}</div>
            ) : (<div></div>)
            }
            {this.state.bucketList.length == 0 ? (
              <div
                className="yr-body-p yr-mt-20 automatedMarketing"
                style={{
                  display: this.state.tab == "tab1" ? "inline-block" : "none",
                }}
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="kt-margin-t-30">
                      <strong>
                        All Contacts must be placed "buckets". By creating, we
                        know which users to send each email to. You could have
                        one bucket with all of your users or 100 buckets for
                        every specific customer types. The choice is all yours!
                      </strong>
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 yr-mt-20">
                    <div className="yr-workspace">
                      <ul>
                        <li
                          onClick={() => this.onClickNext("tab2")}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="row">
                            <div className="col-md-3 kt-align-center">
                              <img
                                src="../assets/media/icons/plus.svg"
                                alt=""
                              />
                            </div>
                            <div className="col-md-9">
                              <h2>Click here to create your first bucket</h2>
                            </div>
                            <div className="col-md-12  yr-mt-20 kt-align-center">
                              <img
                                src="../assets/media/icons/automated-marketing.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              this.state.tab == "" && (
                <div>
                  {" "}
                  <p
                    className="kt-margin-t-30"
                    style={{
                      color: "#959393 !important",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    <strong>
                      All Contacts must be placed "buckets". By creating, we
                      know which users to send each email to. You could have one
                      bucket with all of your users or 100 buckets for every
                      specific customer types. The choice is all yours!
                    </strong>
                  </p>
                  <DatatableList
                    createBucket={this.createBucket}
                    userdata={this.props.user}
                  />
                </div>
              )
            )}

            <div
              className="yr-body-p yr-mt-20 automatedMarketing"
              style={{
                display: this.state.tab == "tab2" ? "inline-block" : "none",
              }}
            >
              <div className="row">
                <form
                  id="uploadContacts"
                  name="uploadContactsForm"
                  encType="multipart/form-data"
                  method="post"
                >
                  <div className="col-lg-12 col-md-12 col-sm-12 focusError">
                    <p>What would you like to name your bucket?</p>
                    <div class="yr-line-input" style={{ marginBottom: "30px" }}>
                      <input
                        type="text"
                        name="title"
                        onChange={(e) => this.onchange(e)}
                        value={this.state.title}
                        style={{marginBottom: 2, height: 20}}
                        className={
                          errors && errors["title"]
                            ? "form-control"
                            : "form-control"
                        }
                      />
                      <div style={{ color:'#fd2727',marginBottom:30}}>{this.state.bucket_msg_err}</div>
                      {errors && errors.hasOwnProperty("title") && (
                        <CustomErrorField
                          message={errors["title"]}
                          className="error invalid-feedback"
                        />
                      )}
                    </div>
                    <p>
                      Let's import some contacts! Please choose a CSV file and
                      separate each column with relevant information.
                      <br />
                      For each contact, in addition to the Email address, you
                      can include other properties (First Name, Last Name,
                      Company Name, Phone Number, SMS Number, etc.) <br />
                      <a href="/assets/sample.csv">
                        Download our sample CSV file
                      </a>{" "}
                      to structure your import.
                    </p>
                  </div>
                  <div className="col-lg-7 col-md-9 col-sm-12 yr-mt-20 focusError">
                    <div className="yr-workspace">
                      <div className="uploadfile">
                        <label for="file-upload" className="custom-file-upload">
                          <div>
                            <i className="fa fa-cloud-upload"></i>
                          </div>
                          <div>Browse</div>
                        </label>
                        <input
                          id="file-upload"
                          name="uploadCSV"
                          type="file"
                          onChange={(e) => this.handleCSV(e)}
                          accept="text/csv"
                          style={{ display: "none" }}
                        />
                      </div>
                      <p>{this.state.uploadCSV}</p>
                      {errors && errors.hasOwnProperty("uploadCSV") && (
                        <div style={{ color: "red" }}>
                          {errors["uploadCSV"]}
                        </div>
                      )}
                      <div className="yr-mt-20 text-right">

                        {
                          this.state.uploadCSV == '' ? (<input
                            className="btn linear-gradient zy-save-btn width-100"
                            type="button"
                            onClick={() => this.Next()}
                            value="Continue"
                          />) : (<input
                            className="btn linear-gradient zy-save-btn width-100"
                            type="button"
                            onClick={() => this.onClickNext("tab3")}
                            value="Continue csv"
                          />)

                        }

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="yr-body-p yr-mt-20 automatedMarketing"
              style={{
                display: this.state.tab == "tab3" ? "inline-block" : "none",
              }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h4>Let's categorize each column!</h4>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 yr-mt-20">
                  <div className="yr-workspace yr-mt-20">
                    <div className="yr-cat-column">
                      {this.state.rows.length > 0 &&
                        this.state.rows.map((data, key) => {
                          if (data.type == 'email') {
                            return (
                              <div
                                className="yr-cat-columnBox"
                                name={data.field}
                              >
                                <div className="yr-title-select">
                                  {/* <i class="edit icon"></i> */}
                                  <span className="editicon">
                                    <select
                                      className="form-control yr-tableSelect"
                                      disabled={true}
                                      name={"property[" + data.field + "]"}
                                      onChange={(e) => this.onchangeHeading(e)}
                                      value={this.state.heading[0].hid}
                                    >
                                      {this.renderHeading()}
                                    </select>
                                  </span>
                                  {/* <span className="infoicon"><i class="info icon"></i>
                                                                </span> */}
                                </div>
                                <ul>
                                  {data.value != "" &&
                                    JSON.parse(data.value).map(
                                      (dataD, key2) => {
                                        return <li>{dataD}</li>;
                                      }
                                    )}
                                </ul>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="yr-cat-columnBox"
                                name={data.field}
                              >
                                <div className="yr-title-select">
                                  <i class="trash icon"
                                    onClick={(e) => this.deleteColumn(key)}
                                    title="Delete column"
                                    style={{ cursor: 'pointer' }}
                                  ></i>
                                  <i class="edit icon"></i>
                                  <span className="editicon">
                                    <select
                                      className="form-control yr-tableSelect"
                                      name={"property[" + data.field + "]"}
                                      value={
                                        typeof this.state.pval[data.field] ==
                                          "undefined"
                                          ? ""
                                          : this.state.pval[data.field]
                                      }
                                      onChange={(e) =>
                                        this.onchangeHeading(e, data.field)
                                      }
                                    >
                                      <option key={0} value="">
                                        Not Recognized
                                      </option>
                                      {this.renderHeading()}
                                    </select>
                                  </span>
                                  <i
                                    class="add icon"
                                    title="Add new field"
                                    onClick={(e) => this.addColumn("Add", data)}
                                    style={{ cursor: 'pointer' }}
                                  ></i>
                                  {/* <span className="infoicon"><i class="info icon" title='Remove column'></i>
                                                                </span> */}
                                </div>
                                <ul>
                                  {data.value != "" &&
                                    JSON.parse(data.value).map(
                                      (dataD, key2) => {
                                        return <li>{dataD}</li>;
                                      }
                                    )}
                                </ul>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      className="btn linear-gradient zy-save-btn width-100"
                      onClick={() => this.onClickNext("tab4")}
                      style={{ marginTop: "1rem" }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
     { /* Mosal 1 Start here Add varible */}

          <div
            className={this.state.modalstatus_Add_Variable ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: this.state.modalstatus_Add_Variable ? "block" : "none" }}
          >
          <div className="modal-dialog modal-dialog-centered" role="document">
            {this.state.loading && <div className="ui loading form"></div>}
            <div className="modal-content white-bg yr-campaigns-model" style={{ maxWidth: 540, margin: 'auto' }}>
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.modalClose_AddVariable()}
              >
                X
              </button>
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title pt-0">Add Variables</h5>
              </div>
              <div className="modal-body new-modal-body pb-4">
             <div className="col-lg-12 col-md-12 col-sm-12 focusError">
              
              <div className="row mt-4">
                  <div className="col-lg-12 pr-0">
                    <input type="text"
                      id="notes_team_member"
                      placeholder="Enter variable"
                      class="form-control"
                      value={this.state.vari}
                      onChange={(e) => this.onchangev(e)}
                    />
                    {this.state.vmsg_err != '' ? (
                        <div class="col-lg-12 pl-0" style={{ color: 'red' }} >{this.state.vmsg_err}</div>
                        ) : (<div></div>)
                        }
                    </div>
                  </div>
              </div>
              
         
            <div className="md-footer d-flex justify-content-end align-items-center mt-5">
              <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal" style={{ minWidth: 90, height: 35, position: 'relative', zIndex: 10 }}>
                <span style={{ paddingTop: 5, paddingBottom: 5 }} onClick={() => this.modalClose_AddVariable()}>Cancel</span></button>
              <button type="button" id="save_var" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.saveV()} style={{ minWidth: 90, height: 35, position: 'relative', zIndex: 10 }}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
{/* Mosal 1 End hare veribale */}


            { /* Start here Add varible field */}

            <div
              className={this.state.modalstatus_Variable ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
              data-backdrop="static"
              tabIndex="-1"
              id="backModal"
              role="dialog"
              aria-labelledby="staticBackdrop"
              aria-hidden="true"
              style={{ display: this.state.modalstatus_Variable ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                {this.state.loading && <div className="ui loading form"></div>}
                <div className="modal-content white-bg yr-campaigns-model" style={{ maxWidth: 540, margin: 'auto' }}>
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.modalClose_Variable()}
                  >
                    X
                  </button>
                  <div className="modal-header border-bottom-0">
                    <h5 className="modal-title pt-0">Customers added to this bucket posses the following variables:</h5>
                  </div>
                  <div className="modal-body new-modal-body pb-4 px-4">
                  <div className= {this.state.vmsg == '' ? 'dp--height' : 'dp--height maxHeight-320'}>
                    <div className="col-sm-12 yr-mt-20">
                    {this.state.vmsg != '' ? (
                    <div class="col-lg-12 ui green message del-msg-padding">{this.state.vmsg}</div>
                  ) : (<div></div>)
                  }
                   
                    <div className="yr-workspace" style={{position: 'relative'}}>
                        <div id="teamMemberCallList" className={this.state.disableClassName}>
                            <ReactMultiSelectCheckboxes
                              options={this.state.variable}
                              placeholderButtonLabel="Select variable"
                              onChange={this.VariableChange}
                              value={this.state.variable2}
                              setState={this.state.variable2}
                              isOpen={false}
                            />
                          </div>
                          <a>&nbsp;</a>
                          <div className="addicon" style={{ position: 'absolute', top: 10, left: 334, zIndex: 2}} onClick={this.modalStyle}>
                            <div className="pointer" title="Add Variable" onClick={()=>this.viewVariable()} ><img src="../../assets/images/plus-blue.svg" alt="" width="10" height="10"/></div>
                          </div>
                          {/* </div> */}
                        </div>
                        </div>
                    </div>
                    <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                      <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal" style={{ minWidth: 90, height: 35, position: 'relative', zIndex: 10 }}>
                        <span style={{ paddingTop: 5, paddingBottom: 5 }} onClick={() => this.modalClose_Variable()}>Cancel</span></button>
                      <button type="button" id="save_var" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.save()} style={{ minWidth: 90, height: 35, position: 'relative', zIndex: 10, marginRight:'9.82rem' }}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End hare veribale field */}

            <div className="yr-body-p yr-mt-20 automatedMarketing"
              style={{
                display: this.state.tab == "tab4" ? "inline-block" : "none",
              }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <p>
                    <strong>
                      We're almost done creating your bucket! Please confirm the
                      following:
                    </strong>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 yr-mt-20">
                  <div className="yr-workspace  yr-mt-20">
                    <p>
                      <label className="kt-checkbox chat-checkbox">
                        <input
                          type="checkbox"
                          name="fTerm"
                          value="1"
                          checked={
                            this.state.checkTerm.indexOf("1") > -1
                              ? true
                              : false
                          }
                          onClick={(e) => this.oncheckTerm(e)}
                        />{" "}
                        I've done prior business with users on this list OR ther
                        have subscribed to received email communication.
                        (Required for email sending)
                        <span />
                      </label>
                    </p>
                    <p>
                      <label className="kt-checkbox chat-checkbox">
                        <input
                          type="checkbox"
                          name="sTerm"
                          value="2"
                          checked={
                            this.state.checkTerm.indexOf("2") > -1
                              ? true
                              : false
                          }
                          onClick={(e) => this.oncheckTerm(e)}
                        />{" "}
                        These users have provided explicit consent to recive SMS
                        communication (Required for outbound SMS messages)
                        <span />
                      </label>
                    </p>
                    <div className="yr-mt-20">
                      <button
                        className="btn linear-gradient zy-save-btn width-100"
                        onClick={() => this.onClickNext("tab5")}
                        disabled={
                          this.state.checkTerm.length == 2 ? false : true
                        }
                      >
                        I'm done!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.dialogShow ? "modal fade show" : "modal fade"
              }
              data-backdrop="static"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="staticBackdrop"
              aria-hidden="true"
              style={{ display: this.state.dialogShow ? "block" : "none" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: 600 }}
              >
                {this.state.dialogLoading && (
                  <div className="ui loading form"></div>
                )}
                <div className="modal-content">
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => this.addColumn("Close", "")}
                  >
                    X
                  </button>
                  <div className="modal-header">
                    <h5 className="modal-title">{this.state.dialogTitle == "Delete" ? "Confirm your action" : "Add Field"}</h5>
                  </div>
                  <div className="modal-body">
                    <div className="px-4">
                      {this.state.dialogTitle == "Add" ? (
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Field :
                          </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div className="ui fluid input">
                                <input
                                  autocomplete="off"
                                  name="newField"
                                  placeholder=""
                                  type="text"
                                  value={this.state.newField}
                                  onChange={(e) => this.onchange(e)}
                                />
                              </div>
                              {errors && errors.hasOwnProperty("newField") && (
                                <div className="red">{errors["newField"]}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        this.state.dialogMessage
                      )}
                    </div>
                  </div>
                  {this.state.dialogTitle == "Delete" ? (
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        onClick={(e) => this.addColumn("Close", "")}
                        data-dismiss="modal"
                      >
                        <span>No</span>
                      </button>
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) => this.addColumn("Delete", "")}
                      >
                        Yes
                      </button>
                    </div>
                  ) : (
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        onClick={(e) => this.addColumn("Close", "")}
                        data-dismiss="modal"
                      >
                        <span>Cancel</span>
                      </button>
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) => this.addColumn("Save", "")}
                      >
                        Add
                      </button>
                    </div>
                  )}

                </div>
              </div>
            </div>
            {/* email errors */}

            <div
              className={
                this.state.isEmailError ? "modal fade show" : "modal fade"
              }
              data-backdrop="static"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="staticBackdrop"
              aria-hidden="true"
              style={{ display: this.state.isEmailError ? "block" : "none" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: 600 }}
              >
                {this.state.dialogLoading && (
                  <div className="ui loading form"></div>
                )}
                <div className="modal-content">
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => this.addColumn("Close1", "")}
                  >
                    X
                  </button>
                  <div className="modal-header">
                    <h5 className="modal-title">CSV Errors</h5>
                  </div>
                  <div className="modal-body">
                  <div class="kt-section__body" style={{maxHeight:'500px',overflow:'auto'}}>
                      {this.displayEmailErrors()}
                  </div>
                  </div>
                  
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        onClick={(e) => this.addColumn("Close1", "")}
                        data-dismiss="modal"
                      >
                        <span>Cancel</span>
                      </button>
                      
                    </div>
                
</div>
                </div>
              </div>
            

{/* end email errors */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(Contacts);
