import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import "./dataTable.css";
import { Segment, Table } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import DatatableDeleteBox from "./DatatableDeleteBox";
import TeamDeleteBox from "./TeamDeleteBox";
import DialogBoxAddEdit from "./DialogBoxAddEdit";
import { DatatableRow } from "./DatatableRow";
import { CSVLink, CSVDownload } from "react-csv";
import utilFunc from "../../../../../util_funs";
import DialogContactDetail from "./DialogContactDetail";
import DialogBoxContactVia from "./DialogBoxContactVia";
import { Message } from "semantic-ui-react";
import DialogBoxDetailOnCSVUploadError from "./DialogBoxDetailOnCSVUploadError";

const tableHeader = [
 {
    title: "Title",
    width: "0",
    // sort: "title",
  },
  {
    title: "Subject",
    width: "0",
    // sort: "subject",
  },
  {
    title: "Email",
    width: "0",
    // sort: "email",
  },
  {
    title: "Opens",
    width: "0",
    // sort: "opens",
  },

  {
    title: "Clicks",
    width: "0",
    // sort: "clicks",
  },
  {
    title: "",
    width: "0",
    // sort: "",
  },
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      team: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modalstatusDelete: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modalstatusConfirm: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      notification_text: 0,
      notification_email: 0,
      duplicateamailError: "",
      duplicatePhoneError: "",
      duplicatePhoneErrorSms: "",
      renderOption1: [],
      renderOption2: [],
      renderOption3: [],
      renderOption4: [],
      country_code: "+1",
      country_code_sms: "+1",
      phone_number: "",
      sms_number: "",
      csvdata: [],
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
      profile_pic: null,
      croppedImage: null,
      DeletePic: 1,
      oldImage: 1,
      DeletePicAdd: 0,
      modaldetailstatus: false,
      modalContactVia: false,
      modalContactViaObject: {
        show: false,
        title: "Please select one of the below option",
        loading: false,
      },
      currentContactLabelData: [],
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      modalstatusCSVfailure: false,
      csvFailArr: [],
      currentContactLabelDataOnEdit: [],
      currentContactLabelDetailDataOnEdit: [],
      calllist_id: "",
      mass_action: "",
      notesdata: "",
      tmnotesdata: "",
      emailcontent: "",
      yourteamdata:[],
      startDate : null,
      endDate : null
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {

    console.log(this.props.params);

    //this.setState({pwsid: this.props.match.params.id});
    //this.loadData({});
    this.country_code();
  }

  componentDidUpdate(prevProps) {

    if(this.props.indextime!=prevProps.indextime){
              
      this.loadData({});
    }
  }

  handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        src: fileReader.result,
        oldImage: 0,
        DeletePicAdd: 1,
        DeletePic: 1,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  handleRemovePic = (key) => {
    this.setState({ DeletePic: 0 });
  };
  handleRemovePicAdd = (key) => {
    this.setState({ DeletePicAdd: 0 });
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  country_code = (params) => {
    api.manageTeam.callCountryCode().then((data) => {
      try {
        this.setState({ renderOption1: data.data.defaultCountryCodeArr });
        this.setState({ renderOption2: data.data.remailCountryCodeArr });
        this.setState({ renderOption3: data.data.defaultCountryCodeArr });
        this.setState({ renderOption4: data.data.remailCountryCodeArr });
      } catch (err) {
        console.log(err);
      }
    });
  };

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  
  onSubmitFilter = (filter, val) => {
    if( filter == 'daterange'){
      console.log("dateRange",val);
      this.setState({...this.state,startDate:val.startDate,endDate:val.endDate},() => this.loadData({startDate:val.startDate,endDate:val.endDate}));
    }else if(filter !== this.state.q) {
      this.loadData({ [filter]: val, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteAction();
    }

    if (data.modalType == "ResendDeviceKey") {
      this.onResendDeviceKeyAction();
    }

    if (data.modalType == "Popup") {
      this.setState({
        modalstatus: false,
        modaltitle: "",
        modalmessage: "",
        modalbuttonmsg: {},
        modalType: "",
      });
    }
  };
  editContact = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.cid === id);
    let modaldata = this.state.lists[recordlist];
    console.log(modaldata);
    let editDateModule = [];
    let moduleList = this.state.accessModuleList;

    moduleList.forEach((item, index) => {
      Object.keys(item).forEach(function (key) {
        if (modaldata.linksetting.includes(key)) editDateModule.push(key);
      });
    });
    this.setState({
      country_code: modaldata.country_code,
    });

    this.setState({
      phone_number: modaldata.phone_number,
    });
    if (modaldata.notification_text == "1") {
      this.setState({
        notification_text: 1,
      });
    } else {
      this.setState({
        notification_text: 0,
      });
    }

    if (modaldata.notification_email == "1") {
      this.setState({
        notification_email: 1,
      });
    } else {
      this.setState({
        notification_email: 0,
      });
    }

    api.marketing
      .getContactLabelAndDataOnEdit({
        bid: this.props.bid,
        user_id: this.props.userdata.user_id,
        cid: id,
      })
      .then((data) => {
        this.setState({
          modalstatusAddEdit: true,
          modaltitle: "Edit Contact",
          modalType: "Edit",
          modaldata: modaldata,
          modaldata: { opt: "static", submitBtnName: "Update" },
          selectedOptions: editDateModule,
          currentContactLabelDataOnEdit: data.data.data.contact[0],
          currentContactLabelDetailDataOnEdit: data.data.data.contactDetail,
          phone_number: data.data.data.contact[0].phone,
        });
      });
  };

  deleteConfirm = (notes_id) => {
   
    //alert(notes_id);
    this.setState({
          modalstatusDelete: true,
          modaltitle: "Confirm your action",
          modalmessage: "Are you sure you want to send it again ?",
          modalbuttonmsg: { submit: "Yes", cancel: "No", call_notes_id:notes_id },
          modalType: "Delete",
         
        });
  };

  OtherDetails = (id) => {
    this.setState({
      modalstatus: true,
      modaltitle: "This bucket is associated with 4 campaigns",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Details",
    });
  };

  ResendDeviceKey = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to send it again ?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "ResendDeviceKey",
      modaldata: modaldata,
    });
  };

  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });

    api.marketing
      .deleteContact({ cid: this.state.modaldata.cid, bid: this.props.bid })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          let recordDeleted = this.state.lists.findIndex(
            (list) => list.cid === data.data.cid
          );

          let oldArr = this.state.lists;
          oldArr.splice(recordDeleted, 1);
          if (oldArr.length == 0) {
            var link = document.getElementById("backtocontats");
            link.click();
          } else {
            this.setState(
              {
                modalFormLoader: false,

                modalstatusDelete: false,
                modaltitle: "",
                modalmessage: "",
                modalbuttonmsg: {},
                modalType: "",
                modalErrorMsg: false,
              },
              function () {
                this.loadData({});
                this.props.loadData();
              }
            );
            this.props.showUserToaster(
              "green",
              "User has been deleted successfully."
            );
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
  };

  onResendDeviceKeyAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageTeam
      .ResendDeviceKey({ id: this.state.modaldata.id })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });
          this.props.showUserToaster(
            "green",
            "Device key has been sent successfully."
          );
          utilFunc.scrollTop(800);
        } catch (err) {
          console.log(err);
        }
      });
  };
  preOpensetting = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let access_selected = this.state.lists[recordlist].linksetting;
    let access_selected_lists_html = "";
    access_selected_lists_html = access_selected.map((item, value) => (
      <p
        key={item}
        style={{
          textTransform: "capitalize",
          color: "#6c7293",
          fontWeight: "400",
        }}
      >
        {item.replace(/_/g, " ")}
      </p>
    ));
    this.setState({
      modalstatus: true,
      modaltitle: "Setting/Privileges",
      modalmessage: access_selected_lists_html,
      modalbuttonmsg: { submit: "Ok", cancel: "" },
      modalType: "Popup",
    });
  };

  modalClose = (flag, fm) => {
    // document.getElementById("isd_code_id_review").selectedIndex = 0;
    // document.getElementById("isd_code_id_review_sms").selectedIndex = 0;
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      duplicateamailError: "",
      duplicatePhoneError: "",
      currentContactLabelData: [],
      currentContactLabelDetailDataOnEdit: [],
    });
  };

  modalCloseDeleteBox = (flag, fm) => {
    this.setState({
      modalstatusDelete: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  modalCloseTeamDeleteBox = (flag, fm) => {
    this.setState({
      modalstatusteamDelete: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  handleCheck = (key) => {
    const target = key.target;
    if (target.checked) {
      this.setState({
        [target.name]: 1,
      });
    } else {
      this.setState({
        [target.name]: 0,
      });
    }
  };

  handleDrop = (key) => {
    const target = key.target.value;
    this.setState({
      country_code: target,
    });
  };

  handleDropSms = (key) => {
    const target = key.target.value;
    this.setState({
      country_code_sms: target,
    });
  };

 onValidSubmit = (sendData) => {
    if (this.state.modalType == "Add") {
      var $msg = "User has been added successfully.";
    } else {
      var $msg = "User has been updated successfully.";
    }
    this.loadData({});
    this.setState({
      modalstatusAddEdit: false,
      currentContactLabelData: [],
      currentContactLabelDetailDataOnEdit: [],
    });
    this.showUserToaster("green", $msg);
    utilFunc.scrollTop(800);
  };


  downloadCSV = (event, done) => {

    //alert('Hi');
    // let list = this.state.lists;
    // let selected_arr = [];
    // list.filter(function (key) {
    //   if (key["checked"] == true) {
    //     selected_arr.push(key["auto_id"]);
    //   }
    // });

    let params = {};
    
    params["_order"] = this.state._order;
    params["_limit"] = this.state._limit;
    params["_page"] = this.state._page;
    params["_sort"] = this.state._sort;
    params["q"] = this.state.q;
    params["pwsid"]= this.props.pwsid;
    params["strsearch"] = this.state.q;
    params['dateFrom'] = this.state.startDate;
    params['dateTo'] = this.state.endDate;
    console.log(params);

    // this.setState({ loading: true });

    api.marketing.exportplayBookBranchWiseStats(params).then((data) => {
      try {
        this.setState(
          {
            csvdata: data.data.data,
          },
          function () {
            setTimeout(
              function () {
                document.querySelector("#chatcsv").click();
                this.setState({ loading: false });
              }.bind(this),
              1000
            );
          }
        );
      } catch (err) {}
    });
  };

  loadData = (params) => {
    
    console.log(this.props);

    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });
   
    params["pwsid"]= this.props.pwsid;
    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }

    this.setState(newState, () => {
      this.setState({ loading: true });
      api.marketing.playBookBranchWiseStats(query).then((data) => {
       try {
          let accessModuleStore = [];
            this.setState({ playbookname: data.playbookname });
            //alert(data.playbookname);
            let datatableRowstemp;
          if (data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                pid={this.props.pwsid}
                userdata={this.props.userdata}
                ResendDeviceKey={this.ResendDeviceKey}
                preOpensetting={this.preOpensetting}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.totalrecord,
            lists: data.data.list,
            accessModuleList: accessModuleStore,
            
          },()=>{
            //this.getTeamMembers();
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

 modalCloseContactBox = (flag, fm) => {
    this.setState({
      modaldetailstatus: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  modalCloseContactViaBox = () => {
    let modalContactViaObject = {
      show: false,
      title: "Please select one of the below option",
      loading: false,
    };
    this.setState({
      modalContactViaObject: modalContactViaObject,
    });
  };
 
showUserToaster = (color, msg) => {
    
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    //setTimeout(
    // function () {
    // this.setState({ userToaster: false });
    //}.bind(this),
    //5000
    //);
  };

  showCustomToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
  };

  onValidSubmitCSV = (e) => {
    this.loadData({});

    this.setState({
      modalstatusAddEdit: false,
    });

    if (e.failStatus == false) {
      this.showUserToaster("green", "CSV User has been added successfully.");
      utilFunc.scrollTop(800);
    } else {
      this.setState({
        modalstatusCSVfailure: true,
        modaltitle: "CSV upload error",
        modalmessage: "Are you sure you want to delete?",
        modalbuttonmsg: { submit: "Yes", cancel: "No" },
        modalType: "Details",
        csvFailArr: e.failArray,
      });
    }
  };
  modalCloseCSVError = (e) => {
    this.setState({
      modalstatusCSVfailure: false,
    });
  };

render() {
    return (
      <Segment style={{ width: "100%", paddingRight: "20px", paddingLeft: "20px" }}>
       
        {this.state.loading && <div className="ui loading form"></div>}
       
         <Message
          color="green"
          
          style={{
            display: this.state.responsemsg ? "block" : "none",
            marginTop: 30,
          }}
        >
          {this.state.responsemsg}
        </Message>
        <DatatableFilter
           filter={this.state.q}
           totalCount={this.state.totalCount}
           onSubmitFilter={this.onSubmitFilter}
           loading={this.state.loading}
           csvdata={this.state.csvdata}
           playbookname={this.state.playbookname}
           downloadCSV={this.downloadCSV}
           indextime={new Date()}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          yourNotes={this.yourNotes}
          limit={this.state._limit.toString()}
        />
        <DialogBoxDetail
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          emailcontent={this.state.emailcontent}
          modalClose={this.modalClose}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        />
       <DatatableDeleteBox
          show={this.state.modalstatusDelete}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalCloseDeleteBox={this.modalCloseDeleteBox}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          deleteConfirm={this.deleteConfirm}
          loading={this.state.loading}
        />
       
        <CSVLink
          id="chatcsv"
          data={this.state.csvdata}
          filename={this.state.playbookname+'.csv'}
          className="hidden"
          target="_blank"
        />
    </Segment>
    );
  }
}
