import axios from "../config/axios";

const chatsetup = {
  saveHideShowData: (formData) =>
    axios.post("/chatsetup/showhideurl/save", { data: formData }),

  getBotHideShowList: ()=>
    axios
      .post("/chatsetup/showhideurl/list", {  })
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => console.log(err)),
  
  getChatBotHoursData: ()=>
      axios
        .post("/chatsetup/Chatbothours/gethoursdata", {  })
        .then((resp) => (resp ? resp.data : null))
        .catch((err) => console.log(err)),  
        
  getCurrentTime: (data)=>
        axios
          .post("/chatsetup/Chatbothours/currentTime", data)
          .then((resp) => (resp ? resp.data : null))
          .catch((err) => console.log(err)),    

   saveTimeZoneData: (data)=>
          axios
            .post("/chatsetup/Chatbothours/saveuserchatbothours", data)
            .then((resp) => (resp ? resp.data : null))
            .catch((err) => console.log(err)),    
            
            
            saveWorkingHoursData: (data) =>
            axios
              .post("/chatsetup/Workinghours/saveuserchatbothours", data)
              .then((resp) => (resp ? resp.data : null))
              .catch((err) => console.log(err)),
        
          getWorkingHoursData: () =>
            axios
              .post("/chatsetup/Workinghours/gethoursdata", {})
              .then((resp) => (resp ? resp.data : null))
              .catch((err) => console.log(err)),
             
  
};

export default chatsetup;
