import React from 'react';

import CustomErrorField from "../../../../Form/CustomErrorField";
class MultipleChoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            questions:[''],
            errors:[],
            edit_id:'',
            multiple_choice_options:[]
        };

    }


    componentDidMount() {
       
        this.onLoad();
    }
    
    componentDidUpdate(prevProps) {
     
        if(this.props.indextime!=prevProps.indextime){
          
           this.onLoad();
        }
      
      }
      onLoad=()=>{
      
         if(this.props.branch_new_id !=''){
            
             let temp_state=this.state.multiple_choice_options;
            
             temp_state.target_branch_id=this.props.branch_new_id;
            this.setState({multiple_choice_options:temp_state})
         }else{
             
             if(this.props.create_modal_status==''){
            if(this.props.branchDetails.multiple_choice_options.confirmation_button==''){
               this.setState({multiple_choice_options:{confirmation_button:'',option:{'1':''},target_branch_id:''}})
            }else{
                this.setState({multiple_choice_options:this.props.branchDetails.multiple_choice_options})
            }
          }
            
         }
        
       
      }
      saveMultipleChoiceOption=()=>{
        const errors=[];
        console.log(this.props)
      
        new Promise(resolve => {
            let ques=this.state.questions;
            
            Object.keys(this.state.multiple_choice_options.option).forEach((data,key)=>{
               if(this.state.multiple_choice_options.option[data]==''){
                 errors[data]='This field is required';
                 
               }
            })
            //alert(this.state.multiple_choice_options.confirmation_button)
            if(this.state.multiple_choice_options.confirmation_button==''){
               
                errors['confirmation_button']='This field is required';
            }

            if(this.state.multiple_choice_options.target_branch_id==''){
               
                errors['target_branch_id']='This field is required';
            }


            
            resolve(errors);


        }).then((data) => {
          if(Object.keys(data).length>0){
              this.setState({errors:data})
          }else{
              this.setState({loading:true})
              this.props.saveMultipleChoice(this.state.multiple_choice_options,(data)=>{
                this.setState({loading:false},function(){
                    this.props.popupClose();
                    let scrolltop=document.querySelector("#multiplecholice").offsetTop - document.body.scrollTop;
                    window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
                })
              })
          }
           
           
        })
      }


      multipleChoiceAction=(tab,data,index)=>{
        if(tab=='add'){
           
           
           
           let multiple_choice=this.state.multiple_choice_options;
           let lastkey_temp=Object.keys(multiple_choice.option);
           let lastkey=lastkey_temp.slice(-1)[0] ;
           multiple_choice.option[lastkey+1]='';
           
           this.setState({multiple_choice_options:multiple_choice})
        }
       
        if(tab=='deletechoice'){
         let multiple_choice=this.state.multiple_choice_options;
         delete multiple_choice.option[data];
         this.setState({multiple_choice_options:multiple_choice})
       }
       if(tab=='addchoice'){
            let multiple_choice=this.state.multiple_choice_options;
            multiple_choice.option[index]=data.target.value;
            this.setState({multiple_choice_options:multiple_choice})
       }
       if(tab=='confirmation_change'){
            let multiple_choice=this.state.multiple_choice_options;
            multiple_choice.confirmation_button=data.target.value;
            this.setState({multiple_choice_options:multiple_choice})
       }
       
       if(tab=='go_to_branch'){
            let multiple_choice=this.state.multiple_choice_options;
            multiple_choice.target_branch_id=data.target.value;
            this.setState({multiple_choice_options:multiple_choice})
       }
       }



    render(){

        const {errors}=this.state;
        console.log(errors)
        
    return(
        <div>
       <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
        <div className="box-title">Add Multiple Questions</div>

        

        {typeof this.state.multiple_choice_options.option !='undefined' && Object.keys(this.state.multiple_choice_options.option).map((obj,index)=>{
         let indexto_update=index+1
       return (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: 10 }}>
                          <label>Option {index+1}</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group relative" style={{ marginBottom: 10 }}>
                                <input type="text" value={this.state.multiple_choice_options.option[obj]} className={
                                        Object.keys(errors).length > 0 && typeof errors[obj] != 'undefined' 
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    } placeholder={"Option "+indexto_update} onChange={(e)=>this.multipleChoiceAction('addchoice',e,obj)}/>
                                {index>0 && 
                                <a class="cusrsorpointer choicedelete" title="Delete" onClick={()=>this.multipleChoiceAction('deletechoice',obj,'')}><i class="la la-trash"></i></a>
                                }

                            { Object.keys(errors).length > 0 && typeof errors[obj] != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors[obj] }
                                                                        className="error invalid-feedback"
                                                                    />
                                                                )}
                            </div>
                        </div>                        
                    </div>
       )
    }) }
                    {/* <div className="row">
                        <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: 10 }}>
                                <label>Questions 2</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: 10 }}>
                                <input type="text" className="form-control" placeholder="Looking for sale" />
                            </div>
                            <div className="delBtn">
                                <button type="button" class="btn btn-icon"><i class="yr-delete red"></i></button>
                            </div>
                        </div>                        
                    </div> */}
                    <a className="add-gradient form-group cusrsorpointer" onClick={()=>this.multipleChoiceAction('add','','')}>+Add</a>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: 10 }}>
                                <label>Confirmation Button</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: 10 }}>
                                <input type="text" className={
                                        Object.keys(errors).length > 0 && typeof errors['confirmation_button'] != 'undefined'
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    } onChange={(e)=>this.multipleChoiceAction('confirmation_change',e)} placeholder="Confirmation Button"  value={typeof this.state.multiple_choice_options.confirmation_button!='undefined' ? this.state.multiple_choice_options.confirmation_button:''}  />
                            
                                { Object.keys(errors).length > 0 && typeof errors['confirmation_button'] != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors['confirmation_button'] }
                                                                        className="error invalid-feedback"
                                                                    />
                                                                )}
                            
                            </div>
                        </div>                        
                    </div>
                    <div className="form-group">
                        <label className="bold">Go to branch</label>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="yr-selectArrow">
                        <select className={
                                        Object.keys(errors).length > 0 && typeof errors['target_branch_id'] != 'undefined'
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    } onChange={(e)=>this.multipleChoiceAction('go_to_branch',e)} value={typeof this.state.multiple_choice_options.target_branch_id!='undefined' ? this.state.multiple_choice_options.target_branch_id:''}>
                                            <option value="">Select Branch</option>
                                            {this.props.branchList && this.props.branchList.map((value, type) => {
                                                return (
                                                    <option value={value.branch_id}>{value.branch_name}</option>
                                                )
                                            })}
                                        </select>

                                        { Object.keys(errors).length > 0 && typeof errors['target_branch_id'] != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors['target_branch_id'] }
                                                                        className="error invalid-feedback"
                                                                    />
                                                                )}

                        </div>
                        </div>
                    </div>
                    <a className="add-gradient form-group cursorpointer" onClick={()=>this.props.createNewBranch('multiplechoice')}>+Create new branch</a>
        
    </div>
    <div className="row">
        <div className="col-12 d-flex justify-content-end">
     <button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()}><span> Close </span></button>
     <button type="submit" className="btn linear-gradient yr-submitbtn" onClick={()=>this.saveMultipleChoiceOption()}>Save</button>
 </div>
    </div>
 </div>
    )
}
}


export default MultipleChoice;