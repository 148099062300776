import {
	configureStore,
	combineReducers,
	getDefaultMiddleware,
} from "@reduxjs/toolkit";

import api from "./middleware/api";

import userReducer from "./User";
import pageReducer from "./Page";

const reducer = combineReducers({
	user: userReducer,
	page: pageReducer,
});

const store = () => {
	return configureStore({
		reducer,
		middleware: [...getDefaultMiddleware(), api],
	});
};

export default store();
