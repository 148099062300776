import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import "./email.css";
import { library, icon } from "@fortawesome/fontawesome-svg-core";

import api from "../../../../api";
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import DatatableList from "./PlayBook/DatatableList";
import { Multiselect } from "multiselect-react-dropdown";
import { Dropdown } from 'semantic-ui-react'
import "../../Marketing/yr-style.css";
class PlayBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      tab: "",
      bucketList: [],
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      dialogTitle: "",
      dialogMessage: "",
      dialogLoading: false,
      dialogShow: false,
      title: "",
      pb_id:"",
      selectedValue: [],
      selectedValuePlayBook:[],
      checkedValue: [],
      actionId: "",
      playbookList:"",
      playbookList1: [],
      pb_flag:0,
    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Email",
      navigationName: "marketing",
      subHeaderTitle: "Re-Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.key != prevProps.location.key) {
      this.loadData();
    }
  }

  loadData = () => {
    api.marketing.playbookinfo({}).then((data) => {
      try {
        console.log(data.adminPlaybook);
        if (data.status) {
          this.setState({
            loading: false,
            bucketList: data.data,
            playbookList:  data.adminPlaybook.map((item, index) =>
                 item["type"] == 1
                  ? { value: item["play_id"], text: item["play_title"] }
                  : { value: item["play_id"], text: item["play_title"] }
              ),
            });
        } else {
          this.showToaster("red", data.data);
          utilFunc.scrollTop(800);
        }
        console.log(this.state.bucketList);
      } catch (err) {
        console.log(err);
      }
    });
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  setPbid = (e) => {

    let pb_id = e.target.value;
    alert( pb_id );
    if (e.target.value != "") {

          let pb_id = e.target.value;
           //alert( pb_id );
           this.setState({ pb_id: pb_id });
    } else {
          this.setState({ pb_id: '' });
    }
    
  };
// Start Rohit 
  onSelectPlaybook = (key, data) => {
    //console.log(selectedItem.play_id);
    //alert(data.value);
    this.setState({
      pb_id:  data.value,
     
    });
  };

  // End Rohit 
// Start Rohit 
  onRemovePlaybook = (selectedList, removedItem) => {
    this.setState({
      pb_id: "",
     
    });
   
  };

  // End Rohit 

createPlaybook = (action, data = null) => {
    if (action == "Close") {
      this.setState({
        dialogTitle: "",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: false,
        title: "",
        errors: {},
        selectedValue: [],
        selectedValuePlayBook:[],
        checkedValue: [],
        actionId: "",
      });
    }
    if (action == "Add") {
      this.setState({
        dialogTitle: "Add",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,
        title: "",
        pb_id:"",
        errors: {},
        pb_flag:0,
      });
    }
    if (action == "Added") {
      var obj = this;
      this.validateForm(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ dialogLoading: true });
          api.marketing
            .addplaybook({
              title: obj.state.title,
              bucket: obj.state.checkedValue,
              play_id: obj.state.pb_id,
            })
            .then((data) => {
              obj.setState({ dialogLoading: false });
              try {
                if (data.status) {
                  window.location.replace("/marketing/playbook/" + data.data);
                } else {
                  this.showToaster("red", data.data);
                  utilFunc.scrollTop(800);
                }
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
    }
    if (action == "Edit") {
      //alert('hi');
      //console.log('bucket-length');
      //console.log(data.bucketList.length);
      if( data.bucketList.length > 0 ){
            new Promise((resolve) => {
              let t = 0;
              var chkArr = [];
              data.bucketList.forEach(function (userItem1) {
                chkArr.push(userItem1.id);
                if (t == data.bucketList.length - 1) resolve(chkArr);
                t++;
              });
            }).then((chkArr) => {
              this.setState({
                dialogTitle: "Edit",
                dialogMessage: "",
                dialogLoading: false,
                dialogShow: true,
                title: data.title,
                pb_id:"",
                errors: {},
                selectedValue: data.bucketList,
                checkedValue: chkArr,
                actionId: data.id,
                pb_flag:1,
              });
            });
          }else{
            var chkArr = [];
            this.setState({
              dialogTitle: "Edit",
              dialogMessage: "",
              dialogLoading: false,
              dialogShow: true,
              title: data.title,
              pb_id:"",
              errors: {},
              selectedValue: data.bucketList,
              checkedValue: chkArr,
              actionId: data.id,
              pb_flag:1,
            });
          }
    }

    if (action == "Edited") {
      var obj = this;
      this.validateForm(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ dialogLoading: true });
          api.marketing
            .editplaybook({
              id: obj.state.actionId,
              title: obj.state.title,
              bucket: obj.state.checkedValue,
            })
            .then((data) => {
              obj.setState({ dialogLoading: false });
              console.log(data);
              try {
                if (data.status) {
                  obj.datable.loadData({});
                  obj.createPlaybook("Close");
                  obj.showToaster(
                    "green",
                    "Playbook has been edited successfully."
                  );
                  utilFunc.scrollTop(800);
                } else {
                  obj.showToaster("red", data.data);
                  utilFunc.scrollTop(800);
                }
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
    }

    if (action == "Delete") {
      this.setState({
        dialogTitle: "Delete",
        dialogMessage: "Are you sure you want to delete it?",
        dialogLoading: false,
        dialogShow: true,
        title: data.title,
        errors: {},
        selectedValue: [],
        checkedValue: [],
        actionId: data.id,
      });
    }


    if (action == "Deleted") {
      var obj = this;
      obj.setState({ dialogLoading: true });
      api.marketing.deleteplaybook({ id: obj.state.actionId }).then((data) => {
        obj.setState({ dialogLoading: false });
        try {
          if (data.status) {
            obj.datable.loadData({});
            obj.createPlaybook("Close");
            obj.showToaster("green", "Playbook has been deleted successfully.");
            utilFunc.scrollTop(800);
          } else {
            obj.showToaster("red", data.data);
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  validateForm = (data, callback) => {
    const errors = {};
    if (data.title == "") {
      errors["title"] = "This filed is required.";
    }
    // if (this.state.checkedValue.length == 0) {
    //   errors["selectedBkt"] = "This filed is required.";
    // }
    callback(errors);
  };

  onSelect = (selectedList, selectedItem) => {
    let checkedValueP = this.state.checkedValue;
    checkedValueP.push(selectedItem.id);
    let errList = this.state.errors;
    delete errList["selectedBkt"];
    this.setState({
      checkedValue: checkedValueP,
      errors: errList,
    });
  };

  onRemove = (selectedList, removedItem) => {
    let checkedValueP = this.state.checkedValue;
    checkedValueP.splice(checkedValueP.indexOf(removedItem.id), 1);
    this.setState({
      checkedValue: checkedValueP,
    });
    if (this.state.checkedValue.length == 0) {
      let errList = this.state.errors;
      errList["selectedBkt"] = "";
      this.setState({
        errors: errList,
      });
    }
  };

  onchange = (e) => {
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This filed is required.";
      this.setState({ errors: errList });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { errors } = this.state;
    const pbList = this.state.playbookList;

    return (
      <div
        className="mh-ktContainer kt-container kt-body  kt-grid kt-grid--ver completedchatmain"
        id="kt_body"
       
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact">
            <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster ? "block" : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
            {this.state.loading && <div className="ui loading form"></div>}
            {!this.state.loading && (
              <div>
                {" "}
                <DatatableList
                  ref={(datable) => {
                    this.datable = datable;
                  }}
                  createPlaybook={this.createPlaybook}
                />{" "}
              </div>
            )}
          </div>
        </div>

        <div
          className={this.state.dialogShow ? "modal fade show" : "modal fade"}
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: this.state.dialogShow ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 600 }}
          >
            {this.state.dialogLoading && (
              <div className="ui loading form"></div>
            )}
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => this.createPlaybook("Close")}
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title">
                  {this.state.dialogTitle == "Add"
                    ? "Add Playbook"
                    : this.state.dialogTitle == "Edit"
                    ? "Edit Playbook"
                    : this.state.dialogTitle == "Delete"
                    ? "Confirm your action"
                    : "Alert"}
                </h5>
              </div>
              <div className="modal-body">
                <div className="px-4">
                  {this.state.dialogTitle == "Add" ||
                  this.state.dialogTitle == "Edit" ? (
                    <div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Title :
                        </label>
                        <div className="col-lg-8">
                          <div className="field">
                            <div className="ui fluid input">
                              <input
                                autocomplete="off"
                                name="title"
                                placeholder=""
                                type="text"
                                value={this.state.title}
                                onChange={(e) => this.onchange(e)}
                              />
                            </div>
                            {errors && errors.hasOwnProperty("title") && (
                              <div className="red">{errors["title"]}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Buckets :
                        </label>
                        {this.state.bucketList.length == 0 ? (
                          <div>
                            <a
                              className="add-gradient"
                              href="/marketing/contacts"
                              style={{ marginTop: "23px" }}
                            >
                              Add Buckets
                            </a>
                          </div>
                        ) : (
                          <div className="col-lg-8">
                            <div className="field">
                              <div
                                className="ui fluid input"
                                style={{ marginTop: "5px" }}
                              >
                                <Multiselect
                                  options={this.state.bucketList} // Options to display in the dropdown
                                  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                  onSelect={this.onSelect} // Function will trigger on select event
                                  onRemove={this.onRemove} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                  closeIcon="circle"
                                  avoidHighlightFirstOption={true}
                                  
                                 />
                              </div>
                              {errors &&
                                errors.hasOwnProperty("selectedBkt") && (
                                  <div className="red">
                                    {errors["selectedBkt"]}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Start  Rohit  */}
                  {this.state.pb_flag == 0 ? (
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                            Choose template :
                        </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div
                                className="ui fluid input"
                                style={{ marginTop: "5px" , marginLeft: 0 }}
                              >
                                 <Dropdown  
                                 options={this.state.playbookList}
                                 placeholder='Select' 
                                 value={this.state.pb_id}
                                 search selection 
                                 onChange={this.onSelectPlaybook}
                                 className="form-control-2"
                                />
                              </div>
                             </div>
                          </div>
                      </div>
                        ) : (<div className="form-group row"></div>)
                    }
                   

                       {/* End Rohit  */}
                    </div>
                  ) : (
                    this.state.dialogMessage
                  )}
                </div>
              </div>
              {this.state.dialogTitle == "Add" ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    onClick={(e) => this.createPlaybook("Close")}
                    data-dismiss="modal"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Added")}
                  >
                    Next
                  </button>
                </div>
              ) : this.state.dialogTitle == "Edit" ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    onClick={(e) => this.createPlaybook("Close")}
                    data-dismiss="modal"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Edited")}
                  >
                    Save
                  </button>
                </div>
              ) : this.state.dialogTitle == "Delete" ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    onClick={(e) => this.createPlaybook("Close")}
                    data-dismiss="modal"
                  >
                    <span>No</span>
                  </button>
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Deleted")}
                  >
                    Yes
                  </button>
                </div>
              ) : (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Close")}
                  >
                    Ok
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(PlayBook);
