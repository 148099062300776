import axios from "../config/axios";

const transcriptchatlist = {
  
  getLists: (params) =>
  axios
      .get(`/chatlist/TranscriptChatlist/getchatlist?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  
    getTeamNotes: (params) =>
      axios
          .post('/chatlist/TranscriptChatlist/getTeamNotes', params)
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),   
          
     viewDetails: (params) =>
          axios
              .post('/chatlist/TranscriptChatlist/viewDetails', params)
              .then((resp) => resp.data)
              .catch((err) => console.log(err)),    
     
     saveTeamNotes: (params) =>
              axios
                  .post('/chatlist/TranscriptChatlist/saveTeamNotes', params)
                  .then((resp) => resp.data)
                  .catch((err) => console.log(err)),   

     deleteTeamNotes: (params) =>
                  axios
                      .post('/chatlist/TranscriptChatlist/deleteNote', params)
                      .then((resp) => resp.data)
                      .catch((err) => console.log(err)),  
    
     changeNotesStatus: (params) =>
                      axios
                          .post('/chatlist/TranscriptChatlist/changestatus', params)
                          .then((resp) => resp.data)
                          .catch((err) => console.log(err)),  
        
    getCSVData: (params) =>
                          axios
                              .post('/chatlist/TranscriptChatlist/exportChats', params)
                              .then((resp) => resp.data)
                              .catch((err) => console.log(err)),    

                      

                          
           

  
};

export default transcriptchatlist;
