import React from "react";   
class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false, 
    }; 
  }
  
  render() {
    
    return (
      <div className="yr-chat-round-box">
        {this.state.loading && <div className="ui loading form"></div>}

        <div className="row">
        <p className="px-lg-5 p-title">
              Please click one of the options below to read API Instructions and Integration
              onto your website:
            </p>
          <div className="col-lg-6 px-lg-5">
             
            <ul className="nav nav-tabs tabs-box ">
              <li className="active">
                <a href="/goal-tracking" target="_blank"  className="active">
                  <div className="api-div-tab yr-chat-round-box">
                    {/* <p>
                      <img src="assets/media/logos/filebtn.png" alt="" />
                    </p> */}
                    <p className="text-blue">Google Goal Tracking</p>
                    <p>Use goals to measure how often users complete specific actions. 
                      Goals measure how well your site or app fulfills your target objectives. 
                      A goal represents a completed activity, called a conversion, that contributes
                      to the success of your business. Examples of goals include making a purchase
                      (for an ecommerce site), completing a game level</p>
                  </div>
                </a>
              </li>
              <li className="active">
                <a href="/utm-tracking" target="_blank" className="active">
                  <div className="api-div-tab yr-chat-round-box">
                    {/* <p>
                      <img src="assets/media/logos/filebtn.png" alt="" />
                    </p> */}
                    <p className="text-blue">UTM Tracking</p>
                    <p>By adding campaign parameters to the destination URLs you use in
                       your ad campaigns, you can collect information about the overall 
                       efficacy of those campaigns, and also understand where the campaigns 
                       are more effective. For example, your Summer Sale campaign might be 
                       generating lots of revenue, but if you're running the campaign in</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <div className="tab-content">
            <ul className="nav nav-tabs tabs-box ">
            <li className="active">
                <a href="/gtm-tracking" target="_blank"   className="active">
                  <div className="api-div-tab yr-chat-round-box">
                    {/* <p>
                      <img src="assets/media/logos/filebtn.png" alt="" />
                    </p> */}
                    <p className="text-blue">GTM Tracking</p>
                    <p>Google Tag Manager is a tag management system (TMS) that allows 
                      you to quickly and easily update tracking codes and related code 
                      fragments collectively known as tags on your website or mobile app. 
                      Once the small segment of Tag Manager code has been added to your project, 
                      you can safely and easily deploy analytics and</p>
                  </div>
                </a>
              </li>
              <li className="active">
                <a href="/dashboard-webhooks" target="_blank" className="active">
                  <div className="api-div-tab yr-chat-round-box">
                    {/* <p>
                      <img src="assets/media/logos/filebtn.png" alt="" />
                    </p> */}
                    <p className="text-blue">Chat Dashboard Webhooks</p>
                    <p>Chat Dashboard Webhooks<br/> 
                    Webhooks Version 1.0<br/>
                    23/07/2019</p>
                  </div>
                </a>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Instructions;
