import React, { useState } from "react";
import "../../../../Modal/dialog.css";

const DialogBoxContactVia = ({
  obj,
  modalCloseContactViaBox,
  openContactAddEdit,
}) => {
  const [option, setOption] = useState("static");
  const setOptionValue = (e) => {
    setOption(e);
  };
  const openContactAddEditHere = (e) => {
    modalCloseContactViaBox(false, "");
    openContactAddEdit(option);
    setOption("static");
  };
  const modalCloseContactViaBoxHere = () => {
    modalCloseContactViaBox(false, "");
    setOption("static");
  };
  return (
    <React.Fragment>
      <div
        className={
          obj.modalContactViaObject.show
            ? "modal fade yr-upgrade-popup white-bg new-model-popup show"
            : "modal fade yr-upgrade-popup white-bg new-model-popup"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: obj.modalContactViaObject.show ? "block" : "none" }}
        id="modalContactViaID"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {obj.modalContactViaObject.loading && (
            <div className="ui loading form"></div>
          )}
          <div className="modal-content yr-campaigns-model">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => modalCloseContactViaBoxHere()}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{obj.modalContactViaObject.title}</h5>
            </div>
            <div class="modal-body">
              <form class="kt-form">
                <div class="kt-portlet__body">
                  <div class="kt-section kt-section--first">
                    <div class="kt-section__body">
                      <div class="form-group row">
                        <label class="col-lg-12 col-form-label">
                          Please select one of the below option
                        </label>
                        <div class="col-lg-12">
                          <div class="kt-radio-inline">
                            <div class="mb-2">
                              <label class="kt-radio inalign">
                                <input
                                  type="radio"
                                  onClick={() => setOptionValue("static")}
                                  name="opt"
                                  checked={option == "static" ? "checked" : ""}
                                  value="left"
                                />
                                <span></span>
                              </label>
                              <span class="align-label ml-3">
                                Static field{" "}
                              </span>
                            </div>
                            <div>
                              <label class="kt-radio inalign">
                                <input
                                  type="radio"
                                  name="opt"
                                  onClick={() => setOptionValue("csv")}
                                  checked={option == "csv" ? "checked" : ""}
                                  value="right"
                                />
                                <span></span>
                              </label>
                              <span class="align-label ml-3">
                                Import according to the fields being used
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="kt-portlet__foot">
                  <div class="kt-form__actions">
                    <div class="row">
                      <div class="col-lg-12 d-flex justify-content-end">
                        <button
                          type="reset"
                          onClick={() => modalCloseContactViaBoxHere()}
                          class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                        >
                          <span> Cancel </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => openContactAddEditHere()}
                          class="btn linear-gradient yr-submitbtn"
                          data-toggle="modal"
                          data-target="#kt_modal_8"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DialogBoxContactVia;
