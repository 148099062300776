import React from 'react';


class AddWebhook extends React.Component {
    render(){
    return(
        <div>
        <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                    <div className="box-title">Add Response To A Question</div>
                    <div className="form-group" style={{ marginBottom: 10 }}>
                        <textarea className="form-control">Reponse</textarea>
                    </div>
                    
                    <a className="add-gradient form-group" href="javascript;">+Add</a>
                    {/* <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Close </span></button>
                        <button type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
                    </div>
                </div>             */}
        
        
    </div>
    <div className="row">
        <div className="col-12 d-flex justify-content-end">
        <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()} ><span> Close </span></button>
     <button type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
 </div>
    </div>
 </div>
    )
}
}


export default AddWebhook;