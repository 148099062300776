import React from "react";
import { Form, Dropdown, TextArea } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import "./Popup.css";
import "semantic-ui-css/semantic.min.css";
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";
import DialogBoxLocationSettings from "./DialogBoxLocationSettings";

validationRule.isRequired();
validationRule.usPhoneLength();
validationRule.newPinAndOldPinNotSame();

class DialogBoxIndividualNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.obj.modalstatusNotiAandMisc,
      title: props.modaltitle,
      modalFormLoader: props.modalFormLoader,
      modalstatusNotiAandMiscData: props.modalstatusNotiAandMiscData,
      message1: "none",
      color1: "green",
      msg1: "Setting has been saved successfully.",
      message2: "none",
      message3: "none",
      memberLogo: "",
      imgError: "none",
      loading: "none",
      isd_code: "",
      jumpinMsg: "",
      switchValue1: "",
      switchValue2: "",
      pin: "",
      cpin: "",
      pinMessage: "Pin Setting has been saved successfully.",
      pinMessageState: "none",
      locationSettingModalShow: false,
      locationSettingModalTitle: "",
      id: "",
      sessionUser: {},
      locationDataResult: [],
      locationDataGmap: [],
      locationState: [],
      userState: [],
      recieve_all_chat_if_not_in_current_location: "0",
      recieve_all_chat_if_not_in_current_locationChecked: true,
      email1: "",
      phone1: "",
      email1Ontime: "set",
      phone1Ontime: "set",
      ImgSetStatus: "FALSE",
	    selectedLagacyLocation: ''
    };
    let modalstatusNotiAandMiscData = this.props.modalstatusNotiAandMiscData;
  }

  notiAndMiscmodalClose = () => {
    if (this.state.switchValue1 == "OFF") {
      document.querySelector("#is_livechat_notofication").click();
    }
    if (this.state.switchValue2 == "OFF") {
      document.querySelector("#is_completedchat_notofication").click();
    }
    this.setState({
      memberLogo: "",
      imgError: "none",
      message1: "none",
      color1: "green",
      msg1: "Setting has been saved successfully.",
      message2: "none",
      message3: "none",
      loading: "none",
      isd_code: "",
      jumpinMsg: "",
      switchValue1: "",
      switchValue2: "",
      email1: "",
      phone1: "",
    });
    this.props.notiAndMiscmodalClose(false, "manageNotificationIndiviNoti");
  };
  random = () => {
    return Math.random();
  };

  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };

  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : "qqq"}
        >
          {option.country_name}
        </option>
      );
    });
  };

  handleChangeISD = (e) => {
    this.setState({
      isd_code: e.target.value,
    });
    // setIsd(e.target.value)
  };

  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };
  handlePhoneChange = (e) => {
    let phone1 = e.target.value;
    let p = this.phoneFormat(phone1);
    setTimeout(function () {
      document.getElementById("phoneNumberIndiviNoti").value = p;
    }, 100);
    this.setState({
      phone1: e.target.value,
      email1: "",
    });
  };

  handleEmailChange = (e) => {
    let email1 = e.target.value;
    this.setState({
      email1: e.target.value,
      phone1: "",
    });
  };

  handleCompanyLogo = (event) => {
    // let newState = this.state.customizeinfo;
    let imageMIME = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    if (
      imageMIME.includes(event.target.files[0].type) == true &&
      event.target.files[0].type != ""
    ) {
      // setImgError("none")
      var companyLogo = URL.createObjectURL(event.target.files[0]);
      this.setState({
        memberLogo: companyLogo,
        imgError: "none",
        ImgSetStatus: "TRUE",
      });
    } else {
      this.setState({
        imgError: "block",
      });
    }
  };

  handleCompanyLogoRemove = (event) => {
    this.setState({
      memberLogo: "",
      imgError: "none",
      ImgSetStatus: "FALSE",
    });
  };

  changeSwitch = (e) => {
    var val = "";

    if (e.target.value == "ON") {
      val = "OFF";
    } else {
      val = "ON";
    }
    if (e.target.name == "is_livechat_notofication") {
      this.setState({
        switchValue1: val,
      });
      if (val == "OFF") {
        this.setState({
          showForLiveChat: "none",
        });
      } else if (val == "ON") {
        this.setState({
          showForLiveChat: "block",
        });
      }
    } else if (e.target.name == "is_completedchat_notofication") {
      this.setState({
        switchValue2: val,
      });
    }

    //this.onValidSubmitSwitch(e.target.name, val);
  };

  _handleLocationEvent = ( event ) => {
    this.setState({
      ...this.state,
      selectedLagacyLocation: event.target.value
    });
  }

  onValidSubmitFirstColIndiviNoti = (e) => {
    this.setState({
      loading: "block",
    });

    if (this.state.switchValue1 == "OFF" && this.state.switchValue2 == "OFF") {
      this.setState({
        loading: "none",
        message1: "block",
        color1: "red",
        msg1: "Please select notification type",
      });
      var ref = this;
      setTimeout(function () {
        ref.setState({
          message1: "none",
        });
      }, 5000);
    } else {
      var myform = document.getElementById("manageNotificationIndiviNoti");
      var formData = new FormData(myform);
      formData.append("user_id", this.props.sessionUser.user_id);
      formData.append("chatBot_id", this.props.sessionUser.chatBot_id);
      //console.log(formData);

      api.manageUser
        .IndividualNotificationsSetting(formData)
        .then((resp) => {
          document.getElementById("notificationSetingModalPopup").scroll({
            top: 0,
            behavior: "smooth", // 👈
          });
          if (resp.data.status == true) {
            this.props.UpdateCompleteNotification(resp.data.data.completedAll);
            this.props.UpdateLiveNotification(resp.data.data.liveAll);
            this.setState({
              ImgSetStatus: "FALSE",
              loading: "none",
              message1: "block",
              color1: "green",
              msg1: resp.data.message1,
            });
            var ref = this;
            setTimeout(function () {
              ref.setState({
                message1: "none",
              });
            }, 5000);
            this.props.UpdateToasterMsg(this.state);
            this.props.reloadDataTable();
            this.notiAndMiscmodalClose();
          } else if (resp.data.status == false) {
            this.setState({
              loading: "none",
              message1: "block",
              color1: "red",
              msg1: resp.data.message,
            });
            var ref = this;
            setTimeout(function () {
              ref.setState({
                message1: "none",
              });
            }, 5000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  render() {
    const errorLabel = <div className="red" />;
    return (
      <React.Fragment>
        {
          <div
            style={{ display: this.state.loading }}
            class="ui loading form mh-loading-custom"
          ></div>
        }
        <div
          id="notificationSetingModalPopup"
          className={
            this.props.obj.modalstatusNotiAandMisc
              ? "modal fade show"
              : "modal fade"
          }
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{
            display: this.props.obj.modalstatusNotiAandMisc ? "block" : "none",
          }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.notiAndMiscmodalClose()}
              >
                X
              </button>
              {this.state.modalFormLoader && (
                <div className="ui loading form"></div>
              )}
              <div className="modal-body">
                <div className="row" style={{ width: 100 + "%" }}>
                  <div className="col-lg-12">
                    <div>
                      <Form
                        noValidate
                        autoComplete="off"
                        className="manageNotificationIndiviNoti"
                        id="manageNotificationIndiviNoti"
                        onValidSubmit={this.onValidSubmitFirstColIndiviNoti}
                      >
                        <div className="yr-popBox-1">
                          <div className="box-title mb-1">
                            Individual Notifications:
                          </div>
                          <Message
                            color={this.state.color1}
                            style={{
                              display: this.state.message1,
                            }}
                          >
                            {this.state.msg1}
                          </Message>

                          <div
                            className="form-group row"
                            style={{ marginBottom: 0, height: "45px" }}
                          >
                            <label className="col-5 col-form-label">
                              Live chat notifications
                            </label>
                            <div className="col-7">
                              <span
                                className={
                                  (this.state.switchValue1 == ""
                                    ? this.props.obj.notiAndSettingmodalData
                                        .is_livechat_notoficationChecked == true
                                      ? "ON"
                                      : "OFF"
                                    : this.state.switchValue1) == "ON"
                                    ? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
                                    : "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
                                }
                              >
                                <label>
                                  <input
                                    name="is_livechat_notofication"
                                    id="is_livechat_notofication"
                                    type="checkbox"
                                    value={
                                      this.state.switchValue1 == ""
                                        ? this.props.obj.notiAndSettingmodalData
                                            .is_livechat_notoficationChecked ==
                                          true
                                          ? "ON"
                                          : "OFF"
                                        : this.state.switchValue1
                                    }
                                    onClick={(e) => this.changeSwitch(e)}
                                    defaultChecked={
                                      this.props.obj.notiAndSettingmodalData
                                        .is_livechat_notoficationChecked
                                    }
                                  />
                                  <span />
                                </label>
                              </span>
                            </div>
                          </div>

                          <div
                            className="form-group row"
                            style={{ marginBottom: 10, height: "45px" }}
                          >
                            <label className="col-5 col-form-label">
                              Completed chat notifications
                            </label>
                            <div className="col-7">
                              <span
                                className={
                                  (this.state.switchValue2 == ""
                                    ? this.props.obj.notiAndSettingmodalData
                                        .is_completedchat_notoficationChecked ==
                                      true
                                      ? "ON"
                                      : "OFF"
                                    : this.state.switchValue2) == "ON"
                                    ? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
                                    : "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
                                }
                              >
                                <label>
                                  <input
                                    type="checkbox"
                                    name="is_completedchat_notofication"
                                    id="is_completedchat_notofication"
                                    value={
                                      this.state.switchValue2 == ""
                                        ? this.props.obj.notiAndSettingmodalData
                                            .is_completedchat_notoficationChecked ==
                                          true
                                          ? "ON"
                                          : "OFF"
                                        : this.state.switchValue2
                                    }
                                    onClick={(e) => this.changeSwitch(e)}
                                    defaultChecked={
                                      this.props.obj.notiAndSettingmodalData
                                        .is_completedchat_notoficationChecked
                                    }
                                  />
                                  <span />
                                </label>
                              </span>
                            </div>
                          </div>

                          <div
                            className="form-group"
                            style={{
                              marginBottom: 10,
                              display: this.state.showForLiveChat,
                            }}
                          >
                            <label style={{ marginBottom: 0 }}>Full Name</label>

                            {this.state.showForLiveChat == "none" ? (
                              <Form.Input
                                type="text"
                                className=""
                                name="username"
                                errorLabel={errorLabel}
                              />
                            ) : (
                              <Form.Input
                                type="text"
                                className=""
                                name="username"
                                validations="isRequired"
                                validationErrors={{
                                  isRequired: "This field is required.",
                                }}
                                errorLabel={errorLabel}
                              />
                            )}
                          </div>
                          <div
                            className="form-group"
                            style={{ marginBottom: 10 }}
                          >
                            <label style={{ marginBottom: 0 }}>
                              Email Address
                            </label>

                            {this.state.phone1 == "" &&
                            this.state.email1Ontime != "" ? (
                              <Form.Input
                                type="email"
                                className=""
                                name="email"
                                validations="isEmail,isRequired"
                                validationErrors={{
                                  isEmail:
                                    "Please enter a valid email address.",
                                  isRequired: "This field is required.",
                                }}
                                errorLabel={errorLabel}
                                onChange={this.handleEmailChange}
                                id="emailIndiviNoti"
                              />
                            ) : (
                              <Form.Input
                                type="email"
                                className=""
                                name="email"
                                errorLabel={errorLabel}
                                onChange={this.handleEmailChange}
                                id="emailIndiviNoti"
                              />
                            )}
                          </div>
                          <div
                            className="form-group"
                            style={{ marginBottom: 10 }}
                          >
                            <label>Phone Number</label>
                            <div className="d-flex">
                              <select
                                class="form-control"
                                name="isd_code"
                                id="isd_code"
                                onChange={this.handleChangeISD}
                                id="isd_code_id"
                                style={{ maxWidth: 166, marginRight: "1rem" }}
                              >
                                {this.renderDropDownDefault(
                                  this.props.obj.ISDrenderOption1,
                                  this.props.obj.notiAndSettingmodalData.isd
                                )}
                                <optgroup label="Other countries">
                                  {this.renderDropDownRemaining(
                                    this.props.obj.ISDrenderOption2,
                                    this.props.obj.notiAndSettingmodalData.isd
                                  )}
                                </optgroup>
                              </select>

                              {this.state.email1 == "" &&
                              this.state.phone1Ontime != "" ? (
                                <Form.Input
                                  fluid
                                  autoComplete="off"
                                  name="phone"
                                  maxLength="13"
                                  onChange={this.handlePhoneChange}
                                  placeholder="Phone Number"
                                  validations="isRequired,usPhoneLength"
                                  validationErrors={{
                                    isRequired: "This field is required.",
                                    usPhoneLength:
                                      "Phone number must be at least 10 numbers.",
                                  }}
                                  id="phoneNumberIndiviNoti"
                                  className="w-100"
                                  errorLabel={errorLabel}
                                />
                              ) : (
                                <Form.Input
                                  fluid
                                  autoComplete="off"
                                  name="phone"
                                  maxLength="13"
                                  onChange={this.handlePhoneChange}
                                  placeholder="Phone Number"
                                  id="phoneNumberIndiviNoti"
                                  className="w-100"
                                  errorLabel={errorLabel}
                                />
                              )}
                            </div>
                          </div>
                          
                          {
                            this.state.switchValue2 != 'OFF' ?
                            (<div
                            className="form-group"
                            style={{ marginBottom: 10 }}
                          >
                            <label>Chat Location</label>
                            <div className="d-flex">
                              <select class="form-control" onChange={(e) => this._handleLocationEvent(e)}name="lagacySelectedLocation">
                                <option>All Locations</option>
                                {this.props.legacybuttons()}
                              </select>
                            </div>
                          </div>) : ''
                          }

                          <div
                            style={{
                              display: this.state.showForLiveChat,
                            }}
                          >
                            
                            <div className="row">
                              <div
                                className="form-group col-md-6"
                                style={{ marginBottom: 10 }}
                              >
                                <label style={{ marginBottom: 10 }}>
                                  Default Jump In Message
                                </label>
                                <TextArea
                                  className=""
                                  // validations="isRequired"
                                  // validationErrors={{
                                  //   isRequired: "This field is required.",
                                  // }}
                                  id="jump_in_question"
                                  placeholder="Default Jump In Message"
                                  rows="5"
                                  name="jump_in_question"
                                ></TextArea>
                              </div>

                              <div className="form-group col-md-6">
                                <label>Profile Pic (optional)</label>
                                <div
                                  className="logo-img"
                                  style={{ height: 100, marginTop: 5 }}
                                >
                                  <img
                                    src={
                                      this.state.memberLogo == ""
                                        ? this.props.obj.notiAndSettingmodalData
                                            .image == "" ||
                                          this.props.obj.notiAndSettingmodalData
                                            .image == null
                                          ? "assets/media/logos/upload-bg.png"
                                          : this.props.sessionUser.assetPath +
                                            "/images/chatlogo/" +
                                            this.props.obj
                                              .notiAndSettingmodalData.image +
                                            "?v=" +
                                            this.random()
                                        : this.state.memberLogo
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div className="upload-btn-wrapper">
                                    <button className="btn linear-gradient zy-save-btn width-100">
                                      Edit Logo
                                    </button>
                                    <input
                                      type="file"
                                      name="image"
                                      id="comanyLogoId"
                                      onChange={this.handleCompanyLogo}
                                      accept="image/jpg,image/png,image/jpeg,image/gif"
                                    />
                                  </div>
                                  {this.state.memberLogo && (
                                    <button
                                      type="reset"
                                      className="btn linear-gradient zy-cancel-btn ml-2"
                                      onClick={this.handleCompanyLogoRemove}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: this.state.imgError,
                                    color: "red",
                                  }}
                                >
                                  Invalid file format. Only "jpg, jpeg, png,
                                  gif" files are supported.
                                </div>
                                {/* <input type="hidden" id="dbimgchat" name="dbimgchat"  value={this.state.companyLogo}/> */}
                                {/* <input type="hidden" id="oldChatImgID" name="oldChatImgname" value={this.props.cplogo}/> */}
                                <input
                                  type="hidden"
                                  id="ImgSetStatus"
                                  name="ImgSetStatus"
                                  value={this.state.ImgSetStatus}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-lg-12 text-center"
                              style={{ marginTop: 33 }}
                            >
                              <button
                                type="submit"
                                class="btn linear-gradient yr-submitbtn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DialogBoxIndividualNotifications;
