import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "./analytics.css"; 
import "../../../Modal/dialog.css";
import "../../../Modal/DialogBox.js";

import DatatableList from "./dataTables/DatatableList";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";

class PlayBookBranchWiseStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      yourCallList: [],
      tab: "tab1",
      bid: "",
      subscriber: 0,
      email: "",
      contacts: 0,
      pwsid:0
    };
  }
componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Analiytics",
      navigationName: "marketing",
      subHeaderTitle: "Re-Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.setState({ pwsid: this.props.match.params.id });
    let ref = this;
    setTimeout(function(){
      ref.loadData();
    },1000)
  }
loadData = () => {
    this.setState({
      activetab: 0,
    });
  };

  getCallListStatus=(tab)=>{
    
    this.setState({
      activetab: tab,
    });
  }

  render() {
    const { errors, email, email_subject, action, added_date } = this.state;

    return (
    <div className="col-md-12">
         { <DatatableList activetab={this.state.activetab} pwsid= { this.state.pwsid} indextime={new Date()} />}
     </div>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(PlayBookBranchWiseStats);
