import React from 'react';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
  } from "react-html-parser";
const WaitingForResponse = props => {
    const renderTime = (anonymousUserId, waittime) => {
        var { waittime } = props.state
        return waittime.map(({
            anonymous_user_id,
            displayTime,
            time
        }, idx) => (
                (anonymousUserId == anonymous_user_id) ?
                    <div> {displayTime}</div>
                    : ''
            ));
    }

    const renderNameAndWelcomeMgs = (anonymousUserId, name) => {
        var { name } = props.state
        return name.map(({
            anonymous_user_id,
            name,
            hippaOn,
            welcomeMsg,
            read
        }, idx) => (
                (anonymousUserId == anonymous_user_id && hippaOn == "none") ?
                    <div className="kt-notification-v2__itek-wrapper mh-visitorDetailOnLeftBar">
                        <div className="kt-notification-v2__item-title">
                            <span className="kt-widget3__username">{name}</span>
                        </div>
                        <div title={ReactHtmlParser(welcomeMsg)} className="kt-notification-v2__item-desc mh-leftBarChatMessage">
                            {ReactHtmlParser(welcomeMsg)}
                        </div>
                    </div> : (anonymousUserId == anonymous_user_id && hippaOn == "block" && name == "Site Visitor") ?
                        <div className="kt-notification-v2__itek-wrapper mh-visitorDetailOnLeftBar">
                            <div className="kt-notification-v2__item-title">
                                <span className="kt-widget3__username">{name}</span>
                            </div>
                            <div className="kt-notification-v2__item-desc mh-leftBarChatMessage">
                                {"Hidden"}
                            </div>
                        </div> : (anonymousUserId == anonymous_user_id) ?
                            <div className="kt-notification-v2__itek-wrapper mh-visitorDetailOnLeftBar">
                                <div className="kt-notification-v2__item-title">
                                    <span className="kt-widget3__username">{"Hidden"}</span>
                                </div>
                                <div className="kt-notification-v2__item-desc mh-leftBarChatMessage">
                                    {"Hidden"}
                                </div>
                            </div> : ""
            ));
    }
    var siteVisitorImgPath = props.state.assetPath
    var joinedTheConversation = props.joinedTheConversation;
    if (joinedTheConversation == 0)
        var chat = props.state.chatLeftList;
    else
        var chat = props.state.chatRepondedTimeWise;
    var length = 0;
    if (chat.length > 0) {
        if (joinedTheConversation == 0) {
            var valueArr1 = chat.map(function (item) {
                if (item.join_into_conversation == 0) {
                    return item.join_into_conversation
                }
            });
            valueArr1 = valueArr1.filter(function (element) {
                return element !== undefined;
            });
            length = valueArr1.length
        } else {
            length = 1;
        }
    }
    var anonymous_user_id,
        hippaOn,
        join_into_conversation = "";
    return (
        (chat.length == 0 || length == 0) ? <span className="kt-notification-v2__item"  >
            <div className="mh-noChatAvailableOnLeftBar" >
                <div className="kt-notification-v2__item-time ">No chats available.</div>
            </div>
        </span> : chat.map(({
            anonymous_user_id,
            hippaOn,
            leftBarClass,
            join_into_conversation,
            read
        }, idx) => ((join_into_conversation == joinedTheConversation) ?
            <span className={leftBarClass + " mh-chatLeftBarHover " +read} onClick={() => props.openLiveChatBoxOnClickLeftRow(anonymous_user_id, hippaOn)}>
                <div style={{ overflow: 'hidden' }} className="kt-notification-v2__item-icon mh-chatLeftAUBox">
                    <img className="kt-widget3__img" style={{ height: '50px' }} src={siteVisitorImgPath + "/images/chatlogo/visitor-logo.png"} alt="" />
                </div>
                {renderNameAndWelcomeMgs(anonymous_user_id)}
                <div className="kt-notification-v2__item-time">
                    {renderTime(anonymous_user_id)}
                </div>
            </span> : ""
            ))
    )
}
export default WaitingForResponse;