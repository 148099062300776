import React, { useRef,useState, useEffect } from 'react';
import { render } from 'react-dom';
import EmailEditor from 'react-email-editor';
import ContentEditable from 'react-contenteditable';

import config from "../../../../../config/";
const UnlayerEditor = ({ userId, updateContentNewEditor, selectedEditorContent_JSON, editorUseEffectRun, editorUseEffectRunChange, openEmailPop, variableValue, addAltValue, customVariableValue, handleCustomVariableChange }) => {
  const emailEditorRef = useRef(null);
  const [newVariable, setNewVariable] = useState(customVariableValue);
  const contentText = useRef('');
 
  useEffect(() => {
    setNewVariable(customVariableValue);
  }, [customVariableValue]);

  const handleSubjectChangeUnlayer = evtUnlayer => {
    let a = evtUnlayer.target.value;
    setNewVariable( a );
    handleCustomVariableChange(a);
  };
  const onClickContentEditableUnlayer = evtUnlayer => {
    if (evtUnlayer.target === contentText.current) {
      return;
    }
    // an element in `ContentEditable` was clicked!
    if (evtUnlayer.target.className.indexOf("alt_val_span") > -1) {
      addAltValue(evtUnlayer.target.getAttribute("alt_key"), 'unlayer')
    }
  };

  const  onchangeVariableUnlayer = (val, key) => {
    let emailSub = customVariableValue;
    let val2Unlayer = '&nbsp;<span class="alt_val_span" alt_key=' + key + '>' + val + '</span>';
   let a = newVariable;
      a+= val2Unlayer;
    setNewVariable( a );
    handleCustomVariableChange(a);
  };


  const [loading, setLoding] = useState(false)
  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log(design);
    });

    emailEditorRef.current.editor.saveDesign(function(design) {
     
    });

   
  };

  const openEmailPopChild = () =>{
    openEmailPop();
  }
  
  const onLoad = () => {

    // const returnMergeTags = Object.keys(variableValue).length > 0 && Object.keys(variableValue).map((obj, y) => {
    //   var first_name = variableValue[obj];
    //       first_name = first_name.replace(/\s+/g, '_');
    //       var val = {
    //         [first_name]:{
    //           name: variableValue[obj],
    //           value: '{{' + variableValue[obj] + '}}'
    //         }
    //       };
    //       return val[[first_name]];
    // });
    // emailEditorRef.current.editor.setMergeTags({
    //   ...returnMergeTags
    // });


    emailEditorRef.current.editor.setBodyValues({
      backgroundColor: "#ffffff",
      contentWidth: "500px", // or percent "50%"
      fontFamily: {
        label: "Helvetica",
        value: "'Helvetica Neue', Helvetica, Arial, sans-serif"
      },
      preheaderText: "Hello World"
    });
    setLoding(true)
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(JSON.parse('{"counters":{"u_column":5,"u_row":2,"u_content_heading":2,"u_content_button":1},"body":{"rows":[{"cells":[1],"columns":[{"contents":[{"type":"heading","values":{"containerPadding":"10px","headingType":"h1","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"fontSize":"22px","textAlign":"left","lineHeight":"140%","linkStyle":{"inherit":true,"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"linkHoverUnderline":true},"_meta":{"htmlID":"u_content_heading_2","htmlClassNames":"u_content_heading"},"selectable":true,"draggable":true,"duplicatable":true,"deletable":true,"hideable":true,"text":"Heading"}}],"values":{"backgroundColor":"","padding":"0px","border":{},"_meta":{"htmlID":"u_column_1","htmlClassNames":"u_column"}}}],"values":{"displayCondition":null,"columns":false,"backgroundColor":"","columnsBackgroundColor":"","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"padding":"0px","hideDesktop":false,"_meta":{"htmlID":"u_row_1","htmlClassNames":"u_row"},"selectable":true,"draggable":true,"duplicatable":true,"deletable":true,"hideable":true}}],"values":{"textColor":"#000000","backgroundColor":"#e7e7e7","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"contentWidth":"500px","contentAlign":"center","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"preheaderText":"","linkStyle":{"body":true,"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"linkHoverUnderline":true},"_meta":{"htmlID":"u_body","htmlClassNames":"u_body"}}},"schemaVersion":6}'));
    emailEditorRef.current.editor.addEventListener('design:updated', function(updates) {

      var random="START"+Math.random()*1000000000000000000+"END";

    emailEditorRef.current.editor.setLinkTypes([
      
      {
        name: 'web',
        // label: 'Open Website',
        defaultValue: 'my_custom_link_type',
    
        attrs: {
          href: '{{url}}/?button_id={{Ids}}',
          target: '{{target}}',
        },
        fields: [
          {
            name: 'url',
            label: 'Url',
            defaultValue: '',
            inputType: 'url',
            placeholderText: null,
            options: [],
          },
          {
            name: 'target',
            label: 'Target',
            defaultValue: '_blank',
            inputType: null,
            placeholderText: null,
            options: [
              { value: '_blank', label: 'New Window' },
              { value: '_parent', label: 'Current Window' },
            ],
          },
          {
            name: 'Ids',
            label: 'Button-Id For Internal Use Only',
            defaultValue: random,
            inputType: 'hidden',
            placeholderText: null,
            readonly:"readonly"
            
          }
        ],
        
      },
      {
        name: 'phone',
        enabled: false
      },
      // {
      //   name: 'web',
      //   enabled: false
      // },
      {
        name: 'email',
        enabled: false
      },
      {
        name: 'sms',
        enabled: false
      }
]);





      // Design is updated by the user
      emailEditorRef.current.editor.exportHtml(function(data) {
        var json = data.design; // design json
        var html = data.html; // design html
        emailEditorRef.current.editor.saveDesign(function(design) {
        updateContentNewEditor(html,design)
        });
        // Save the json, or html here
      })
    })
      var random="START"+Math.random()*1000000000000000000+"END";

    emailEditorRef.current.editor.setLinkTypes([
      
      {
        name: 'web',
        // label: 'Open Website',
        defaultValue: 'my_custom_link_type',
    
        attrs: {
          href: '{{url}}/?button_id={{Ids}}',
          target: '{{target}}',
        },
        fields: [
          {
            name: 'url',
            label: 'Url',
            defaultValue: '',
            inputType: 'url',
            placeholderText: null,
            options: [],
          },
          {
            name: 'target',
            label: 'Target',
            defaultValue: '_blank',
            inputType: null,
            placeholderText: null,
            options: [
              { value: '_blank', label: 'New Window' },
              { value: '_parent', label: 'Current Window' },
            ],
          },
          {
            name: 'Ids',
            label: 'Button-Id For Internal Use Only',
            defaultValue: random,
            inputType: 'hidden',
            placeholderText: null,
            readonly:"readonly"
            
          }
        ],
        
      },
      {
        name: 'phone',
        enabled: false
      },
      // {
      //   name: 'web',
      //   enabled: false
      // },
      {
        name: 'email',
        enabled: false
      },
      {
        name: 'sms',
        enabled: false
      }
]);
  };
  useEffect(() => {
    // Update the document title using the browser API
    
    if(loading && editorUseEffectRun){
      loadBodyvalues()
      if(selectedEditorContent_JSON!="")
      emailEditorRef.current.editor.loadDesign(JSON.parse(selectedEditorContent_JSON));
      else
      emailEditorRef.current.editor.loadDesign(JSON.parse('{"counters":{"u_column":1,"u_row":1,"u_content_button":1},"body":{"rows":[{"cells":[1],"columns":[{"contents":[],"values":{"backgroundColor":"","padding":"0px","border":{},"_meta":{"htmlID":"u_column_1","htmlClassNames":"u_column"}}}],"values":{"displayCondition":null,"columns":false,"backgroundColor":"","columnsBackgroundColor":"","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"padding":"0px","hideDesktop":false,"_meta":{"htmlID":"u_row_1","htmlClassNames":"u_row"},"selectable":true,"draggable":true,"duplicatable":true,"deletable":true,"hideable":true}}],"values":{"textColor":"#000000","backgroundColor":"#fff","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"contentWidth":"500px","contentAlign":"center","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"preheaderText":"","linkStyle":{"body":true,"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"linkHoverUnderline":true},"_meta":{"htmlID":"u_body","htmlClassNames":"u_body"}}},"schemaVersion":6}'));
      editorUseEffectRunChange();  
      
    }
      
  });



  const loadBodyvalues=()=>{
  
    emailEditorRef.current.editor.setBodyValues({
      backgroundColor: "#ffffff",
      contentWidth: "500px", // or percent "50%"
      fontFamily: {
        label: "Helvetica",
        value: "'Helvetica Neue', Helvetica, Arial, sans-serif"
      },
      preheaderText: "Hello World"
    });
  }


  return (
    <div>
      <div className="add-branch-title mb-3 mt-4">Add custom variable for Template</div>
      <div className="yr-email-s-line">
        <div style={{ width: "100%" }}>
          <div class="kt-input-icon kt-input-icon--right header-search kt-input-icon--right" style={{ width: "100%" }}>
            
            <ContentEditable
              //  innerRef={contentText}
              html={newVariable}
              disabled={false}       // use true to disable editing
              onChange={handleSubjectChangeUnlayer} // handle innerHTML change
              tagName='div' // Use a custom HTML tag (uses a div by default)
              className="contenteditable"
              onClick={onClickContentEditableUnlayer}
            />
          </div>
        </div>
        
        <div className="" style={{ position: 'relative' }}>
          <a
            className="btn linear-gradient btn-icon dropdown-toggle yr-managebtn cursorpointer" style={{borderRadius: 5, height: 33}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          >Insert Variable <span>
            </span></a>
          <div className="dropdown-menu dropdown-menu-right yr-custom-menu" style={{ minWidth: '250px', maxHeight: '300px', overflowY: 'scroll', zIndex: '100' }}>
            <ul >
              {Object.keys(variableValue).length > 0 && Object.keys(variableValue).map((obj, y) => {
                return (
                  <li onClick={(e) =>onchangeVariableUnlayer('{{' + variableValue[obj] + '}}', obj)} key={y}>
                    <a className="cursorpointer">
                      <span>{variableValue[obj]}</span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <button style={{ marginLeft: 8, height: 33, lineHeight: 1 }} type="button" className="btn linear-gradient zy-save-btn" onClick={() => openEmailPopChild()}>Test Email</button>
        
      </div>

      <EmailEditor 
      id= {'editor'}  
      projectId={config.unlayerProjectID} 
      options={{user:{id: userId}}}
      tabs = {{
        content: {
          enabled: true,
        },
        blocks: {
          enabled: false,
        },
        uploads: {
          enabled:true,
        }}}

      appearance= {{
        theme: 'light',
        panels: {
          tools: {
            dock: 'left'
          }
        },
        customCSS: [
          ".abbasi {background-color: yellow;}"
        ],
      }}    
      ref={emailEditorRef}   
      minHeight={"600px"} 
      onLoad={onLoad} 
      />
    </div>
  );
};

export default UnlayerEditor;
