import React, { useState } from "react";
import "../../../../../Modal/dialog.css";
const DialogBoxContactViaZapier = ({ obj, modalCloseContactViaZapier, changeSwitch, checkedOnOFF, switchValue, labelONOFFC, saveWebhooks, webhooks_title, webhooks_url, webhooks_id, onChangesWebhooks }) => {
	const modalCloseContactViaBoxZapierHere = () => {
		modalCloseContactViaZapier(false, '')
		//setOption("static")


	}

	return (
		<React.Fragment>
			<div
				className={obj.modalContactViaObjectZapier.show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: obj.modalContactViaObjectZapier.show ? "block" : "none" }}
				id="modalContactViaID"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					{obj.modalContactViaObjectZapier.loading && <div className="ui loading form"></div>}
					<div className="modal-content yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseContactViaBoxZapierHere()}
						>
							X
						</button>
						<div className="modal-header" style={{ paddingBottom: '0px !important' }}>

							<h5 className="modal-title">{obj.modalContactViaObjectZapier.title}</h5>
							<div style={{
								marginTop: '1.3rem',
								marginRight: '1.5rem'
							}}>
								<label className="mr-2">Turn {switchValue == 'ON' ? 'off to disable' : 'on to enable'} webhook data to be added</label>
								<span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
									<label style={{ marginBottom: '-0.85rem', marginTop: 0 }}>
										<input
											name="modetype"
											id="ctl00_bc_custom_element_43"
											type="checkbox"
											value={switchValue}
											onClick={(e) => changeSwitch(e)}
											defaultChecked={checkedOnOFF}
										/>
										<span />
									</label>
								</span>
							</div>
						</div>
						<div class="modal-body">
							<form class="kt-form">
								<div id="AddEditContactFormModalBody" class="modal-body"
									style={{ padding: "0 3.25rem", maxHeight: "500px", overflow: "auto" }}>
									<div class="kt-portlet__body py-0">

										<div class="row pl-0">
											<label class="col-lg-12 col-form-label pb-0" style={{ color: '#959393', fontSize: '13px' }}>
												You can connect by Zapier to import user at you dashboard.&nbsp;Below is the URL that will be used in Webhook at Zapier.&nbsp;You can pick data by Webhook in these keys: email, first_name, last_name, phone_number, postcard_city, postcard_province, postcard_postal_code, postcard_country, postcard_address,postcard_address2
											</label>
										</div>
										<div class="kt-section kt-section--first">
											<div class="kt-section__body">
												<div class="form-group row pl-0">
													<div class="col-lg-12">
														<label class="col-form-label d-flex align-items-center mt-0" >
															<span>Webhook URL: </span>
															<span style={{ width: 'calc(100% - 120px)' }} className="ml-1">
																<input autocomplete="off" name="" id="webhookurl" placeholder="Webhook URL" type="" class="form-control inputdata r-url-not-allowed d-inline-block" readonly="" disabled="" value={webhooks_url} />
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="kt-portlet__foot">
									<div class="kt-form__actions">
										<div class="form-group row">
											<div class="col-lg-12 d-flex justify-content-end align-items-center">


												<button type="reset" onClick={() => modalCloseContactViaBoxZapierHere()} class="btn linear-gradient yr-submitbtn ml-4" style={{ minWidth: 'unset' }}><span>Ok</span></button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxContactViaZapier;