import React, { useState,useEffect } from 'react';
import billing from "../../api/billing";

import './CardForm.css';

function CardForms(props) {
    //closeModal
    const [formdata, setFormData] = useState({ cardholder_name: "", card_number: "", expiry_month: "01", expiry_year: new Date().getFullYear(), cvv_number: "", card_number_error: "", expiry_month_error: "", expiry_year_error: "", cvv_number_error: "", cardholder_name_error: "", loading: false });
    const _handleChangeEvent = (event) => {
        let validate = validateFields(event.target.name, event.target.value);
        let newState = {};
        let errorfieldname = event.target.name + "_error";
        newState = { ...formdata, [event.target.name]: event.target.value, [errorfieldname]: validate.msg };
        setFormData({ ...newState });
    }
    let yyyy = new Date().getFullYear();
    let yearoptions = [];

    for (let i = 0; i < 12; i++) {
        yearoptions.push(yyyy + i);
    }
    let monthoptions = [];
    for (let i = 1; i <= 12; i++) {
        if (i < 10) {
            monthoptions.push("0" + i);
        } else {
            monthoptions.push(i);
        }

    }
    useEffect(() => {
        hideErrors(); //children function of interest
      }, [props.refresh]); 
    //hide errros after close
    const hideErrors = () => {
        let newState = { ...formdata, cvv_number_error: '', card_number_error: '', cardholder_name_error: '', cardholder_name: "", card_number: "", expiry_month: "01", expiry_year: new Date().getFullYear(), cvv_number: "" };
        setFormData({ ...newState });
        document.querySelector(".paymentForm").reset();
        return false;
    }
    const onFOrmSubmit = (event) => {
        event.preventDefault();
        let cardss = props.availcards.filter(function (e) {
            var lasrdigits = formdata.card_number.substring(formdata.card_number.length - 4);
            return lasrdigits == e.last_four_digits;
        });

        let card_number_error = "";
        let cardholder_name_error = "";
        let cvv_number_error = "";
        if (formdata.card_number == "") {
            card_number_error = "Please enter 16 digit correct card number. ";
        }
        if (formdata.cardholder_name == "") {
            cardholder_name_error = "Please enter correct card holder name.";
        }
        if (formdata.cvv_number == "") {
            cvv_number_error = "Please enter 3 digit correct cvv number.";
        }
        if (card_number_error.trim() != "" || cardholder_name_error.trim() != "" || cvv_number_error.trim() != "") {
            let newState = { ...formdata, cvv_number_error, card_number_error, cardholder_name_error };
            setFormData({ ...newState });
            return false;
        }

        if (cardss.length > 0) {
           // card_number_error = "Card is duplicate. ";
           let newState = { ...formdata, cvv_number:'', card_number:'', cardholder_name:'',expiry_month: "01", expiry_year: new Date().getFullYear() };
           setFormData({ ...newState });
            props.closepopup();
            document.querySelector(".paymentForm").reset();
            props.reff.current.innerHTML = "<div class='alert alert-danger' style='color: #84147a;background-color: #ffd4fb;border-color: #fec3f9;'>Card already added.</div>";
            setTimeout(() => {
                props.reff.current.innerHTML = "";
            }, 10000);
            return false;
        }

        if (formdata.card_number_error == "" && formdata.cardholder_name_error == "" && formdata.cvv_number_error == "") {
            setFormData({ ...formdata, loading: true });
            const data = {
                user_id: props.user,
                cardholder_name: formdata.cardholder_name,
                card_number: formdata.card_number,
                expiry_month: formdata.expiry_month,
                expiry_year: formdata.expiry_year,
                cvv_number: formdata.cvv_number,
                num_cards: props.user.userBillingDetail.cards.length
            }
            billing.addCreditCard(data)
                .then((userResp) => {
                    let newState = { ...formdata, card_number: '', cardholder_name: '', cvv_number: '', expiry_month: '01', expiry_year: new Date().getFullYear(), loading: false };
                    setFormData(newState);
                    if (userResp.status) {
                        props.closepopup();
                        props.setCardsLIst();
                        props.reff.current.innerHTML = "<div class='alert alert-success' >Card added successfully.</div>";
                        setTimeout(() => {
                            props.reff.current.innerHTML = "";
                        }, 10000);
                    } else {
                        props.closepopup();
                        props.reff.current.innerHTML = "<div class='alert alert-danger' style='color: #84147a;background-color: #ffd4fb;border-color: #fec3f9;'>Please enter valid card detail.</div>";
                        setTimeout(() => {
                            props.reff.current.innerHTML = "";
                        }, 10000);
                    }
                });
            event.target.reset();
        } else {
            return false;
        }
        return false;
    }

    const validateFields = (name, value) => {
      
        let status = { status: true, msg: "" };
        if (name == "card_number" && !/[0-9]{16}/.test(value)) {
            status = { status: false, msg: "Please enter 16 digit correct card number." };
        }
        if (name == "cvv_number" && !/[0-9]{3}/.test(value)) {
            status = { status: false, msg: "Please enter 3 digit correct cvv number." };
        }
        if (name == "cardholder_name" && !/[a-z|A-Z]/.test(value)) {
            status = { status: false, msg: "Please enter  correct card holder name." };
        }
        return status;
    }
    return (
        <>
            {formdata.loading ? (
                <div style={{ height: '150px' }}>
                    <div className="ui loading form"></div>
                </div>
            ) :
                <form onSubmit={(event) => onFOrmSubmit(event)} className="paymentForm">
                    <h2 className="text-center font-weight-bold text-dark" style={{ marginTop: '-2rem' }}>Add New Card </h2>
                    <div className="row">

                        <div className="form-group col-md-9">
                            <label >Cardholder Name</label>
                          
                            <input name="cardholder_name" className="form-control  cinput" 
                                onChange={(event) => { _handleChangeEvent(event) }} value={formdata.cardholder_name}
                            />
                              <div><small id="" class="text-danger cerr">{formdata.cardholder_name_error}</small></div>
                        </div>

                        <div className="form-group  col-md-3">
                            <label >CVV</label>
                          
                            <input type="password" name="cvv_number" className="form-control  cinput"
                                value={formdata.cvv_number} max="3" minLength="3" maxLength="3"
                                onChange={(event) => { _handleChangeEvent(event) }}
                            />
                            <div><small id="" class="text-danger  cerr">{formdata.cvv_number_error}</small></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label >Card Number</label>
                         
                            <input name="card_number" className="form-control  cinput" 
                                onChange={(event) => { _handleChangeEvent(event) }} maxLength="16" minLength="16"
                                value={formdata.card_number}
                            />
                            <div><small id="" class="text-danger  cerr">{formdata.card_number_error}</small></div>   
                        </div>
                    </div>
                    <div className="row" style={{ justifyContent: 'space-between', alignItems: 'flex-end', margin: '1rem 0 2rem' }}>
                        <div className="col-md-5 row">
                            <div className='col-md-6 pl-0'>
                                <label>Expiry Month</label>
                                
                                <select name="expiry_month" className="form-control w-75"
                                    defaultValue={""} id="cexpiry_month"
                                    onChange={(event) => { _handleChangeEvent(event) }}
                                >
                                    {monthoptions.map(function (val, i) {
                                        return <option value={val}>{val}</option>
                                    })}
                                </select>
                                <div>
                                    <small id="" class="text-danger">{formdata.expiry_month_error}</small>
                                </div>
                            </div>


                            <div className='col-md-6 pr-0'>
                                <label>Expiry Year</label>
                               
                                <select name="expiry_year" className="form-control" id="cexpiry_year"
                                    defaultValue={""}
                                    onChange={(event) => { _handleChangeEvent(event) }}
                                >
                                    {yearoptions.map(function (val, i) {
                                        return <option value={val}>{val}</option>
                                    })}
                                </select>
                                <div>
                                    <small id="" class="text-danger">{formdata.expiry_year_error}</small>
                                </div>   
                            </div>
                        </div>

                        <div className="col-md-5 pr-0">
                            <img src="https://dev.zyratalk.com/assets/images/dn__logo.png?version" alt='payment card type' style={{ display: 'block',width:"100% "}} />
                            {/* <img src="https://dev.zyratalk.com/assets/images/cardImage.PNG" alt='payment card type' style={{ width: '73%', marginLeft: 'auto', display: 'inline-block' }} /> */}
                        </div>
                    </div>
                    <div className="form-group" >
                        <input type="submit" name="submit" className="btn btn-block  btn-primary" value="Add New Card" />
                    </div>
                </form>}
        </>
    )
}
export {
    CardForms
}