import React from 'react';
import { ReactSortable } from "react-sortablejs";
import api from "../../../../../api"; 

class BranchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list:  this.props.branchList.slice(1), 
            dropbranch:'',
            selectedlocation:'',
            loadingbranch: false,
            dialogShow:false,
            dialogLoading:false,
            dialogMessage:'',
            dialogId:'',
            

        }; 
      }

      componentDidMount(){
           this.assignList();
      }

      componentDidUpdate(prevProps){
        //  console.log(prevProps)
        //  console.log('-----------------------------------')
        //  console.log(prevProps)
          
        if (this.props.indextime != prevProps.indextime) {
          
            this.assignList();
        }
    }
    
    assignList=()=>{
        
           this.setState({ list:  this.props.branchList.slice(1)})
    }

    onchangeDropbranch = (e) =>{
       this.setState({ [e.target.name]: e.target.name });
       if(e.target.value!=''){
            this.orderBranchList(e.target.value,(callback)=>{
                var elems = document.querySelectorAll('.borderset');
                for (var index = 0 ; index < elems.length; index++) {
                    elems[index].style.border = "none"; 
                }
                document.querySelector('.direction').disabled = true;
                document.querySelector('.branchcount').disabled = true;
                this.setState({ [e.target.name]: '' });

                api.botbuilder.updateposition({'btList':this.state.list}).then(data => {
                    //console.log(data);
                })

            });
        }
    }
 
    onSelectBranch = (e,key,branch_id) =>{
        
        var elems = document.querySelectorAll('.borderset');
        for (var index = 0 ; index < elems.length; index++) {
            elems[index].style.border = "none"; 
            if(index+1==elems.length){
             elems[key].style.border = '1px solid rgb(15, 98, 254)';
             this.setState({ selectedlocation: key });
            }
        }
        this.props.editBranch(branch_id)
        var elems2 = document.querySelector('.branchcount').options;
        for (var index2 = 0 ; index2 < elems2.length; index2++) {
            elems2[index2].disabled = false; 
            elems2[index2].style.backgroundColor = "#fff"; 
            if(index2+1==elems2.length){ 
                elems2[key+1].disabled = true;
                elems2[key+1].style.backgroundColor  = '#cccccc';
                document.querySelector('.direction').disabled = false;
                document.querySelector('.branchcount').disabled = false;
            }
        }
          
    }

    orderBranchList = (movelocation,callback) =>{
        var order = document.querySelector('.direction').value;
        var selectedlocation = this.state.selectedlocation; 
        if(order=='up' && selectedlocation==movelocation-1){ 
            callback(0);
        }else if(order=='down' && selectedlocation==movelocation+1){ 
            callback(0);
        } else {   
            let tempVal = this.state.list[selectedlocation];
            this.state.list.splice(selectedlocation, 1);
            if(order=='up'){ 
                if(movelocation>selectedlocation)
                this.state.list.splice(parseInt(movelocation)-1, 0, tempVal);
                else
                this.state.list.splice(movelocation, 0, tempVal);
            }
            if(order=='down'){ 
                if(movelocation>selectedlocation){  
                 this.state.list.splice(movelocation, 0, tempVal);
                }else{    
                 this.state.list.splice(parseInt(movelocation)+1, 0, tempVal);
                }
            }
            callback(1);
        }
    }
 
    onEndOrder = () =>{ 
        api.botbuilder.updateposition({'btList':this.state.list}).then(data => {
            //console.log(data);
        }) 
    }
       
    copyBranch = (e,id) =>{
        e.stopPropagation()
    this.setState({ loadingbranch: true });
        api.botbuilder.copybranch({'bid':id}).then(data => {
            this.props.updateList(data.data);
            
            this.setState({ loadingbranch: false, list:data.data.slice(1)});
            console.log(data);
        })
    }
       
    actionBranchDelete = (e,action,id='') =>{
        e.stopPropagation()
        if(action=='Open'){
            this.setState({ dialogShow: true, dialogLoading:true});
            api.botbuilder.targetedbranchdetail({'bid':id}).then(data => { 
                try{
                    if(data.status){
                        this.setState({  
                            dialogLoading:false,
                            dialogMessage:data.data,
                            dialogId:id
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            })
        }
        if(action=='Close'){
            this.setState({ 
                dialogShow:false,
                dialogLoading:false,
                dialogMessage:'',
                dialogId:''
            });
        }
        if(action=='Delete'){
            this.setState({ dialogLoading:true});
            api.botbuilder.deletebranch({'bid':this.state.dialogId}).then(data => { 
                try{
                    if(data.status){
                        this.props.updateList(data.data,this.state.dialogId);
                        this.setState({  
                            dialogShow:false,
                            dialogLoading:false,
                            dialogMessage:'',
                            list:data.data.slice(1),
                            dialogId:'',
                            
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            })
        }
    }

   

    render() {
        return (
            <div className="yr-chat-round-box box-bg" style={{maxHeight:'865px',overflow:'auto',paddingTop: "0px"}}>
                 {this.state.loadingbranch && <div className="ui loading form"></div>}
                 {!this.state.loadingbranch && ( <div>
                <div className="row yr-sticky-header">
                    <div className="col-md-2">
                        <button type="button" title="Add Branch" className="btn btn-sm btn-clean btn-icon" onClick={()=>this.props.addBranch()}>
                            <img src="../../assets/media/icons/plus.svg" alt="" width={25} />
                        </button>
                    </div>
                    <div className="col-md-10 d-flex flex-wrap justify-content-end">
                        <button type="button" className="btn btn-clean btn-bold move-btn">
                            Move
                        </button>
                        <div className="inner-wrapper">
                            <select className="form-control ml-2 direction" name='dropdirection' disabled='true'>
                                <option value='down'>Below</option>
                                <option value='up'>Above</option>
                            </select>
                            <select 
                            name='dropbranch'
                            className="form-control ml-2 branchcount"
                            value={this.state.dropbranch}
                            onChange={(e) => this.onchangeDropbranch(e)} 
                            disabled='true'
                            >
                                <option value=''>Branch #</option>
                                {this.state.list && this.state.list.map((value, index) => {
                                    return(
                                    <option value={index} key={index}>{index+1}</option>
                                    )
                                })} 
                            </select>
                        </div>
                    </div>
                </div>
                <div className="yr-chat-round-box inner-box">
                <div className="right-action-btns" style={{textAlign:"right"}}>
                            <button type="button" className="btn btn-icon" title="Copy" onClick={(e) => this.copyBranch(e,this.props.branchList[0].branch_id)}><i className="la la-clone red" /></button>
                                <button type="button" className="btn btn-icon"><i className="la la-edit blue" title="Edit" onClick={()=>this.props.editBranch(this.props.branchList[0].branch_id)}/></button>
                               
                                {/* <button type="button" className="btn btn-icon" onClick={(e) => this.actionBranchDelete('Open',this.props.branchList[0].branch_id)}><i className="flaticon-delete-1" /></button> */}
                                
                            </div>
                        <header className="box-head mb-2">
                            <div className="small-title cursorpointer" onClick={()=>this.props.editBranch(this.props.branchList[0].branch_id)}>
                                {this.props.branchList[0].branch_name}
                            </div>
                           
                        </header>

                        {this.props.branchList[0].branch_questions && this.props.branchList[0].branch_questions.map((obj2, index) => {
                            return (
                                <section className="body-text mb-3 question_map_image" style={{wordBreak:"break-all"}} dangerouslySetInnerHTML={{ __html: obj2.question }}>

                                </section>
                            )
                        })}

                        <footer>
                            {this.props.branchList[0].branch_buttons && this.props.branchList[0].branch_buttons.map((buttonobj, index) => {
                                return (
                                    <button className="btn yr-round-btn">{buttonobj.button_title}</button>
                                )
                            })}
                        </footer>
                </div>
                <ReactSortable
                    animation={400}
                    list={this.state.list}
                    setList={(newState) => this.setState({ list: newState })}
                    onEnd={()=>this.onEndOrder()}
                >
                {this.state.list && this.state.list.map((obj, index) => {
                    return (

                        <div key={index} className="yr-chat-round-box inner-box borderset cursorpointer" data-branch={obj.branch_id} onClick={(e) => this.onSelectBranch(e,index,obj.branch_id)} >
                            
                            <div className="right-action-btns" style={{textAlign:"right"}}>
                                <button type="button" className="btn btn-icon" title="Copy" onClick={(e) => this.copyBranch(e,obj.branch_id)}><i className="la la-clone" /></button>
                                <button type="button" className="btn btn-icon" title="Edit" onClick={()=>this.props.editBranch(obj.branch_id)}><i className="la la-edit" /></button>    
                                    
                                    {index >0 && 
                                    <button type="button" className="btn btn-icon" title="Delete" onClick={(e) => this.actionBranchDelete(e,'Open',obj.branch_id)}><i className="la la-trash" /></button>
                                    }
                                    {/* <button type="button" className="btn btn-icon"><i className="yr-edit blue" /></button> */}
                                </div>
                            <header className="box-head mb-2">
                           
                                <div className="small-title cursorpointer" onClick={()=>this.props.editBranch(obj.branch_id)}>
                                   <span className="kt-badge kt-badge--success kt-badge--square pre-number-box">{index+1}</span>

                                    <span className="boxhastitle">{obj.branch_name}</span>
                                </div>
                               
                            </header>

                            {obj.branch_questions && obj.branch_questions.map((obj2, index) => {
                                return (
                                    <section className="body-text mb-3 question_map_image" dangerouslySetInnerHTML={{ __html: obj2.question }}>

                                    </section>
                                )
                            })}

                            <footer>
                                {obj.branch_buttons && obj.branch_buttons.map((buttonobj, index) => {
                                    return (
                                        <button className="btn yr-round-btn" style={{display:buttonobj.button_respond_on=='REVIEW' && this.props.review_links.length==0 ?'none':'block'}}>{buttonobj.button_title}</button>
                                    )
                                })}
                            </footer>
                        </div>)
                })}
                </ReactSortable>
            </div>)}
            <div
				className={this.state.dialogShow ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: this.state.dialogShow ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {this.state.dialogLoading && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={(e) => this.actionBranchDelete(e,'Close')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">Confirm your action</h5>
						</div>
						<div className="modal-body">
							<div className="px-4">
                             {this.state.dialogMessage && (<p dangerouslySetInnerHTML={{ __html: this.state.dialogMessage }}></p>)}
							 <p>Are you sure you want to delete it?</p>
							</div>
						</div>
						<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.actionBranchDelete('Close')} data-dismiss="modal">
									<span>No</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={(e) => this.actionBranchDelete(e,'Delete')}>
									Yes
								</button>
							</div>
					</div>
				</div>
			</div>

            </div>
        )
    }
}


export default BranchList;