import React from 'react';
import { Popup, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function createMarkup(html){
  return {__html: html};
}

export const DatatableRow = props => (
  //console.log(props);
  <Table.Row >
    <Table.Cell>
    {props.isWel == 0 ? (<a
              onClick={() => props.deleteEntry("Delete", props.list.id)}
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-trash"></i>
            </a>): (
                <Popup 
                  position='top left' 
                  size='small' 
                  content={'You not able to delete the welcome scheduled emails.'}
                  trigger={<i className="la la-trash not-allowed"></i>} 
                />
            )}
           
    
    </Table.Cell>
    <Table.Cell>{props.list.email}</Table.Cell>
    <Table.Cell>{props.list.schedule_date}</Table.Cell>
    <Table.Cell>{props.list.last_applied_rule}</Table.Cell>
    
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
