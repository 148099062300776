import React from "react";
import { ReactSortable } from "react-sortablejs";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum
} from "../../../../../store/Page";
import "../../../../Modal/dialog.css";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import CustomErrorField from "../../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';

import '../../../Marketing/yr-style.css'
import Modal from "../../../ChatSetup/components/Modal";
import FooterText from "./FooterText";
import { Icon, Popup, Button } from 'semantic-ui-react'

import DatePicker from "react-datepicker";
import jstz from 'jstz';
import { Multiselect } from "multiselect-react-dropdown";

const DAY_LIST = [
  {name: 'Sunday', id: '0'},
  {name: 'Monday', id: '1'},
  {name: 'Tuesday', id: '2'},
  {name: 'Wednesday', id: '3'},
  {name: 'Thursday', id: '4'},
  {name: 'Friday', id: '5'},
  {name: 'Saturday', id: '6'},
];

const STATUS_LIST = ['Draft', 'Stopped'];

class EmailListNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pid: '',
      errors: [],
      list_load: false,
      list: [],
      modalstatus: false,
      modaltilte: '',
      modalbody: '',
      modalfooter: '',
      modalindex: '',
      emailtitle: '',
      modalloading: false,
      from_emails: '',
      edittitle: '',
      modalid: '',
      active_id: '',
      editor_update: false,
      template_type: 1,
      addfooter: false,
      footer_content: "",
      footer_contentJson: "",
      loadingEditor: false,
      selectedBranchStatus: false,
      dropbranch: "",
      selectedlocation: 0,
      infoModal: false,
      infoModalDisabled: false,
      infoContent : 
        {
          emailContent : 'For each contact that has a reachable email address, we will email them the content presented in this branch of the playbook.',
          directEmailContent : 'For each contact that has a reachable physical address, we will mail them the postcard presented in this branch of the playbook.',
          disabledContent : 'Please select the email configured from "Select email" to start the campaign.'
        },
      status:"all",  
      setDate:new Date(),
      startTimeForDuration: new Date(),
      current__run_if_new_user_added:0,
      current__new_user_schedule_interval:"",
      current__new_user_schedule_interval_error:"none",
      timeZone:[],
      errorsT: { 'timezoneerror': false, errors: [] },
      currenttime:"",
      currentSelectedTimezone:"",
      minDate:new Date(),
      edit_campaign: null,
      sendImmediately: false,
      selectedValue: [],
      actionStart: '',
      playbookStatus: null
    };

    this.childTwo = React.createRef();
    this.multiselectRef = React.createRef();
  }

  updateBranchListSequense = ( newState ) => {
	this.setState({ list: newState },() => {
		this.props.updateBranchListSequense( newState );
	});
  }
  componentDidMount() {
    this.loadData();
    this.setLocalTime();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.indextime != prevProps.indextime) {

      this.loadData();
      //alert(this.props.editor_update);
    }
  }


  loadData = () => {

    
    this.setState({
      list: this.props.emaillist.list,
      list_load: true,
      pid: this.props.pid,
      from_emails: this.props.emaillist.from_email,
      active_id: this.props.active_id,
      editor_update: this.props.editor_update,
      selectedBranchStatus: this.props.selectedBranchStatus,
      infoModal: this.state.infoContent.emailContent,
      infoModalDisabled: this.state.infoContent.disabledContent,
      template_type: 1,
      playbookStatus : this.props.playbookStatus
    },
      function () { }
    );
  };

  addTemplate = (type, name, modalid) => {

    let edittitle = '';
    let emailmodaltitle = ''
    if (type == 'edit' && name != '') {
      edittitle = name;
    }

    if (type == 'add' && this.state.editor_update == true) {
      emailmodaltitle = 'Alert';
    }

    if (type == 'delete') {
      emailmodaltitle = 'Confirm your action';
    }

    this.setState({
      modalstatus: true,
      modalindex: type,
      emailtitle: '',
      errors: [],
      edittitle: edittitle,
      modalid: modalid,
      modaltitle: emailmodaltitle
    }, function () {

      let modalbody = '';
      if (type == 'add' && this.state.editor_update == true) {
        modalbody = 'You have not save the changes please save other wise the changes will removed'
      } else {
        modalbody = this.modalBody(type)


      }
      this.setState({ modalbody: modalbody });
    })
  }

  copyTemplate = (id) => {
    api.playbookdetail.copyPlaybookNewTemplate({ temp_id: id, pid: this.state.pid }).then((data) => {
      this.setState({ list: data.data.list, modalloading: false, modalid: '' }, function () {
        this.modalClose('')

        this.props.updateList(data.data.list, data.data.details.template_type, data.data.insert_id)
        if (data.data.details.template_type == 1) {
          this.props.addNewTemplate(data.data.details);
        }
      })

    });
  }

  // modalClose = (index) => {
  //   this.setState({
  //     modalstatus: false,
  //     modalid: '',
  //     addfooter: false,
  //     infoModal: this.state.infoContent.emailContent,
  //     template_type: 1,
  //     current__run_if_new_user_added:0,
  //     current__new_user_schedule_interval:"",
  //     sendImmediately: false,
  //     selectedValue: []
  //   });
  // };

  modalClose = (closeType = null) => {
    if (closeType && closeType === 'edit_campaign') {
      this.setState({
        modalstatus: false,
        modalid: '',
        addfooter: false,
        sendImmediately: false,
        selectedValue: [],
        current__new_user_schedule_interval_error: "none",
        current__run_if_new_user_added: this.state.current__run_if_new_user_added == 1 ? 1 : 0,
        current__new_user_schedule_interval: this.state.current__new_user_schedule_interval != '' ? this.state.current__new_user_schedule_interval : ''
        
      });
    }else{
      this.setState({
        modalstatus: false,
        modalid: '',
        addfooter: false,
        infoModal: this.state.infoContent.emailContent,
        template_type: 1,
        current__new_user_schedule_interval_error: "none",
        current__run_if_new_user_added:  0,
        current__new_user_schedule_interval: '',
        sendImmediately: false,
        selectedValue: []
      });
      
    }
  };

  onChangeInput = (type, data) => {
    
    if (type === 'template_type') {
      this.setState({
        infoModal : data.target.value == 2 ? this.state.infoContent.directEmailContent: this.state.infoContent.emailContent,
        [type]: data.target.value
      }, () => {
        this.setState({ modalbody: this.modalBody('add') });
      });
    }else{
      this.setState({ [type]: data.target.value })
    }
  }


  modalFooter = (index, id=null) => {
    console.log("dd", index, id);

    if (index == 'add' || index == 'edit') {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Close</span>
          </button>
          <button
            type="button"
            onClick={() => this.savePlaybookTemplate(index)}
            className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save</span>
          </button>
        </div>
      );
    }
    else if (index == 'addfooter') {

      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Close</span>
          </button>
          <button
            type="button"
            onClick={() => this.setDesign()}
            className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save</span>
          </button>
        </div>
      );

    }else if (index === 'start_campaign') {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.selectClose(id, 'start')}
            className={(this.props.sendmailfrom == 0) ? 'btn linear-gradient yr-submitbtn btn-sm disabled' : 'btn linear-gradient yr-submitbtn btn-sm'}
            disabled={this.props.sendmailfrom == 0 ? 'true' : ''}
          >
            <span>Next</span>
          </button>
          {(this.props.sendmailfrom == 0) && <div className="email-info-content error" style={{position: 'unset'}}>
                <Popup 
                  position='top right' 
                  size='small' 
                  content={this.state.infoModalDisabled}
                  trigger={<Icon name='info' size='large' circular />} 
                />
              </div>}
         

        </div>
      );
    } else if (index === 'start') {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No </span>
          </button>
          <button
            type="button"
            onClick={() => this.updateActionPlaybook1('Start')}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }else if(index === 'edit_campaign'){
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.selectClose(id, 'edit_campaign_stop')}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          >
            <span>Stop</span>
          </button>
          <button
            type="button"
            onClick={() => this.selectClose(id, 'edit_campaign')}
            className={(this.props.sendmailfrom == 0) ? 'btn linear-gradient yr-submitbtn btn-sm disabled' : 'btn linear-gradient yr-submitbtn btn-sm'}
            disabled={this.props.sendmailfrom == 0 ? 'true' : ''}
          >
            <span>Next</span>
          </button>
          {(this.props.sendmailfrom == 0) && <div className="email-info-content error" style={{position: 'unset'}}>
                <Popup 
                  position='top right' 
                  size='small' 
                  content={this.state.infoModalDisabled}
                  trigger={<Icon name='info' size='large' circular />} 
                />
              </div>}
        </div>
      );
    }else if (index === 'stop') {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No </span>
          </button>
          <button
            type="button"
            onClick={() => this.updateActionPlaybook1('Stopped')}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }


    else {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose('')}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.onDeleteAction(this.state.modalid)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }
  };


  modalBody = (tab) => {

    if (tab == 'add' || tab == 'edit') {
      //alert( this.state.rename)
      let time = Date.now();
      //this.setState({rename:})
      const { edittitle } = this.state
      return (
        <div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <label className="form-control-label">Title <span>*</span></label>
            <input type="text" className={
              Object.keys(this.state.errors).length > 0 && typeof this.state.errors['title_data'] != 'undefined'
                ? "form-control is-invalid focusError"
                : "form-control"
            } key={time} placeholder="Enter Title" defaultValue={this.state.edittitle} onChange={(e) => this.onChangeInput('edittitle', e)} />
            {Object.keys(this.state.errors).length > 0 && typeof this.state.errors['title_data'] != 'undefined' &&
              <CustomErrorField message={this.state.errors['title_data']} id="help-error" className="error invalid-feedback" />
            }
          </div>

          {tab == 'add' &&
            <div className="col-lg-12 col-md-12 col-sm-12 mt10 pt-4">
              <label className="form-control-label">Template type <span>*</span> </label>
              <select value={this.state.template_type} type="text" className={
                Object.keys(this.state.errors).length > 0 && typeof this.state.errors['template_type'] != 'undefined'
                  ? "form-control is-invalid focusError"
                  : "form-control"
              } key={time} placeholder="Enter Title" onChange={(e) => this.onChangeInput('template_type', e)} >
                <option value="1">Email</option>
                <option value="2">Direct Mail</option>
              </select>
              {/* { Object.keys(this.state.errors).length > 0 && typeof this.state.errors['title_data'] !='undefined'  &&
        <CustomErrorField message={this.state.errors['title_data']}   id="help-error" className="error invalid-feedback" />
         } */}
              
               
              <div className="email-info-content">
                <Popup 
                  position='top right' 
                  size='small' 
                  content={this.state.infoModal}
                  trigger={<Icon name='info' size='large' circular />} 
                />
              </div>
            </div>
          }
        </div>
      )
    }
    if (tab == 'delete') {
      return "Are you sure you want to delete it?"
    }

    if (tab == 'addfooter') {

      return (
        <div className="footer_text" style={{ width: "100%" }}>
          {this.state.addfooter &&
            <FooterText
              ref={this.childTwo}
              updateContentNewEditorFooter={this.updateContentNewEditorFooter}
              footer_content={this.state.footer_content}
              footer_contentJson={this.state.footer_contentJson}
              loading={this.state.loadingEditor}
            />
          }
        </div>
      )
    }


  };



  savePlaybookTemplate = (index) => {
    //alert(index + ' added ');
    this.setState({ editor_update: false })
    
    let error = [];

    if (this.state.edittitle == '') {
      error['title_data'] = 'This field is required';
    }
    if (Object.keys(error).length > 0) {


      this.setState({ errors: error }, function () {
        let modalbody = this.modalBody(index)
        this.setState({ modalbody: modalbody })
      })

    } else {

      var elems = document.querySelectorAll('.borderset');
      for (var ind = 0; ind < elems.length; ind++) {
        elems[ind].style.border = "none";
        elems[ind].classList.remove('active_branch');
        if ((ind + 1) == elems.length) {
          elems[ind].classList.add('active_branch');
          this.setState({ selectedlocation: ind });
        }
      }

      this.setState({ modalloading: true })

      api.playbookdetail.savePlaybookTemplate({ 'pid': this.state.pid, 'title': this.state.edittitle, modalid: this.state.modalid, template_type: this.state.template_type }).then(data => {
        try {

          this.setState({ list: data.data.list, modalloading: false, modalid: '', template_type: 1 }, function () {

            this.modalClose('')
            if (index == 'add') {
              this.props.updateList(data.data.list, data.data.details.template_type, data.data.insert_id)
              if (data.data.details.template_type == 1) {
                this.props.addNewTemplate(data.data.details);
              }

              
              
            } else {
              this.props.updateList(data.data.list, data.data.details.template_type)
              this.props.updateEditorStatus(false);
             
            }

          })

          

        } catch (err) {
          console.log(err);
        }
      });

    }
  }




  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });
    var mol_id = this.state.modalid;

    api.playbookdetail.deletePlaybookTemplate({ modalid: this.state.modalid, pid: this.state.pid }).then((data) => {
      this.setState({ list: data.data.list, modalloading: false, modalid: '' }, function () {
        this.modalClose('')
        let new_id = null;
        let teplate_type = null;
        if (this.state.active_id == mol_id) {
          let current = typeof data.data.list[0] != 'undefined' ? data.data.list[0] : null;
          new_id = typeof current.id != 'undefined' ? current.id : null
          teplate_type = typeof current.id != 'undefined' ? current.id : null
        }

        this.props.updateList(data.data.list, teplate_type, new_id)
      })

    });
  };

  openNextRule = () => {
    // this.setState({
    //   modalstatus: true, 
    //   modalindex:'nextemailrule',
    //   emailtitle:'',
    //   errors:[],
    //   edittitle:'',
    //   modalid:modalid,
    //   modaltitle:'Template next rule'
    //  },function(){
    //   let  modalbody='';
    //   modalbody =this.modalBody(type)
    //   this.setState({ modalbody: modalbody });
    //  })
  }

  changeFromEmail = (e) => {
    this.props.updateEmailChanges(e.target.value);
  }

  addTemplateFooter = () => {

    api.playbookdetail.openTemplateFooter({ pid: this.state.pid }).then((data) => {

      this.setState({ footer_content: data.footer_content, footer_contentJson: data.footer_contentJson }, () => {

        this.setState({ modalindex: 'addfooter', addfooter: true });
      })

    });


  }


  updateContentNewEditorFooter = (html, designArr) => {
    //alert('update')
    var designStr = JSON.stringify(designArr);

    var designStr = JSON.stringify(designArr);
    api.playbookdetail.updateContentNewEditorFooter({ 'email_content_json': designStr, 'pid': this.state.pid, 'content': html }).then((data) => {
      try {
        // this.setState({footer_content:data.footer_content,footer_contentJson:data.footer_contentJson})
      } catch (err) {
        console.log(err);
      }
    });
  }

  setDesign = () => {
    this.setState({ loadingEditor: true }, function () {
      this.props.showToaster('green', 'Template Footer successfully');
      this.setState({ loadingEditor: false, modalindex: '', addfooter: false })
    })
    // console.log(this.childTwo)
    //  this.childTwo.current.saveDesign();
  };

  // START ADDED BY ANKIT FOR DRAG, DROP AND MOVE FUNCTIONALITY =================
  onchangeDropbranch = (e) => {
    this.setState({ [e.target.name]: e.target.name });
    if (e.target.value != "") {
      this.orderBranchList(e.target.value, (callback) => {
        var elems = document.querySelectorAll(".borderset");

        // console.log('here print');
        // console.log(elems);

        for (var index = 0; index < elems.length; index++) {
          elems[index].style.border = "none";
        }
        document.querySelector(".direction").disabled = true;
        document.querySelector(".branchcount").disabled = true;
        this.setState({ [e.target.name]: "" });

        // console.log('here print');
        // console.log(this.state.list);

        api.playbookdetail
          .updateposition({ btList: this.state.list })
          .then((data) => {
            //console.log(data);
          });
      });
    }
  };


  onSelectBranch = (e, key, branch_id) => {

    this.setState({ editor_update: false });
    var elems = document.querySelectorAll('.borderset');
    for (var index = 0; index < elems.length; index++) {
      elems[index].style.border = "none";
      if ((index + 1) == elems.length) {
        elems[key].style.border = '1px solid rgb(15, 98, 254)';
        this.setState({ selectedlocation: key });
      }
    }
    //this.props.editBranch(branch_id)
    // var elems2 = document.querySelector('.branchcount');
    // for (var index2 = 0; index2 < elems2.length; index2++) {
    //   elems2[index2].disabled = false;
    //   elems2[index2].style.backgroundColor = "#fff";
    //   if (index2 + 1 == elems2.length) {
    //     elems2[key + 1].disabled = true;
    //     elems2[key + 1].style.backgroundColor = '#cccccc';
    //     document.querySelector('.direction').disabled = false;
    //     document.querySelector('.branchcount').disabled = false;
    //   }
    // }

  }


  orderBranchList = (movelocation, callback) => {
    var order = document.querySelector(".direction").value;
    var selectedlocation = this.state.selectedlocation;

    // alert(movelocation);
    // alert(selectedlocation);
    // alert(order);

    if (order == "up" && selectedlocation == movelocation - 1) {
      callback(0);
    } else if (order == "down" && selectedlocation == movelocation + 1) {
      callback(0);
    } else {
      let tempVal = this.state.list[selectedlocation];
      this.state.list.splice(selectedlocation, 1);
      if (order == "up") {
        if (movelocation > selectedlocation)
          this.state.list.splice(parseInt(movelocation) - 1, 0, tempVal);
        else this.state.list.splice(movelocation, 0, tempVal);
      }
      if (order == "down") {
        if (movelocation > selectedlocation) {
          this.state.list.splice(movelocation, 0, tempVal);
        } else {
          this.state.list.splice(parseInt(movelocation) + 1, 0, tempVal);
        }
      }
      callback(1);
    }
  };

  onEndOrder = (event ) => {
    api.playbookdetail.updateposition({ 'btList': this.state.list }).then(data => {
      this.onSelectBranch(null,event.newIndex,null);
      this.props.openAttr(this.state.list[event.newIndex].id);
	
    })
  }
  // END ADDED BY ANKIT =================


  startCampaign = (type, id) => {
    console.log('dd', id);
    
    if (type === 'edit_campaign') {
      console.log();
      this.setLocalTime('edit_campaign');
      
      // console.log(JSON.parse(id.next_start_allday));

      let date = new Date();
      date = new Date();
      let startTimeForDuration = new Date();
      if (id.start_date_new == null || id.start_date_new == '0000-00-00 00:00:00') {
      } else {
        date = new Date(id.start_date_new)
        if ((id.next_start_date !== 'N/A') && (id.next_start_date !== '0000-00-00 00:00:00')) {
          startTimeForDuration = new Date(id.next_start_date_real)
          // startTimeForDuration = id.next_start_date_real
        }
      }

      let selectedIntervalday = null;
      if (id.next_start_allday) {
        selectedIntervalday = JSON.parse(id.next_start_allday);
      } else if (id.new_user_schedule_interval && (id.next_start_allday == null || id.next_start_allday === '')) {
        const selDay = id.new_user_schedule_interval;
        const dayArray = DAY_LIST.filter(dayL => dayL.name.toLowerCase() === selDay.toLowerCase());
        selectedIntervalday = dayArray.length > 0 ? dayArray : [];
      }

      var timezoneObj = jstz.determine();
      api.marketing.getTimezoneData({ timezonename: timezoneObj.name() }).then(data => {
        
        try {
          this.setState({
            timeZone: data.data.timezone,
            currenttime: data.data.currenttime,
            currentSelectedTimezone: timezoneObj.name(),
            modalstatus: true,
            modalindex: type,
            emailtitle: '',
            errors: [],
            current__run_if_new_user_added: id.run_if_new_user_added,
            current__new_user_schedule_interval: id.new_user_schedule_interval,
            // edittitle: edittitle,
            selectedValue : selectedIntervalday,
            startTimeForDuration: startTimeForDuration,
            setDate: date,
            modalid: id.id,
            modaltitle: 'Edit Schedule',
            edit_campaign: id,
            actionStart: 'Edit'
          }, () => {
            let modalbody = this.getCampaignBody();
            this.setState({ modalbody: modalbody });
          });
        } catch (err) {
          console.log(err);
        }
      });

      // this.setState({
      //   modalstatus: true,
      //   modalindex: type,
      //   emailtitle: '',
      //   errors: [],
      //   current__run_if_new_user_added: id.run_if_new_user_added,
      //   current__new_user_schedule_interval: id.new_user_schedule_interval,
      //   // edittitle: edittitle,
      //   selectedValue : selectedIntervalday,
      //   startTimeForDuration: startTimeForDuration,
      //   setDate: date,
      //   modalid: id.id,
      //   modaltitle: 'Edit Schedule',
      //   edit_campaign: id,
      // }, function () {

      //   // let modalbody = this.modalBody('startCampaign')
      //   let modalbody = this.getCampaignBody();
      //   this.setState({ modalbody: modalbody });
      // })
    } 
    if(type === 'start_campaign'){
      this.setLocalTime();
      let date = new Date();
      let startTimeForDuration = new Date();
      if (id.start_date == null || id.start_date == '0000-00-00 00:00:00') {
      } else {
        date = new Date(id.start_date)
        if (id.next_start_date && id.next_start_date != '0000-00-00 00:00:00') {
          startTimeForDuration = new Date(id.next_start_date)
        }
      }
      var timezoneObj = jstz.determine();
      api.marketing.getTimezoneData({ timezonename: timezoneObj.name() }).then(data => {
        
        try {
          this.setState({
            timeZone: data.data.timezone,
            currenttime: data.data.currenttime,
            currentSelectedTimezone: timezoneObj.name(),
            modalstatus: true,
            modalindex: type,
            emailtitle: '',
            errors: [],
            // edittitle: edittitle,
            setDate: date,
            startTimeForDuration: startTimeForDuration,
            modalid: id.id,
            modaltitle: 'Schedule',
            actionStart: 'Start'
          }, () => {
            let modalbody = this.getBody();
            this.setState({ modalbody: modalbody });
          });
        } catch (err) {
          console.log(err);
        }
      });
      // this.setState({
      //   modalstatus: true,
      //   modalindex: type,
      //   emailtitle: '',
      //   errors: [],
      //   // edittitle: edittitle,
      //   setDate: date,
      //   startTimeForDuration: startTimeForDuration,
      //   modalid: id.id,
      //   modaltitle: 'Schedule',
      // }, function () {

      //   // let modalbody = this.modalBody('startCampaign')
      //   let modalbody = this.getBody();
      //   this.setState({ modalbody: modalbody });
      // })
    }
    if(type=='start'){ 
   
      this.setState({
        errors: [],
        modalstatus: true,
        modalindex: 'start',
        modalid: this.state.modalid,
        modaltitle: 'Confirm your action',
        // modalfooter: this.modalFooter('start', id),
  
      }, () => {this.setState({modalbody: 'Are you sure you want to start this playbook?'});});
    }
    
    if(type=='stop'){
      this.setState({
        errors: [],
        modalstatus: true,
        modalindex: 'stop',
        modalid: this.state.modalid,
        modaltitle: 'Confirm your action',
        modalfooter: this.modalFooter('stop', id),
      }, () => {this.setState({modalbody: 'Are you sure you want to stop this playbook?'})});  
    }

    if (type == 'start_edit') {
      
      this.setState({
        errors: [],
        modalstatus: true,
        modalindex: 'start',
        modalid: this.state.modalid,
        modaltitle: 'Confirm your action',
        modalfooter: this.modalFooter('start', id),
        

      }, () => {this.setState({modalbody: 'Are you sure you want to edit this playbook?'});});
    }
    
  }

  setStartDate = (date) => {
    this.setState({
      setDate: date
    }, () => {
      this.setState({ modalbody: this.getBody() });
    });
  }

  setStartTimeForDuration=(date, bodyType = null) => {
    
    this.setState({
      startTimeForDuration:date
    },()=>{
      this.setState({modalbody: bodyType ? this.getCampaignBody() : this.getBody()});
    });
  }

  changeCheckBoxState = (e, boxType=null) => {
    var current__run_if_new_user_added = (e.target.checked == true) ? 1 : 0
    this.resetValues();
    this.setState({
      current__run_if_new_user_added: current__run_if_new_user_added,
      current__new_user_schedule_interval_error: "none",
      selectedValue: []
      // current__new_user_schedule_interval : (e.target.checked == true) ""
    }, function () {
      this.setState({ modalbody: boxType ? this.getCampaignBody() : this.getBody() });
    })
  }
  
  changeDropDownState = (e, actionType = null) => {
    this.setState({
      current__new_user_schedule_interval_error: "none",
      current__new_user_schedule_interval: e.target.value
    }, function () {
      this.setState({ modalbody: actionType ? this.getCampaignBody() : this.getBody() });
    })
  }

  handleSelectChange = (e) => {
    let list = this.state.timeZone
    let currentTimeZone = e.target.value;
    let default_val = e.target.value;
    let error = this.state.errors;
    if (e.target.value == '') {
      currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    api.marketing.getCurrentTime({ 'timezone': currentTimeZone }).then(data => {

      try {
        list.selectedtimezone = default_val

        if (typeof error != 'undefined' && error != null && error.hasOwnProperty('timezoneerror') && default_val != '') {
          error['timezoneerror'] = false;

        }

        this.setState({
          currenttime: data.data.currenttime,
          currentSelectedTimezone: currentTimeZone,
          minDate: new Date(data.data.currenttime),
          setDate: new Date(data.data.currenttime),
          startTimeForDuration: new Date(data.data.currenttime),
          errors: error
        }, function () {
          this.setState({ modalbody: this.getBody() });
        });

      } catch (err) {
        console.log(err);
      }

    });

  }

  renderTimeZone = (list) => {
    if (typeof list != 'undefined') {

      return Object.keys(list).map(function (key, index) {
        return (
          <option key={index} value={key}>{list[key]}</option>
        )
      });
    }
  }

  setLocalTime = (modalType = null) => {
    var timezoneObj = jstz.determine();
    api.marketing.getTimezoneData({ timezonename: timezoneObj.name() }).then(data => {
      const timezone = data.data.timezone;
      try {
        this.setState({
          timeZone: data.data.timezone,
          currenttime: data.data.currenttime,
          currentSelectedTimezone: timezoneObj.name(),
        }, () => {
          this.setState({ modalbody: modalType ? this.getCampaignBody() : this.getBody() });
        });
        // this.setState({
        //   timeZone: data.data.timezone,
        //   currenttime: data.data.currenttime,
        //   currentSelectedTimezone: timezoneObj.name()
        // });

      } catch (err) {
        console.log(err);
      }
    });
  }

  // this is for multiselct day 

  onSelect = (selectedList, selectedItem, bodytype = null) => {
    console.log(selectedList, selectedItem);
    this.setState({ selectedValue: selectedList, current__new_user_schedule_interval_error: "none", }, 
    () => { this.setState({modalbody:  bodytype ? this.getCampaignBody() :  this.getBody() })}
    );
  }

  // on remove form multiselect day this will trigger

  onRemove = (selectedList, removedItem) => {
    console.log(selectedList, removedItem);
    this.setState({ selectedValue: selectedList });
  }

  resetValues() {
    // By calling the belowe method will reset the selected values programatically
    this.multiselectRef.current.resetSelectedValues();
  }

  getBody = () => {
    const { errorsT, currenttime } = this.state

    return (
      <div className="form-group row">
        <label className="col-lg-3" style={{ padding: 0 }}>Date :</label>
        <div className="col-lg-9">
          <DatePicker
            selected={this.state.setDate}
            onChange={this.setStartDate}
            minDate={this.state.minDate}
            timeInputLabel="Time:"
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            className="form-control"
            name="setDate"
            excludeOutOfBoundsTimes
          />

        </div>
        <label className="col-lg-2"></label>
        <div className="col-lg-10"></div>
        <label className="col-lg-3" style={{ padding: 0 }}>Time Zone :</label>
        <div className="col-lg-9">

          <select className={
            errorsT && errorsT.timezoneerror
              ? "form-control is-invalid "
              : "form-control"
          } id="exampleSelectd" value={this.state.currentSelectedTimezone} onChange={(e) => this.handleSelectChange(e)}>
            {this.renderTimeZone(this.state.timeZone)}
          </select>
          {currenttime &&
            <span className="form-text text-muted">The local date time is currently {currenttime}  </span>
          }

          {errorsT && errorsT.timezoneerror && (
            <CustomErrorField
              message="This field is required"
              id="timezne"
              className="error invalid-feedback"
            />
          )}

        </div>


        <label className="col-lg-12">

          <a className="add-gradient mb-3" style={{ color: '#087ab6', marginTop: 10 }}   >
            <label style={{ float: "left" }} class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
              <input type="checkbox" name="run_if_new_user_added" onClick={(e) => this.changeCheckBoxState(e)} checked={(this.state.current__run_if_new_user_added == 1) ? true : false} />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">Would you like to send an email to the new added users?</span>
          </a>


        </label>
        <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-2">Every :</label>
        <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-10">
          <Multiselect
            options={DAY_LIST} // Options to display in the dropdown
            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
            onSelect={(selectedList, selectedItem) => this.onSelect(selectedList, selectedItem)} // Function will trigger on select event
            onRemove={this.onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            closeIcon="circle"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRef}
          />
          <div style={{ display: this.state.current__new_user_schedule_interval_error }} className="red">{"This field is required"}</div>


        </div>
        <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none", marginTop: 10 }} className="col-lg-2"></label>
        <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none", marginTop: 10 }} className="col-lg-10">
        </div>
        <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-2">Time :</label>
        <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-10">

          <DatePicker
            selected={this.state.startTimeForDuration}
            onChange={this.setStartTimeForDuration}
            showTimeInput
            showTimeSelectOnly
            className="form-control"
            // timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
      </div>


    );
  }

  modalCloseStart=()=>{
    this.setState({
      modalstatus: false,
      current__run_if_new_user_added:0,
      current__new_user_schedule_interval:"",
      sendImmediately: false,
      selectedValue: []
    });

    
  }

  selectClose = (id, footer_type = null) =>{
    console.log('id',id);
    if(this.state.current__run_if_new_user_added==1 && this.state.selectedValue.length === 0){
      this.setState({
        current__new_user_schedule_interval_error:"block",
      },function(){
        this.setState({ modalbody: footer_type ? this.getCampaignBody() : this.getBody(), actionStart: footer_type ? 'Edit' : 'Start' });
      })
      return;
    }

    // this.setState({
    //   errors: [],
    //   modalstatus: true,
    //   modalindex: 'start',
    //   modalid: id.id,
    //   modaltitle: 'Confirm your action',
    //   // modalfooter: this.modalFooter('start'),
    //   modalbody: 'Are you sure you want to start this playbook?',

    // });
    // this.modalClose('edit_campaign');
    if (footer_type && footer_type === 'edit_campaign_stop') {
      this.startCampaign('stop', id)
    } else if(footer_type && footer_type === 'edit_campaign'){
      console.log('edit', id);
      this.startCampaign('start_edit', id)
    }else {
      this.startCampaign('start', id)
    }
  }

  updateActionPlaybook1 = (action) => {
    
    this.setState({
      modalloading: true
    });
    let run_if_new_user_added = this.state.current__run_if_new_user_added
    let new_user_schedule_interval = this.state.current__new_user_schedule_interval

    let date = this.state.setDate.getDate();
    let month = this.state.setDate.getMonth() + 1
    let year = this.state.setDate.getFullYear();
    let hrs = this.state.setDate.getHours();
    let min = this.state.setDate.getMinutes();
    let sec = this.state.setDate.getSeconds();
    let final = year + "-" + month + "-" + date + " " + hrs + ":" + min + ":" + sec;

    let date1 = this.state.startTimeForDuration.getDate();
    let month1 = this.state.startTimeForDuration.getMonth() + 1
    let year1 = this.state.startTimeForDuration.getFullYear();
    let hrs1 = this.state.startTimeForDuration.getHours();
    let min1 = this.state.startTimeForDuration.getMinutes();
    let sec1 = this.state.startTimeForDuration.getSeconds();
    let final1 = year1 + "-" + month1 + "-" + date1 + " " + hrs1 + ":" + min1 + ":" + sec1;

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
    let selectedDayItems = [];
    if (this.state.selectedValue !== null && this.state.selectedValue.length > 0) {
      selectedDayItems = this.state.selectedValue.sort(function (a, b) {
        return a.id - b.id;
      });
    }
    api.marketing.updateActionPlaybook({ 'currentSelectedTimezone':this.state.currentSelectedTimezone,'startTimeForDuration':final1,'startDate':final, 'run_if_new_user_added':run_if_new_user_added,'new_user_schedule_interval':new_user_schedule_interval, 'id': this.state.modalid,'action':action,'time_zone':time_zone, 'sendImmediately' : this.state.sendImmediately, 'startAction': this.state.actionStart, selectedValue: JSON.stringify(selectedDayItems)}).then(data => {
      try {
        this.setState({
          loading:false,
          modalstatus: false,
          modalid:'',
          current__run_if_new_user_added:0,
          current__new_user_schedule_interval:"",
          modalloading: false,
          sendImmediately: false,
        }); 
        this.props.onStartCampaign(data);
        window.location.reload();
      } catch (err) {
          console.log(err);
      }
    });
  }

  // when edit campaign button clicked this body will placed in modal 

  getCampaignBody = () => {
    const { errorsT, currenttime } = this.state

    return (
      <div className="form-group row">
        <label className="col-lg-12">
          <a className="add-gradient mb-3" style={{ color: '#087ab6', marginTop: 10 }}   >
            <label style={{ float: "left" }} class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
              <input type="checkbox" name="run_if_new_user_added" onClick={(e) => this.sendImmediately(e, 'campaign')} checked={(this.state.sendImmediately === true) ? true : false} />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">Would you like to send immediately?</span>
          </a>
        </label>
        <label className="col-lg-3">Date :</label>
        <div className="col-lg-9">
          <DatePicker
            selected={this.state.setDate}
            onChange={this.setStartDate}
            minDate={this.state.minDate}
            timeInputLabel="Time:"
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            className="form-control"
            name="setDate"
            excludeOutOfBoundsTimes
            disabled
          />

        </div>
        <label className="col-lg-2"></label>
        <div className="col-lg-10"></div>
        <label className="col-lg-3" >Time Zone :</label>
        <div className="col-lg-9">

          <select disabled className={
            errorsT && errorsT.timezoneerror
              ? "form-control is-invalid "
              : "form-control"
          } id="exampleSelectd" value={this.state.currentSelectedTimezone} onChange={(e) => this.handleSelectChange(e, 'edit_campaign')} readOnly={true}>
            {this.renderTimeZone(this.state.timeZone)}

          </select>
          {currenttime &&
            <span className="form-text text-muted">The local date time is currently {currenttime}  </span>
          }

          {errorsT && errorsT.timezoneerror && (
            <CustomErrorField
              message="This field is required"
              id="timezne"
              className="error invalid-feedback"
            />
          )}

        </div>


        <label className="col-lg-12">

          <a className="add-gradient mb-3" style={{ color: '#087ab6', marginTop: 10 }}   >
            <label style={{ float: "left" }} class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
              <input type="checkbox" name="run_if_new_user_added" onClick={(e) => this.changeCheckBoxState(e, 'campaign')} checked={(this.state.current__run_if_new_user_added == 1) ? true : false} />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">Would you like to send an email to the new added users?</span>
          </a>


        </label>
        <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-2">Every :</label>
        <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-10">

          <Multiselect
            options={DAY_LIST} // Options to display in the dropdown
            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
            onSelect={(selectedList, selectedItem) => this.onSelect(selectedList, selectedItem, 'editcampaign')} // Function will trigger on select event
            onRemove={this.onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            closeIcon="circle"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRef}
          />

          <div style={{ display: this.state.current__new_user_schedule_interval_error }} className="red">{"This field is required"}</div>


        </div>
        <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none", marginTop: 10 }} className="col-lg-2"></label>
        <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none", marginTop: 10 }} className="col-lg-10">
        </div>
        <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-2">Time :</label>
        <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-10">

          <DatePicker
            selected={this.state.startTimeForDuration}
            onChange={(date) => this.setStartTimeForDuration(date, 'edit_campaign')}
            showTimeInput
            showTimeSelectOnly
            className="form-control"
            // timeIntervals={15}            
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
      </div>
    );
  }

  // sendImmediately

  sendImmediately = (e) => {
    let startdate = e.target.checked ? new Date() : new Date(this.state.edit_campaign.start_date_new);

    this.setState({
      sendImmediately: !this.state.sendImmediately,
      setDate: startdate
    }, () => {
      this.setState({ modalbody: this.getCampaignBody() });
    })
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="yr-chat-round-box box-bg">
        <div className="row">
          <div className="col-lg-12 d-flex  mb-3">
            <div
              className="yr-nowrap kt-font-bold"
              style={{ lineHeight: "38px" }}
            >
              Send emails from:
            </div>

            <select
              className="form-control ml-3"
              onChange={(e) => this.changeFromEmail(e)}
              value={this.props.sendmailfrom}
            >
              <option value="">Select</option>
              {this.state.from_emails.length > 0 &&
                this.state.from_emails.map((obj, index) => {
                  return (
                    <option key={index} value={obj.id}>
                      {obj.email}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className={`col-lg-12 mb-2 ${this.props.playbookStatus === 'Archived' ? 'text-right' : 'playbook-action-container'}`}>
          <div>
              <button type="button" title="Add branch"
                className="btn btn-sm btn-clean btn-icon zyr-close-icon" onClick={() => this.addTemplate('add', '', '')}>
                <img src="/assets/media/icons/plus.svg" alt="" style={{ width: "100%" }} />
              </button>

              <button type="button" title="Add template footer" style={{ marginLeft: "10px" }}
                className="btn btn-sm btn-clean btn-icon zyr-close-icon" onClick={() => this.addTemplateFooter()}>
                <img src="/assets/media/icons/footer.png" alt="" style={{ width: "100%" }} />
              </button>
            </div>
            {STATUS_LIST.includes(this.props.playbookStatus) &&
              <div className="zy-save-btn yr-nowrap kt-font-bold" style={{ cursor: 'pointer', padding: 10 }} onClick={() => this.startCampaign('start_campaign', this.props.playbookDetail)}>Start campaign</div>
            }

            {this.props.playbookStatus === 'Active' &&
              <div className="zy-save-btn yr-nowrap kt-font-bold" style={{ cursor: 'pointer', padding: 10 }} onClick={() => this.startCampaign('edit_campaign', this.props.playbookDetail)}>Edit campaign</div>
            }      
            
            {/* <a className='add-gradient mb-3'  style={{ color: '#087ab6' }} onClick={() => this.openNextRule()} > Next Email Rule</a> */}
          </div>
          {/* <div className="col-lg-10 d-flex">
                                <button type="button"
                                        className="btn linear-gradient btn-clean btn-bold move-btn">
                                    Move
                                </button>
                                <select className="form-control ml-3">
                                    <option>Below</option>
                                    <option>Above</option>
                                </select>
                                <select className="form-control ml-3">
                                    <option>Branch #</option>
                                    <option>Branch 1</option>
                                </select>
                            </div> */}
          {
          this.state.playbookStatus !== 'Active' &&
          <div className="col-lg-12 d-flex justify-content-end">
            <button type="button" className="btn btn-clean btn-bold move-btn">
              Move
            </button>
            <div className="inner-wrapper">
              <select
                className="form-control ml-2 direction"
                name="dropdirection"
                disabled={
                  this.state.selectedBranchStatus == false ? true : false
                }
              >
                <option value="down">Below</option>
                <option value="up">Above</option>
              </select>
              <select
                name="dropbranch"
                className="form-control ml-2 branchcount"
                value={this.state.dropbranch}
                onChange={(e) => this.onchangeDropbranch(e)}
                disabled={
                  this.state.selectedBranchStatus == false ? true : false
                }
              >
                <option value="">Branch #</option>
                {this.state.list &&
                  this.state.list.map((value, index) => {
                    if(this.state.selectedlocation==index ){
                    return (
                      <option value={index} key={index} disabled style={{backgroundColor: '#ccc'}}>
                        {index + 1}
                      </option>
                    );
                    }
                    else{
                      return (
                      <option value={index} key={index}>
                        {index + 1}
                      </option>
                    );
                    }
                  })}
              </select>
            </div>
          </div>
          }
        </div>
        {
          this.state.playbookStatus &&
          <ReactSortable
            disabled = {this.state.playbookStatus == 'Active' ? true : false}
            animation={400}
            list={this.state.list}
            setList={(newState) => this.updateBranchListSequense(newState)}
            onEnd={( event ) => this.onEndOrder( event )}
          >
            {typeof this.state.list != "undefined" &&
              this.state.list.length > 0 &&
              this.state.list.map((obj, y) => {
                return (
                  <div
                    className={
                      this.state.active_id == obj.id
                        ? "yr-chat-round-box inner-box active_branch zyr-chat-box zyra--left__boxes borderset"
                        : "yr-chat-round-box inner-box zyr-chat-box  zyra--left__boxes borderset"
                    }
                    key={y}
                    data-branch={obj.id} onClick={(e) => this.onSelectBranch(e, y, obj.id)}
                  >

                    {/* <div
               
                className="yr-chat-round-box inner-box active_branch zyr-chat-box zyra--left__boxes borderset" data-branch={obj.id} onClick={(e) => this.onSelectBranch(e,y,obj.id)}
              
                key={y}
              > */}
                    <header className="box-head mb-2 border-bottom pb-2">
                      <section
                        className="body-text mb-2"
                        onClick={() => this.props.openAttr(obj.id, y)}
                      >
                        <div class="d-flex align-items-start">
                          <img
                            width="20"
                            src={"/assets/images/emailicon.png"}
                            alt=""
                            class="mr-2"
                          />
                          <h4 class="mt-1 font-weight-bold text=blue">
                            {obj.template_type == 1
                              ? "Customer Email"
                              : "Customer Direct Mail"}
                          </h4>
                        </div>
                      </section>

                      <div className="right-action-btns">
                        <button
                          type="button"
                          className="btn  btn-icon zyr-btn-icon"
                          title="Edit"
                          onClick={() =>
                            this.addTemplate("edit", obj.title, obj.id)
                          }
                        >
                          <i className="far fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          className="btn  btn-icon zyr-btn-icon"
                          title="Copy"
                          onClick={() => this.copyTemplate(obj.id)}
                        >
                          <i className="far fa-copy"></i>
                        </button>
                        {y > 0 && (
                          <button
                            type="button"
                            className="btn btn-icon zyr-btn-icon"
                            title="Delete"
                            onClick={() => this.addTemplate("delete", "", obj.id)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        )}
                      </div>
                    </header>

                    <div
                      className="zyr-title cursorpointer"
                      onClick={() => this.props.openAttr(obj.id, y)}
                      title={obj.title}
                      style={{ textTransform: "unset", letterSpacing: 0 }}
                    >
                      <span className="kt-badge kt-badge--success kt-badge--square radius-20 pre-number-box ">
                        {y + 1}
                      </span>
                      {obj.title}
                    </div>

                    {/* <footer>
                                  <button className="btn linear-gradient yr-round-btn">HVAC</button>
                                  <button className="btn linear-gradient yr-round-btn">Plumbing </button>
                                  <button className="btn linear-gradient yr-round-btn">Electrical</button>
                              </footer> */}
                  </div>
                );
              })}
          </ReactSortable>
        }
        <Modal
          show={this.state.modalstatus}
          footer={this.modalFooter(this.state.modalindex)}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          loading={this.state.modalloading}
        />
        <div class="footer_modal">
          <Modal
            show={this.state.addfooter}
            footer={this.modalFooter(this.state.modalindex)}
            onclose={this.modalClose}
            title={'Add Footer'}
            body={this.modalBody(this.state.modalindex)}
            loading={this.state.modalloading}
          />
        </div>
    </div>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum

})(EmailListNew);
