import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function createMarkup(html) {
  return { __html: html };
}

const status = (status) => {
  if (status == "active") {
    var str = '<div class="lightgreen" style={{ width: "200px" }}>Active</div>';
  } else if (status == "unverified") {
    var str = '<div class="orange" style={{ width: "200px" }}>Unverified</div>';
  }
  return ReactHtmlParser(str);
};

// const from_name = (from_name) => {
//   //alert(from_name);
//   var abc = "string";
//   return abc;
// };

export const DatatableRow = (props) => (
  <Table.Row>
    <Table.Cell>
      <div style={{ width: "200px" }}>{props.list.from_name}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "200px" }}>{props.list.from_email+'@'+props.list.domain_name}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "200px" }}>{props.list.domain_name}</div>
    </Table.Cell>
    <Table.Cell>{status(props.list.status)}</Table.Cell>
    <Table.Cell>
      <div style={{ width: "200px" }}>
        {typeof props.list.txt_records[0].valid != "undefined" &&
          props.list.txt_records[0].valid == "unknown" ? (
          <div class="orange" style={{ width: "200px" }}>
            Pending
          </div>
        ) : (
          <div class="lightgreen" style={{ width: "200px" }}>
            Validated
          </div>
        )}
      </div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "200px" }}>
        {typeof props.list.txt_records[0].valid != "undefined" &&
          props.list.txt_records[1].valid == "unknown" ? (
          <div class="orange" style={{ width: "200px" }}>
            Pending
          </div>
        ) : (
          <div class="lightgreen" style={{ width: "200px" }}>
            Validated
          </div>
        )}
      </div>
    </Table.Cell>
    <Table.Cell style={{ textAlign: "left" }}>
      <a
        href="#"
        class="btn-icon dropdown-toggle yr-managebtn yr-dropdown-toggle-icon"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Manage{" "}
      </a>
      <div className="dropdown-menu dropdown-menu-right yr-custom-menu">
        <ul className="table-list-bg">
          <li>
            <a href="javascript:void(0);" onClick={(e) => props.editDomain('Open', props.list.auto_id)}>
              <i className="far fa-file-alt"></i>
              <span>Edit</span>
            </a>
          </li>
          <li>
            <Link to={"/marketing/detaildomain/" + props.list.auto_id}>
              <i className="far fa-file-alt"></i>
              <span>View Detail</span>
            </Link>
          </li>
          <li>
            <a href="javascript:void(0);" onClick={(e) => props.deleteDomain('Open', props.list.auto_id)}>
              <i className="fa fa-trash"></i>
              <span>Delete</span>
            </a>
          </li>
        </ul>
      </div>
    </Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
