import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function createMarkup(html) {
  return { __html: html };
}
const status = (status) => {
  if (status == "Active") {
    var str = '<span><i class="fa fa-circle green"></i>Active</span>';
  } else if (status == "Stopped") {
    var str = '<span><i class="fa fa-circle red"></i>Stopped</span>';
  } else if (status == "Draft") {
    var str =
      '<span><i class="fab fa-firstdraft" style="color: rgb(57, 129, 181);"></i>Draft</span>';
  } else if (status == "Archived") {
    var str =
      '<span><i class="fas fa-archive" style="color: rgb(57, 129, 181);"></i>Archived</span>';
  }
  return ReactHtmlParser(str);
};

export const DatatableRow = (props) => (

  <Table.Row>
    <Table.Cell>
      <div style={{ width: "100px" }}>
        {props.list.user_type != "main" ? (
          <span>
            <a
              ls_user_id={props.list.id}
              className="btn btn-icon"
              onClick={() => props.createPlaybook("Edit", props.list)}
              title="Edit"
            >
              <i className="la la-edit blue"></i>
            </a>
            <a
              onClick={() => props.createPlaybook("Delete", props.list)}
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-trash"></i>
            </a>{" "}
          </span>
        ) : (
          ""
        )}
      </div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "200px" }}>
        <a href={"/marketing/playbook/" + props.list.id}>{props.list.title}</a>
      </div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "150px" }}>{props.list.display_start_date}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "150px" }}>{props.list.next_start_date}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "150px" }}>{props.list.display_next_start_date}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "150px" }}>{props.list.selected_time_zone}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "150px" }}>
        {ReactHtmlParser(props.list.buckets)}
      </div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "150px" }}>{props.list.eCount}</div>
    </Table.Cell>
    <Table.Cell>
      <div style={{ width: "100px" }}>{status(props.list.status)}</div>
    </Table.Cell>

    <Table.Cell>
      <div style={{ width: "150px" }}>{props.list.added_datetime}</div>
    </Table.Cell>
    <Table.Cell style={{ textAlign: "right" }}>
      <a
        style={{ color: "#087ab6" }}
        href="#"
        class="btn btn-icon dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Manage{" "}
      </a>
      <div className="dropdown-menu dropdown-menu-right yr-custom-menu">
        <ul className="table-list-bg">
          <li>
            <a
              className="cursorpointer"
              onmouseover="adClass()"
              onmouseleave="rdClass()"
              onClick={() => props.createPlaybook("Edit", props.list)}
            >
              <i class="far fa-edit"></i>
              <span>Edit</span>
            </a>
          </li>
          <li className="analiytics-link-email">
            <Link
              to={`playBookBranchWiseStats/${props.list.id}`}
              className="cursorpointer"
            >
              <i class="fa fa-chart-bar"></i>
              <span>Analiytics</span>
            </Link>
          </li>
          <li>
            <a
              className="cursorpointer"
              onClick={() => props.bucketAction("duplicate", props.list.id)}
            >
              <i class="fa fa-copy"></i>
              <span>Duplicate</span>
            </a>
          </li>
          { (props.list.status != "Active" && props.list.status != "Archived" ) && (
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("selectdate", props.list)}
              >
                <i class="far fa-sticky-note"></i>
                <span>Start</span>
              </a>
            </li>
          )}

          {/* {props.list.status == "Active" && (
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("stop", props.list.id)}
              >
                <i class="far fa-sticky-note"></i>
                <span>Stop</span>
              </a>
            </li>
          )} */}

          {props.list.status === "Active" && (
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("edit_campaign", props.list)}
              >
                <i class="fas fa-stopwatch"></i>
                <span>Edit Campaign </span>
              </a>
            </li>
          )}

          {props.list.status != "Archived" && (
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("archive", props.list.id)}
              >
                <i class="far fa-folder-open"></i>
                <span>Archive</span>
              </a>
            </li>
          )}
          {props.list.status === "Archived" && (
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("restore", props.list.id)}
              >
                <i class="fas fa-trash-restore"></i>
                <span>Restore</span>
              </a>
            </li>
          )}
        </ul>

        {/* <ul>
          <li>
            <a className="cursorpointer" onClick={()=>props.bucketAction('edit',props.list.bid)}>
              <span>Edit Name</span>
            </a>
          </li>
          <li>
            <a className="cursorpointer" onClick={()=>props.bucketAction('delete',props.list.bid)}>
              <span>Delete</span>
            </a>
          </li>
        </ul> */}
      </div>
      {/* <div className="kt-form__label">
        <label className="kt-checkbox">
          <input type="checkbox" />
          <span></span>
        </label>

      </div> */}

      {/* <div style={{display:"inline-block"}}>
      <span style={{textAlign:"left"}} className="blue b t-sm cursorpointer" ><Link className="blue"   to={"/marketing/contactlist/"+props.list.bid} >{props.list.title}</Link></span>
      <span>Created {props.list.added_datetime}</span>
      </div> */}
    </Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
