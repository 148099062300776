import React, { useCallback } from 'react'
import CustomErrorField from "../../../Form/CustomErrorField";
import './Automation.css';
import { Tab } from 'semantic-ui-react'

const selectedTabValue = ['link','call','sms'];
class TabExampleSecondaryPointing extends React.Component {
  
  constructor(props) {
    super(props); 
    this.state = {
      link:'',
      call:'',
      sms:'',
      selectedTab:'link',
      errors: {},
      activeIndex:'0',
      autoId:'',
    }
    
  }
  componentDidMount() {
    this.onLoad();
  }

  componentDidUpdate(prevProps) {
    if(this.props.getTabData.position!=prevProps.getTabData.position){
       this.onLoad();
    }
  }

  onLoad = () => {

    if(this.props.getTabData.position!=''){
      let tabEditdata;
      tabEditdata = this.props.getTabData.automationinfo.buttonList[this.props.getTabData.position];
      this.setState({ 
        link:'',
        call:'',
        sms:'',
        selectedTab: tabEditdata.type,
        [tabEditdata.type] : tabEditdata.url,
        activeIndex: selectedTabValue.indexOf(tabEditdata.type),
        errors: {},
        autoId: tabEditdata.auto_id,
      });
    }else{
      this.setState({ 
        link:'',
        call:'',
        sms:'',
        selectedTab:'link',
        errors: {},
        activeIndex:'0',
        autoId:''
      });
    }

  }

  phoneFormat = (p) => { 
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
        return p;
    }else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };

  onchange = (e,f1,f2) => { 
    let inputValue ; 
    if(e.target.name=='call' || e.target.name=='sms')
    inputValue = this.phoneFormat(e.target.value);
    else
    inputValue = e.target.value;
    this.setState({ [e.target.name]: inputValue, [f1]:'', [f2]:''});  
    let errList = this.state.errors;
    delete errList[e.target.name];
    this.setState({ errors: errList });
  };

  onTabChange =(e,data)=>{
   this.setState({selectedTab: selectedTabValue[data.activeIndex],
    activeIndex: data.activeIndex }); 
  }

  onTabSubmit =(data,callback)=>{
    const errors = {}; 
    if (this.state['link']=='' && this.state.selectedTab=='link') { 
       errors['link'] = "This filed is required.";
    } 
      if (this.state['call']=='' && this.state.selectedTab=='call') { 
        errors['call'] = "This filed is required.";
    } 
    if (this.state['call'].trim() != '' && this.state['call'].match(/\d/g).length != 10) {
       errors['call'] = "Please Enter Valid Number.";
    }
      if (this.state['sms']=='' && this.state.selectedTab=='sms') { 
        errors['sms'] = "This filed is required.";
    } 
    if (this.state['sms'].trim() != '' && this.state['sms'].match(/\d/g).length != 10) {
      errors['sms'] = "Please Enter Valid Number.";
    }
    this.setState({ errors });
    if(Object.keys(errors).length==0){ 
      callback(this.state);
    }
  }

  render() { 
     
    const { errors } = this.state;
    const panes = [
      {
        menuItem: 'URL',
        render: () => <Tab.Pane attached={false} >
            <div className="form-group">
                <label>Open URL</label>
                <input type="text" name='link' 
                className={
                  errors && errors['link']
                      ? "form-control is-invalid"
                      : "form-control"
                 } 
                value={this.state.link}  
                onChange={(e) => this.onchange(e,'call','sms')}  placeholder="Url"/>
            {errors && errors.hasOwnProperty('link') && (
                <CustomErrorField
                    message={errors['link']}
                    className="error invalid-feedback"
                />
            )}
            </div>
            
        </Tab.Pane>,
      },
      {
        menuItem: 'Phone call',
        render: () => <Tab.Pane attached={false} >
            <div className="form-group">
                <label>Phone No</label>
                <input type="text" name='call' 
                className={
                  errors && errors['call']
                      ? "form-control is-invalid"
                      : "form-control"
                 } 
                value={this.state.call} 
                onChange={(e) => this.onchange(e,'link','sms')}  placeholder="Phone"/>
            {errors && errors.hasOwnProperty('call') && (
                <CustomErrorField
                    message={errors['call']}
                    className="error invalid-feedback"
                />
            )}
            </div>
            
        </Tab.Pane>,
      },
      {
        menuItem: 'SMS',
        render: () => <Tab.Pane attached={false} >
            <div className="form-group">
                <label>SMS</label>
                <input type="text" name='sms' 
                className={
                  errors && errors['sms']
                      ? "form-control is-invalid"
                      : "form-control"
                 } 
                value={this.state.sms}  
                onChange={(e) => this.onchange(e,'link','call')} placeholder="Sms"/>
            {errors && errors.hasOwnProperty('sms') && (
                <CustomErrorField
                    message={errors['sms']}
                    className="error invalid-feedback"
                />
            )}
            </div>
            
        </Tab.Pane>,
      },
    ]
    

      return(
        <Tab menu={{ secondary: true, pointing: true }} activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.onTabChange} />
      )
  }
}

export default TabExampleSecondaryPointing

