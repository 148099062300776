import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Message } from "semantic-ui-react";
import CustomErrorField from "../../../Form/CustomErrorField";
import AddNewButtonTab from "./AddNewButtonTab";
import PreviewWorkspace from "./PreviewWorkspace";
import DialogBox from "./DialogBox";
import AddQuestionDialog from "./AddQuestionDialog";
import "./Automation.css";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";
var new_configurationUserWise;
var convert_chatbotMiscellaneousOptionSet;
var autoCount = 0;
var startS = 0;
class Automation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      automationinfo: [],
      errors: {},
      buttonBox: false,
      buttonBoxStatus: "custom",
      textName: "",
      setButtonDefaultValue: "",
      position: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      dialogTitle: "",
      dialogMessage: "",
      dialogLoading: false,
      dialogShow: false,
      buttonTextName: "",
      dialogTitleCustom: "",
      dialogMessageCustom: "",
      dialogLoadingCustom: false,
      dialogShowCustom: false,
      questionBtList: [],
      questionAfter: "",
      questionTitle: "",
      editCustomKey: "",
      errorsRespons: {},
      blockfeature: false,
    };
  }
  componentDidMount() {
    const { setPageTitle, toggleNavigation, toggleBreadcrum } = this.props;
    setPageTitle({
      title: "Legacy conversations",
      navigationName: "workspace",
      subHeaderTitle: "Conversations",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }
  componentDidUpdate(prevProps, prevState) {
    startS = 0;
  }

  loadData = () => {
    api.automation.automationinfo().then((data) => {
      try {
        Promise.all([
          new Promise((resolve) => {
            var key,
              keys = Object.keys(data.data.botdata.configurationUserWise);
            var n = keys.length;
            var newobj = {};
            while (n--) {
              key = keys[n];
              newobj[key.toLowerCase()] =
                data.data.botdata.configurationUserWise[key];
              if (n == 0) resolve(newobj);
            }
          }),
          new Promise((resolve) => {
            var key,
              keys = Object.keys(
                data.data.botdata.chatbotMiscellaneousOptionSet
              );
            var n = keys.length;
            var newobj = [];
            while (n--) {
              key = keys[n];
              newobj.push(key.toLowerCase());
              if (n == 0) resolve(newobj);
            }
          }),
        ]).then((fdata) => {
          new_configurationUserWise = fdata[0];
          convert_chatbotMiscellaneousOptionSet = fdata[1];
          this.setState({
            loading: false,
            automationinfo: data.data,
          });
          if (data.data.blockworkspace != "") {
            this.setState({
              blockfeature: true,
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  blockfeature = (val) => {
    this.setState({
      blockfeature: false,
    });
    //if(val=='Ok'){
    window.location.href =
      "botbuilder/" + this.state.automationinfo.blockworkspace;
    // }
  };

  addButton = () => {
    let errList = this.state.errors;
    delete errList["textName"];
    this.setState({
      buttonBox: true,
      buttonBoxStatus: "custom",
      errors: errList,
      position: "",
      textName: "",
    });
    let scrolltop =
      document.querySelector(".yr-chat-round-box").offsetTop -
      document.body.scrollTop;
    window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
  };

  editButton = (obj, y) => {
    let errList = this.state.errors;
    delete errList["textName"];
    if (obj.type == "") {
      this.setState({
        buttonBox: true,
        buttonBoxStatus: "inbuild",
        textName: obj.text,
        setButtonDefaultValue: this.state.automationinfo.defaultButtonList[y],
        position: y,
        errors: errList,
      });
    } else {
      this.setState({
        buttonBox: true,
        buttonBoxStatus: "custom",
        textName: obj.text,
        position: y,
        errors: errList,
      });
    }
    let scrolltop =
      document.querySelector(".yr-chat-round-box").offsetTop -
      document.body.scrollTop;
    window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
  };

  deleteButton = (text) => {
    this.setState({ textName: text }, function () {
      this.actionButtonText("Delete");
    });
  };

  closeButton = () => {
    let errList = this.state.errors;
    delete errList["textName"];
    this.setState({
      buttonBox: false,
      errors: errList,
    });
  };

  onchange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This filed is required.";
      this.setState({ errors: errList });
    }

    if (
      e.target.name == "textName" &&
      this.state.buttonBoxStatus == "custom" &&
      e.target.value != ""
    ) {
      let errList = this.state.errors;
      if (this.state.automationinfo.buttonList.length > 0) {
        if (this.state.position == "") {
          this.state.automationinfo.buttonList.map((x, y) => {
            if (
              x.text.trim().toLowerCase() ===
              e.target.value.trim().toLowerCase()
            ) {
              errList[e.target.name] = "Duplicate button text.";
            }
            if (this.state.automationinfo.buttonList.length == y + 1)
              this.setState({ errors: errList });
          });
        } else {
          if (
            e.target.value !=
            this.state.automationinfo.buttonList[this.state.position].text
          ) {
            this.state.automationinfo.buttonList.map((x, y) => {
              if (
                x.text.trim().toLowerCase() ===
                e.target.value.trim().toLowerCase()
              ) {
                errList[e.target.name] = "Duplicate button text.";
              }
              if (this.state.automationinfo.buttonList.length == y + 1)
                this.setState({ errors: errList });
            });
          } else {
            this.setState({ errors: errList });
          }
        }
      } else {
        this.setState({ errors: errList });
      }
    }
  };

  setDefaultBt = () => {
    let errList = this.state.errors;
    delete errList["textName"];
    this.setState({
      textName: this.state.setButtonDefaultValue,
      // automationinfo: btList,
      errors: errList,
    });
  };

  saveInbuildBt = () => {
    var obj = this;
    this.validateForm(this.state, function (errors) {
      obj.setState({ errors });
      if (Object.keys(errors).length == 0) {
        obj.setState({ loading: true });
        api.automation
          .welcomebuttontext({
            textName: obj.state.textName,
            position: obj.state.position + 1,
          })
          .then((data) => {
            obj.setState({ loading: false });
            try {
              let btList = obj.state.automationinfo;
              btList.buttonList[obj.state.position].text = obj.state.textName;
              obj.setState({
                automationinfo: btList,
              });
              obj.closeButton();
              obj.showToaster("green", data.data);
              utilFunc.scrollTop(800);
            } catch (err) {
              console.log(err);
            }
          });
      } else {
        let scrolltop =
          document.querySelector(".focusError").offsetTop -
          document.body.scrollTop;
        window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
      }
    });
  };

  validateForm = (data, callback) => {
    const errors = {};
    if (data.textName == "") {
      errors["textName"] = "This filed is required.";
    }
    callback(errors);
  };

  actionButtonText = (val) => {
    if (val == "Add") {
      this.setState({
        dialogTitle: "Add",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,
        buttonTextName: "",
      });
    }
    if (val == "Delete") {
      if (this.state.textName == "") {
        this.setState({
          dialogTitle: "Alert",
          dialogMessage: "Please select button text and try again to delete.",
          dialogLoading: false,
          dialogShow: true,
        });
      } else {
        this.setState({
          dialogTitle: "Delete",
          dialogMessage:
            "Are you sure you want to delete the selected button text?",
          dialogLoading: false,
          dialogShow: true,
        });
      }
    }
    if (val == "Close") {
      let errList = this.state.errors;
      delete errList["buttonTextName"];
      this.setState({
        dialogTitle: "",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: false,
        errors: errList,
      });
    }
  };

  saveButtonText = () => {
    var obj = this;
    this.validateForm2(this.state, function (errors) {
      obj.setState({ errors });
      if (Object.keys(errors).length == 0) {
        obj.setState({ dialogLoading: true });
        api.automation
          .buttontextadd({ textName: obj.state.buttonTextName })
          .then((data) => {
            obj.setState({ dialogLoading: false });
            try {
              let btList = obj.state.automationinfo;
              btList.buttonTextList.push(data.data);
              obj.setState({
                automationinfo: btList,
              });
              obj.actionButtonText("Close");
              obj.showToaster("green", "Button text added successfully.");
              utilFunc.scrollTop(800);
            } catch (err) {
              console.log(err);
            }
          });
      }
    });
  };

  validateForm2 = (data, callback) => {
    const errors = {};
    if (data.buttonTextName == "") {
      errors["buttonTextName"] = "This filed is required.";
    }
    if (this.state.automationinfo.buttonTextList.length > 0) {
      this.state.automationinfo.buttonTextList.map((x, y) => {
        if (
          x.text_name.trim().toLowerCase() ===
          data.buttonTextName.trim().toLowerCase()
        ) {
          errors["buttonTextName"] = "This button text already exists.";
        }
        if (this.state.automationinfo.buttonTextList.length == y + 1)
          callback(errors);
      });
    } else {
      callback(errors);
    }
  };

  deleteButtonText = () => {
    this.setState({ dialogLoading: true });
    api.automation
      .buttontextdelete({ textName: this.state.textName })
      .then((data) => {
        this.setState({ dialogLoading: false });
        try {
          let recordDeleted =
            this.state.automationinfo.buttonTextList.findIndex(
              (list) =>
                list.text_name.trim().toLowerCase() ===
                this.state.textName.trim().toLowerCase()
            );
          let dataState = this.state.automationinfo;
          dataState.buttonTextList.splice(recordDeleted, 1);

          let recordDeletedBt = this.state.automationinfo.buttonList.findIndex(
            (list) =>
              list.text.trim().toLowerCase() ===
              this.state.textName.trim().toLowerCase()
          );
          if (recordDeletedBt >= 0)
            dataState.buttonList.splice(recordDeletedBt, 1);
          this.setState({
            automationinfo: dataState,
            textName: "",
          });
          this.actionButtonText("Close");
          this.showToaster("green", "Button text deleted successfully.");
          utilFunc.scrollTop(800);
        } catch (err) {
          console.log(err);
        }
      });
  };

  saveButton = () => {
    var obj = this;
    this.validateForm3(this.state, function (errors) {
      obj.setState({ errors });
      if (Object.keys(errors).length == 0) {
        obj.child.onTabSubmit("", function (data) {
          obj.setState({ loading: true });
          let dataPost = {
            buttonText: obj.state.textName,
            textName: data[data.selectedTab],
            textType: data.selectedTab,
            autoId: data.autoId,
          };
          if (data.autoId == "") {
            api.automation.buttonadd(dataPost).then((data) => {
              obj.setState({ loading: false });
              try {
                let btList = obj.state.automationinfo;
                btList.buttonList.push({
                  text: dataPost.buttonText,
                  type: dataPost.textType,
                  url: dataPost.textName,
                  auto_id: data.data.autoId,
                });
                obj.setState({
                  automationinfo: btList,
                });
                obj.closeButton();
                obj.showToaster("green", "Custom button saved successfully.");
                utilFunc.scrollTop(800);
              } catch (err) {
                console.log(err);
              }
            });
          } else if (data.autoId != "") {
            api.automation.buttonedit(dataPost).then((data) => {
              obj.setState({ loading: false });
              try {
                let btList = obj.state.automationinfo;
                let recordDeleted = btList.buttonList.findIndex(
                  (list) => list.auto_id === dataPost.autoId
                );
                btList.buttonList[recordDeleted].text = dataPost.buttonText;
                btList.buttonList[recordDeleted].type = dataPost.textType;
                btList.buttonList[recordDeleted].url = dataPost.textName;
                obj.setState({
                  automationinfo: btList,
                });
                obj.closeButton();
                obj.showToaster("green", "Custom button saved successfully.");
                utilFunc.scrollTop(800);
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      }
    });
  };

  validateForm3 = (data, callback) => {
    const errors = {};
    if (data.textName == "") {
      errors["textName"] = "This filed is required.";
    }
    if (this.state.automationinfo.buttonList.length > 0) {
      if (this.state.position == "") {
        this.state.automationinfo.buttonList.map((x, y) => {
          if (
            x.text.trim().toLowerCase() === data.textName.trim().toLowerCase()
          ) {
            errors["textName"] = "Duplicate button text.";
          }
          if (this.state.automationinfo.buttonList.length == y + 1)
            callback(errors);
        });
      } else {
        if (
          data.textName !=
          this.state.automationinfo.buttonList[this.state.position].text
        ) {
          this.state.automationinfo.buttonList.map((x, y) => {
            if (
              x.text.trim().toLowerCase() === data.textName.trim().toLowerCase()
            ) {
              errors["textName"] = "Duplicate button text.";
            }
            if (this.state.automationinfo.buttonList.length == y + 1)
              callback(errors);
          });
        } else {
          callback(errors);
        }
      }
    } else {
      callback(errors);
    }
  };

  onchangeStateWp = (e) => {
    let dataState = this.state.automationinfo;
    dataState[e.target.name] = e.target.value;
    var obj = this;
    obj.setState({ loading: true });
    api.automation.onchangewp({ chatVersion: e.target.value }).then((data) => {
      obj.setState({ loading: false });
      try {
        dataState.botdata = data.data;
        this.setState({ automationinfo: dataState, errorsRespons: {} });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleCategory = (e, main, sub) => {
    let newState = this.state.automationinfo;
    let status = false;
    if (e.target.checked) status = true;
    if (sub == "main") {
      newState.botdata["category"][main].status = status;
      if (newState.botdata["category"][main].subcat.length > 0) {
        for (
          var i = 0;
          i < newState.botdata["category"][main].subcat.length;
          i++
        ) {
          newState.botdata["category"][main].subcat[i].status = status;
          if (newState.botdata["category"][main].subcat.length == i + 1)
            this.setState({ automationinfo: newState });
        }
      } else {
        this.setState({ automationinfo: newState });
      }
    } else {
      newState.botdata["category"][main].subcat[sub].status = status;
      if (newState.botdata["category"][main].subcat.length > 0) {
        let setMain = 0;
        for (
          var i = 0;
          i < newState.botdata["category"][main].subcat.length;
          i++
        ) {
          if (newState.botdata["category"][main].subcat[i].status == true)
            setMain = 1;
          if (
            newState.botdata["category"][main].subcat.length == i + 1 &&
            setMain == 0
          ) {
            newState.botdata["category"][main].status = status;
            this.setState({ automationinfo: newState });
          }
        }
      } else {
        this.setState({ automationinfo: newState });
      }
    }
  };

  actionCustomQ = (action, data, key = "") => {
    if (action == "Add") {
      this.setState({
        dialogTitleCustom: "Add",
        dialogMessageCustom: "",
        dialogLoadingCustom: false,
        dialogShowCustom: true,
        questionAfter: "firstwelcomemsg",
      });
    }
    if (action == "Edit") {
      Promise.all([
        new Promise((resolve) => {
          let locationList =
            this.state.automationinfo.botdata.customresponseLocation;
          let dataSend = [];
          if (locationList.length > 0) {
            locationList.map((data1, key1) => {
              if (
                data1.question == data.responseVariable &&
                data1.custom_resp_id == data.auto_id
              ) {
                dataSend.push(data1.btnVal);
              }
            });
            resolve(dataSend);
          } else {
            resolve(dataSend);
          }
        }),
      ])
        .then((dataSend) => {
          let btArr = data.buttons.split("****");
          let newStateL = this.state.questionBtList;
          if (btArr.length > 0) {
            btArr.map((data1, key1) => {
              newStateL.push({
                text: data1,
                location: dataSend[0].includes(data1),
              });
            });
            return newStateL;
          } else {
            return [];
          }
        })
        .then((val2) => {
          this.setState({
            dialogTitleCustom: "Edit",
            dialogMessageCustom: "",
            dialogLoadingCustom: false,
            dialogShowCustom: true,
            questionAfter: data.responseVariable,
            questionTitle: data.response,
            questionBtList: val2,
            editCustomKey: data,
          });
        });
    }
    if (action == "Delete") {
      let oldArr = this.state.automationinfo;
      let recordlist = [];
      new Promise((resolve) => {
        let oldLocation =
          this.state.automationinfo.botdata.customresponseLocation;
        if (oldLocation.length > 0) {
          recordlist = oldArr.botdata.customresponseLocation.filter(
            (list) =>
              list.question !== data.responseVariable &&
              list.custom_resp_id !== data.auto_id
          );
          resolve(recordlist);
        } else {
          resolve(recordlist);
        }
      }).then((recordlist) => {
        oldArr.botdata.customresponseInfo.splice(key, 1);
        oldArr.botdata.customresponseLocation = recordlist;
        this.setState({
          automationinfo: oldArr,
        });
      });
    }
    if (action == "Close") {
      this.setState({
        dialogTitleCustom: "",
        dialogMessageCustom: "",
        dialogLoadingCustom: false,
        dialogShowCustom: false,
        questionBtList: [],
        questionAfter: "",
        questionTitle: "",
        editCustomKey: "",
        errors: {},
      });
    }

    if (action == "Added") {
      var obj = this;
      this.validateForm4(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          let oldArr = obj.state.automationinfo;
          let btarr = [];
          new Promise((resolve) => {
            if (obj.state.questionBtList.length > 0) {
              obj.state.questionBtList.map((x, y) => {
                if (x.text.toLowerCase() != "") {
                  btarr.push(x.text);
                  if (x.location) {
                    oldArr.botdata.customresponseLocation.push({
                      btnVal: x.text,
                      custom_resp_id: "auto_" + autoCount,
                      question: obj.state.questionAfter,
                    });
                  }
                }
                if (obj.state.questionBtList.length == y + 1) resolve(btarr);
              });
            } else {
              resolve(btarr);
            }
          }).then((btarr) => {
            oldArr.botdata.customresponseInfo.push({
              added_datetime: "",
              auto_id: "auto_" + autoCount,
              buttons: btarr.join("****"),
              cahtversion: obj.state.automationinfo.chatVersion,
              chatBot_id: obj.state.automationinfo.chatBotid,
              response: obj.state.questionTitle,
              responseVariable: obj.state.questionAfter,
            });
            autoCount++;
            obj.setState({
              automationinfo: oldArr,
              dialogTitleCustom: "",
              dialogMessageCustom: "",
              dialogLoadingCustom: false,
              dialogShowCustom: false,
              questionBtList: [],
              questionAfter: "",
              questionTitle: "",
            });
          });
        }
      });
    }

    if (action == "Edited") {
      var obj = this;
      this.validateForm4(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          let oldArr = obj.state.automationinfo;
          let btarr = [];
          let index = oldArr.botdata.customresponseInfo.findIndex(
            (list) => list.auto_id === obj.state.editCustomKey.auto_id
          );
          new Promise((resolve) => {
            if (obj.state.questionBtList.length > 0) {
              let recordlist = [];
              new Promise((resolve) => {
                let oldLocation = oldArr.botdata.customresponseLocation;
                if (oldLocation.length > 0) {
                  recordlist = oldArr.botdata.customresponseLocation.filter(
                    (list) =>
                      // list.question !== obj.state.questionAfter &&
                      list.custom_resp_id !== obj.state.editCustomKey.auto_id
                  );
                  resolve(recordlist);
                } else {
                  resolve(recordlist);
                }
              }).then((recordlist) => {
                oldArr.botdata.customresponseLocation = recordlist;
                if (obj.state.questionBtList.length > 0) {
                  obj.state.questionBtList.map((x, y) => {
                    if (x.text.toLowerCase() != "") {
                      btarr.push(x.text);
                      if (x.location) {
                        oldArr.botdata.customresponseLocation.push({
                          btnVal: x.text,
                          custom_resp_id: obj.state.editCustomKey.auto_id,
                          question: obj.state.questionAfter,
                        });
                      }
                    }
                    if (obj.state.questionBtList.length == y + 1)
                      resolve(btarr);
                  });
                } else {
                  resolve(btarr);
                }
              });
            } else {
              resolve(btarr);
            }
          }).then((btarr) => {
            oldArr.botdata.customresponseInfo[index].response =
              obj.state.questionTitle;
            oldArr.botdata.customresponseInfo[index].buttons =
              btarr.join("****");
            obj.setState({
              automationinfo: oldArr,
              dialogTitleCustom: "",
              dialogMessageCustom: "",
              dialogLoadingCustom: false,
              dialogShowCustom: false,
              questionBtList: [],
              questionAfter: "",
              questionTitle: "",
              editCustomKey: "",
            });
          });
        }
      });
    }
  };

  validateForm4 = (data, callback) => {
    const errors = {};
    if (data.questionTitle == "") {
      errors["questionTitle"] = "This filed is required.";
    }
    if (data.questionBtList.length > 0) {
      data.questionBtList.map((x, y) => {
        let fIndex = data.questionBtList.findIndex(
          (list) =>
            list.text.toLowerCase().trim() === x.text.toLowerCase().trim()
        );
        if (fIndex >= 0 && fIndex != y && x.text.trim() != "")
          errors["text[" + y + "]"] = "Button text can not be duplicate.";
        if (data.questionBtList.length == y + 1) callback(errors);
      });
    } else {
      callback(errors);
    }
  };

  actionButtonForQ = (action, val, data = "") => {
    if (action == "Add") {
      let newState = this.state.questionBtList;
      newState.push({ text: "", location: false });
      this.setState({
        questionBtList: newState,
      });
    }
    if (action == "Delete") {
      let oldArrBot = this.state.automationinfo;
      if (data.location) {
        let recordlist =
          this.state.automationinfo.botdata.customresponseLocation.findIndex(
            (list) =>
              list.custom_resp_id === this.state.editCustomKey.auto_id &&
              list.question === this.state.editCustomKey.responseVariable &&
              list.btnVal === data.text
          );
        if (recordlist >= 0)
          oldArrBot.botdata.customresponseLocation.splice(recordlist, 1);
      }

      let oldArr = this.state.questionBtList;
      oldArr.splice(val, 1);
      this.setState({
        questionBtList: oldArr,
        automationinfo: oldArrBot,
      });
    }
  };

  onchangeNewQuestion = (e, key = "", field = "") => {
    if (e.target.name == "questionAfter" || e.target.name == "questionTitle") {
      this.setState({ [e.target.name]: e.target.value });
      if (e.target.value != "") {
        let errList = this.state.errors;
        delete errList[e.target.name];
        this.setState({ errors: errList });
      }
    } else {
      let oldArr = this.state.questionBtList;
      if (field == "text") {
        oldArr[key].text = e.target.value;
      }
      if (field == "location" && e.target.checked == true) {
        oldArr[key].location = true;
      }
      if (field == "location" && e.target.checked == false) {
        oldArr[key].location = false;
      }
      this.setState({
        questionBtList: oldArr,
      });
    }
  };

  onchangeCustom = (e, type = "") => {
    if (type == "tfa") {
      let newState = this.state.automationinfo;
      newState.botdata[e.target.name] = e.target.value;
      this.setState({
        customizeinfo: newState,
      });
      if (e.target.value != "") {
        let errList = this.state.errorsRespons;
        delete errList[e.target.name];
        this.setState({ errorsRespons: errList });
      } else {
        let errList = this.state.errorsRespons;
        errList[e.target.name] = "This filed is required.";
        this.setState({ errorsRespons: errList });
      }
    } else if (type == "") {
      let newState = this.state.automationinfo;
      newState[e.target.name] = e.target.value;
      this.setState({
        customizeinfo: newState,
      });
      if (e.target.value != "") {
        let errList = this.state.errorsRespons;
        delete errList[e.target.name];
        this.setState({ errorsRespons: errList });
      } else {
        let errList = this.state.errorsRespons;
        errList[e.target.name] = "This filed is required.";
        this.setState({ errorsRespons: errList });
      }
    } else {
      let newState = this.state.automationinfo;
      newState.botdata[type][e.target.name] = e.target.value;
      newState.botdata.configurationUserWise[e.target.name] = e.target.value;
      this.setState({
        customizeinfo: newState,
      });
      if (e.target.value != "") {
        let errList = this.state.errorsRespons;
        delete errList[e.target.name];
        this.setState({ errorsRespons: errList });
      } else {
        let errList = this.state.errorsRespons;
        errList[e.target.name] = "This filed is required.";
        this.setState({ errorsRespons: errList });
      }
    }
  };

  onchangeSkipValidation = (e, data, type = "") => {
    if (type == "skip") {
      let newState = this.state.automationinfo;
      if (e.target.checked) {
        if (newState.botdata.configurationUserWise.length == 0)
          newState.botdata.configurationUserWise = {};
        if (e.target.name == "Lender") {
          newState.botdata.configurationUserWise[data] = "True";
          new_configurationUserWise[data.toLowerCase()] = "True";
        } else {
          new_configurationUserWise[data.toLowerCase()] = "true";
          newState.botdata.configurationUserWise[data] = "true";
        }
      } else {
        delete newState.botdata.configurationUserWise[data];
        delete new_configurationUserWise[data.toLowerCase()];
        //  let indexS = newState.botdata.configurationUserWise.indexOf(data);
        //  newState.botdata.configurationUserWise.splice(indexS, 1);
      }
      this.setState({
        customizeinfo: newState,
      });
    }
    if (type == "validation") {
      let newState = this.state.automationinfo;
      if (e.target.checked) {
        newState.botdata.validationlistUserWise.push(data.toLowerCase());
      } else {
        let indexV = newState.botdata.validationlistUserWise.indexOf(data);
        newState.botdata.validationlistUserWise.splice(indexV, 1);
      }
      //console.log(newState);
      this.setState({
        customizeinfo: newState,
      });
    }
  };

  onSubmitQuestion = (e) => {
    let errList = this.state.errorsRespons;
    if (this.state.automationinfo.botdata.tfa_authentication_message == "") {
      errList["tfa_authentication_message"] = "This filed is required.";
    }
    if (this.state.automationinfo.invalid_email_message == "") {
      errList["invalid_email_message"] = "This filed is required.";
    }
    if (this.state.automationinfo.not_service == "") {
      errList["not_service"] = "This filed is required.";
    }
    if (this.state.automationinfo.working_hour_finish == "") {
      errList["working_hour_finish"] = "This filed is required.";
    }
    if (this.state.automationinfo.openMessage == "") {
      errList["openMessage"] = "This filed is required.";
    }
    this.setState({ errorsRespons: errList }, () => {
      if (Object.keys(this.state.errorsRespons).length == 0) {
        this.setState({ loading: true });
        api.automation
          .editautomationinfo(this.state.automationinfo)
          .then((data) => {
            this.setState({ loading: false });
            try {
              if (
                this.state.automationinfo.botdata.customresponseLocation
                  .length == 0
              ) {
                api.automation
                  .deletLocation({
                    customBtnLoc: data.data,
                    chatVersion: this.state.automationinfo.chatVersion,
                  })
                  .then((data) => {
                    //console.log(data);
                  });
              } else {
                api.automation
                  .saveLocation({
                    customBtnLoc: data.data,
                    chatVersion: this.state.automationinfo.chatVersion,
                  })
                  .then((data) => {
                    //console.log(data);
                  });
              }
              this.showToaster(
                "green",
                "Customized chatbot saved successfully."
              );
              utilFunc.scrollTop(800);
            } catch (err) {
              console.log(err);
            }
          });
      } else {
        //let scrolltop = document.querySelector(".yr-ansDiv").offsetParent.offsetTop  - document.querySelector(".qa").scrollHeight;
        //document.querySelector(".qa").scroll({top: scrolltop, left: 0, behavior: 'smooth' });
        document.querySelector(".qa").scroll({
          top: document.querySelector(".error-scroll").offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  loadSkip = () => {
    startS = 1;
    return (
      <div>
        {this.state.automationinfo.botdata.chatbotMiscellaneousOptionSet && (
          <div className="form-group">
            <label>Please select which question you want to skip:</label>
            <div className="kt-checkbox-list">
              <ul>
                {Object.keys(
                  this.state.automationinfo.botdata
                    .chatbotMiscellaneousOptionSet
                ).map((data, key) => {
                  let check1;
                  if (
                    typeof this.state.automationinfo.botdata
                      .configurationUserWise[data] == "undefined"
                  ) {
                    check1 = false;
                  } else {
                    check1 =
                      this.state.automationinfo.botdata.configurationUserWise[
                        data
                      ].toLowerCase() == "true"
                        ? true
                        : false;
                  }
                  let isData =
                    this.state.automationinfo.botdata
                      .chatbotMiscellaneousOptionSet[data];
                  if (typeof isData == "object") {
                    if (
                      this.state.automationinfo.chatVersion == isData.template
                    ) {
                      return (
                        <li key={key}>
                          <label className="kt-checkbox chat-checkbox">
                            <input
                              type="checkbox"
                              name={data}
                              checked={check1}
                              onChange={(e) =>
                                this.onchangeSkipValidation(e, data, "skip")
                              }
                            />{" "}
                            {isData.title}
                            <span />
                          </label>
                        </li>
                      );
                    }
                  } else {
                    return (
                      <li key={key}>
                        <label className="kt-checkbox chat-checkbox">
                          <input
                            type="checkbox"
                            name={data}
                            checked={check1}
                            onChange={(e) =>
                              this.onchangeSkipValidation(e, data, "skip")
                            }
                          />{" "}
                          {isData}
                          <span />
                        </label>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        )}

        {this.state.automationinfo.botdata.validationlist && (
          <div className="form-group">
            <label>
              Please select on which question you want the validation:
            </label>
            <div className="kt-checkbox-list">
              <ul>
                {Object.keys(
                  this.state.automationinfo.botdata.validationlist
                ).map((data, key) => {
                  let check2;
                  if (
                    typeof this.state.automationinfo.botdata
                      .configurationUserWise[data] == "undefined"
                  ) {
                    check2 = true;
                  } else {
                    check2 =
                      this.state.automationinfo.botdata.configurationUserWise[
                        data
                      ].toLowerCase() == "true"
                        ? false
                        : true;
                  }
                  let isData2 =
                    this.state.automationinfo.botdata.validationlist[data];
                  if (check2) {
                    return (
                      <li key={key}>
                        <label className="kt-checkbox chat-checkbox">
                          <input
                            type="checkbox"
                            name={data}
                            onChange={(e) =>
                              this.onchangeSkipValidation(e, data, "validation")
                            }
                            checked={
                              this.state.automationinfo.botdata.validationlistUserWise.includes(
                                data.toLowerCase()
                              )
                                ? true
                                : false
                            }
                          />{" "}
                          {isData2}
                          <span />
                        </label>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { errors } = this.state;
    const { errorsRespons } = this.state;
    const wplist = this.state.automationinfo.workspaceList;

    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p botbuilderdetails">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div class="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Link to="/botbuilder" className={"nav-link "}>
                            <span className="kt-menu__link-text">
                              Workspaces
                            </span>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/automation" className={"nav-link active"}>
                            <span className="kt-menu__link-text">
                              Legacy conversations
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className="wsab-flex yr-automation-uodate-bt"
                  style={{ width: "30%" }}
                >
                  <div className="right-btns">
                    {this.state.automationinfo.blockworkspace == "" ? (
                      <button
                        type="button"
                        onClick={(e) => this.onSubmitQuestion(e)}
                        className="btn linear-gradient yr-submitbtn"
                      >
                        Save
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="yr-body-p yr-mt-20">
                <Message
                  color={
                    this.state.userToasterColor
                      ? this.state.userToasterColor
                      : "teal"
                  }
                  style={{
                    display: this.state.userToaster ? "block" : "none",
                    marginTop: 30,
                  }}
                >
                  {this.state.userToasterMsg}
                </Message>
                <div className="row">
                  {this.state.loading && (
                    <div className="ui loading form"></div>
                  )}
                  {!this.state.loading && (
                    <div className="col-lg-12 yr-chat-box-row yr-automation-uodate">
                      <div
                        className="yr-chat-round-box width-35 yr-mt-20 yr-max-height kt-scroll qa"
                        data-scroll="true"
                      >
                        <div className="form-group">
                          <label className="form-group">
                            Let's customomize your chat experience!
                          </label>
                          <div
                            className="form-group focusError"
                            style={{ marginBottom: 10 }}
                          >
                            <label style={{ marginBottom: 10 }}>
                              First message for your bot
                            </label>
                            <textarea
                              name="openMessage"
                              onChange={(e) => this.onchangeCustom(e)}
                              value={this.state.automationinfo.openMessage}
                              className={
                                errorsRespons && errorsRespons["openMessage"]
                                  ? "form-control is-invalid error-scroll"
                                  : "form-control"
                              }
                            />
                            {errorsRespons &&
                              errorsRespons.hasOwnProperty("openMessage") && (
                                <CustomErrorField
                                  message={errorsRespons["openMessage"]}
                                  className="error invalid-feedback"
                                />
                              )}
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn linear-gradient yr-submitbtn"
                              onClick={(e) => this.actionCustomQ("Add", "")}
                            >
                              Add New Question
                            </button>
                          </div>
                        </div>
                        <div className="form-group row mb-3">
                          <div className="col-12">
                            <label className="form-group">
                              What template would you like to use?
                            </label>
                            <select
                              className="form-control"
                              name="chatVersion"
                              onChange={(e) => this.onchangeStateWp(e)}
                              value={this.state.automationinfo.chatVersion}
                            >
                              {this.state.automationinfo.workspaceList &&
                                Object.keys(wplist).map(function (key) {
                                  return (
                                    <option key={key} value={key}>
                                      {wplist[key]}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        {this.state.automationinfo.botdata.category && (
                          <div
                            className="form-group"
                            style={{ visibility: "hidden", height: "0px" }}
                          >
                            <label>
                              Please select which services you provide:
                            </label>
                            <div className="kt-checkbox-list">
                              {this.state.automationinfo.botdata.category.map(
                                (data, key) => {
                                  return (
                                    <ul key={key}>
                                      <li>
                                        <label className="kt-checkbox chat-checkbox">
                                          <input
                                            type="checkbox"
                                            checked={data.status}
                                            onClick={(e) =>
                                              this.handleCategory(
                                                e,
                                                key,
                                                "main"
                                              )
                                            }
                                          />
                                          {data.name}
                                          <span />
                                        </label>
                                      </li>
                                      <li>
                                        {data.subcat &&
                                          data.status === true &&
                                          data.subcat.map((subdata, key2) => {
                                            return (
                                              <ul key={key2}>
                                                <li>
                                                  <label className="kt-checkbox chat-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      defaultChecked={
                                                        subdata.status
                                                      }
                                                      onClick={(e) =>
                                                        this.handleCategory(
                                                          e,
                                                          key,
                                                          key2
                                                        )
                                                      }
                                                    />
                                                    {subdata.name}
                                                    <span />
                                                  </label>
                                                </li>
                                              </ul>
                                            );
                                          })}
                                      </li>
                                    </ul>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                        <div className="form-group row mb-3">
                          <div className="col-12">
                            <label className="form-group">
                              {this.state.automationinfo.openMessage}
                            </label>
                          </div>
                        </div>
                        {this.state.automationinfo.botdata.customresponseInfo.findIndex(
                          (list) => list.responseVariable === "firstwelcomemsg"
                        ) >= 0 &&
                          this.state.automationinfo.botdata.customresponseInfo.map(
                            (data, key) => {
                              if (data.responseVariable == "firstwelcomemsg") {
                                let btArr = [];
                                if (data.buttons != "")
                                  btArr = data.buttons.split("****");

                                return (
                                  <div
                                    className="form-group row mb-3"
                                    key={key}
                                  >
                                    <div className="col-12 yr-customRes">
                                      <p className="form-group">
                                        {data.response}
                                      </p>
                                      {btArr.length > 0 &&
                                        btArr.map((data1, key1) => {
                                          return (
                                            <button
                                              class="btn yr-submitbtn"
                                              style={{
                                                float: "left",
                                                margin: "0 4px 5px 0",
                                              }}
                                            >
                                              {data1}
                                            </button>
                                          );
                                        })}
                                      <div class="yr-btnArea-action">
                                        <a
                                          title="Edit"
                                          className="btn btn-icon gray"
                                          onClick={(e) =>
                                            this.actionCustomQ(
                                              "Edit",
                                              data,
                                              key
                                            )
                                          }
                                        >
                                          <i className="la la-pencil"></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          className="btn btn-icon gray"
                                          onClick={(e) =>
                                            this.actionCustomQ(
                                              "Delete",
                                              data,
                                              key
                                            )
                                          }
                                        >
                                          <i className="la la-trash"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        {this.state.automationinfo.botdata.chatbotVariables &&
                          Object.keys(
                            this.state.automationinfo.botdata.chatbotVariables
                          ).map((data, key) => {
                            if (
                              data !== "CanHelp" &&
                              this.state.automationinfo.botdata
                                .chatbotVariables[data] !== "true"
                            ) {
                              let val1 =
                                this.state.automationinfo.botdata
                                  .configurationUserWise[data];

                              if (val1 == "" || typeof val1 == "undefined") {
                                val1 =
                                  this.state.automationinfo.botdata
                                    .configurationAdminWise[data];
                              }
                              console.log(val1);
                              console.log(this.state.automationinfo.botdata);
                              return (
                                <div key={key}>
                                  <div
                                    className={
                                      errorsRespons && errorsRespons[data]
                                        ? "form-group row mb-3 error-scroll"
                                        : "form-group row mb-3"
                                    }
                                  >
                                    <div className="col-12">
                                      <label className="form-group">
                                        {
                                          this.state.automationinfo.botdata
                                            .chatbotVariablesTitle[data]
                                        }
                                      </label>
                                      <textarea
                                        key={data}
                                        className={
                                          errorsRespons && errorsRespons[data]
                                            ? "yr-ansDiv form-control is-invalid"
                                            : "yr-ansDiv form-control"
                                        }
                                        name={data}
                                        onChange={(e) =>
                                          this.onchangeCustom(
                                            e,
                                            "chatbotVariables"
                                          )
                                        }
                                      >
                                        {val1}
                                      </textarea>
                                      {errorsRespons &&
                                        errorsRespons.hasOwnProperty(data) && (
                                          <CustomErrorField
                                            message={errorsRespons[data]}
                                            className="error invalid-feedback"
                                          />
                                        )}
                                      {this.state.automationinfo.botdata
                                        .chatbotVariablesTitleOptions[data] &&
                                        Object.keys(
                                          this.state.automationinfo.botdata
                                            .chatbotVariablesTitleOptions[data]
                                        ).map((opt, key) => {
                                          return (
                                            <button
                                              class="btn yr-submitbtn"
                                              style={{
                                                float: "left",
                                                margin: "0 4px 5px 0",
                                              }}
                                            >
                                              {
                                                this.state.automationinfo
                                                  .botdata
                                                  .chatbotVariablesTitleOptions[
                                                  data
                                                ][opt]
                                              }
                                            </button>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  {this.state.automationinfo.botdata.customresponseInfo.findIndex(
                                    (list) => list.responseVariable === data
                                  ) >= 0 &&
                                    this.state.automationinfo.botdata.customresponseInfo.map(
                                      (data0, key0) => {
                                        if (data0.responseVariable == data) {
                                          let btArr = [];
                                          if (data0.buttons != "")
                                            btArr = data0.buttons.split("****");
                                          return (
                                            <div
                                              className="form-group row mb-3"
                                              key={key0}
                                            >
                                              <div className="col-12 yr-customRes">
                                                <p className="form-group">
                                                  {data0.response}
                                                </p>
                                                {btArr.length > 0 &&
                                                  btArr.map((data1, key1) => {
                                                    return (
                                                      <button
                                                        class="btn yr-submitbtn"
                                                        style={{
                                                          float: "left",
                                                          margin: "0 4px 5px 0",
                                                        }}
                                                      >
                                                        {data1}
                                                      </button>
                                                    );
                                                  })}
                                                <div class="yr-btnArea-action">
                                                  <a
                                                    title="Edit"
                                                    className="btn btn-icon gray"
                                                    onClick={(e) =>
                                                      this.actionCustomQ(
                                                        "Edit",
                                                        data0,
                                                        key0
                                                      )
                                                    }
                                                  >
                                                    <i className="la la-pencil"></i>
                                                  </a>
                                                  <a
                                                    title="Delete"
                                                    className="btn btn-icon gray"
                                                    onClick={(e) =>
                                                      this.actionCustomQ(
                                                        "Delete",
                                                        data0,
                                                        key0
                                                      )
                                                    }
                                                  >
                                                    <i className="la la-trash"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              );
                            }
                          })}

                        {this.state.automationinfo.botdata
                          .chatbotMiscellaneous &&
                          Object.keys(
                            this.state.automationinfo.botdata
                              .chatbotMiscellaneous
                          ).map((data, key) => {
                            let display_option = true;
                            let details = data.toLowerCase().split("capture");
                            let opt = convert_chatbotMiscellaneousOptionSet;

                            if (
                              details.length > 0 &&
                              opt.includes(details[0])
                            ) {
                              display_option =
                                new_configurationUserWise[details[0]] == "true"
                                  ? false
                                  : true;
                            }
                            if (display_option) {
                              let val2 =
                                this.state.automationinfo.botdata
                                  .configurationUserWise[data];
                              if (val2 == "" || typeof val2 == "undefined") {
                                val2 =
                                  this.state.automationinfo.botdata
                                    .configurationAdminWise[data];
                              }
                              return (
                                <div key={key}>
                                  {(data == "personcapture" ||
                                    data == "zipcodecapture" ||
                                    data == "phonenumbercapture" ||
                                    data == "emailcapture") &&
                                    startS == 0 &&
                                    this.loadSkip()}
                                  <div
                                    className={
                                      errorsRespons && errorsRespons[data]
                                        ? "form-group row mb-3 error-scroll"
                                        : "form-group row mb-3"
                                    }
                                  >
                                    <div className="col-12">
                                      <label className="form-group">
                                        {
                                          this.state.automationinfo.botdata
                                            .chatbotMiscellaneousTitle[data]
                                        }
                                      </label>
                                      <textarea
                                        key={data}
                                        className={
                                          errorsRespons && errorsRespons[data]
                                            ? "yr-ansDiv form-control is-invalid"
                                            : "yr-ansDiv form-control"
                                        }
                                        name={data}
                                        onChange={(e) =>
                                          this.onchangeCustom(
                                            e,
                                            "chatbotMiscellaneous"
                                          )
                                        }
                                      >
                                        {val2}
                                      </textarea>
                                      {errorsRespons &&
                                        errorsRespons.hasOwnProperty(data) && (
                                          <CustomErrorField
                                            message={errorsRespons[data]}
                                            className="error invalid-feedback"
                                          />
                                        )}
                                    </div>
                                  </div>
                                  {this.state.automationinfo.botdata.customresponseInfo.findIndex(
                                    (list) => list.responseVariable === data
                                  ) >= 0 &&
                                    this.state.automationinfo.botdata.customresponseInfo.map(
                                      (data0, key0) => {
                                        if (data0.responseVariable == data) {
                                          let btArr = [];
                                          if (data0.buttons != "")
                                            btArr = data0.buttons.split("****");

                                          return (
                                            <div
                                              className="form-group row mb-3"
                                              key={key0}
                                            >
                                              <div className="col-12 yr-customRes">
                                                <p className="form-group">
                                                  {data0.response}
                                                </p>
                                                {btArr.length > 0 &&
                                                  btArr.map((data1, key1) => {
                                                    return (
                                                      <button
                                                        class="btn yr-submitbtn"
                                                        style={{
                                                          float: "left",
                                                          margin: "0 4px 5px 0",
                                                        }}
                                                      >
                                                        {data1}
                                                      </button>
                                                    );
                                                  })}
                                                <div class="yr-btnArea-action">
                                                  <a
                                                    title="Edit"
                                                    className="btn btn-icon gray"
                                                    onClick={(e) =>
                                                      this.actionCustomQ(
                                                        "Edit",
                                                        data0,
                                                        key0
                                                      )
                                                    }
                                                  >
                                                    <i className="la la-pencil"></i>
                                                  </a>
                                                  <a
                                                    title="Delete"
                                                    className="btn btn-icon gray"
                                                    onClick={(e) =>
                                                      this.actionCustomQ(
                                                        "Delete",
                                                        data0,
                                                        key0
                                                      )
                                                    }
                                                  >
                                                    <i className="la la-trash"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              );
                            } else {
                              return (
                                <div key={key}>
                                  {(data == "personcapture" ||
                                    data == "zipcodecapture" ||
                                    data == "phonenumbercapture" ||
                                    data == "emailcapture") &&
                                    startS == 0 &&
                                    this.loadSkip()}
                                </div>
                              );
                            }
                          })}
                        {this.state.automationinfo.botdata.showTfaOption ==
                          1 && (
                          <div
                            className="form-group row mb-3"
                            className={
                              errorsRespons &&
                              errorsRespons["tfa_authentication_message"]
                                ? "form-group row mb-3 error-scroll"
                                : "form-group row mb-3"
                            }
                          >
                            <div className="col-12">
                              <label className="form-group">
                                Two factor authentication message for SMS bot.
                              </label>
                              <textarea
                                key="tfa_authentication_message"
                                className={
                                  errorsRespons &&
                                  errorsRespons["tfa_authentication_message"]
                                    ? "yr-ansDiv form-control is-invalid"
                                    : "yr-ansDiv form-control"
                                }
                                name="tfa_authentication_message"
                                onChange={(e) => this.onchangeCustom(e, "tfa")}
                              >
                                {
                                  this.state.automationinfo.botdata
                                    .tfa_authentication_message
                                }
                              </textarea>
                              {errorsRespons &&
                                errorsRespons.hasOwnProperty(
                                  "tfa_authentication_message"
                                ) && (
                                  <CustomErrorField
                                    message={
                                      errorsRespons[
                                        "tfa_authentication_message"
                                      ]
                                    }
                                    className="error invalid-feedback"
                                  />
                                )}
                            </div>
                          </div>
                        )}

                        <div
                          className={
                            errorsRespons &&
                            errorsRespons["invalid_email_message"]
                              ? "form-group row mb-3 error-scroll"
                              : "form-group row mb-3"
                          }
                        >
                          <div className="col-12">
                            <label className="form-group">
                              When someone enters an invalid email
                            </label>
                            <textarea
                              key="invalid_email_message"
                              className={
                                errorsRespons &&
                                errorsRespons["invalid_email_message"]
                                  ? "yr-ansDiv form-control is-invalid"
                                  : "yr-ansDiv form-control"
                              }
                              name="invalid_email_message"
                              onChange={(e) => this.onchangeCustom(e)}
                            >
                              {this.state.automationinfo.invalid_email_message}
                            </textarea>
                            {errorsRespons &&
                              errorsRespons.hasOwnProperty(
                                "invalid_email_message"
                              ) && (
                                <CustomErrorField
                                  message={
                                    errorsRespons["invalid_email_message"]
                                  }
                                  className="error invalid-feedback"
                                />
                              )}
                          </div>
                        </div>

                        <div
                          className={
                            errorsRespons && errorsRespons["not_service"]
                              ? "form-group row mb-3 error-scroll"
                              : "form-group row mb-3"
                          }
                        >
                          <div className="col-12">
                            <label className="form-group">
                              What should we say if we don't service the area
                            </label>
                            <textarea
                              key="not_service"
                              className={
                                errorsRespons && errorsRespons["not_service"]
                                  ? "yr-ansDiv form-control is-invalid"
                                  : "yr-ansDiv form-control"
                              }
                              name="not_service"
                              onChange={(e) => this.onchangeCustom(e)}
                            >
                              {this.state.automationinfo.not_service}
                            </textarea>
                            {errorsRespons &&
                              errorsRespons.hasOwnProperty("not_service") && (
                                <CustomErrorField
                                  message={errorsRespons["not_service"]}
                                  className="error invalid-feedback"
                                />
                              )}
                          </div>
                        </div>
                        {this.state.automationinfo.botdata.customresponseInfo.findIndex(
                          (list) => list.responseVariable === "firstwelcomemsg"
                        ) >= 0 &&
                          this.state.automationinfo.botdata.customresponseInfo.map(
                            (data, key) => {
                              if (data.responseVariable == "notserviceAres") {
                                let btArr = [];
                                if (data.buttons != "")
                                  btArr = data.buttons.split("****");

                                return (
                                  <div
                                    className="form-group row mb-3"
                                    key={key}
                                  >
                                    <div className="col-12 yr-customRes">
                                      <p className="form-group">
                                        {data.response}
                                      </p>
                                      {btArr.length > 0 &&
                                        btArr.map((data1, key1) => {
                                          return (
                                            <button
                                              class="btn yr-submitbtn"
                                              style={{
                                                float: "left",
                                                margin: "0 4px 5px 0",
                                              }}
                                            >
                                              {data1}
                                            </button>
                                          );
                                        })}
                                      <div class="yr-btnArea-action">
                                        <a
                                          title="Edit"
                                          className="btn btn-icon gray"
                                          onClick={(e) =>
                                            this.actionCustomQ(
                                              "Edit",
                                              data,
                                              key
                                            )
                                          }
                                        >
                                          <i className="la la-pencil"></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          className="btn btn-icon gray"
                                          onClick={(e) =>
                                            this.actionCustomQ(
                                              "Delete",
                                              data,
                                              key
                                            )
                                          }
                                        >
                                          <i className="la la-trash"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}

                        <div
                          className={
                            errorsRespons &&
                            errorsRespons["working_hour_finish"]
                              ? "form-group row mb-3 error-scroll"
                              : "form-group row mb-3"
                          }
                        >
                          <div className="col-12">
                            <label className="form-group">
                              How should we end a conversation when a customer
                              chats outside of working hours?
                            </label>
                            <textarea
                              key="working_hour_finish"
                              className={
                                errorsRespons &&
                                errorsRespons["working_hour_finish"]
                                  ? "yr-ansDiv form-control is-invalid"
                                  : "yr-ansDiv form-control"
                              }
                              name="working_hour_finish"
                              onChange={(e) => this.onchangeCustom(e)}
                            >
                              {this.state.automationinfo.working_hour_finish}
                            </textarea>
                            {errorsRespons &&
                              errorsRespons.hasOwnProperty(
                                "working_hour_finish"
                              ) && (
                                <CustomErrorField
                                  message={errorsRespons["working_hour_finish"]}
                                  className="error invalid-feedback"
                                />
                              )}
                          </div>
                        </div>
                        {this.state.automationinfo.botdata.customresponseInfo.findIndex(
                          (list) => list.responseVariable === "firstwelcomemsg"
                        ) >= 0 &&
                          this.state.automationinfo.botdata.customresponseInfo.map(
                            (data, key) => {
                              if (data.responseVariable == "outsideWorking") {
                                let btArr = [];
                                if (data.buttons != "")
                                  btArr = data.buttons.split("****");

                                return (
                                  <div
                                    className="form-group row mb-3"
                                    key={key}
                                  >
                                    <div className="col-12 yr-customRes">
                                      <p className="form-group">
                                        {data.response}
                                      </p>
                                      {btArr.length > 0 &&
                                        btArr.map((data1, key1) => {
                                          return (
                                            <button
                                              class="btn yr-submitbtn"
                                              style={{
                                                float: "left",
                                                margin: "0 4px 5px 0",
                                              }}
                                            >
                                              {data1}
                                            </button>
                                          );
                                        })}
                                      <div class="yr-btnArea-action">
                                        <a
                                          title="Edit"
                                          className="btn btn-icon gray"
                                          onClick={(e) =>
                                            this.actionCustomQ(
                                              "Edit",
                                              data,
                                              key
                                            )
                                          }
                                        >
                                          <i className="la la-pencil"></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          className="btn btn-icon gray"
                                          onClick={(e) =>
                                            this.actionCustomQ(
                                              "Delete",
                                              data,
                                              key
                                            )
                                          }
                                        >
                                          <i className="la la-trash"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>

                      <PreviewWorkspace
                        automationinfo={this.state.automationinfo}
                        addButton={this.addButton}
                        editButton={this.editButton}
                        deleteButton={this.deleteButton}
                      />

                      {this.state.buttonBox && (
                        <div
                          className="width-30 yr-mt-20 yr-max-height"
                          style={{ overflow: "visible", position: "relative" }}
                        >
                          <div className="yr-chat-round-box">
                            <button
                              type="button"
                              class="close linear-gradient"
                              onClick={() => this.closeButton()}
                            >
                              &#10006;
                            </button>
                            <div className="yr-addButtonBox">
                              <h4>Add New Button</h4>
                              <div className="form-group focusError">
                                <label>Button Name</label>
                                {this.state.buttonBoxStatus == "inbuild" ? (
                                  <div>
                                    <input
                                      type="text"
                                      name="textName"
                                      value={this.state.textName}
                                      onChange={(e) => this.onchange(e)}
                                      placeholder="Button Title"
                                      className={
                                        errors && errors["textName"]
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                    />
                                    {errors &&
                                      errors.hasOwnProperty("textName") && (
                                        <CustomErrorField
                                          message={errors["textName"]}
                                          className="error invalid-feedback"
                                        />
                                      )}
                                  </div>
                                ) : (
                                  <div style={{ display: "flex" }}>
                                    <div style={{ width: 80 + "%" }}>
                                      <select
                                        name="textName"
                                        onChange={(e) => this.onchange(e)}
                                        value={this.state.textName}
                                        className={
                                          errors && errors["textName"]
                                            ? "form-control is-invalid"
                                            : "form-control"
                                        }
                                      >
                                        <option value="">Select</option>
                                        {this.state.automationinfo
                                          .buttonTextList &&
                                          this.state.automationinfo.buttonTextList.map(
                                            (x, y) => {
                                              return (
                                                <option
                                                  key={y}
                                                  value={x.text_name}
                                                >
                                                  {x.text_name}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                      {errors &&
                                        errors.hasOwnProperty("textName") && (
                                          <CustomErrorField
                                            message={errors["textName"]}
                                            className="error invalid-feedback"
                                          />
                                        )}
                                    </div>
                                    <div style={{ width: 21 + "%" }}>
                                      <a
                                        title="Add"
                                        className="btn btn-icon"
                                        onClick={() =>
                                          this.actionButtonText("Add")
                                        }
                                      >
                                        <i
                                          className="la la-plus"
                                          style={{ color: "green" }}
                                        ></i>
                                      </a>
                                      {this.state.automationinfo.buttonTextList
                                        .length > 0 && (
                                        <a
                                          title="Delete"
                                          class="btn btn-icon gray"
                                          onClick={() =>
                                            this.actionButtonText("Delete")
                                          }
                                        >
                                          <i class="la la-trash"></i>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display:
                                  this.state.buttonBoxStatus == "inbuild"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <AddNewButtonTab
                                getTabData={this.state}
                                ref={(child) => {
                                  this.child = child;
                                }}
                              />
                            </div>

                            {this.state.buttonBoxStatus == "inbuild" ? (
                              <div className="text-right form-group">
                                <button
                                  type="reset"
                                  class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                                  onClick={() => this.setDefaultBt()}
                                >
                                  <span> Set Default </span>
                                </button>
                                <button
                                  type="button"
                                  className="btn yr-submitbtn"
                                  onClick={() => this.saveInbuildBt()}
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              <div className="text-right form-group">
                                <button
                                  type="reset"
                                  class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                                  onClick={() => this.closeButton()}
                                >
                                  <span> Cancel </span>
                                </button>
                                <button
                                  type="submit"
                                  className="btn yr-submitbtn"
                                  onClick={() => this.saveButton()}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <AddQuestionDialog
                        data={this.state}
                        actionCustomQ={this.actionCustomQ}
                        onchangeNewQuestion={this.onchangeNewQuestion}
                        actionButtonForQ={this.actionButtonForQ}
                      />
                    </div>
                  )}
                </div>

                <DialogBox
                  data={this.state}
                  actionButtonText={this.actionButtonText}
                  saveButtonText={this.saveButtonText}
                  deleteButtonText={this.deleteButtonText}
                  onchange={this.onchange}
                />

                <div
                  className={
                    this.state.blockfeature ? "modal fade show" : "modal fade"
                  }
                  data-backdrop="static"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="staticBackdrop"
                  aria-hidden="true"
                  style={{
                    display: this.state.blockfeature ? "block" : "none",
                  }}
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                    style={{ maxWidth: 600 }}
                  >
                    <div className="modal-content">
                      <button
                        type="button"
                        className="close linear-gradient zy-save-btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.blockfeature("Close")}
                      >
                        X
                      </button>
                      <div className="modal-header">
                        <h5 className="modal-title">Alert</h5>
                      </div>
                      <div className="modal-body">
                        <div className="px-4">
                          You have activated Bot Builder Workspace so this
                          featured is disabled. To use this feature you have to
                          remove default workspace .Click the link given below.
                          <a
                            target="_blank"
                            href={
                              "botbuilder/" +
                              this.state.automationinfo.blockworkspace
                            }
                          >
                            Click here
                          </a>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn linear-gradient yr-submitbtn btn-sm"
                          onClick={() => this.blockfeature("Ok")}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(Automation);
