import React from "react";
import { Link ,Redirect} from 'react-router-dom';
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Pagination, Popup, Icon } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect"; 
import Modal from "./Modal";
import CustomErrorField from "../../../../Form/CustomErrorField";
import DatePicker from "react-datepicker";
import jstz from 'jstz';
import { Multiselect } from "multiselect-react-dropdown";

const DAY_LIST = [
  {name: 'Sunday', id: '0'},
  {name: 'Monday', id: '1'},
  {name: 'Tuesday', id: '2'},
  {name: 'Wednesday', id: '3'},
  {name: 'Thursday', id: '4'},
  {name: 'Friday', id: '5'},
  {name: 'Saturday', id: '6'},
];

const tableHeader = [
  
  {
    title: "Actions",
    width: "1",
    sort: "",
  },{
    title: "Title",
    width: "3",
    sort: "title",
  },
  {
    title: "Schedule Date",
    width: "1",
    sort: "start_date",
  },
  {
    title: "Next Welcome Schedule Date",
    width: "1",
    sort: "next_start_date",
  },
  {
    title: "Next Schedule Email Date",
    width: "1",
    // sort: "next_start_date",
  },
  {
    title: "Time Zone",
    width: "1",
    sort: "selected_time_zone",
  },
  {
    title: "Bucket(s)",
    width: "1",
    sort: "bucket",
  },
  {
    title: "Email(s)",
    width: "1",
    sort: "eCount",
  },
  {
    title: "Status",
    width: "1",
    sort: "status",
  },
  {
    title: "Created On",
    width: "1",
    sort: "created_date",
  },
  
  {
    title: "Other Action",
    width: "1",
    sort: "",
  },
  
  
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "title",
  "added_datetime",
  
];

export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      title: "",
      added_datetime: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      loading: false,
      modalstatus: false, 
      modalbody:'',
      modalfooter:'',
      datatableHeader: null,
      datatableRows: null,  
      auto_id: "", 
      csvdata: [], 
      rename:'',
      modal_id:'',
      errors:[],
      status:"all",
      setDate:new Date(),
      startTimeForDuration: new Date(),
      current__run_if_new_user_added:0,
      current__new_user_schedule_interval:"",
      current__new_user_schedule_interval_error:"none",
      timeZone:[],
      errorsT: { 'timezoneerror': false, errors: [] },
      currenttime:"",
      currentSelectedTimezone:"",
      minDate:new Date(),
      edit_campaign: null,
      sendImmediately: false,
      selectedValue: [],
      actionStart: '', 
      disabledContent : 'Please select the email configured from "Select email" to start the campaign.',
    };
    this.csvLink = React.createRef();
    this.setStartDate = this.setStartDate.bind(this);
    this.multiselectRef = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
    this.setLocalTime();
  }



  setLocalTime = (modalType = null) => {
    var timezoneObj = jstz.determine();
    api.marketing.getTimezoneData({ timezonename: timezoneObj.name() }).then(data => {
      const timezone = data.data.timezone;
      try {
        this.setState({
          timeZone: data.data.timezone,
          currenttime: data.data.currenttime,
          currentSelectedTimezone: timezoneObj.name(),
        }, () => {
          this.setState({ modalbody: modalType ? this.getCampaignBody() : this.getBody() });
        });
        // this.setState({
        //   timeZone: data.data.timezone,
        //   currenttime: data.data.currenttime,
        //   currentSelectedTimezone: timezoneObj.name()
        // });

      } catch (err) {
        console.log(err);
      }
    });
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }
  

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };





  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
   
    this.loadData({ q: filter, _page: 1 });

    // this.loadData({ [filter]: val, _page: 1 });
    
  };
 

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

 

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    console.log(newState)
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });
    

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;
    params["status"] = this.state.status;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.marketing.listPlayBook(query).then((data) => {
        try {
          let datatableRowstemp;
          this.setState({ lists: data.data.list });

          if (data.data.totalrecord) {
            console.log(data.data.list);
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                openContactList={this.openContactList}
                key={index}
                list={list} 
                bucketAction={this.bucketAction}
                createPlaybook={this.props.createPlaybook}

              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={6} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };
  
  
  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list} 
          bucketAction={this.bucketAction}
          createPlaybook={this.props.createPlaybook}

        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
          createPlaybook={this.props.createPlaybook}

        />
      )),
      lists: datalist,
    });
 
  };

  bucketAction=(tab,id)=>{ 
  if(tab=='edit'){
     
      let list_data = this.state.lists;
      let recordid = list_data.findIndex(
        list_data => list_data.bid == id
      )
      
      this.setState({
        errors:[],
        rename: list_data[recordid]['title'],
        modaltitle:'Rename Bucket',
        modalstatus: true,
        modal_id:id,
        modalfooter:this.modalFooter('','edit')
      }, function () {
        
        let modalbody =this.modalBody('edit')
  
        this.setState({ modalbody: modalbody });
      });  
}

  if(tab=='delete'){
    this.setState({
      errors:[],
      modalstatus: true,
      modal_id:id,
      modaltitle:'Confirm your action',
      modalfooter:this.modalFooter('','delete'),
      modalbody:'Are you sure you want to delete it?'
    });  
}
if(tab=='selectdate'){
  
  let date=new Date()
    date = new Date()
    let startTimeForDuration = new Date();
  if(id.start_date==null || id.start_date=='0000-00-00 00:00:00' ){
  }else{
    date=new Date(id.start_date)
    startTimeForDuration = new Date(id.next_start_date)
  }

  var timezoneObj = jstz.determine();
    api.marketing.getTimezoneData({ timezonename: timezoneObj.name() }).then(data => {
      const timezone = data.data.timezone;
      try {
        this.setState({
          timeZone: data.data.timezone,
          currenttime: data.data.currenttime,
          currentSelectedTimezone: timezoneObj.name(),
          errors:[],
          modalstatus: true,
          modal_id:id.id,
          setDate: date,
          startTimeForDuration:startTimeForDuration,
          modaltitle:'Schedule',
          modalfooter:this.modalFooter(id.id,'selectdate', id.domain_id),
          actionStart: 'Start'
        }, () => {
          this.setState({modalbody: this.getBody()});
        });
      } catch (err) {
        console.log(err);
      }
    });
}

if(tab=='start'){ 
   
  this.setState({
    errors:[],
    modalstatus: true,
    modal_id:id,
    modaltitle:'Confirm your action',
    modalfooter:this.modalFooter('','start'),
    
  }, () => { this.setState({modalbody:'Are you sure you want to start this playbook?'})});  
}

if(tab=='stop'){
  this.setState({
    errors:[],
    modalstatus: true,
    modal_id:id,
    modaltitle:'Confirm your action',
    modalfooter:this.modalFooter('','stop'),
  }, () => {this.setState({modalbody:'Are you sure you want to stop this playbook?'})});  
}


if(tab=='archive'){
  this.setState({
    errors:[],
    modalstatus: true,
    modal_id:id,
    modaltitle:'Confirm your action',
    modalfooter:this.modalFooter('','archive'),
    modalbody:'Are you sure you want to archive this playbook?'
  });  
}


if(tab=='duplicate'){
  this.setState({
    errors:[],
    modalstatus: true,
    modal_id:id,
    modaltitle:'Confirm your action',
    modalfooter:this.modalFooter('','duplicate'),
    modalbody:'Are you sure you want to duplicate this playbook?'
  });  
}

    if (tab == 'restore') {
      this.setState({
        errors: [],
        modalstatus: true,
        modal_id: id,
        modaltitle: 'Restore playbook',
        modalfooter: this.modalFooter('', 'restore'),
        modalbody: 'Are you sure you want to restore this playbook to Draft?',
      });
    }

    
    if (tab == 'start_edit') {

      this.setState({
        errors: [],
        modalstatus: true,
        modal_id: id,
        modaltitle: 'Confirm your action',
        modalfooter: this.modalFooter('', 'start'),
      },() => {this.setState({modalbody: 'Are you sure you want to edit this playbook?'})});
    }

    if (tab == 'edit_campaign') {
      var timezoneObj = jstz.determine();
      let date = new Date();
      date = new Date();
      let startTimeForDuration = new Date();
      if (id.start_date_new == null || id.start_date_new == '0000-00-00 00:00:00') {
      } else {
        date = new Date(id.start_date_new)
        if ((id.next_start_date !== 'N/A') && (id.next_start_date !== '0000-00-00 00:00:00')) {
          startTimeForDuration = new Date(id.next_start_date_real)
          // startTimeForDuration = id.next_start_date_real
        }
      }

      let selectedIntervalday = null;
      if (id.next_start_allday) {
        selectedIntervalday = JSON.parse(id.next_start_allday);
      }else if (id.new_user_schedule_interval && (id.next_start_allday == null || id.next_start_allday === '')) {
        const selDay = id.new_user_schedule_interval;
        const dayArray = DAY_LIST.filter(dayL => dayL.name.toLowerCase() === selDay.toLowerCase());
        selectedIntervalday = dayArray.length > 0 ? dayArray : [];
      }
      
    api.marketing.getTimezoneData({ timezonename: timezoneObj.name() }).then(data => {
      try {
        this.setState({
          timeZone: data.data.timezone,
          currenttime: data.data.currenttime,
          currentSelectedTimezone: timezoneObj.name(),
          errors: [],
          modalstatus: true,
          modal_id: id.id,
          setDate: date,
          current__run_if_new_user_added: id.run_if_new_user_added,
          current__new_user_schedule_interval: id.new_user_schedule_interval,
          // selectedValue : id.next_start_allday ? JSON.parse(id.next_start_allday): [],
          selectedValue : selectedIntervalday,
          startTimeForDuration: startTimeForDuration,
          modaltitle: 'Edit Schedule',
          modalfooter: this.modalFooter(id.id, 'edit_campaign', id.domain_id),
          edit_campaign: id,
          actionStart: 'Edit'
        }, () => {
          this.setState({ modalbody: this.getCampaignBody() });
        });
      } catch (err) {
        console.log(err);
      }
    });
    }
  }

  setStartDate=(date) => {
    this.setState({
      setDate:date
    },()=>{
      this.setState({modalbody: this.getBody()});
    });
  }

  setStartTimeForDuration=(date, bodyType = null) => {
    
    this.setState({
      startTimeForDuration:date
    },()=>{
      this.setState({modalbody: bodyType ? this.getCampaignBody() : this.getBody()});
    });
  }

  pad=(size,val) => {
    var s = String(val);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }

  renderTimeZone = (list) => {
    if (typeof list != 'undefined') {

        return Object.keys(list).map(function (key, index) {
            return (
                <option key={index} value={key}>{list[key]}</option>
            )
        });
    }
}

handleSelectChange = (e, actionType = null) => {
  let list = this.state.timeZone
  let currentTimeZone = e.target.value;
  let default_val = e.target.value;
  let error= this.state.errors;
  if (e.target.value == '') {
      currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  api.marketing.getCurrentTime({ 'timezone': currentTimeZone }).then(data => {
     
      try {
          list.selectedtimezone = default_val

          if(typeof error !='undefined' && error!=null  && error.hasOwnProperty('timezoneerror') && default_val!=''){
              error['timezoneerror']=false;
              
          }

          this.setState({
              currenttime: data.data.currenttime,
              currentSelectedTimezone: currentTimeZone,
              minDate: new Date(data.data.currenttime),
              setDate: new Date(data.data.currenttime),
              startTimeForDuration: new Date(data.data.currenttime),
              errors:error
          },function(){
            this.setState({ modalbody: actionType ? this.getCampaignBody() :  this.getBody() });
          });

      } catch (err) {
          console.log(err);
      }

  });

}

// sendImmediately

sendImmediately = (e) => {
  let startdate = e.target.checked ? new Date() : new Date(this.state.edit_campaign.start_date);

  this.setState({
    sendImmediately: !this.state.sendImmediately,
    setDate: startdate
  }, () => {
    this.setState({ modalbody: this.getCampaignBody() });
  })
}

// when edit campaign button clicked this body will placed in modal 

getCampaignBody = () => {
  const { errorsT, currenttime } = this.state

  return (
    <div className="form-group row">
      <label className="col-lg-12">
        <a className="add-gradient mb-3" style={{ color: '#087ab6', marginTop: 10 }}   >
          <label style={{ float: "left" }} class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
            <input type="checkbox" name="run_if_new_user_added" onClick={(e) => this.sendImmediately(e, 'campaign')} checked={(this.state.sendImmediately === true) ? true : false} />
            <span></span>
          </label>
          <span style={{ float: "left" }} className="custom-textRule">Would you like to send immediately?</span>
        </a>
      </label>
      <label className="col-lg-3">Date :</label>
      <div className="col-lg-9">
        <DatePicker
          selected={this.state.setDate}
          onChange={this.setStartDate}
          minDate={this.state.minDate}
          timeInputLabel="Time:"
          dateFormat="MMMM d, yyyy h:mm aa"
          showTimeInput
          className="form-control"
          name="setDate"
          excludeOutOfBoundsTimes
          disabled
        />

      </div>
      <label className="col-lg-2"></label>
      <div className="col-lg-10"></div>
      <label className="col-lg-3" >Time Zone :</label>
      <div className="col-lg-9">

        <select disabled className={
          errorsT && errorsT.timezoneerror
            ? "form-control is-invalid "
            : "form-control"
        } id="exampleSelectd" value={this.state.currentSelectedTimezone} onChange={(e) => this.handleSelectChange(e, 'edit_campaign')} readOnly={true}>
          {this.renderTimeZone(this.state.timeZone)}

        </select>
        {currenttime  &&
          <span className="form-text text-muted">The local date time is currently {currenttime}  </span>
        }

        {errorsT && errorsT.timezoneerror && (
          <CustomErrorField
            message="This field is required"
            id="timezne"
            className="error invalid-feedback"
          />
        )}

      </div>


      <label className="col-lg-12">

        <a className="add-gradient mb-3" style={{ color: '#087ab6', marginTop: 10 }}   >
          <label style={{ float: "left" }} class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
            <input type="checkbox" name="run_if_new_user_added" onClick={(e) => this.changeCheckBoxState(e, 'campaign')} checked={(this.state.current__run_if_new_user_added == 1) ? true : false} />
            <span></span>
          </label>
          <span style={{ float: "left" }} className="custom-textRule">Would you like to send an email to the new added users?</span>
        </a>


      </label>
      <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-2">Every :</label>
      <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-10">
        {/* <select onChange={(e) => this.changeDropDownState(e, 'edit_campaign')} value={this.state.current__new_user_schedule_interval} className={
          (this.state.current__new_user_schedule_interval_error == "block") ? "form-control error-border-red-class" : "form-control"
        } name="duration_week" >
          <option value="">Select</option>
          <option value="sunday">Sunday</option>
          <option value="monday">Monday</option>
          <option value="tuesday">Tuesday</option>
          <option value="wednesday">Wednesday</option>
          <option value="thursday">Thursday</option>
          <option value="friday">Friday</option>
          <option value="saturday">Saturday</option>
        </select> */}

        <Multiselect
          options={DAY_LIST} // Options to display in the dropdown
          selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
          onSelect={(selectedList, selectedItem) => this.onSelect(selectedList, selectedItem, 'startcampaign')} // Function will trigger on select event
          onRemove={this.onRemove} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
          closeIcon="circle"
          avoidHighlightFirstOption={true}
          ref={this.multiselectRef}
        />

        <div style={{ display: this.state.current__new_user_schedule_interval_error }} className="red">{"This field is required"}</div>


      </div>
      <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none", marginTop: 10 }} className="col-lg-2"></label>
      <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none", marginTop: 10 }} className="col-lg-10">
      </div>
      <label style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-2">Time :</label>
      <div style={{ display: (this.state.current__run_if_new_user_added == 1) ? "block" : "none" }} className="col-lg-10">

        <DatePicker
          selected={this.state.startTimeForDuration}
          onChange={ (date) => this.setStartTimeForDuration(date, 'edit_campaign')}
          showTimeInput
          showTimeSelectOnly
          className="form-control"
          // timeIntervals={15}            
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
      </div>
    </div>
  );
}

  // this is for multiselct day 

  onSelect = (selectedList, selectedItem, bodytype = null) => {
    console.log(selectedList, selectedItem);
    this.setState({ selectedValue: selectedList, current__new_user_schedule_interval_error: "none", }, 
    () => { this.setState({modalbody:  bodytype ? this.getCampaignBody() :  this.getBody() })}
    );
  }

  // on remove form multiselect day this will trigger

  onRemove = (selectedList, removedItem) => {
    console.log(selectedList, removedItem);
    this.setState({ selectedValue: selectedList });
  }

  resetValues() {
    // By calling the belowe method will reset the selected values programatically
    this.multiselectRef.current.resetSelectedValues();
  }

  getBody = () => {
    const {  errorsT, currenttime } = this.state

        return(      
              <div className="form-group row"> 
                  <label className="col-lg-3" style={{padding:0}}>Date :</label>
                  <div className="col-lg-9">
                    <DatePicker
                       selected={this.state.setDate} 
                       onChange={this.setStartDate}
                        minDate={this.state.minDate}
                        timeInputLabel="Time:"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeInput
                        className="form-control"
                        name="setDate"
                        excludeOutOfBoundsTimes
                      />
                    
                    </div>
                  <label className="col-lg-2"></label>
                  <div className="col-lg-10"></div>
                  <label className="col-lg-3" style={{padding:0}}>Time Zone :</label>
                  <div className="col-lg-9">
                       
                  <select className={
                                  errorsT && errorsT.timezoneerror
                                      ? "form-control is-invalid "
                                      : "form-control"
                              } id="exampleSelectd" value={this.state.currentSelectedTimezone}  onChange={(e) => this.handleSelectChange(e)}>
                                  {this.renderTimeZone(this.state.timeZone)}
                              </select>
                              {currenttime &&
                                  <span className="form-text text-muted">The local date time is currently {currenttime}  </span>
                              }

                              {errorsT && errorsT.timezoneerror && (
                                  <CustomErrorField
                                      message="This field is required"
                                      id="timezne"
                                      className="error invalid-feedback"
                                  />
                              )}
                    
                    </div>


                    <label className="col-lg-12">
                      
                    <a className="add-gradient mb-3" style={{ color: '#087ab6' ,marginTop:10}}   >
                      <label style={{float:"left"}} class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
                        <input type="checkbox" name="run_if_new_user_added" onClick={(e) => this.changeCheckBoxState(e)} checked={(this.state.current__run_if_new_user_added==1)? true : false}   />
                        <span></span>
                        </label>
                        <span style={{float:"left"}} className="custom-textRule">Would you like to send an email to the new added users?</span> 
                        </a>

                      
                      </label>
                      <label style={{display:(this.state.current__run_if_new_user_added==1)?"block":"none"}} className="col-lg-2">Every :</label>
                  <div style={{display:(this.state.current__run_if_new_user_added==1)?"block":"none"}} className="col-lg-10">
                  {/* <select onChange={(e) => this.changeDropDownState(e)} value={this.state.current__new_user_schedule_interval} className={
                                             (this.state.current__new_user_schedule_interval_error=="block")?"form-control error-border-red-class":"form-control"
                                              } name="duration_week" >
                                                <option value="">Select</option>
                                                <option value="sunday">Sunday</option>
                                                <option value="monday">Monday</option>
                                                <option value="tuesday">Tuesday</option>
                                                <option value="wednesday">Wednesday</option>
                                                <option value="thursday">Thursday</option>
                                                <option value="friday">Friday</option>
                                                <option value="saturday">Saturday</option>
                                              </select> */}
              <Multiselect
                options={DAY_LIST} // Options to display in the dropdown
                selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem) => this.onSelect(selectedList, selectedItem)} // Function will trigger on select event
                onRemove={this.onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                closeIcon="circle"
                avoidHighlightFirstOption={true}
                ref={this.multiselectRef}
              />
              <div style={{ display: this.state.current__new_user_schedule_interval_error }} className="red">{"This field is required"}</div>


                      </div>
                      <label style={{display:(this.state.current__run_if_new_user_added==1)?"block":"none",marginTop:10}} className="col-lg-2"></label>
                  <div style={{display:(this.state.current__run_if_new_user_added==1)?"block":"none",marginTop:10}} className="col-lg-10">
</div>
                      <label style={{display:(this.state.current__run_if_new_user_added==1)?"block":"none"}} className="col-lg-2">Time :</label>
                  <div style={{display:(this.state.current__run_if_new_user_added==1)?"block":"none"}} className="col-lg-10">

                      <DatePicker
                            selected={this.state.startTimeForDuration}
                            onChange={this.setStartTimeForDuration}
                            showTimeInput
                            showTimeSelectOnly
                            className="form-control"
                            // timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                          </div>
                    </div>

              
        );
}
  
  modalClose = (closeType = null) => {
    console.log('**********', 'closetype', closeType);
    if (closeType && closeType === 'edit_campaign') {
      this.setState({
        modalstatus: false,
        sendImmediately: false,
        current__new_user_schedule_interval_error: "none",
        current__run_if_new_user_added: this.state.current__run_if_new_user_added == 1 ? 1 : 0,
        current__new_user_schedule_interval: this.state.current__new_user_schedule_interval != '' ? this.state.current__new_user_schedule_interval : '',
        modalbody: '',
      });
    }else{
      this.setState({
        modalstatus: false,
        current__new_user_schedule_interval_error: "none",
        current__run_if_new_user_added:  0,
        current__new_user_schedule_interval: '',
        sendImmediately: false,
        selectedValue: [],
        modalbody: ''
      });
      
    }
    
  };

  modalCloseStart=()=>{
    this.setState({
      modalstatus: false,
      current__run_if_new_user_added:0,
      current__new_user_schedule_interval:"",
      sendImmediately: false,
      selectedValue: [],
    });

    
  }

  selectClose = (id, footer_type = null) =>{
    if(this.state.current__run_if_new_user_added==1 && this.state.selectedValue.length === 0){
      this.setState({
        current__new_user_schedule_interval_error:"block",
      },function(){
        this.setState({ 
          modalbody: footer_type ? this.getCampaignBody() : this.getBody(), 
          actionStart: footer_type ? 'Edit' : 'Start'
        });
      })
      return;
    }
    // this.modalClose('edit_campaign');
    if (footer_type && footer_type === 'edit_campaign_stop') {
      this.bucketAction('stop', id)
    } else if(footer_type && footer_type === 'edit_campaign'){
      this.bucketAction('start_edit', id)
    }else {
      this.bucketAction('start', id)
    }
  }
  modalFooter = (id, type, domain_id=null) => {
    //console.log(id, '**', type, '**', domain_id);

    if(type=='edit'){
        return (
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => this.modalClose()}
              className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
              data-dismiss="modal"
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              onClick={() => this.updateBucketName(this.state.modal_id)}
              className="btn linear-gradient yr-submitbtn btn-sm"
            >
              <span>Save</span>
            </button>
          </div>
        );
    }
    if(type=='delete'){
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.deleteBucketName(this.state.modal_id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
    }
    
    if(type=='selectdate'){
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.selectClose(id) }
            className={(domain_id == 0) ? 'btn linear-gradient yr-submitbtn btn-sm disabled' : 'btn linear-gradient yr-submitbtn btn-sm'}
            disabled={domain_id == 0 ? 'true' : ''}
          >
            <span>Next</span>
          </button>
          {(domain_id == 0) && <div className="email-info-content error" style={{position: 'unset'}}>
                <Popup 
                  position='top right' 
                  size='small' 
                  content={this.state.disabledContent}
                  trigger={<Icon name='info' size='large' circular />} 
                />
              </div>}

        </div>
      );
      }

    if(type=='start'){
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.updateActionPlaybook('Start')}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
      }

      if(type=='stop'){
        return (
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => this.modalClose()}
              className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
              data-dismiss="modal"
            >
              <span>No</span>
            </button>
            <button
              type="button"
              onClick={() => this.updateActionPlaybook('Stopped')}
              className="btn linear-gradient yr-submitbtn btn-sm"
            >
              <span>Yes</span>
            </button>
          </div>
        );
        }

        if(type=='archive'){
          return (
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => this.modalClose()}
                className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                data-dismiss="modal"
              >
                <span>No</span>
              </button>
              <button
                type="button"
                onClick={() => this.updateActionPlaybook('Archived')}
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                <span>Yes</span>
              </button>
            </div>
          );
        }

        if(type=='duplicate'){
            return (
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => this.modalClose()}
                  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                  data-dismiss="modal"
                >
                  <span>No</span>
                </button>
                <button
                  type="button"
                  onClick={() => this.updateActionPlaybook('Duplicate')}
                  className="btn linear-gradient yr-submitbtn btn-sm"
                >
                  <span>Yes</span>
                </button>
              </div>
            );
        }

      // restore playbook
      if (type === 'restore') {
        return (
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => this.modalClose()}
              className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
              data-dismiss="modal"
            >
              <span>No</span>
            </button>
            <button
              type="button"
              onClick={() => this.updateActionPlaybook('Restore')}
              className="btn linear-gradient yr-submitbtn btn-sm"
            >
              <span>Yes</span>
            </button>
          </div>
        );
      }
      //  for edit campaign playbook time and stop playbook

    if (type == 'edit_campaign') {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalCloseStart()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.selectClose(id, 'edit_campaign_stop')}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          >
            <span>Stop</span>
          </button>
          <button
            type="button"
            onClick={() => this.selectClose(id, 'edit_campaign')}
            className={(domain_id == 0) ? 'btn linear-gradient yr-submitbtn btn-sm disabled' : 'btn linear-gradient yr-submitbtn btn-sm'}
            disabled={domain_id == 0 ? 'true' : ''}
          >
            <span>Next</span>
          </button>
          {(domain_id == 0) && <div className="email-info-content error" style={{position: 'unset'}}>
                <Popup 
                  position='top right' 
                  size='small' 
                  content={this.state.disabledContent}
                  trigger={<Icon name='info' size='large' circular />} 
                />
              </div>}
        </div>
      );
    }
  };

  modalBody = (tab) => {
    
    if(tab=='edit'){
      //alert( this.state.rename)
      let time=Date.now();
      //this.setState({rename:})
     const {rename}=this.state
      return (
        <div className="col-lg-12 col-md-12 col-sm-12">
        <label className="form-control-label">Title <span>*</span></label>
        <input type="text" className={
                                        Object.keys(this.state.errors).length > 0 && typeof this.state.errors['rename_data'] !='undefined'  
                                            ? "form-control is-invalid focusError"
                                            : "form-control"
                                    } key={time} placeholder="Enter Summary" defaultValue={rename} onChange={(e) => this.onChangeInput('rename',e)} />
        { Object.keys(this.state.errors).length > 0 && typeof this.state.errors['rename_data'] !='undefined'  &&
        <CustomErrorField message={this.state.errors['rename_data']}   id="help-error" className="error invalid-feedback" />
         }
      </div>
      )
    }
    if(tab=='delete'){
      return "Are you sure"
    }
  };

  onChangeInput=(type,data)=>{
    this.setState({rename:data.target.value})
    
  }
  updateBucketName=(id)=>{


    let error=[];
    if(this.state.rename==''){
        error['rename_data']='This field is required';
    }
    if(Object.keys(error).length>0){
      
      
      this.setState({errors:error},function(){
        let modalbody =this.modalBody('edit')
        this.setState({modalbody:modalbody})
       
      })
    }else{
     
     this.setState({loading:true})
     
      api.marketing.updateBucketName({ 'bid': id ,'title':this.state.rename}).then(data => {
        try {

          if(data.status==false){
            let error=[];
            error['rename_data']=data.message;
            this.setState({errors:error,loading:false},function(){
              let modalbody =this.modalBody('edit')
              this.setState({modalbody:modalbody})
             
            })
          }
          else{
          let list_data = this.state.lists;
          let recordid = list_data.findIndex(
            list_data => list_data.bid == id
          )
          list_data[recordid].title=this.state.rename;

          

          this.setState({
            loading:false,
            lists: list_data,
            modalstatus: false,
            modal_id:'',
           
          },function(){
              let datatableRowstemp = this.state.lists.map((list, index) => (
                <DatatableRow
                  channgeEvent={this.checkboxChangeEvent}
                  openContactList={this.openContactList}
                  key={index}
                  list={list} 
                  bucketAction={this.bucketAction}
                  createPlaybook={this.props.createPlaybook}

                />
              ));
              this.setState({datatableRows: datatableRowstemp})
          }); 
        }
        } catch (err) {
            console.log(err);
        }
    });
  }
  }

  deleteBucketName=(id)=>{
    api.marketing.deleteBucketName({ 'bid': id}).then(data => {
      try {
        let list_data = this.state.lists;
        let recordid = list_data.findIndex(
          list_data => list_data.bid == id
        )
        list_data.splice(recordid,1)
        this.setState({
          loading:false,
          lists: list_data,
          modalstatus: false,
          modal_id:'',
         
        },function(){
            let datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                openContactList={this.openContactList}
                key={index}
                list={list} 
                bucketAction={this.bucketAction}
                createPlaybook={this.props.createPlaybook}

              />
            ));
            this.setState({datatableRows: datatableRowstemp})
        }); 
          
      } catch (err) {
          console.log(err);
      }
  });
  }

  getListByStatus = (e)=>{
    if(this.state.status!=e) { 
      document.getElementById("kt_form_status").value="title";
      document.getElementById("playBookSearchBar").value="";
      this.setState({
        loading:true,
        status:e
      }, () => {
        this.getListByStatusNow()
      })
    }
  }

  getListByStatusNow = () =>{
      this.loadData({
        _limit: 10,
        _order: "desc",
        _sort: "id",
        q: "",
        _page: 1,
        title: "",
        });
  }
   
  updateActionPlaybook=(action)=>{
    let run_if_new_user_added =  this.state.current__run_if_new_user_added
    let new_user_schedule_interval =  this.state.current__new_user_schedule_interval
   
    let date = this.state.setDate.getDate();
    let month = this.state.setDate.getMonth() + 1
    let year = this.state.setDate.getFullYear();
    let hrs = this.state.setDate.getHours();
    let min = this.state.setDate.getMinutes();
    let sec = this.state.setDate.getSeconds();
    let final=year+"-"+month+"-"+date+" "+hrs+":"+min+":"+sec;

    let date1 = this.state.startTimeForDuration.getDate();
    let month1 = this.state.startTimeForDuration.getMonth() + 1
    let year1 = this.state.startTimeForDuration.getFullYear();
    let hrs1 = this.state.startTimeForDuration.getHours();
    let min1 = this.state.startTimeForDuration.getMinutes();
    let sec1 = this.state.startTimeForDuration.getSeconds();
    let final1=year1+"-"+month1+"-"+date1+" "+hrs1+":"+min1+":"+sec1;

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
    
    let selectedDayItems = [];
    if (this.state.selectedValue !== null && this.state.selectedValue.length > 0) {
      selectedDayItems = this.state.selectedValue.sort(function (a, b) {
        return a.id - b.id;
      });
    }
    api.marketing.updateActionPlaybook({ 'currentSelectedTimezone':this.state.currentSelectedTimezone,'startTimeForDuration':final1,'startDate':final, 'run_if_new_user_added':run_if_new_user_added,'new_user_schedule_interval':new_user_schedule_interval, 'id': this.state.modal_id,'action':action,'time_zone':time_zone, 'sendImmediately' : this.state.sendImmediately, 'startAction': this.state.actionStart, selectedValue: JSON.stringify(selectedDayItems)}).then(data => {
      try {
        this.setState({
          loading:false,
          modalstatus: false,
          modal_id:'',
          current__run_if_new_user_added:0,
          current__new_user_schedule_interval:""
        }); 
        this.loadData({});
      } catch (err) {
          console.log(err);
      }
  });
  }

  changeCheckBoxState = (e, boxType=null) => {
    var current__run_if_new_user_added = (e.target.checked == true) ? 1 : 0
    this.resetValues();
    this.setState({
      current__run_if_new_user_added: current__run_if_new_user_added,
      current__new_user_schedule_interval_error: "none",
      selectedValue: []
      // current__new_user_schedule_interval : (e.target.checked == true) ""
    }, function () {
      this.setState({ modalbody: boxType ? this.getCampaignBody() : this.getBody() });
    })
  }
  
  changeDropDownState = (e, actionType = null) => {
    this.setState({
      current__new_user_schedule_interval_error: "none",
      current__new_user_schedule_interval: e.target.value
    }, function () {
      this.setState({ modalbody: actionType ? this.getCampaignBody() : this.getBody() });
    })
  }

  render() {
    return (
      <div>
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          handleSort={this.handleSort}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading} 
          csvdata={this.state.csvdata} 
          createPlaybook={this.props.createPlaybook}
          getListByStatus = {this.getListByStatus}
        />
                  {this.state.loading && <div className="ui loading form"></div>}

        <Segment className="width-fluid" style={{ width: "100%",   minHeight:"300px", margin: "0px",padding:0,paddingTop: "0px", overflow:'auto'}}>

          <DatatableTable
            lists={this.state.lists}
            datatableHeader={tableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>
        {/* <DatatablePageSizeSelect
          limit={this.state._limit.toString()}
          onChangeLimit={this.onChangeLimit}
        />{" "}
        Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
        <Pagination
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          activePage={this.state._page}
          onPageChange={this.onChangePage}
        />  */}
         
         <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
        />

      </div>
    );
  }
}
