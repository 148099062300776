import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import api from "../../../../../api";
import "./BotBuilder.css";
import BranchList from "./BranchList";
import BranchDetail from "./BranchDetail";
import AddButton from "./AddButton";

import Modal from "../../../ChatSetup/components/Modal";

import config from "../../../../../config";
import AddSkills from "./AddSkills";
import QuestionResponse from "./QuestionResponse";
import MultipleChoice from "./MultipleChoice";
import AddLinks from "./AddLinks";
import AddWebhook from "./AddWebhook";
import CreateBranch from "./CreateBranch";
import LiveChatRule from "./LiveChatRule";
import TestView from "./TestView";
import ZipCodeRules from "./ZipCodeRules";
import TranscriptRule from "./TranscriptRule";

class BotBuilderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
      update_list: [],
      modalstatus: false,
      modalfooter: "",
      modaltitle: "",
      modalbody: "",
      rename: "",
      keyauto_id: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      workspace_id: "",
      branchList: [],
      captured_as: [],
      countryCodeLists: [],
      location_list: [],
      branchDetails: [],
      captured_list: [],
      activemodal: [],
      defaultCountryCodeLists: [],
      location_list: [],
      button_action_id: "add",
      deletemodalid: "",
      intent_data: [],
      active_branch_id: "",
      AIRulesData: [
        {
          cat_id: "",
          sub_cat_id: "",
          intent_id: "",
          intent_branch_id: "",
          intent_status: false,
        },
      ],
      loadingaction: false,
      toster: { color: "", status: false, message: "" },
      create_modal_status: "",
      branch_new_id: "",
      modalindex: "",
      livechatshow: false,
      editortrue: false,
      iframestatus: false,
      testView: false,
      alert_message_display: false,
      custom_workspace_id: "",
      zipcodenewbranch_id: "",
      checkzipcaptured: false,
      review_links: "",
      contact_bucket: [],
      checkedValue: [],
      checkedValueID: [],
    };
  }
  componentDidMount() {
    const { setPageTitle, toggleNavigation, toggleBreadcrum } = this.props;
    setPageTitle({
      title: "BotBuilder",
      navigationName: "workspace",
      subHeaderTitle: "Conversations",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    //alert( this.props.match.params.id)
    this.setState({ workspace_id: this.props.match.params.id }, function () {
      this.loadData();
    });
    // this.loadData();
    // this.loadData();
  }

  loadData = () => {
    api.botbuilder
      .viewwokspacedetail({ auto_id: this.state.workspace_id })
      .then((data) => {
        try {
          this.setState({
            branchList: data.data.branchLists,
            captured_as: data.data.captured_as,
            countryCodeLists: data.data.countryCodeLists,
            branchDetails: data.data.branch_details,
            defaultCountryCodeLists: data.data.defaultCountryCodeLists,
            location_list: data.data.location_list,
            captured_list: data.data.captured_list,
            userinfo: data.data.userinfo,
            bot_type: data.data.bot_type,
            response_time: data.data.response_time,
            intent_data: data.data.intent_data,
            active_branch_id: data.data.branch_details.branch_id,
            alert_message_display: data.data.alert_message_display,
            custom_workspace_id: data.data.custom_workspace_id,
            checkzipcaptured: data.data.branch_details.checkzipcaptured,
            loading: false,
            originalData: data.data,
            review_links: data.data.reviews,
            contact_bucket: data.data.contact_bucket,
            //checkedValueID: data.data.branch_details.details.selected_buckets,
            //checkedValueID: data.data.CheckedValArr,
            checkedValue: data.data.checkedValue,
          });
          this.modalClose();

          setTimeout(() => {
            this.setState({ userToaster: false });
          }, 3000);

        } catch (err) {
          console.log(err);
        }
      });

      

  };

  updateList = (data, index) => {
    let newintex = typeof index != "undefined" ? index : "";

    if (newintex != "" && newintex == this.state.active_branch_id) {
      let actid = data[1].branch_id;

      this.setState({ branchList: data, activemodal: "" }, function () {
        this.editBranch(actid);
      });
    } else {
      this.setState({ branchList: data, activemodal: "" });
    }
  };

  saveButton = (data, type_id) => {
    // console.log(data);
    data["branch_id"] = this.state.active_branch_id;
    data["user_id"] = "12333";
    data["workspace_id"] = this.state.workspace_id;

    if (type_id == "add") {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_buttons.push(data);
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "order") {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_buttons = data;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "validation") {
      let branchDetails = this.state.branchDetails;
      if (
        data.target.value ==
          this.state.originalData.branch_details.details.validation &&
        this.state.originalData.branch_details.details.validation != ""
      ) {
        branchDetails.details.validation_reply =
          this.state.originalData.branch_details.validation_reply;
      } else {
        branchDetails.details.validation_reply = [];
      }
      branchDetails.details.validation = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "validationreply") {
      let branchDetails = this.state.branchDetails;
      if (branchDetails.details.validation == "phonenumber") {
        branchDetails.details.validation_reply = [];
        branchDetails.details.validation_reply.push(
          {
            phone_length_reply:
              document.getElementsByName("phone_length_reply")[0].value,
          },
          {
            invalid_phone_reply: document.getElementsByName(
              "invalid_phone_reply"
            )[0].value,
          }
        );
      }
      if (branchDetails.details.validation == "email") {
        branchDetails.details.validation_reply = [];
        branchDetails.details.validation_reply.push({
          email_reply: document.getElementsByName("email_reply")[0].value,
        });
      }
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "captured_as") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.capture_as = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "is_end") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.is_end = data.target.checked ? "Y" : "";
      branchDetails.details.team_workspace_members = "0";
      branchDetails.details.team_profile = "1";
      branchDetails.team_data = [
        {
          branch_id: "",
          created_at: "",
          email: "",
          id: "",
          phone: "",
          user_id: "",
          workspace_id: "",
          country_code: "",
        },
      ];
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "team_profile") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.team_profile = data.target.checked ? "1" : "0";
      branchDetails.details.team_workspace_members = "0";
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "team_workspace_members") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.team_workspace_members = data.target.checked
        ? "1"
        : "0";
      branchDetails.details.team_profile = "0";
      branchDetails.team_data = [
        {
          branch_id: "",
          created_at: "",
          email: "",
          id: "",
          phone: "",
          user_id: "",
          workspace_id: "",
          country_code: "",
        },
      ];
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "capturedSave") {
      let listcaptured = this.state.captured_list;
      listcaptured.push(data.list);
      this.setState({ captured_list: listcaptured });
    } else if (type_id == "capturedDelete") {
      let listcaptured = this.state.captured_list;

      let activeindex = listcaptured.findIndex((list) => list.slug == data.key);

      listcaptured.splice(activeindex, 1);
      if (this.state.branchDetails.details.capture_as == data.key) {
        let details = this.state.branchDetails;
        details.details.capture_as = "";
        this.setState({ branchDetails: details });
      } else {
        this.setState({ captured_list: listcaptured });
      }
    } else if (type_id == "noAIselected") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.target_branch = data.target.value;

      var index = data.target.selectedIndex;
      var optionElement = data.target.childNodes[index];
      var attribute = optionElement.getAttribute("optiontext");
      let optiontext = 0;
      if (typeof attribute != "undefined" && attribute != "") {
        optiontext = attribute;
      }

      branchDetails.details.target_branch_type = optiontext;

      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "transcriptRule") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.transscript_rule = data.transscript_rule;
      branchDetails.details.trans_script_rule_button =
        data.trans_script_rule_button;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "delete_transcript_rule") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.transscript_rule = "";
      branchDetails.details.trans_script_rule_button = "";
      this.setState({ branchDetails: branchDetails }, function () {
        //this.setState({ activemodal: "" });
      });
    } 
    else if (type_id == "select_contact") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.select_contact = data.target.checked
        ? "1"
        : "0";
      this.setState({ branchDetails: branchDetails }, function () {
      });
    }
    else if (type_id == "show_country_code") {
      
      let branchDetails = this.state.branchDetails;
      branchDetails.details.show_country_code = data.target.checked
        ? "1"
        : "0";
      this.setState({ branchDetails: branchDetails }, function () {
      });
    }

    else {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_buttons[type_id] = data;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    }
  };

  actionTeamMembers = (data, action, index) => {
    if (action == "add") {
      let add_team_array = {
        branch_id: "",
        created_at: "",
        email: "",
        id: "",
        phone: "",
        user_id: "",
        workspace_id: "",
        country_code: "",
      };

      let branchDetails = this.state.branchDetails;
      branchDetails.team_data.push(add_team_array);
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    }

    if (action == "delete") {
      let branchDetails = this.state.branchDetails;
      branchDetails.team_data.splice(index, 1);

      this.setState({ branchDetails: branchDetails }, function () {
        this.modalClose();
      });
    }

    if (action == "email_change") {
      let branchDetails = this.state.branchDetails;
      branchDetails.team_data[index].email = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {});
    }

    if (action == "isd_code_change") {
      let branchDetails = this.state.branchDetails;
      branchDetails.team_data[index].country_code = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {});
    }

    if (action == "phone_change") {
      let branchDetails = this.state.branchDetails;

      branchDetails.team_data[index].phone = this.phoneFormat(
        data.target.value
      );

      this.setState({ branchDetails: branchDetails }, function () {});
    }

    if (action == "checkedValue") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.selected_buckets = data;
      this.setState({ branchDetails: branchDetails }, function () {});
    }

  };

  openTab = (tab) => {
    if (tab == "textarea") {
      let add_question_array = {
        auto_id: "",
        branch_id: "",
        question: "",
        status: "",
        id: "",
        user_id: "",
        workspace_id: "",
        country_code: "",
      };

      let branchDetails = this.state.branchDetails;
      branchDetails.branch_questions.push(add_question_array);
      this.setState(
        { branchDetails: branchDetails, editortrue: true },
        function () {
          this.setState({ activemodal: "" });
        }
      );
    } else {
      if (tab == "skills") {
        this.setState(
          {
            activemodal: tab,
            button_action_id: "add",
            AIRulesData: [
              {
                cat_id: "",
                sub_cat_id: "",
                intent_id: "",
                intent_branch_id: "",
                intent_status: false,
              },
            ],
          },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      } else {
        this.setState(
          { activemodal: tab, button_action_id: "add", branch_new_id: "" },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      }
    }
  };
  popupClose = () => {
    this.setState({ activemodal: "" });
  };

  saveBranch = (data, callback) => {
    api.botbuilder.saveBranch(this.state.branchDetails).then((data) => {
      try {
        let branchList = this.state.branchList;
        let activeindex = branchList.findIndex(
          (list) => list.branch_id == this.state.active_branch_id
        );

        branchList[activeindex] = data.single_branch_data;

        let branch_details = this.state.branchDetails;
        branch_details.details.attachment_on_off =
          data.single_branch_data.attachment_on_off;
        this.setState(
          {
            branchList: branchList,
            branchDetails: branch_details,
            editortrue: false,
            alert_message_display: true,
          },
          function () {
            callback("success");
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  buttonAction = (action, index) => {
    if (action == "edit") {
      this.setState(
        { activemodal: "button", button_action_id: index },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }

    if (action == "delete") {
      this.popupClose();
      this.setState({
        modalstatus: true,
        modaltitle: "Confirm Your Action",
        modalbody: "Are you sure you want to delete?",
        deletemodalid: index,
      });
    }
  };

  skillsAction = (action, index, delete_ai_id) => {
    if (action == "edit") {
      let aidata = this.state.branchDetails.ai_list[index];

      let data = [
        {
          cat_id: aidata.cat_id,
          sub_cat_id: aidata.sub_cat_id,
          intent_id: aidata.intent_id,
          intent_branch_id: aidata.intent_branch_id,
          intent_status: true,
        },
      ];

      this.setState(
        {
          activemodal: "skills",
          button_action_id: delete_ai_id,
          AIRulesData: data,
        },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }

    if (action == "delete") {
      this.popupClose();
      api.botbuilder
        .deleteAIRules({ delete_ai_id: delete_ai_id })
        .then((data) => {
          try {
            let branchDetails = this.state.branchDetails;
            branchDetails.ai_list.splice(index, 1);
            this.setState({ branchDetails: branchDetails });
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  modalFooter = (index) => {
    if (index == "finalsave") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmFinalSave()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    } else if (index == "multiplechoicedelete") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmdeleteMultipleChoiceQuestion()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    } else {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmDeleteButton(this.state.deletemodalid)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }
  };
  confirmDeleteButton = (id) => {
    let branchDetails = this.state.branchDetails;
    //   branchDetails.branch_buttons[type_id]=data
    // let activeindex = branchDetails.branch_buttons.findIndex(
    //   (list) => list === buttoninfo.button_respond_on
    // );

    branchDetails.branch_buttons.splice(id, 1);

    this.setState({ branchDetails: branchDetails }, function () {
      this.modalClose();
    });

    //alert(id)
  };
  modalClose = () => {
    this.setState({
      modalstatus: false,
      modaltitle: "",
      modalbody: "",
      modalindex: "",
    });
  };

  modalCloseCreate = () => {
    this.setState({
      create_modal_status: "",
    });
  };

  onDeploy = (action) => {
    if (action == "Open") {
      let msg = "";
      if (
        (this.state.userinfo.default_workspace_id != "0" &&
          this.state.userinfo.default_workspace_id != null &&
          this.state.userinfo.default_workspace_id ==
            this.state.custom_workspace_id &&
          this.state.bot_type == "WEB") ||
        (this.state.userinfo.default_sms_workspace_id != "0" &&
          this.state.userinfo.default_sms_workspace_id != null &&
          this.state.userinfo.default_sms_workspace_id ==
            this.state.custom_workspace_id &&
          this.state.bot_type == "SMS") ||
        (this.state.userinfo.default_fb_workspace_id != "0" &&
          this.state.userinfo.default_fb_workspace_id != null &&
          this.state.userinfo.default_fb_workspace_id ==
            this.state.custom_workspace_id &&
          this.state.bot_type == "FB")
      ) {
        this.setState({ deployAction: "Remove" });
        msg = "Are you sure want to reset this workspace from default chatbot?";
      } else {
        this.setState({ deployAction: "Deploy" });
        msg =
          "After deploying , this workspace is set to your default bot . Are you sure want to do this?";
      }
      this.setState({
        dialogDeployTitle: "Confirm Your Action",
        dialogDeploy: true,
        dialogDeployMessage: msg,
      });
    }
    if (action == "Close") {
      this.setState({
        dialogDeploy: false,
        dialogDeployMessage: "",
        dialogDeployTitle: "",
      });
    }
    if (action == "Deploy" || action == "Remove") {
      let st;
      if (action == "Deploy") st = "1";
      if (action == "Remove") st = "0";
      api.botbuilder
        .deployworkspace({
          wid: this.state.workspace_id,
          status: st,
          bt: this.state.bot_type,
        })
        .then((data) => {
          try {
            let updatedata = this.state.userinfo;
            if (this.state.bot_type == "WEB")
              updatedata.default_workspace_id = data.data.default_workspace_id;
            if (this.state.bot_type == "SMS")
              updatedata.default_sms_workspace_id =
                data.data.default_sms_workspace_id;
            if (this.state.bot_type == "FB")
              updatedata.default_fb_workspace_id =
                data.data.default_fb_workspace_id;

            this.setState({
              dialogDeploy: false,
              dialogDeployMessage: "",
              userinfo: updatedata,
            });
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  show_user_iframe = (chat_key, workspace_id) => {
    if (this.state.iframestatus == false) {
      let flag = "0";
      if (
        this.state.branchList.length > 0 &&
        this.state.branchList[0].status == "1"
      )
        flag = "1";

      if (flag == "0") {
        this.setState({
          dialogDeployTitle: "Alert",
          dialogDeploy: true,
          dialogDeployMessage:
            'Before deploy you have to save your workspace.please click on the "Save" button before deploy.',
          deployAction: "",
        });
      } else {
        this.setState({ iframestatus: true });
        var chatActivity = {};
        chatActivity.info = {};
        chatActivity.info.zipcode = "";
        chatActivity.info.price = "";
        chatActivity.info.propertyId = "";
        var this_js_script = document.getElementById("chatBT");
        var chatKey = chat_key;
        if (typeof chatKey === "undefined") {
          var chatKey =
            "some problem in chat bot code please contact to zyra administrator.";
          alert(chatKey);
        }
        // For UTM
        var documentUrl = document.URL;
        var n = documentUrl.indexOf("utm_source");
        if (n !== -1) {
          var utm_url = encodeURIComponent(documentUrl);
        } else {
          var utm_url = "";
        }

        var referrer_url = document.referrer;

        var css = "";
        css +=
          ".BOT_chatcontainer{border: none;display: block;position: fixed;top: auto;left: auto;bottom: 20px;right: 20px;visibility: visible;z-index: 2147483647;max-height: 700px;transition: none 0s ease 0s;background: none transparent;opacity: 1;height: 90% !important;width: 400px !important;}@media only screen and (max-width: 480px){.BOT_chatcontainer {width: 100% !important;right: 0;bottom: 0;height: 100% !important;}}";
        //css += '.BOT_chatcontainer_Add{width: 185px !important; height:95px !important;}'
        var head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");
        style.type = "text/css";
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        var chatDiv = document.createElement("div");
        chatDiv.id = "zychatsection";
        if (document.body.firstChild) {
          document.body.insertBefore(chatDiv, document.body.firstChild);
        } else {
          document.body.appendChild(chatDiv);
        }

        var randomNumber = Math.floor(Math.random() * 100000000000000000);

        document.getElementById("zychatsection").innerHTML =
          '<iframe allow="autoplay" bottype="backend" frameborder="0" class="BOT_chatcontainer BOT_chatcontainer_Add" src="' +
          config.apiBaseURL.split("api")[0] +
          "contractorschatbot/index.html?chatKey=" +
          chatKey +
          "&hostName=" +
          encodeURIComponent(window.location.href) +
          "&referrer_url=" +
          referrer_url +
          "&utm_url=" +
          utm_url +
          "&documentUrl=" +
          documentUrl +
          "&randomNumber=" +
          randomNumber +
          "&workspace_id=" +
          workspace_id +
          ' " id="zychatObject"></iframe>';
        var eventMethod = window.addEventListener
          ? "addEventListener"
          : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent =
          eventMethod == "attachEvent" ? "onmessage" : "message";
        eventer(
          messageEvent,
          function (e) {
            var key = e.message ? "message" : "data";
            var data = e[key];
            console.log(key);
            if (typeof data == "string") {
              if (data == "open") {
                document
                  .getElementById("zychatObject")
                  .classList.remove("BOT_chatcontainer_Add");
                var receiver =
                  document.getElementById("zychatObject").contentWindow;
                receiver.postMessage("openclose", "*");
              } else if (data == "close") {
                document
                  .getElementById("zychatObject")
                  .classList.add("BOT_chatcontainer_Add");
                var receiver =
                  document.getElementById("zychatObject").contentWindow;
                receiver.postMessage("openclose", "*");
              } else if (data == "Onload") {
                var receiver =
                  document.getElementById("zychatObject").contentWindow;
                receiver.postMessage(chatActivity, "*");
              } else if (data.split("_")[0] == "return") {
                //  try {
                //      parent.dataLayer.push({'event': 'gtm.click', 'gtm.elementId': data.split("_")[1]});
                //      try{
                //          if (typeof gtag !='undefined' &&  typeof gtag === "function") {
                //              if ( data.split("_")[1] == 'ChatEndID' ){gtag('event', 'Zyratalk Completed Chat', {'event_category': 'click', 'event_label': '','value': 0});}
                //          }
                //      }catch(ex){console.log(ex);}
                //  } catch (e) {
                //  }
              } else if (data.split("_")[0] == "iframresize") {
                var css2 =
                  ".BOT_chatcontainer_Add{width: " +
                  data.split("_")[1] +
                  "px !important; height:" +
                  data.split("_")[2] +
                  "px !important;}";
                style.appendChild(document.createTextNode(css2));
              } else if (data == "dynamicCall") {
                try {
                  if (
                    typeof document.querySelectorAll('a[href^="tel:"]') !=
                      "undefined" &&
                    document.querySelectorAll('a[href^="tel:"]').length > 0
                  ) {
                    var dynamicCall = document
                      .querySelectorAll('a[href^="tel:"]')[0]
                      .href.replace("tel:", "");
                  } else {
                    var dynamicCall = "";
                  }
                  var receiver =
                    document.getElementById("zychatObject").contentWindow;
                  receiver.postMessage("dynamicCallReturn#" + dynamicCall, "*");
                } catch (e) {}
              } else if (data.split("_")[0] == "dataattrid") {
                try {
                  if (typeof data.split("_")[1] != "undefined") {
                    var receiver = document
                      .getElementById("zychatObject")
                      .setAttribute("data-chat-id", data.split("_")[1]);
                  }
                } catch (e) {}
              } else if (data == "hideChatOnPage") {
                var myobj = document.getElementById("zychatObject");
                myobj.remove();
              } else if (data.split("_")[0] == "toggleIframSize") {
                let newData = JSON.parse(data.split("_")[1]);
                if (parseInt(newData.width) <= 52) {
                  newData.width = 70;
                  newData.height = 70;
                }
                var css2 =
                  ".BOT_chatcontainer_Add{width: " +
                  newData.width +
                  "px !important; height:" +
                  newData.height +
                  "px !important;}";
                style.appendChild(document.createTextNode(css2));
              }
            }
          },
          false
        );
      }
    }
  };

  editBranch = (branch_id) => {
    api.botbuilder.editBranch({ branch_id: branch_id }).then((data) => {
      try {
        console.log(data);

        this.setState(
          {
            branchDetails: data.branch_details,
            active_branch_id: branch_id,
            activemodal: "",
            editortrue: false,
            checkzipcaptured: data.branch_details.checkzipcaptured,
            //checkedValueID: data.data.branch_details.details.selected_buckets,
            //checkedValueID: data.data.CheckedValArr,
            checkedValue: data.checkedValue,
          },
          function () {
            let scrolltop =
              document.querySelector(".addEditBranch").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  saveAIRules = (data, selected_branch_id, callback) => {
    new Promise((resolve) => {
      data.forEach((element, index) => {
        //delete element[index]['categories];
        delete element["categories"];
        delete element["subcategories"];
        delete element["intents"];
      });
      resolve(data);
    }).then((data) => {
      let params = {
        data: data,
        selected_branch_id: selected_branch_id,
        branch_id: this.state.active_branch_id,
        workspace_id: this.state.workspace_id,
      };
      api.botbuilder.saveAIRules(params).then((data) => {
        try {
          let branch_detail = this.state.branchDetails;
          branch_detail.ai_list = data.list;
          this.setState({ branchDetails: branch_detail });
          callback("success");
        } catch (err) {
          console.log(err);
        }
      });
    });
  };

  updateAIRules = (data, edit_id, callback) => {
    //alert(edit_id)

    new Promise((resolve) => {
      data.forEach((element, index) => {
        //delete element[index]['categories];
        delete element["categories"];
        delete element["subcategories"];
        delete element["intents"];
      });
      resolve(data);
    }).then((data) => {
      let params = {
        data: data,
        branch_id: this.state.active_branch_id,
        workspace_id: this.state.workspace_id,
        edit_id: edit_id,
      };
      api.botbuilder.updateAIRules(params).then((data) => {
        try {
          let branch_detail = this.state.branchDetails;
          branch_detail.ai_list = data.list;
          this.setState({ branchDetails: branch_detail });
          callback("success");
        } catch (err) {
          console.log(err);
        }
      });
    });
  };

  textAreaAction = (data, action, index) => {
    if (action == "delete") {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_questions.splice(index, 1);

      this.setState({ branchDetails: branchDetails }, function () {
        this.modalClose();
      });
    }

    if (action == "textchange") {
      let branchDetails = this.state.branchDetails;

      branchDetails.branch_questions[index].question = data;
      this.setState({ branchDetails: branchDetails }, function () {});
    }

    if (action == "addtitle") {
      let branchDetails = this.state.branchDetails;

      branchDetails.details.branch_name = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {});
    }
  };

  addBranch = () => {
    let params = { workspace_id: this.state.workspace_id };

    this.setState({ loadingaction: true }, function () {
      api.botbuilder.createBranch(params).then((data) => {
        let branch_id = data.data[0].branch_id;
        let branchList = this.state.branchList;
        branchList.push(data.data[0]);

        this.setState(
          {
            branchDetails: data.branch_details,
            active_branch_id: branch_id,
            branchList: branchList,
            loadingaction: false,
            branch_new_id: branch_id,
            toster: {
              color: "green",
              status: true,
              message: "Branch addedd successfully.",
            },
          },
          function () {
            setTimeout(
              function () {
                this.setState({
                  toster: { color: "", status: false, message: "" },
                });
              }.bind(this),
              3000
            );
          }
        );
      });
    });
  };

  createNewBranch = (modal) => {
    let params = { workspace_id: this.state.workspace_id };

    this.setState({ create_modal_status: modal }, function () {});
  };
  saveNewBranch = (data, button, callback) => {
    let params = { workspace_id: this.state.workspace_id, data: data };
    api.botbuilder.addNewBranch(params).then((data) => {
      let branch_id = data.data[0].branch_id;
      let branchList = this.state.branchList;

      branchList.push(data.data[0]);

      if (button == "button") {
        this.setState(
          {
            branchList: branchList,
            button_action_id: "create_branch",
            branch_new_id: branch_id,
          },
          function () {
            this.modalCloseCreate();
            callback("success");
            //   setTimeout(
            //     function () {
            //         this.setState({  toster:{color:"",status:false,message:''} });
            //     }.bind(this),
            //     3000
            // );
          }
        );
      }

      if (button == "skills") {
        this.setState(
          { branchList: branchList, branch_new_id: branch_id },
          function () {
            console.log(this.state.branchList);
            new Promise((resolve) => {
              let airules = this.state.AIRulesData;
              airules.map((obj, index) => {
                if (obj.intent_branch_id == "") {
                  return (obj.intent_branch_id = branch_id);
                }
              });

              resolve(airules);
            }).then((data) => {
              this.modalCloseCreate();
              this.setState({ AIRulesData: data });
              callback("success");
            });
          }
        );
      }

      if (button == "multiplechoice") {
        this.setState(
          { branchList: branchList, branch_new_id: branch_id },
          function () {
            this.modalCloseCreate();
          }
        );
      }

      if (button == "zipcoderules") {
        this.setState(
          {
            branchList: branchList,
            branch_new_id: branch_id,
            zipcodenewbranch_id: branch_id,
          },
          function () {
            this.modalCloseCreate();
            callback("success");
          }
        );
      }
    });
  };

  responseTimeChange = (e) => {
    this.setState({ response_time: e.target.value });
  };

  finalSave = () => {
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm Your Action",
      modalbody:
        "This is your final submission. Please click Yes after that you can preview your ChatBot.",
      modalindex: "finalsave",
    });
  };

  confirmFinalSave = () => {
    let params = {
      workspace_id: this.state.workspace_id,
      response_time: this.state.response_time,
    };
    api.botbuilder.finalSave(params).then((data) => {
      this.modalClose();
      window.location.reload(false);
    });
  };

  saveQuestionResponse = (data, edit_id, callback) => {
    api.botbuilder
      .saveQuestionResponse({
        question: data,
        workspace_id: this.state.workspace_id,
        branch_id: this.state.active_branch_id,
        response_question_id: edit_id,
      })
      .then((data) => {
        let branch_detail = this.state.branchDetails;
        branch_detail.question_response_list = data.question_response_list;
        this.setState({ branch_detail: branch_detail });
        callback("success");
      });
  };

  actionQuestionResponse = (action, params, index) => {
    if (action == "delete") {
      api.botbuilder.deleteQuestionResponse(params).then((data) => {
        let branch_detail = this.state.branchDetails;

        let activeindex = branch_detail.question_response_list.findIndex(
          (list) => list == index
        );

        branch_detail.question_response_list.splice(activeindex, 1);
        this.setState({ branch_detail: branch_detail }, function () {
          // let scrolltop=document.querySelector("#responsetoquestion").offsetTop - document.body.scrollTop;
          // window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
        });
      });
    }
    if (action == "edit") {
      this.setState(
        {
          activemodal: "responsequestion",
          button_action_id: params.question_id,
        },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }
  };

  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };

  saveMultipleChoice = (data, callback) => {
    let params = {
      branch_id: this.state.active_branch_id,
      data: data,
      workspace_id: this.state.workspace_id,
    };
    api.botbuilder.saveMultipleChoice(params).then((data) => {
      //callback('success');
      let branch_detail = this.state.branchDetails;
      branch_detail.multiple_choice_options = data.multiple_choice_options;
      this.setState({ branchDetails: branch_detail }, function () {
        callback("success");
      });
    });
  };
  MultipleChoiceAction = (tab, data) => {
    if (tab == "delete") {
      this.setState({
        modalstatus: true,
        modaltitle: "Confirm Your Action",
        modalbody: "Are you sure want to delete this ?",
        modalindex: "multiplechoicedelete",
      });
    }
  };

  confirmdeleteMultipleChoiceQuestion = () => {
    let params = {
      branch_id: this.state.active_branch_id,
      workspace_id: this.state.workspace_id,
    };
    api.botbuilder.deleteMultipleChoiceQuestionAll(params).then((data) => {
      //callback('success');
      let branch_detail = this.state.branchDetails;
      branch_detail.multiple_choice_options = data.multiple_choice_options;
      this.setState({ branchDetails: branch_detail, modalstatus: false });
    });
  };

  saveBranchLinks = (datainput, callback) => {
    let params = {
      branch_id: this.state.active_branch_id,
      workspace_id: this.state.workspace_id,
      data: datainput,
    };
    api.botbuilder.saveBranchLink(params).then((data) => {
      //callback('success');

      if (data.link_exist.length > 0) {
        callback(data);
      } else {
        let branch_detail = this.state.branchDetails;
        branch_detail.workspace_branch_url = data.workspace_branch_url;
        branch_detail.link_team_memebers = data.link_team_memebers;
        branch_detail.details.is_link_notification =
          datainput.is_link_notification;
        branch_detail.details.is_link_team_profile =
          datainput.is_link_team_profile;
        branch_detail.details.is_link_team_members =
          datainput.is_link_team_members;

        this.setState({ branchDetails: branch_detail }, function () {
          // console.log(this.state.branchDetails);
          callback(data);
        });
      }
    });
  };

  linkAction = (tab, datainput, index) => {
    if (tab == "delete") {
      let params = {
        branch_id: this.state.active_branch_id,
        workspace_id: this.state.workspace_id,
        branch_link_id: datainput.branch_link_id,
      };
      api.botbuilder.deleteBranchLink(params).then((data) => {
        let branch_detail = this.state.branchDetails;
        branch_detail.workspace_branch_url = data.workspace_branch_url;

        this.setState(
          { branchDetails: branch_detail, activemodal: "" },
          function () {
            //console.log(this.state.branchDetails);
          }
        );
      });
    }
  };

  liveChatRules = (action, data, index) => {
    if (action == "show") {
      let val = data.target.checked ? "1" : "0";

      let params = {
        val: val,
        workspace_id: this.state.workspace_id,
        branch_id: this.state.active_branch_id,
      };
      api.botbuilder.updateBranchTeamNotifyMembers(params).then((data) => {
        let branchDetails = this.state.branchDetails;
        branchDetails.details.live_chat_notification = val;
        if (data.status == true && val == 1) {
          this.setState({ livechatshow: true, branchDetails: branchDetails });
        } else {
          this.setState({ livechatshow: false, branchDetails: branchDetails });
          console.log(data.message);
        }
      });
    }
    if (action == "close") {
      this.setState({ livechatshow: false });
    }
    if (action == "view") {
      this.setState({ livechatshow: true });
    }
  };

  loadTestView = (tab) => {
    if (tab == "open") {
      this.setState({ testView: true });
    }
    if (tab == "close") {
      this.setState({ testView: false });
    }
    if (tab == "load") {
      this.setState({ testView: false }, function () {
        this.setState({ testView: true });
      });
    }
  };

  jumpToBranch = (branch_id) => {
    if (typeof branch_id != "undefined") {
      var main = document.querySelector(".box-bg");
      main.scrollTop = 0;
      var element = document.querySelector(
        'div[data-branch="' + branch_id + '"]'
      );
      this.scrollIfNeeded(element, main, () => {
        this.editBranch(branch_id);
      });
    }
  };

  scrollIfNeeded = (element, container, callback) => {
    if (element.offsetTop < container.scrollTop) {
      container.scrollTop = element.offsetTop;
    } else {
      const offsetBottom = element.offsetTop + element.offsetHeight;
      const scrollBottom = container.scrollTop + container.offsetHeight;
      if (offsetBottom > scrollBottom) {
        container.scrollTop = offsetBottom - container.offsetHeight + 200;
      }
    }
    callback();
  };

  updateZipCodeBranchStatus = () => {
    this.setState({ zipcodenewbranch_id: "" });
  };

  updateDeleteZipRule = (type) => {
    this.setState({ checkzipcaptured: type });
  };

  render() {
    return (
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver botbuilderdetailbody"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p botbuilderdetails">
              <div className="row">
                <div className="wsab-flex">
                  <div
                    className="alert workspace-note"
                    className={
                      this.state.alert_message_display == true
                        ? "alert workspace-note"
                        : "workspace-note"
                    }
                    role="alert"
                  >
                    {this.state.alert_message_display == true && (
                      <span>
                        <strong>Note! &nbsp;</strong> To apply changes, please
                        click on the "Save" button to prevent losing any work.
                      </span>
                    )}{" "}
                  </div>
                  <div className="right-btns">
                    <button
                      type="reset"
                      className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                      onClick={() => this.loadTestView("open")}
                    >
                      <span> Click to Test! </span>
                    </button>
                    <button
                      type="submit"
                      className="btn linear-gradient yr-submitbtn"
                      onClick={() => this.finalSave()}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {this.state.loading && (
                    <div className="ui loading form"></div>
                  )}
                  <div>
                    <div className="kt-portlet shadow-none">
                      <div className="kt-portlet__body workspace-automation px-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group row">
                              <div className="col-lg-6 col-md-6">
                                <label className="col-form-label bold mar-top-0 inline-block">
                                  Delay time between responses:
                                </label>

                                <select
                                  className="form-control inline-block"
                                  name="response_time"
                                  value={this.state.response_time}
                                  onChange={(e) => this.responseTimeChange(e)}
                                >
                                  <option value="">Select Time</option>
                                  <option value="3">3 Seconds</option>
                                  <option value="4">4 Seconds</option>
                                  <option value="5">5 Seconds</option>
                                  <option value="10">10 Seconds</option>
                                  <option value="20">20 Seconds</option>
                                  <option value="60">1 Minute</option>
                                  <option value="300">5 Minute</option>
                                </select>
                              </div>
                              {!this.state.loading && (
                                <div className="col-lg-6 col-md-6 text-align-right mob-align-left">
                                  <button
                                    type="button"
                                    className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                                    onClick={(e) =>
                                      this.show_user_iframe(
                                        this.state.userinfo.user_id,
                                        this.state.workspace_id
                                      )
                                    }
                                  >
                                    <span> View user perspective </span>
                                  </button>
                                  {(this.state.userinfo.default_workspace_id !=
                                    "0" &&
                                    this.state.userinfo.default_workspace_id !=
                                      null &&
                                    this.state.userinfo.default_workspace_id ==
                                      this.state.custom_workspace_id &&
                                    this.state.bot_type == "WEB") ||
                                  (this.state.userinfo
                                    .default_sms_workspace_id != "0" &&
                                    this.state.userinfo
                                      .default_sms_workspace_id != null &&
                                    this.state.userinfo
                                      .default_sms_workspace_id ==
                                      this.state.custom_workspace_id &&
                                    this.state.bot_type == "SMS") ||
                                  (this.state.userinfo
                                    .default_fb_workspace_id != "0" &&
                                    this.state.userinfo
                                      .default_fb_workspace_id != null &&
                                    this.state.userinfo
                                      .default_fb_workspace_id ==
                                      this.state.custom_workspace_id &&
                                    this.state.bot_type == "FB") ? (
                                    <button
                                      type="button"
                                      className="btn linear-gradient yr-submitbtn"
                                      onClick={(e) => this.onDeploy("Open")}
                                    >
                                      Remove
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn linear-gradient yr-submitbtn"
                                      onClick={(e) => this.onDeploy("Open")}
                                    >
                                      Deploy
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.dialogDeploy
                              ? "modal fade show"
                              : "modal fade"
                          }
                          data-backdrop="static"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="staticBackdrop"
                          aria-hidden="true"
                          style={{
                            display: this.state.dialogDeploy ? "block" : "none",
                          }}
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                            style={{ maxWidth: 600 }}
                          >
                            {this.state.dialogDeployLoading && (
                              <div className="ui loading form"></div>
                            )}
                            <div className="modal-content">
                              <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.onDeploy("Close")}
                              >
                                X
                              </button>
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  {this.state.dialogDeployTitle != ""
                                    ? this.state.dialogDeployTitle
                                    : "Confirm your action"}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <div className="px-4">
                                  {this.state.dialogDeployMessage && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.dialogDeployMessage,
                                      }}
                                    ></p>
                                  )}
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                                  onClick={() => this.onDeploy("Close")}
                                  data-dismiss="modal"
                                >
                                  <span>
                                    {this.state.deployAction != ""
                                      ? "No"
                                      : "Close"}
                                  </span>
                                </button>
                                {this.state.deployAction != "" && (
                                  <button
                                    type="button"
                                    className="btn linear-gradient yr-submitbtn btn-sm"
                                    onClick={() =>
                                      this.onDeploy(this.state.deployAction)
                                    }
                                  >
                                    Yes
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!this.state.loading && (
                          <div className="row">
                            <div className="col-lg-3 branchlistbuilder">
                              <BranchList
                                indextime={new Date()}
                                branchList={this.state.branchList}
                                branch_new_id={this.state.branch_new_id}
                                updateList={this.updateList}
                                editBranch={this.editBranch}
                                addBranch={this.addBranch}
                                review_links={this.state.review_links}
                              />
                            </div>
                            <div
                              className="col-lg-5 workSpaceAddField yr-chat-box-row "
                              style={{ display: "block" }}
                            >
                              <BranchDetail
                                openTab={this.openTab}
                                branchDetails={this.state.branchDetails}
                                captured_list={this.state.captured_list}
                                saveBranch={this.saveBranch}
                                buttonAction={this.buttonAction}
                                skillsAction={this.skillsAction}
                                button_action_id={this.state.button_action_id}
                                saveButton={this.saveButton}
                                actionTeamMembers={this.actionTeamMembers}
                                textAreaAction={this.textAreaAction}
                                loadingaction={this.state.loadingaction}
                                toster={this.state.toster}
                                bot_type={this.state.bot_type}
                                workspace_id={this.state.workspace_id}
                                branchList={this.state.branchList}
                                actionQuestionResponse={
                                  this.actionQuestionResponse
                                }
                                countryCodeLists={this.state.countryCodeLists}
                                defaultCountryCodeLists={
                                  this.state.defaultCountryCodeLists
                                }
                                MultipleChoiceAction={this.MultipleChoiceAction}
                                linkAction={this.linkAction}
                                liveChatRules={this.liveChatRules}
                                indextime={new Date()}
                                editortrue={this.state.editortrue}
                                checkzipcaptured={this.state.checkzipcaptured}
                                updateDeleteZipRule={this.updateDeleteZipRule}
                                popupClose={this.popupClose}
                                indextime={new Date()}
                                review_links={this.state.review_links}
                                contact_bucket={this.state.contact_bucket}
                                checkedValue={this.state.checkedValue}
                                checkedValueID = {this.state.checkedValueID}
                                
                              />
                            </div>
                            <div className="col-lg-4 rightbotbuilder">
                              {this.state.activemodal == "button" && (
                                <AddButton
                                  saveButton={this.saveButton}
                                  popupClose={this.popupClose}
                                  branchList={this.state.branchList}
                                  countryCodeLists={this.state.countryCodeLists}
                                  defaultCountryCodeLists={
                                    this.state.defaultCountryCodeLists
                                  }
                                  location_list={this.state.location_list}
                                  buttondata={
                                    this.state.branchDetails.branch_buttons
                                  }
                                  button_action_id={this.state.button_action_id}
                                  createNewBranch={this.createNewBranch}
                                  branch_new_id={this.state.branch_new_id}
                                  review_links={this.state.review_links}
                                />
                              )}

                              {this.state.activemodal == "skills" && (
                                <AddSkills
                                  saveButton={this.saveButton}
                                  popupClose={this.popupClose}
                                  branchList={this.state.branchList}
                                  aiRules={this.state.AIRulesData}
                                  button_action_id={this.state.button_action_id}
                                  intent_data={this.state.intent_data}
                                  saveAIRules={this.saveAIRules}
                                  aiRulesList={this.state.branchDetails.ai_list}
                                  updateAIRules={this.updateAIRules}
                                  createNewBranch={this.createNewBranch}
                                  branch_new_id={this.state.branch_new_id}
                                  indextime={new Date()}
                                />
                              )}
                              {this.state.activemodal == "webhook_response" && (
                                <AddWebhook
                                  saveButton={this.saveButton}
                                  popupClose={this.popupClose}
                                />
                              )}

                              {this.state.activemodal == "responsequestion" && (
                                <QuestionResponse
                                  saveButton={this.saveButton}
                                  popupClose={this.popupClose}
                                  questionResponsAction={
                                    this.questionResponsAction
                                  }
                                  button_action_id={this.state.button_action_id}
                                  saveQuestionResponse={
                                    this.saveQuestionResponse
                                  }
                                  branchDetails={this.state.branchDetails}
                                />
                              )}

                              {this.state.activemodal == "links" && (
                                <AddLinks
                                  popupClose={this.popupClose}
                                  indextime={new Date()}
                                  branchDetails={this.state.branchDetails}
                                  countryCodeLists={this.state.countryCodeLists}
                                  defaultCountryCodeLists={
                                    this.state.defaultCountryCodeLists
                                  }
                                  saveBranchLinks={this.saveBranchLinks}
                                  workspace_id={this.state.workspace_id}
                                  branch_id={this.state.active_branch_id}
                                />
                              )}

                              {this.state.activemodal == "multiplechoice" && (
                                <MultipleChoice
                                  saveMultipleChoice={this.saveMultipleChoice}
                                  indextime={new Date()}
                                  popupClose={this.popupClose}
                                  branchDetails={this.state.branchDetails}
                                  branchList={this.state.branchList}
                                  createNewBranch={this.createNewBranch}
                                  branch_new_id={this.state.branch_new_id}
                                  create_modal_status={
                                    this.state.create_modal_status
                                  }
                                />
                              )}

                              {this.state.activemodal == "zipcoderules" && (
                                <ZipCodeRules
                                  indextime={new Date()}
                                  popupClose={this.popupClose}
                                  branchDetails={this.state.branchDetails}
                                  branchList={this.state.branchList}
                                  createNewBranch={this.createNewBranch}
                                  branch_new_id={this.state.branch_new_id}
                                  branch_id={this.state.active_branch_id}
                                  workspace_id={this.state.workspace_id}
                                  create_modal_status={
                                    this.state.create_modal_status
                                  }
                                  zipcodenewbranch_id={
                                    this.state.zipcodenewbranch_id
                                  }
                                  updateZipCodeBranchStatus={
                                    this.updateZipCodeBranchStatus
                                  }
                                  updateDeleteZipRule={this.updateDeleteZipRule}
                                />
                              )}

                              {this.state.activemodal == "transcriptrule" && (
                                <TranscriptRule
                                  indextime={new Date()}
                                  popupClose={this.popupClose}
                                  branchDetails={this.state.branchDetails}
                                  branchList={this.state.branchList}
                                  saveButton={this.saveButton}
                                  branch_new_id={this.state.branch_new_id}
                                  branch_id={this.state.active_branch_id}
                                />
                              )}

                              <Modal
                                show={this.state.modalstatus}
                                footer={this.modalFooter(this.state.modalindex)}
                                onclose={this.modalClose}
                                title={this.state.modaltitle}
                                body={this.state.modalbody}
                              />

                              <CreateBranch
                                show={this.state.create_modal_status}
                                onclose={this.modalCloseCreate}
                                saveNewBranch={this.saveNewBranch}
                              ></CreateBranch>

                              {this.state.livechatshow && (
                                <LiveChatRule
                                  livechatshow={this.state.livechatshow}
                                  liveChatRules={this.liveChatRules}
                                  indextime={new Date()}
                                  branch_id={this.state.active_branch_id}
                                  workspace_id={this.state.workspace_id}
                                  branchDetails={this.state.branchDetails}
                                  countryCodeLists={this.state.countryCodeLists}
                                  defaultCountryCodeLists={
                                    this.state.defaultCountryCodeLists
                                  }
                                />
                              )}
                              {this.state.testView && (
                                <TestView
                                  loadTestView={this.loadTestView}
                                  workspace_id={this.state.workspace_id}
                                  jumpToBranch={this.jumpToBranch}
                                  review_links={this.state.review_links}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(BotBuilderDetail);
