import React from 'react'
import '../../Automation/Automation.css';
import CustomErrorField from "../../../../Form/CustomErrorField";

import { Tab } from 'semantic-ui-react'
const selectedTabValue = ['Branch', 'URL', 'PhoneCall', 'SMS', 'REVIEW'];
class AddButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            buttontitle: '',
            button_respond_on: '',
            button_respond_on_branch: '',
            button_respond_on_url: '',
            button_respond_on_phonecall: '',
            button_respond_on_sms: '',
            button_respond_on_phonecall_country_code: '',
            button_respond_on_sms_country_code: '',
            button_phone_call_use_phone_var: 0,
            button_phone_call_use_dynamic_call: 0,
            button_phone_call_use_input_method: 0,
            button_respond_on_review: '',
            button_review_location: '',
            branchList: [],
            activeIndex: 0,
            selectedTab: 'Branch',
            errors: [],
            button_action_id:''



        };

    }


    

      componentDidMount() {

        this.setState({ branchList: this.props.branchList })
        const county_list = this.props.countryCodeLists;
        const default_list = this.props.defaultCountryCodeLists;
        
        this.onLoad();
    }
    
      componentDidUpdate(prevProps) {
        //console.log(this.props.button_action_id+"============"+prevProps.button_action_id)
      
        if(this.props.button_action_id!=prevProps.button_action_id){
            
           this.onLoad();
        }
        // if(this.props.button_action_id=='0'){
        //     this.onLoad();
        // }
        //this.onLoad();
      }

      onLoad=()=>{
        // let scrolltop=document.querySelector('.addbutton').offsetTop - document.body.scrollTop;
        // window.scroll({top: document.querySelector('.addbutton').offsetTop, left: 0, behavior: 'smooth' });
         if(this.props.button_action_id=='add'){
                this.setState({
                    buttontitle: '',
                    button_respond_on: 'Branch',
                    button_respond_on_branch: '',
                    button_respond_on_url: '',
                    button_respond_on_phonecall: '',
                    button_respond_on_sms: '',
                    button_respond_on_phonecall_country_code: '',
                    button_respond_on_sms_country_code: '',
                    button_phone_call_use_phone_var: 0,
                    button_phone_call_use_dynamic_call: 0,
                    button_phone_call_use_input_method: 0,
                    button_respond_on_review: '',
                    button_review_location: '',
                    activeIndex: 0,
                    selectedTab: 'Branch',
                })
         }else if(this.props.button_action_id=='create_branch'){
             this.setState({button_respond_on_branch:this.props.branch_new_id})
         }
         else
          {
            
              let buttoninfo=this.props.buttondata[this.props.button_action_id];
              
              let activeindex = selectedTabValue.findIndex(
                (list) => list === buttoninfo.button_respond_on
              );
              
              this.setState({
                buttontitle: buttoninfo.button_title,
                button_respond_on:selectedTabValue[activeindex],
                button_respond_on_branch: buttoninfo.button_respond_on_branch,
                button_respond_on_url: buttoninfo.button_respond_on_url,
                button_respond_on_phonecall: buttoninfo.button_respond_on_phonecall,
                button_respond_on_sms: buttoninfo.button_respond_on_sms,
                button_respond_on_phonecall_country_code: buttoninfo.button_respond_on_phonecall_country_code,
                button_respond_on_sms_country_code: buttoninfo.button_respond_on_phonecall_country_code,
                button_phone_call_use_phone_var: buttoninfo.button_phone_call_use_phone_var,
                button_phone_call_use_dynamic_call: buttoninfo.button_phone_call_use_dynamic_call,
                button_phone_call_use_input_method: buttoninfo.button_phone_call_use_input_method,
                button_respond_on_review: buttoninfo.button_respond_on_review,
                button_review_location: buttoninfo.button_review_location,
                selectedTab: selectedTabValue[activeindex],
                activeIndex: activeindex,
                
              })
          }
          
      }   

    saveButton = (type_id) => {
        //let data={test:"hllo"};
       // alert(this.state.activeIndex)

        const errors = {};

        if (this.state.buttontitle == '') {

            errors['buttontitle'] = "This filed is required.";
        }
        
        if (this.state.selectedTab == 'Branch' && this.state.button_respond_on_branch == '') {
            errors['Branch'] = "This filed is required.";
        }

        if (this.state.selectedTab == 'URL' && this.state.button_respond_on_url == '') {
            errors['URL'] = "This filed is required.";
        }

        if (this.state.selectedTab == 'PhoneCall') {

            if ((this.state.button_phone_call_use_phone_var == 0 || this.state.button_phone_call_use_phone_var == '')
                && (this.state.button_phone_call_use_dynamic_call == 0 || this.state.button_phone_call_use_dynamic_call == '')
                && (this.state.button_phone_call_use_input_method == 0 || this.state.button_phone_call_use_input_method == '')
            ) {
                errors['PhoneCall'] = "Please select one option.";
            } else {

                if (this.state.button_respond_on_phonecall.trim() == '' && this.state.button_phone_call_use_input_method==1) {
                    errors['PhoneCallNumber'] = "This filed is required.";
                }

                if (this.state.button_respond_on_phonecall.trim() != '' && this.state.button_phone_call_use_input_method==1 && this.state.button_respond_on_phonecall.match(/\d/g).length != 10) {
                    errors['PhoneCallNumber'] = "Please Enter Valid Number.";
                }
            }

        }

        if (this.state.selectedTab == 'SMS' && this.state.button_respond_on_sms == '') {
            errors['SMS'] = "This filed is required.";
        }

        if (this.state.selectedTab == 'SMS' && this.state.button_respond_on_sms.trim() != '' && this.state.button_respond_on_sms.match(/\d/g).length != 10) {
            errors['SMS'] = "Please Enter Valid Number.";
        }



        if (this.state.selectedTab == 'REVIEW') {

            if ((this.state.button_respond_on_review == 0 || this.state.button_respond_on_review == '')

            ) {
                errors['REVIEW'] = "Please select one option.";
            } else {


                if (this.state.button_respond_on_review.trim() == 2) {

                    if (this.state.button_review_location.trim() == '') {
                       
                        errors['REVIEWERR'] = "This filed is required.";
                    }

                }


            }

        }


      

        this.setState({ errors: errors });
        if (Object.keys(errors).length == 0) {
            let data={
                button_title:this.state.buttontitle,
                button_respond_on:this.state.button_respond_on,
                button_respond_on_branch:this.state.button_respond_on_branch,
                button_respond_on_url:this.state.button_respond_on_url,
                button_respond_on_phonecall:this.state.button_respond_on_phonecall,
                button_respond_on_sms:this.state.button_respond_on_sms,
                button_respond_on_phonecall_country_code:this.state.button_respond_on_phonecall_country_code,
                button_phone_call_use_phone_var:this.state.button_phone_call_use_phone_var,
                button_phone_call_use_dynamic_call:this.state.button_phone_call_use_dynamic_call,
                button_phone_call_use_input_method:this.state.button_phone_call_use_input_method,
                button_respond_on_review:this.state.button_respond_on_review,
                button_review_location:this.state.button_review_location,
                
            }
            
            this.props.saveButton(data,type_id);
        }


    }
    
    onchange = (e) => {
        this.setState({ buttontitle: e.target.value });
    }
    changeBranch = (e) => {
        if (this.state.selectedTab == 'Branch') {
            this.setState({ button_respond_on_branch: e.target.value });
        }
    }

    onChangeUrlTab = (e) => {
        if (this.state.selectedTab == 'URL') {
            this.setState({ button_respond_on_url: e.target.value });
        }
    }

    onSelectPhoneVar = (e, value) => {

        if (this.state.selectedTab == 'PhoneCall') {
            let button_phone_call_use_phone_var = '';
            let button_phone_call_use_dynamic_call = '';
            let button_phone_call_use_input_method = '';
            //    button_phone_call_use_input_method:'',
            if (value == 'button_phone_call_use_phone_var') {
                button_phone_call_use_phone_var = 1;
                button_phone_call_use_dynamic_call = 0;
                button_phone_call_use_input_method = 0;
            }
            if (value == 'button_phone_call_use_dynamic_call') {
                button_phone_call_use_phone_var = 0;
                button_phone_call_use_dynamic_call = 1;
                button_phone_call_use_input_method = 0;
            }
            if (value == 'button_phone_call_use_input_method') {
                button_phone_call_use_phone_var = 0;
                button_phone_call_use_dynamic_call = 0;
                button_phone_call_use_input_method = 1;
            }
            this.setState({
                button_phone_call_use_phone_var: button_phone_call_use_phone_var,
                button_phone_call_use_dynamic_call: button_phone_call_use_dynamic_call,
                button_phone_call_use_input_method: button_phone_call_use_input_method
            },function(){
               
            })
        }
    }

    onTabChange = (e, data) => {
       
        this.setState({
            selectedTab: selectedTabValue[data.activeIndex],
            activeIndex: data.activeIndex,
            button_respond_on: selectedTabValue[data.activeIndex],

        });
    }


    onInputChange = (e) => {
       
       
        let inputValue;
        if (e.target.name == 'button_respond_on_phonecall' || e.target.name == 'button_respond_on_sms'){
            inputValue = this.phoneFormat(e.target.value);
            
        }
            
        else
            inputValue = e.target.value;
        if(e.target.name == 'button_respond_on_review'){
           
            
            this.setState({ [e.target.name]: inputValue,button_review_location:'' });
        } else{
            this.setState({ [e.target.name]: inputValue });
        }   
       

        // let errList = this.state.errors;
        // delete errList[e.target.name];
        // this.setState({ errors: errList });
    }



    phoneFormat = (p) => {
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;
            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };



    render() {
        const { errors } = this.state;

     

        const panes = [

            {
                menuItem: 'Branch',
                render: () => <Tab.Pane attached={false} >
                    <div className="form-group">
                        <label>Go to branch</label>
                        <select className={
                            errors && errors['Branch']
                                ? "form-control is-invalid"
                                : "form-control"
                        } onChange={(e) => this.changeBranch(e)} value={this.state.button_respond_on_branch}>
                            <option value="">Select Branch</option>
                            {this.state.branchList && this.state.branchList.map((value, index) => {
                                return (
                                    <option value={value.branch_id}>{value.branch_name}</option>
                                )
                            })}
                        </select>
                        {errors && errors.hasOwnProperty('Branch') && (
                            <CustomErrorField
                                message={errors['Branch']}
                                className="error invalid-feedback"
                            />
                        )}
                        <a nohref="" class="addTextArea cursorpointer" onClick={()=>this.props.createNewBranch('button')}><br />
                            <i class="la la-plus"></i> Create new branch</a>
                    </div>
                </Tab.Pane>,
            },

            {
                menuItem: 'URL',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Open URL</label>
                        <input type="text" className={
                            errors && errors['URL']
                                ? "form-control is-invalid"
                                : "form-control"
                        } defaultValue={this.state.button_respond_on_url} placeholder="Add URL" onChange={(e) => this.onChangeUrlTab(e)} />
                        {errors && errors.hasOwnProperty('URL') && (
                            <CustomErrorField
                                message={errors['URL']}
                                className="error invalid-feedback"
                            />
                        )}
                    </div>
                </Tab.Pane>,
            },
            {
                menuItem: 'Phonecall',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Add Contact</label>
                        <div className="row">
                            <div className="col-md-7 col-xs-10"> <div> Use PHONE_NUMBER variable </div></div>
                            <div className="col-md-5 col-xs-2">

                                <input type="radio" className="phone_var" name="button_phone_call_use_input_method" phone_tex_val="(955)465-254" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_phone_var')} checked={this.state.button_phone_call_use_phone_var>0 ? true :false} />
                                <span></span>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-7 col-xs-10"> <div> Use dynamic call </div></div>
                            <div className="col-md-5 col-xs-2">

                                <input type="radio" className="phone_var" name="button_phone_call_use_input_method" phone_tex_val="" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_dynamic_call')} checked={this.state.button_phone_call_use_dynamic_call>0 ? true :false}/>
                                <span></span>

                            </div>
                        </div>

                        <div className="row marginbt5">
                            <div className="col-md-7 col-xs-10"> <div> Enter manual phone number </div></div>
                            <div className="col-md-5 col-xs-2">

                                <input type="radio" className="phone_var" id="button_phone_call_use_input_method" name="button_phone_call_use_input_method" phone_tex_val="" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_input_method')} checked={this.state.button_phone_call_use_input_method>0 ? true :false}/>
                                <span></span>

                            </div>
                        </div>
                        <div className={
                            errors && errors['PhoneCall']
                                ? "form-control is-invalid"
                                : "form-control"
                        } style={{ display: "none" }}></div>
                        {errors && errors.hasOwnProperty('PhoneCall') && (
                            <CustomErrorField
                                message={errors['PhoneCall']}
                                className="error invalid-feedback"
                            />
                        )}
                        {this.state.button_phone_call_use_input_method > 0 &&
                            <div className="select_simple_call" style={{ display: "block" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                <select name="button_respond_on_phonecall_country_code" style={{ float: "left", width: "114px;" }} value={this.state.button_respond_on_phonecall_country_code} className="form-control" onChange={(e) => { this.onInputChange(e) }}>

                                    {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                        return (
                                            <option value={value.country_code} >{value.country_name}</option>

                                        )
                                    })}

                                    <optgroup label="Other countries">

                                        {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                            return (
                                                <option value={value.country_code}  >{value.country_name}</option>

                                            )
                                        })} </optgroup>
                                </select>
                                </div>
                                <div className="col-md-6">
                                <input className={
                                    errors && errors['PhoneCallNumber']
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } autocomplete="off" type="text" id="phonecall" name="button_respond_on_phonecall" placeholder="Add Call No." value={this.state.button_respond_on_phonecall} onChange={(e) => this.onInputChange(e)} />
                                {errors && errors.hasOwnProperty('PhoneCallNumber') && (
                                    <CustomErrorField
                                        message={errors['PhoneCallNumber']}
                                        className="error invalid-feedback"
                                    />
                                )}
                                </div>
                                </div>
                            </div>
                        }



                    </div>
                </Tab.Pane>,
            },
            {
                menuItem: 'SMS',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Add Contact</label>
                        <div className="row">
                            <div className="col-md-6">
                        <select name="button_respond_on_sms_country_code" id="isd_code_for_phone_call"  value={this.state.button_respond_on_sms_country_code} style={{ float: "left", width: "114px;" }} className="form-control" onChange={(e) => { this.onInputChange(e) }}>
                            {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                return (
                                    <option value={value.country_code}>{value.country_name}</option>

                                )
                            })}

                            <optgroup label="Other countries">

                                {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                    return (
                                        <option value={value.country_code}>{value.country_name}</option>

                                    )
                                })} </optgroup>
                        </select>
                        </div>
                        <div className="col-md-6">
                        <input type="text" className={
                            errors && errors['SMS']
                                ? "form-control is-invalid"
                                : "form-control"
                        } name="button_respond_on_sms" placeholder="Phone Number" onChange={(e) => this.onInputChange(e)} value={this.state.button_respond_on_sms}/>
                        {errors && errors.hasOwnProperty('SMS') && (
                            <CustomErrorField
                                message={errors['SMS']}
                                className="error invalid-feedback"
                            />
                        )}
                        </div>
 </div>
                    </div>
                </Tab.Pane>,
            },

            {
                menuItem: 'Review Capture',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group mt-10">
                        <div className="row">

                            <div className="col-md-12 col-xs-12">
                                <input type="radio" className="review_capture" id="review_type_first" name="button_respond_on_review" value="1" onChange={(e) => this.onInputChange(e)}  checked={this.state.button_respond_on_review==1 ? true:false}/>
                                <span className="review_tb_content marginbt5"> This button will use the review functionality from the review tab.</span>


                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-12 col-xs-12">
                                <input type="radio" className="review_capture marginbt5" id="review_type_second" name="button_respond_on_review" value="2" onChange={(e) => this.onInputChange(e)} checked={this.state.button_respond_on_review==2 ? true:false}/>
                                <div className={
                                    errors && errors['REVIEW']
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } style={{ display: "none" }}></div>
                                {errors && errors.hasOwnProperty('REVIEW') && (
                                    <CustomErrorField
                                        message={errors['REVIEW']}
                                        className="error invalid-feedback"
                                    />
                                )}
                                <span className="review_tb_content marginbt5" > Skip the location question and capture leads for:</span>
                                <select className={
                                    errors && errors['REVIEWERR']
                                        ? "form-control is-invalid mt-10 reviewselect"
                                        : "form-control mt-10 reviewselect"
                                } name="button_review_location" id="reviewbutton_review_location_location_button" value={this.state.button_review_location} style={{ marginLeft: "10px", display: "block" }} onChange={(e) => this.onInputChange(e)}>
                                    <option value="">Select Location</option>
                                    {this.props.location_list && this.props.location_list.map((value, index) => {
                                        return (
                                            <option value={value.id}>{value.location}</option>
                                        )
                                    })}
                                </select>
                                {errors && errors.hasOwnProperty('REVIEWERR') && (
                                    <CustomErrorField
                                        message={errors['REVIEWERR']}
                                        className="error invalid-feedback"
                                    />
                                )}

                            </div>

                        </div>

                    </div>
                </Tab.Pane>,
            },


        ]

      if(this.props.review_links.length==0){
         panes.pop();
      }


        return (
            <div className="yr-max-height addbutton" style={{ overflow: "visible", position: "relative" }}>
                <div className="yr-chat-round-box yr-closebtn yr-automation-uodate">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                   
                    <div className="yr-addButtonBox">
                        <h4>Add New Button</h4>
                        <div className="form-group focusError">
                            <label>Button Name</label>
                            <div>
                                <input type="text"
                                    name='buttontitle'
                                    value={this.state.buttontitle}
                                    onChange={(e) => this.onchange(e)} placeholder="Button Title"
                                    className={
                                        errors && errors['buttontitle']
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }

                                />
                                {errors && errors.hasOwnProperty('buttontitle') && (
                                    <CustomErrorField
                                        message={errors['buttontitle']}
                                        className="error invalid-feedback"
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                    <div style={{ display: 'block' }} className="addButtonDiv">
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.state.activeIndex} onTabChange={this.onTabChange} />
                    </div>

                    

                </div>
                <div className="text-right form-group">
                        <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()} ><span> Cancel </span></button>
                        <button type="submit" className="btn linear-gradient yr-submitbtn" onClick={() => this.saveButton(this.props.button_action_id)}>Save</button>
                    </div>

            </div>
        )
    }
}

export default AddButton



