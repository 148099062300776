import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export const DatatableRow = (props) => (
  <Table.Row>
    <Table.Cell>
        {<label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  cid={props.list.cid} onChange={()=>props.channgeEvent(props.list.cid)}></input>&nbsp;<span></span></label>}
       </Table.Cell>
    <Table.Cell style={{ whiteSpace: "nowrap" }}>
      <a
        id={props.list.cid}
        className="btn btn-icon"
        onClick={() => props.viewContactDetail(props.list.cid)}
        title="View"
      >
        <i className="la la-eye blue"></i>
      </a>
      <a
        id={props.list.cid}
        className="btn btn-icon"
        onClick={() => props.editContact(props.list.cid)}
        title="Edit"
      >
        <i className="la la-edit blue"></i>
      </a>
      <a
        onClick={() => props.deleteContact(props.list.cid)}
        title="Delete"
        className="btn btn-icon gray"
      >
        <i className="la la-trash red"></i>
      </a>
    </Table.Cell>
    <Table.Cell>
    <span title={props.list.email}>{props.list.email}</span>
    </Table.Cell>
    {/* <Table.Cell>{props.list.phone}</Table.Cell> */}
    <Table.Cell style={{textAlign:"center"}}>
    {(props.list.toolTipText)?<ReactTooltip 
            place={"top"}
            type={"dark"}
            effect={"float"}
           multiline={true}
             />:""}
      {(props.list.subscribedText == 'Unsubscribed' )?
      <span style={{backgroundColor:"#ef6767"}} data-tip={props.list.toolTipText} class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill">
        
        {props.list.subscribedText}

        
        
      </span>
      :<span   class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill">
        {props.list.subscribedText}
    </span>
    
    }
    
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell>{props.list.source}</Table.Cell>
    
  </Table.Row>
  
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
