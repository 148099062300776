import React from "react";
import {
  toggleBreadcrum,
  setPageTitle,

} from "../../store/Page";

import { connect } from "react-redux";

import Widgets from "./Widgets";
import Facebook from "./Facebook";
import SalesForce from "./Salesforce";
import Webhook from "./Webhook";
import Keep from "./Keep";
import MarketSharp from "./MarketSharp";
import Zapier from "./Zapier";
import Instructions from "./Instructions";
import ActCrm from "./ActCrm";
import './ActCrm.css';

class InstallationAndIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: "widget",
    };
    this.props.toggleBreadcrum({ toggle: false });
    this.props.setPageTitle({
      title: "Installation And Integration",
      subHeaderTitle: "Conversations",
      navigationName: null,
    });

  }

  componentDidMount() { }
  handleClick = (selectedMenu) => this.setState({ activeMenu: selectedMenu });
  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <div className="kt-portlet shadow-none">
                    <div className="kt-portlet__body yr-pd0-lr yr-mt-20 yr-pd0-mob">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="box-title mx-lg-3">
                            Installation and Integration
                          </div>
                          <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4">
                            <ul className="nav nav-tabs tabs-left-nav">
                              <li
                                onClick={(param) => this.handleClick("widget")}
                                className={
                                  this.state.activeMenu === "widget"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  href="#a"
                                  data-toggle="tab"
                                  className="active"
                                >
                                  <span className="yr-icon-install icon-mr" />
                                  Install onto your website
                                </a>
                              </li>
                              <li
                                onClick={(param) =>
                                  this.handleClick("facebook")
                                }
                                className={
                                  this.state.activeMenu === "facebook"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#b" data-toggle="tab">
                                  <span className="yr-icon-facebook icon-mr" />
                                  Facebook Messenger Integration
                                </a>
                              </li>
                              {/* <li
                                onClick={(param) => this.handleClick("sms")}
                                className={
                                  this.state.activeMenu === "sms"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#c" data-toggle="tab">
                                  <span className="yr-icon-sms-texting icon-mr" />
                                  Sms Texting Integration
                                </a>
                              </li> */}
                              <li
                                onClick={(param) =>
                                  this.handleClick("salesforce")
                                }
                                className={
                                  this.state.activeMenu === "salesforce"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#d" data-toggle="tab">
                                  <span className="yr-icon-sales-force icon-mr" />
                                  Salesforce Integration
                                </a>
                              </li>
                              <li
                                onClick={(param) => this.handleClick("actcrm")}
                                className={
                                  this.state.activeMenu === "actcrm"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#actcrm" data-toggle="tab">
                                  <span className="yr-icon-actcrm icon-mr" />
                                  Act! Integration
                                </a>
                              </li>
                              <li
                                onClick={(param) => this.handleClick("keep")}
                                className={
                                  this.state.activeMenu === "keep"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#e" data-toggle="tab">
                                  <span className="yr-icon-keap icon-mr" />
                                  Keap Integration
                                </a>
                              </li>
                              <li
                                onClick={(param) =>
                                  this.handleClick("marketsharp")
                                }
                                className={
                                  this.state.activeMenu === "marketsharp"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  href="#marketsharp-integration"
                                  data-toggle="tab"
                                >
                                  <span class="yr-icon-marketsharp icon-mr"></span>
                                  Marketsharp Integration
                                </a>
                              </li>

                              <li
                                onClick={(param) => this.handleClick("zapier")}
                                className={
                                  this.state.activeMenu === "zapier"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#f" data-toggle="tab">
                                  <span className="yr-icon-zapier icon-mr" />
                                  Zapier Integration
                                </a>
                              </li>
                              <li
                                onClick={(param) => this.handleClick("webhook")}
                                className={
                                  this.state.activeMenu === "webhook"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#g" data-toggle="tab">
                                  <span className="yr-icon-webhook icon-mr" />
                                  Webhook Integration
                                </a>
                              </li>
                              <li
                                onClick={(param) =>
                                  this.handleClick("analytic")
                                }
                                className={
                                  this.state.activeMenu === "analytic"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#h" data-toggle="tab">
                                  <span className="yr-icon-tag-m-ga icon-mr" />
                                  Google Analytics / Tag manager Setup
                                </a>
                              </li>
                              <li
                                onClick={(param) =>
                                  this.handleClick("instructions")
                                }
                                className={
                                  this.state.activeMenu === "instructions"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a href="#i" data-toggle="tab">
                                  <span className="yr-icon-api-instructions icon-mr" />
                                  API Instructions
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="box-title mx-lg-3">
                            Let's get you up and running
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="a">
                              <Widgets user={this.props.user} />
                            </div>
                            <div className="tab-pane" id="b">
                              <div className="yr-chat-round-box">
                                <Facebook user={this.props.user} />
                              </div>
                            </div>
                            {/* <div className="tab-pane" id="c">
                              <div className="yr-chat-round-box">
                                Sms Texting Integration
                              </div>
                            </div> */}
                            <div className="tab-pane" id="d">
                              <div className="yr-chat-round-box">
                                <SalesForce />
                              </div>
                            </div>
                            <div className="tab-pane" id="e">
                              <div className="yr-chat-round-box">
                                <Keep />
                              </div>
                            </div>
                            <div
                              className="tab-pane"
                              id="marketsharp-integration"
                            >
                              <div className="yr-chat-round-box">
                                <MarketSharp />
                              </div>
                            </div>

                            <div className="tab-pane" id="f">
                              <div className="yr-chat-round-box">
                                <Zapier />
                              </div>
                            </div>
                            <div className="tab-pane" id="actcrm">
                              <div className="yr-chat-round-box">
                                <ActCrm />
                              </div>
                            </div>
                            <div className="tab-pane" id="g">
                              <div className="yr-chat-round-box">
                                <Webhook />
                              </div>
                            </div>
                            <div className="tab-pane" id="h">
                              <div className="yr-chat-round-box">
                                Google Analytics / Tag manager Setup
                              </div>
                            </div>
                            <div className="tab-pane" id="i">

                              <Instructions />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  toggleBreadcrum,
  setPageTitle,

})(InstallationAndIntegration);
